import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Flex, Form, Select, Col, Input, Row } from "antd";
import { useSelector } from "react-redux";
import { MdOutlineErrorOutline } from "react-icons/md";
import { getOneFieldStatus } from "../../../../../redux/fieldStatus/FieldStatusSlice";
const { Option } = Select;
const FootwearSupercat = (
    {
        subcategoryid,
        categoryid,
        supercategoryid,
        categoryTagid,
        buttonPress,
        getPropDetails,
        resetield,
    }
) => {
    const [distinctfield, setdistinctfield] = useState([]);
    const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);
    const dispatch = useDispatch();





    // resetield
    useEffect(() => {
        if (resetield === true) {
        }
    }, [resetield]);


    useEffect(() => {
        if (buttonPress === true) {
            console.log(distinctfieldValidate, "distinctfieldValidate");

            const finalarray = distinctfieldValidate.filter((data) =>
                data.field !== "occasion" && data.field !== "brandd" && data.field !== "type_of_formal")

            if (finalarray.every((field) => field.value !== "")) {
                let finalarray = [];
                for (let index = 0; index < distinctfieldValidate.length; index++) {
                    let element = distinctfieldValidate[index];
                    let obj = { [element.field]: element.value };
                    finalarray = [...finalarray, obj];
                }
                finalarray = finalarray;

                const singleObject = finalarray.reduce((acc, obj) => {
                    return { ...acc, ...obj };
                }, {});

                const newForm = {
                    ...singleObject,
                    occasion: "abc"
                }
                getPropDetails(newForm, true);
            } else {
            }
        }
    }, [buttonPress, distinctfieldValidate]);

    useEffect(() => {

        if (categoryTagid) {
            const getdata = async () => {
                const formData = {
                    superCategoryId: supercategoryid,
                    categoryId: categoryid,
                    SubCategoryId: subcategoryid,
                    CategoryTagId: categoryTagid,
                };
                console.log(formData, "formData");

                const data = await dispatch(getOneFieldStatus(formData));
                console.log(data.payload, "data.payload");

                if (data.payload.success === true) {
                    const resp = data.payload.field;
                    const filterShow = resp.selectedFields.filter(
                        (data) => data.status === true
                    );
                    const distinctNames = [
                        ...new Set(filterShow.map((item) => item.field_Name)),
                    ];
                    let distValfielda = [];
                    for (let i = 0; i < filterShow.length; i++) {
                        let element = filterShow[i];
                        let obje2 = { field: element.field_Name, value: "" };
                        distValfielda = [...distValfielda, obje2];
                    }
                    distValfielda = distValfielda;
                    setdistinctfield(distinctNames);
                    setdistinctfieldValidate(distValfielda);
                }
            };
            getdata();
        }
    }, [subcategoryid, categoryid, supercategoryid, categoryTagid]);
    // updatedvalues
    const updateValues = (fieldname, value) => {
        const index = distinctfieldValidate.findIndex(
            (item) => item.field === fieldname
        );
        let updateValue = distinctfieldValidate;
        updateValue[index].value = value;

        setdistinctfieldValidate([...updateValue]);
    };
    // Nishithaa......🤞
    const {
        Outer_material,
        Ideal_for,
        Occasion,
        Type_of_sports,
        Sole_material,
        Closure,
        Season,
        Upper_pattern,
        Type_of_casual,
        Tip_shape,
        Type_of_formal,
        Type,
        Type_of_heels,
        Type_of_flats,
        Number_of_pairs,
        Size,
        Pack_of,
        Net_quantity,
        Closure_type,
        Base_material,
        Strap_material,
        Sub_type,
        Heel_type,
        Length,
        Heel_design,
        Brand_color,
        Character,
        Insole_material,
        Weight,
        Warranty,
        Color,
        Primary_color,
        Inner_lining,
        Removable_Insole,
        Secondary_color,
    } = useSelector((store) => store.Messurents)
    const [color, setColor] = useState("");
    const [color_error, setColor_error] = useState("");

    const [outer_material, setOuter_material] = useState("");
    const [outer_material_error, setOuter_material_error] = useState("");

    const [model_name, setModel_name] = useState("");
    const [model_name_error, setModel_name_error] = useState("");

    const [ideal_for, setIdeal_for] = useState("");
    const [ideal_for_error, setIdeal_for_error] = useState("");

    const [occassion, setOccassion] = useState("");
    const [occassion_error, setOccassion_error] = useState("");

    const [type_of_sports, setType_of_sports] = useState("");
    const [type_of_sports_error, setType_of_sports_error] = useState("");

    const [net_quantity, setNet_quantity] = useState("");
    const [net_quantity_error, setNet_quantity_error] = useState("");

    const [sole_material, setSole_material] = useState("");
    const [sole_material_error, setSole_material_error] = useState("");

    const [closure, setClosure] = useState("");
    const [closure_error, setClosure_error] = useState("");

    const [weight, setWeight] = useState("");
    const [weight_error, setWeight_error] = useState("");

    const [upper_pattern, setUpper_pattern] = useState("");
    const [upper_pattern_error, setUpper_pattern_error] = useState("");

    const [sales_package, setSales_package] = useState("");
    const [sales_package_error, setSales_package_error] = useState("");

    const [pack_of, setPack_of] = useState("");
    const [pack_of_error, setPack_of_error] = useState("");

    const [technology_used, setTechnology_used] = useState("");
    const [technology_used_error, setTechnology_used_error] = useState("");

    const [season, setSeason] = useState("");
    const [season_error, setSeason_error] = useState("");

    const [care_instruction, setCare_instruction] = useState("");
    const [care_instruction_error, setCare_instruction_error] = useState("");

    const [type_of_causual, setType_of_causual] = useState("");
    const [type_of_causual_error, setType_of_causual_error] = useState("");

    const [tip_shape, setTip_shape] = useState("");
    const [tip_shape_error, setTip_shape_error] = useState("");

    const [type_of_formals, setType_of_formals] = useState("");
    const [type_of_formals_error, setType_of_formals_error] = useState("");

    const [type, setType] = useState("");
    const [type_error, setType_error] = useState("");

    const [type_of_flats, setType_of_flats] = useState("");
    const [type_of_flats_error, setType_of_flats_error] = useState("");

    const [heel_type, setHeel_type] = useState("");
    const [heel_type_error, setHeel_type_error] = useState("");

    const [type_of_heels, setType_of_heels] = useState("");
    const [type_of_heels_error, setType_of_heels_error] = useState("");

    const [length, setLength] = useState("");
    const [length_error, setLength_error] = useState("");

    const [number_of_pairs, setNumber_of_pairs] = useState("");
    const [number_of_pairs_error, setNumber_of_pairs_error] = useState("");

    const [brand, setbrand] = useState("");
    const [brand_error, setbrand_error] = useState("");

    const [style_code, setStyle_code] = useState("");
    const [style_code_error, setStyle_code_error] = useState("");

    const [size, setSize] = useState("");
    const [size_error, setSize_error] = useState("");

    const [brand_color, setBrand_color] = useState("");
    const [brand_color_error, setBrand_color_error] = useState("");

    const [closure_type, setClosure_type] = useState("");
    const [closure_type_error, setClosure_type_error] = useState("");

    const [base_material, setBase_material] = useState("");
    const [base_material_error, setBase_material_error] = useState("");

    const [strap_material, setStrap_material] = useState("");
    const [strap_material_error, setStrap_material_error] = useState("");

    const [primary_color, setPrimary_color] = useState("");
    const [primary_color_error, setPrimary_color_error] = useState("");

    const [secondary_color, setSecondary_color] = useState("");
    const [secondary_color_error, setSecondary_color_error] = useState("");

    const [sub_type, setSub_type] = useState("");
    const [sub_type_error, setSub_type_error] = useState("");

    const [character, setCharacter] = useState("");
    const [character_error, setCharacter_error] = useState("");

    const [heel_design, setHeel_design] = useState("");
    const [heel_design_error, setHeel_design_error] = useState("");

    const [warranty_summary, setWarranty_summary] = useState("");
    const [warranty_summary_error, setWarranty_summary_error] = useState("");

    const [insole_material, setInsole_material] = useState("");
    const [insole_material_error, setInsole_material_error] = useState("");

    const [inner_lining, setInner_lining] = useState("");
    const [inner_lining_error, setInner_lining_error] = useState("");

    const [removable_insole, setRemovable_insole] = useState("");
    const [removable_insole_error, setRemovable_insole_error] = useState("");


    // errors for that
    useEffect(() => {
        if (buttonPress === true) {
            if (color === "") {
                setColor_error("Required");
            } else {
                setColor_error("");
            }
            if (outer_material === "") {
                setOuter_material_error("Required");
            } else {
                setOuter_material_error("");
            }

            if (model_name === "") {
                setModel_name_error("Required");
            } else {
                setModel_name_error("");
            }
            if (ideal_for === "") {
                setIdeal_for_error("Required");
            } else {
                setIdeal_for_error("");
            }

            // if (occassion === "") {
            //     setOccassion_error("Required");
            // } else {
            //     setOccassion_error("");
            // }

            if (type_of_sports === "") {
                setType_of_sports_error("Required");
            } else {
                setType_of_sports_error("");
            }

            if (net_quantity === "") {
                setNet_quantity_error("Required");
            } else {
                setNet_quantity_error("");
            }

            if (sole_material === "") {
                setSole_material_error("Required");
            } else {
                setSole_material_error("");
            }

            if (closure === "") {
                setClosure_error("Required");
            } else {
                setClosure_error("");
            }

            if (weight === "") {
                setWeight_error("Required");
            } else {
                setWeight_error("");
            }

            if (upper_pattern === "") {
                setUpper_pattern_error("Required");
            } else {
                setUpper_pattern_error("");
            }

            if (sales_package === "") {
                setSales_package_error("Required");
            } else {
                setSales_package_error("");
            }

            if (pack_of === "") {
                setPack_of_error("Required");
            } else {
                setPack_of_error("");
            }

            if (technology_used === "") {
                setTechnology_used_error("Required");
            } else {
                setTechnology_used_error("");
            }

            if (season === "") {
                setSeason_error("Required");
            } else {
                setSeason_error("");
            }

            if (care_instruction === "") {
                setCare_instruction_error("Required");
            } else {
                setCare_instruction_error("");
            }

            if (type_of_causual === "") {
                setType_of_causual_error("Required");
            } else {
                setType_of_causual_error("");
            }

            if (tip_shape === "") {
                setTip_shape_error("Required");
            } else {
                setTip_shape_error("");
            }

            if (type_of_formals === "") {
                setType_of_formals_error("Required");
            } else {
                setType_of_formals_error("");
            }

            if (type === "") {
                setType_error("Required");
            } else {
                setType_error("");
            }

            if (type_of_flats === "") {
                setType_of_flats_error("Required");
            } else {
                setType_of_flats_error("");
            }

            if (heel_type === "") {
                setHeel_type_error("Required");
            } else {
                setHeel_type_error("");
            }

            if (type_of_heels === "") {
                setType_of_heels_error("Required");
            } else {
                setType_of_heels_error("");
            }

            if (length === "") {
                setLength_error("Required");
            } else {
                setLength_error("");
            }

            if (number_of_pairs === "") {
                setNumber_of_pairs_error("Required");
            } else {
                setNumber_of_pairs_error("");
            }

            if (brand === "") {
                setbrand_error("Required");
            } else {
                setbrand_error("");
            }

            if (style_code === "") {
                setStyle_code_error("Required");
            } else {
                setStyle_code_error("");
            }

            if (size === "") {
                setSize_error("Required");
            } else {
                setSize_error("");
            }

            if (brand_color === "") {
                setBrand_color_error("Required");
            } else {
                setBrand_color_error("");
            }

            if (closure_type === "") {
                setClosure_type_error("Required");
            } else {
                setClosure_type_error("");
            }

            if (base_material === "") {
                setBase_material_error("Required");
            } else {
                setBase_material_error("");
            }

            if (strap_material === "") {
                setStrap_material_error("Required");
            } else {
                setStrap_material_error("");
            }

            if (primary_color === "") {
                setPrimary_color_error("Required");
            } else {
                setPrimary_color_error("");
            }

            if (secondary_color === "") {
                setSecondary_color_error("Required");
            } else {
                setSecondary_color_error("");
            }

            if (sub_type === "") {
                setSub_type_error("Required");
            } else {
                setSub_type_error("");
            }

            if (character === "") {
                setCharacter_error("Required");
            } else {
                setCharacter_error("");
            }

            if (heel_design === "") {
                setHeel_design_error("Required");
            } else {
                setHeel_design_error("");
            }

            if (warranty_summary === "") {
                setWarranty_summary_error("Required");
            } else {
                setWarranty_summary_error("");
            }

            if (insole_material === "") {
                setInsole_material_error("Required");
            } else {
                setInsole_material_error("");
            }

            if (inner_lining === "") {
                setInner_lining_error("Required");
            } else {
                setInner_lining_error("");
            }

            if (removable_insole === "") {
                setRemovable_insole_error("Required");
            } else {
                setRemovable_insole_error("");
            }


        }
    }, [buttonPress,
        distinctfieldValidate,
        color,
        outer_material,
        model_name,
        ideal_for,
        // occassion,
        type_of_sports,
        net_quantity,
        sole_material,
        closure, weight,
        upper_pattern,
        sales_package,
        pack_of,
        technology_used,
        season,
        care_instruction,
        type_of_causual,
        tip_shape,
        type_of_formals,
        type,
        type_of_flats,
        heel_type,
        type_of_heels,
        length,
        number_of_pairs,
        brand,
        style_code,
        size,
        brand_color,
        closure_type,
        base_material,
        strap_material,
        primary_color,
        secondary_color,
        sub_type,
        character,
        heel_design,
        warranty_summary,
        insole_material,
        inner_lining,
        removable_insole
    ])


    return (
        <>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Flex wrap="wrap">
                    {distinctfield.includes("model_name") && (
                        <Col className="gutter-row" span={12}>
                            <span>Model Name:</span>
                            <Input
                                status={model_name_error !== "" ? "error" : ""}
                                suffix={
                                    model_name_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Model Name"
                                value={model_name}
                                onChange={(e) => {
                                    setModel_name(e.target.value);
                                    updateValues("model_name", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("sales_package") && (
                        <Col className="gutter-row" span={12}>
                            <span>Sales Package:</span>
                            <Input
                                status={sales_package_error !== "" ? "error" : ""}
                                suffix={
                                    sales_package_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Sales Package"
                                value={sales_package}
                                onChange={(e) => {
                                    setSales_package(e.target.value);
                                    updateValues("sales_package", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("technology_used") && (
                        <Col className="gutter-row" span={12}>
                            <span>Technology Used:</span>
                            <Input
                                status={technology_used_error !== "" ? "error" : ""}
                                suffix={
                                    technology_used_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Technology Used"
                                value={technology_used}
                                onChange={(e) => {
                                    setTechnology_used(e.target.value);
                                    updateValues("technology_used", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("care_instruction") && (
                        <Col className="gutter-row" span={12}>
                            <span>Care Instruction:</span>
                            <Input
                                status={care_instruction_error !== "" ? "error" : ""}
                                suffix={
                                    care_instruction_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Care Instruction"
                                value={care_instruction}
                                onChange={(e) => {
                                    setCare_instruction(e.target.value);
                                    updateValues("care_instruction", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("brand") && (
                        <Col className="gutter-row" span={12}>
                            <span>Brand:</span>
                            <Input
                                status={brand_error !== "" ? "error" : ""}
                                suffix={
                                    brand_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Brand"
                                value={brand}
                                onChange={(e) => {
                                    setbrand(e.target.value);
                                    updateValues("brand", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>

                <Flex wrap="wrap">
                    {distinctfield.includes("style_code") && (
                        <Col className="gutter-row" span={12}>
                            <span>Style Code:</span>
                            <Input
                                status={style_code_error !== "" ? "error" : ""}
                                suffix={
                                    style_code_error !== "" ? <MdOutlineErrorOutline /> : ""
                                }
                                type="text"
                                placeholder="Enter Style Code"
                                value={style_code}
                                onChange={(e) => {
                                    setStyle_code(e.target.value);
                                    updateValues("style_code", e.target.value);
                                }}
                                style={{ width: 240 }}
                            />
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="color"
                                hasFeedback
                                validateStatus={color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select color"
                                    value={color}
                                    onChange={(value) => {
                                        setColor(value);
                                        updateValues("color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>


                <Flex wrap="wrap">
                    {distinctfield.includes("outer_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Outer Material"
                                hasFeedback
                                validateStatus={outer_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Outer Material"
                                    value={outer_material}
                                    onChange={(value) => {
                                        setOuter_material(value);
                                        updateValues("outer_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Outer_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={outer_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("ideal_for") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Ideal For"
                                hasFeedback
                                validateStatus={ideal_for_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Ideal For"
                                    value={ideal_for}
                                    onChange={(value) => {
                                        setIdeal_for(value);
                                        updateValues("ideal_for", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Ideal_for.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={ideal_for === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("occassion") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Occassion"
                                hasFeedback
                                validateStatus={occassion_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Occassion"
                                    value={occassion}
                                    onChange={(value) => {
                                        setOccassion(value);
                                        updateValues("occassion", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Occasion.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={occassion === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("type_of_sports") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Type Of Sports"
                                hasFeedback
                                validateStatus={type_of_sports_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Type Of Sports"
                                    value={type_of_sports}
                                    onChange={(value) => {
                                        setType_of_sports(value);
                                        updateValues("type_of_sports", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Type_of_sports.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={type_of_sports === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("net_quantity") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Net Quantity"
                                hasFeedback
                                validateStatus={net_quantity_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Net Quantity"
                                    value={net_quantity}
                                    onChange={(value) => {
                                        setNet_quantity(value);
                                        updateValues("net_quantity", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Net_quantity.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={net_quantity === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("sole_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Sole Material"
                                hasFeedback
                                validateStatus={sole_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Sole Material"
                                    value={sole_material}
                                    onChange={(value) => {
                                        setSole_material(value);
                                        updateValues("sole_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Sole_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={sole_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("closure") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Closure"
                                hasFeedback
                                validateStatus={closure_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Closure"
                                    value={closure}
                                    onChange={(value) => {
                                        setClosure(value);
                                        updateValues("closure", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Closure.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={closure === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("weight") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Weight"
                                hasFeedback
                                validateStatus={weight_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Weight"
                                    value={weight}
                                    onChange={(value) => {
                                        setWeight(value);
                                        updateValues("weight", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Weight.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={weight === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("upper_pattern") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Upper Pattern"
                                hasFeedback
                                validateStatus={upper_pattern_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Upper Pattern"
                                    value={upper_pattern}
                                    onChange={(value) => {
                                        setUpper_pattern(value);
                                        updateValues("upper_pattern", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Upper_pattern.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={upper_pattern === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("pack_of") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Pack Of"
                                hasFeedback
                                validateStatus={pack_of_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Pack Of"
                                    value={pack_of}
                                    onChange={(value) => {
                                        setPack_of(value);
                                        updateValues("pack_of", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Pack_of.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={pack_of === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("season") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Season"
                                hasFeedback
                                validateStatus={season_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Season"
                                    value={season}
                                    onChange={(value) => {
                                        setSeason(value);
                                        updateValues("season", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Season.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={season === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("type_of_causual") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Type Of Causual"
                                hasFeedback
                                validateStatus={type_of_causual_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Type Of Causual"
                                    value={type_of_causual}
                                    onChange={(value) => {
                                        setType_of_causual(value);
                                        updateValues("type_of_causual", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Type_of_casual.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={type_of_causual === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("tip_shape") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Tip Shape"
                                hasFeedback
                                validateStatus={tip_shape_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Tip Shape"
                                    value={tip_shape}
                                    onChange={(value) => {
                                        setTip_shape(value);
                                        updateValues("tip_shape", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Tip_shape.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={tip_shape === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("type_of_formals") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Type Of Formals"
                                hasFeedback
                                validateStatus={type_of_formals_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Type Of Formals"
                                    value={type_of_formals}
                                    onChange={(value) => {
                                        setType_of_formals(value);
                                        updateValues("type_of_formals", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Type_of_formal.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={type_of_formals === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Type"
                                hasFeedback
                                validateStatus={type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Type"
                                    value={type}
                                    onChange={(value) => {
                                        setType(value);
                                        updateValues("type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("type_of_flats") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Type Of Flats"
                                hasFeedback
                                validateStatus={type_of_flats_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Type Of Flats"
                                    value={type_of_flats}
                                    onChange={(value) => {
                                        setType_of_flats(value);
                                        updateValues("type_of_flats", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Type_of_flats.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={type_of_flats === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("heel_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Heel Type"
                                hasFeedback
                                validateStatus={heel_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Heel Type"
                                    value={heel_type}
                                    onChange={(value) => {
                                        setHeel_type(value);
                                        updateValues("heel_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Heel_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={heel_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("type_of_heels") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Type Of Heels"
                                hasFeedback
                                validateStatus={type_of_heels_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Type Of Heels"
                                    value={type_of_heels}
                                    onChange={(value) => {
                                        setType_of_heels(value);
                                        updateValues("type_of_heels", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Type_of_heels.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={type_of_heels === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("length") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Length"
                                hasFeedback
                                validateStatus={length_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Length"
                                    value={length}
                                    onChange={(value) => {
                                        setLength(value);
                                        updateValues("length", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Length.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={length === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("number_of_pairs") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Number Of Pairs"
                                hasFeedback
                                validateStatus={number_of_pairs_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Number Of Pairs"
                                    value={number_of_pairs}
                                    onChange={(value) => {
                                        setNumber_of_pairs(value);
                                        updateValues("number_of_pairs", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Number_of_pairs.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={number_of_pairs === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("size") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Size"
                                hasFeedback
                                validateStatus={size_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Size"
                                    value={size}
                                    onChange={(value) => {
                                        setSize(value);
                                        updateValues("size", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Size.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={size === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("brand_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Brand Color"
                                hasFeedback
                                validateStatus={brand_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Brand Color"
                                    value={brand_color}
                                    onChange={(value) => {
                                        setBrand_color(value);
                                        updateValues("brand_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Brand_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={brand_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("closure_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Closure Type"
                                hasFeedback
                                validateStatus={closure_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Closure Type"
                                    value={closure_type}
                                    onChange={(value) => {
                                        setClosure_type(value);
                                        updateValues("closure_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Closure_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={closure_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("base_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Base Material"
                                hasFeedback
                                validateStatus={base_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Base Material"
                                    value={base_material}
                                    onChange={(value) => {
                                        setBase_material(value);
                                        updateValues("base_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Base_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={base_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("strap_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Strap Material"
                                hasFeedback
                                validateStatus={strap_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Strap Material"
                                    value={strap_material}
                                    onChange={(value) => {
                                        setStrap_material(value);
                                        updateValues("strap_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Strap_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={strap_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("primary_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Primary Color"
                                hasFeedback
                                validateStatus={primary_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Primary Color"
                                    value={primary_color}
                                    onChange={(value) => {
                                        setPrimary_color(value);
                                        updateValues("primary_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Primary_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={primary_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("secondary_color") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Secondary Color"
                                hasFeedback
                                validateStatus={secondary_color_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Secondary Color"
                                    value={secondary_color}
                                    onChange={(value) => {
                                        setSecondary_color(value);
                                        updateValues("secondary_color", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Secondary_color.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={secondary_color === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("sub_type") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Sub Type"
                                hasFeedback
                                validateStatus={sub_type_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Sub Type"
                                    value={sub_type}
                                    onChange={(value) => {
                                        setSub_type(value);
                                        updateValues("sub_type", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Sub_type.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={sub_type === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("character") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Character"
                                hasFeedback
                                validateStatus={character_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Character"
                                    value={character}
                                    onChange={(value) => {
                                        setCharacter(value);
                                        updateValues("character", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Character.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={character === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("heel_design") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Heel Design"
                                hasFeedback
                                validateStatus={heel_design_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Heel Design"
                                    value={heel_design}
                                    onChange={(value) => {
                                        setHeel_design(value);
                                        updateValues("heel_design", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Heel_design.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={heel_design === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("warranty_summary") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Warranty Summary"
                                hasFeedback
                                validateStatus={warranty_summary_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Warranty Summary"
                                    value={warranty_summary}
                                    onChange={(value) => {
                                        setWarranty_summary(value);
                                        updateValues("warranty_summary", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Warranty.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={warranty_summary === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("insole_material") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Insole Material"
                                hasFeedback
                                validateStatus={insole_material_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Insole Material"
                                    value={insole_material}
                                    onChange={(value) => {
                                        setInsole_material(value);
                                        updateValues("insole_material", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Insole_material.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={insole_material === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("inner_lining") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Inner Lining"
                                hasFeedback
                                validateStatus={inner_lining_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Inner Lining"
                                    value={inner_lining}
                                    onChange={(value) => {
                                        setInner_lining(value);
                                        updateValues("inner_lining", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Inner_lining.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={inner_lining === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
                <Flex wrap="wrap">
                    {distinctfield.includes("removable_insole") && (
                        <Col className="gutter-row" span={6}>
                            <Form.Item
                                label="Removable Insole"
                                hasFeedback
                                validateStatus={removable_insole_error !== "" ? "error" : ""}
                                style={{ width: 240 }}
                                name="layout"
                            >
                                <Select
                                    placeholder="Select Removable Insole"
                                    value={removable_insole}
                                    onChange={(value) => {
                                        setRemovable_insole(value);
                                        updateValues("removable_insole", value);
                                    }}
                                    style={{ width: 240 }}
                                >
                                    {Removable_Insole.map((data, index) => (
                                        <Option
                                            key={index}
                                            value={data.label}
                                            name={data.label}
                                            selected={removable_insole === data.label}
                                        >
                                            {data.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    )}
                </Flex>
            </Row>
        </>
    )
}

export default FootwearSupercat
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { brandDelete, brandUpdate } from "../../../redux/brand/brandSlice";
import { Modal, ModalHeader } from "reactstrap";
import { Modal as OtherModal } from "antd";
const ListBrand = () => {
  const dispatch = useDispatch();

  const { brandtotal } = useSelector((store) => store.brand);

  const [selectBrand, setSelectBrand] = useState("");
  const [cancelmodel, setCancelModel] = useState(false);
  const [ShowBrand, setShowBrand] = useState([]);
  const [selectedPriority, setSelectedPriority] = useState("");
  const [newSelectedPriority, setNewSelectedPriority] = useState("");
  const cancelClick = (e, subcategory) => {
    setCancelModel(true);
    setSelectBrand(subcategory);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePriority = (data) => {
    setSelectedPriority(data);
    setIsModalOpen(true);
  };
  const handleChangePriority = async (e) => {
    const value = e.target.value;
    const newPriority = {
      ...selectedPriority,
      priorityNo: Number(value),
      brandid: selectedPriority._id,
    };
    console.log(newPriority,'newPriority');
    setSelectedPriority(newPriority);
  };
  const handlePriorityNo = async () => {
    setIsModalOpen(false);
    const updateCat = await dispatch(brandUpdate(selectedPriority));
    console.log(updateCat.payload,'updateCat.payload');
    if (updateCat.payload.success) {
      alert("The following brand has been marked as per the priority");
    }
  };
  const handleOk = () => {
    handlePriorityNo()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (brandtotal.length > 0) {
      // let slotFilter = [...brandtotal].sort((a, b) =>
      //   a.name > b.name ? 1 : -1
      // );
      let slotFilter = [...brandtotal].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateB - dateA; // Descending order (most recent first)
      });
      slotFilter = slotFilter.map((row, index) => ({
        ...row,
        index: index + 1,
      }));
      setShowBrand(slotFilter);
    }
  }, [brandtotal]);

  const deleteClick = async (e, id) => {
    const data = await dispatch(brandDelete(selectBrand._id));
    setCancelModel(false);
  };
  const statusChange = async (e, data) => {
    e.preventDefault();
    const value = e.target.checked;

    if (data) {
      const formData = {
        brandid: data._id,
        status: value,
      };
      const updateCat = await dispatch(brandUpdate(formData));
    }
  };
  const priorityChange = async (e, data) => {
    e.preventDefault();
    const value = !data.priority;
    console.log(value, "value");
    if (data) {
      const formData = {
        brandid: data._id,
        priority: value,
      };
      console.log(formData, "formData");
      const updateCat = await dispatch(brandUpdate(formData));
      if (updateCat.payload.success) {
        alert("The following brand has been marked as per the priority");
      }
    }
  };
  const columns = [
    {
      field: "index",
      headerName: "S. No",
      align: "center",
      headerAlign: "center",
      filterable: false,
      headerClassName: "customsheader",
    },
    {
      field: "name",
      headerName: "BRAND NAME",
      width: 250,
      align: "center",
      headerAlign: "center",
      headerClassName: "customsheader",
    },
    // {
    //   field: "thumbnail",
    //   headerName: "IMAGE",
    //   width: 230,
    //   headerAlign: "center",

    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <img
    //           className="d-flex m-auto"
    //           src={params.row.thumbnail}
    //           width={100}
    //           alt="thumnail"
    //         />
    //       </>
    //     );
    //   },
    // },
    {
      field: "STATUS",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">
              <Col>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=" "
                  value={params.row.status}
                  checked={params.row.status}
                  onChange={(e) => statusChange(e, params.row)}
                />
              </Col>
            </div>
          </>
        );
      },
    },
    {
      field: "Priority No",
      width: 200,
      headerAlign: "center",
      align: "center",
      headerClassName: "customsheader",
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex m-auto">{params.row.priorityNo}</div>
          </>
        );
      },
    },
    {
      field: "Change Priority Number",
      width: 240,
      headerAlign: "center",
      headerClassName: "customsheader",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              color="primary"
              className="editbuttonnn"
              onClick={() => handlePriority(params.row)}
            >
              EDIT
            </Button>
          </div>
        );
      },
    },
    {
      field: "ACTION",
      width: 240,
      headerAlign: "center",
      headerClassName: "customsheader",
      renderCell: (params) => {
        return (
          <div className="d-flex m-auto">
            <Link to={`/admin/updatebrand/${params.row.slugUrl}`}>
              <Button
                variant="contained"
                color="primary"
                className="editbuttonnn"
              >
                EDIT
              </Button>
            </Link>
          </div>
        );
      },
    },
    {
      field: "ACTION.",
      width: 300,
      headerAlign: "center",
      headerClassName: "customsheader",
      renderCell: (cellValues) => {
        return (
          <div className="d-flex m-auto">
            <Button
              variant="contained"
              onClick={(e) => cancelClick(e, cellValues.row)}
              color="error"
              className="deletebuttomss"
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Fragment>
        <div className="content-wrapper ">
          <div
            style={{ height: 650, width: "100%" }}
            className="d-flex m-auto text-align-center"
          >
            <DataGrid
              rows={ShowBrand}
              columns={columns}
              getRowId={(row) => row._id}
              pageSize={10}
              rowsPerPageOptions={[10]}
              // checkboxSelection
            />
          </div>

          <Modal
            size="md"
            isOpen={cancelmodel}
            toggle={() => setCancelModel(!cancelmodel)}
          >
            <ModalHeader>
              <div className=" ">
                <div className="col-12 ">
                  <div className="col-12">
                    <h3>
                      Do You Want to{" "}
                      <span style={{ color: "red" }}>Delete</span>{" "}
                      {selectBrand !== "" ? (
                        <>
                          <span
                            style={{
                              color: "#dc3545",
                            }}
                          >
                            {selectBrand.name}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}{" "}
                      Brand
                    </h3>
                    <div className="col-12 mt-3 ">
                      <div className="col-6 d-flex justify-content-between">
                        <Button
                          variant="contained"
                          color="error"
                          className="mx-1 px-5"
                          onClick={() => setCancelModel(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          className="mx-1 px-5"
                          onClick={(e) => deleteClick(e)}
                        >
                          Delete
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalHeader>
          </Modal>
          <OtherModal
            title={`Brand: ${selectedPriority.name}`}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <input
              type="text"
              value={selectedPriority.priorityNo}
              onChange={(e) => handleChangePriority(e)}
            />
          </OtherModal>
        </div>
      </Fragment>
    </>
  );
};

export default ListBrand;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../../../redux/fieldStatus/FieldStatusSlice";
import { values } from "pdf-lib";
import { Flex, Form, Select,Col, Row} from "antd";
const { Option } = Select;
const BeautySupercat = ({
  subcategoryid,
  categoryid,
  supercategoryid,
  categoryTagid,
  buttonPress,
  getPropDetails,
  resetield,
}) => {
  const dispatch = useDispatch();

  const [getfields, setGetFields] = useState([]);
  const [getfieldsShow, setGetFieldsShow] = useState([]);

  const [distinctfield, setdistinctfield] = useState([]);
  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);

  const [skin_type, setSkin_type] = useState("");
  const [skin_type_error, setSkin_type_error] = useState("");

  const [concern, setConcern] = useState("");
  const [concern_error, setConcern_error] = useState("");

  const [pack_size, setPack_size] = useState("");
  const [pack_size_error, setPack_size_error] = useState("");

  const [coverage, setCoverage] = useState("");
  const [coverage_error, setCoverage_error] = useState("");

  const [finish, setFinish] = useState("");
  const [finish_error, setFinish_error] = useState("");

  const [formulation, setFormulation] = useState("");
  const [formulation_error, setFormulation_error] = useState("");

  const [preference, setPreference] = useState("");
  const [preference_error, setPreference_error] = useState("");

  const [skin_tone, setSkin_tone] = useState("");
  const [skin_tone_error, setSkin_tone_error] = useState("");

  const [sustainable, setSustainable] = useState("");
  const [sustainable_error, setSustainable_error] = useState("");

  const [concious, setConcious] = useState("");
  const [concious_error, setConcious_error] = useState("");

  const [benefits, setBenefits] = useState("");
  const [benefits_error, setBenefits_error] = useState("");

  const [pack_of, setPack_of] = useState("");
  const [pack_of_error, setPack_of_error] = useState("");

  const [ingredients, setIngredients] = useState("");
  const [ingredients_error, setIngredients_error] = useState("");

  const [gender, setGender] = useState("");
  const [gender_error, setGender_error] = useState("");

  const [country_origin, setCountry_origin] = useState("");
  const [country_origin_error, setCountry_origin_error] = useState("");

  const [color, setColor] = useState("");
  const [color_error, setColor_error] = useState("");

  const [description, setDescription] = useState("");
  const [description_error, setDescription_error] = useState("");

  const [how_to_use, setHow_to_use] = useState("");
  const [how_to_use_error, setHow_to_use_error] = useState("");

  const [key_ingredients, setKey_ingredients] = useState("");
  const [key_ingredients_error, setKey_ingredients_error] = useState("");

  const [usage_period, setUsage_period] = useState("");
  const [usage_period_error, setUsage_period_error] = useState("");

  const [color_family, setColor_family] = useState("");
  const [color_family_error, setColor_family_error] = useState("");

  const [color_shade_name, setColor_shade_name] = useState("");
  const [color_shade_name_error, setColor_shade_name_error] = useState("");

  const [features, setFeatures] = useState("");
  const [features_error, setFeatures_error] = useState("");

  const [type, setType] = useState("");
  const [type_error, setType_error] = useState("");

  const [fragrance, setFragrance] = useState("");
  const [fragrance_error, setFragrance_error] = useState("");

  const [strength, setStrength] = useState("");
  const [strength_error, setStrength_error] = useState("");

  const [spf, setSpf] = useState("");
  const [spf_error, setSpf_error] = useState("");


  // Nishitha.........................🤞

  useEffect(() => {
    if (categoryTagid) {
      const getdata = async () => {
        const formData = {
          superCategoryId: supercategoryid,
          categoryId: categoryid,
          SubCategoryId: subcategoryid,
          CategoryTagId: categoryTagid,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          let distValfielda = [];
          for (let i = 0; i < filterShow.length; i++) {
            let element = filterShow[i];
            let obje2 = { field: element.field_Name, value: "" };
            distValfielda = [...distValfielda, obje2];
          }
          distValfielda = distValfielda;
          setdistinctfield(distinctNames);
          setdistinctfieldValidate(distValfielda);
        }
      };
      getdata();
    }
  }, [subcategoryid, categoryid, supercategoryid, categoryTagid]);

  const {
    Skin_type,
    Concern,
    Pack_size,
    Coverage,
    Finish,
    Formulation,
    Preference,
    Skin_tone,
    Strength,
    Color,
    Sustainable,
    Concious,
    Benefits,
    Pack_of,
    Ingredients,
    Gender,
    Country_origin,
    Description,
    How_to_use,
    Key_ingredients,
    Usage_period,
    Color_shade_name,
    Features,
    Type,
    Fragrance,
    Spf,
  } = useSelector((store) => store.Messurents);


  useEffect(() => {
    if (resetield === true) {
    }
  }, [resetield]);

  const updateValues = (fieldname, value) => {
    const index = distinctfieldValidate.findIndex(
      (item) => item.field === fieldname
    );
    let updateValue = distinctfieldValidate;
    updateValue[index].value = value;

    setdistinctfieldValidate([...updateValue]);
  };
  useEffect(() => {
    if (buttonPress === true) {
        console.log(distinctfieldValidate, "distinctfieldValidate");

        const finalarray = distinctfieldValidate.filter((data) =>
            data.field !== "occasion" && data.field !== "brandd" && data.field !== "type_of_formal")

        if (finalarray.every((field) => field.value !== "")) {
            let finalarray = [];
            for (let index = 0; index < distinctfieldValidate.length; index++) {
                let element = distinctfieldValidate[index];
                let obj = { [element.field]: element.value };
                finalarray = [...finalarray, obj];
            }
            finalarray = finalarray;

            const singleObject = finalarray.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});

            const newForm = {
                ...singleObject,
                occasion: "abc"
            }
            getPropDetails(newForm, true);
        } else {
        }
    }
}, [buttonPress, distinctfieldValidate]);

  useEffect(() => {
    if (buttonPress === true) {
      if (skin_type === "") {
        setSkin_type_error("Required");
      } else {
        setSkin_type_error("");
      }

      if (concern === "") {
        setConcern_error("Required");
      } else {
        setConcern_error("");
      }

      if (pack_size === "") {
        setPack_size_error("Required");
      } else {
        setPack_size_error("");
      }

      if (coverage === "") {
        setCoverage_error("Required");
      } else {
        setCoverage_error("");
      }

      if (finish === "") {
        setFinish_error("Required");
      } else {
        setFinish_error("");
      }

      if (formulation === "") {
        setFormulation_error("Required");
      } else {
        setFormulation_error("");
      }

      if (preference === "") {
        setPreference_error("Required");
      } else {
        setPreference_error("");
      }

      if (skin_tone === "") {
        setSkin_tone_error("Required");
      } else {
        setSkin_tone_error("");
      }

      if (sustainable === "") {
        setSustainable_error("Required");
      } else {
        setSustainable_error("");
      }

      if (concious === "") {
        setConcious_error("Required");
      } else {
        setConcious_error("");
      }

      if (benefits === "") {
        setBenefits_error("Required");
      } else {
        setBenefits_error("");
      }

      if (pack_of === "") {
        setPack_of_error("Required");
      } else {
        setPack_of_error("");
      }

      if (ingredients === "") {
        setIngredients_error("Required");
      } else {
        setIngredients_error("");
      }

      if (gender === "") {
        setGender_error("Required");
      } else {
        setGender_error("");
      }

      if (country_origin === "") {
        setCountry_origin_error("Required");
      } else {
        setCountry_origin_error("");
      }

      if (color === "") {
        setColor_error("Required");
      } else {
        setColor_error("");
      }

      if (description === "") {
        setDescription_error("Required");
      } else {
        setDescription_error("");
      }

      if (how_to_use === "") {
        setHow_to_use_error("Required");
      } else {
        setHow_to_use_error("");
      }

      if (key_ingredients === "") {
        setKey_ingredients_error("Required");
      } else {
        setKey_ingredients_error("");
      }

      if (usage_period === "") {
        setUsage_period_error("Required");
      } else {
        setUsage_period_error("");
      }

      if (color_family === "") {
        setColor_family_error("Required");
      } else {
        setColor_family_error("");
      }

      if (color_shade_name === "") {
        setColor_shade_name_error("Required");
      } else {
        setColor_shade_name_error("");
      }

      if (features === "") {
        setFeatures_error("Required");
      } else {
        setFeatures_error("");
      }

      if (type === "") {
        setType_error("Required");
      } else {
        setType_error("");
      }

      if (fragrance === "") {
        setFragrance_error("Required");
      } else {
        setFragrance_error("");
      }

      if (strength === "") {
        setStrength_error("Required");
      } else {
        setStrength_error("");
      }

      if (spf === "") {
        setSpf_error("Required");
      } else {
        setSpf_error("");
      }

    }
  }, [
    buttonPress,
    distinctfieldValidate,
    skin_type,
    concern,
    pack_size,
    coverage,
    finish,
    formulation,
    preference,
    skin_tone,
    sustainable,
    concious,
    benefits,
    pack_of,
    ingredients,
    gender,
    country_origin,
    color,
    description,
    how_to_use,
    key_ingredients,
    usage_period,
    color_family,
    color_shade_name,
    features,
    type,
    fragrance,
    spf,

  ]
  )



  return (
    <>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

        <Flex wrap="wrap">
          {distinctfield.includes("skin_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Skin Type"
                hasFeedback
                validateStatus={skin_type_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Skin Type"
                  value={skin_type}
                  onChange={(value) => {
                    setSkin_type(value);
                    updateValues("skin_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Skin_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={skin_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("concern") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Concern"
                hasFeedback
                validateStatus={concern_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Concern"
                  value={concern}
                  onChange={(value) => {
                    setConcern(value);
                    updateValues("concern", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Concern.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={concern === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pack_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pack Size"
                hasFeedback
                validateStatus={pack_size_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Pack Size"
                  value={pack_size}
                  onChange={(value) => {
                    setPack_size(value);
                    updateValues("pack_size", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Pack_size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pack_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("coverage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Coverage"
                hasFeedback
                validateStatus={coverage_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Coverage"
                  value={coverage}
                  onChange={(value) => {
                    setCoverage(value);
                    updateValues("coverage", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Coverage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={coverage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("finish") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Finish"
                hasFeedback
                validateStatus={finish_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Finish"
                  value={finish}
                  onChange={(value) => {
                    setFinish(value);
                    updateValues("finish", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Finish.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={finish === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("formulation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Formulation"
                hasFeedback
                validateStatus={formulation_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Formulation"
                  value={formulation}
                  onChange={(value) => {
                    setFormulation(value);
                    updateValues("formulation", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Formulation.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={formulation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("preference") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Preference"
                hasFeedback
                validateStatus={preference_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Preference"
                  value={preference}
                  onChange={(value) => {
                    setPreference(value);
                    updateValues("preference", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Preference.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={preference === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("skin_tone") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Skin Tone"
                hasFeedback
                validateStatus={skin_tone_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Skin Tone"
                  value={skin_tone}
                  onChange={(value) => {
                    setSkin_tone(value);
                    updateValues("skin_tone", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Skin_tone.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={skin_tone === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sustainable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sustainable"
                hasFeedback
                validateStatus={sustainable_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sustainable"
                  value={sustainable}
                  onChange={(value) => {
                    setSustainable(value);
                    updateValues("sustainable", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sustainable.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sustainable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("concious") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Concious"
                hasFeedback
                validateStatus={concious_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Concious"
                  value={concious}
                  onChange={(value) => {
                    setConcious(value);
                    updateValues("concious", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Concious.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={concious === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("benefits") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Benefits"
                hasFeedback
                validateStatus={benefits_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Benefits"
                  value={benefits}
                  onChange={(value) => {
                    setBenefits(value);
                    updateValues("benefits", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Benefits.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={benefits === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pack_of") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pack Of"
                hasFeedback
                validateStatus={pack_of_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Pack Of"
                  value={pack_of}
                  onChange={(value) => {
                    setPack_of(value);
                    updateValues("pack_of", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Pack_of.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pack_of === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ingredients") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ingredients"
                hasFeedback
                validateStatus={ingredients_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Ingredients"
                  value={ingredients}
                  onChange={(value) => {
                    setIngredients(value);
                    updateValues("ingredients", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Ingredients.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ingredients === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("gender") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Gender"
                hasFeedback
                validateStatus={gender_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Gender"
                  value={gender}
                  onChange={(value) => {
                    setGender(value);
                    updateValues("gender", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Gender.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={gender === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("country_origin") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Country Origin"
                hasFeedback
                validateStatus={country_origin_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Country Origin"
                  value={country_origin}
                  onChange={(value) => {
                    setCountry_origin(value);
                    updateValues("country_origin", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Country_origin.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={country_origin === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color"
                hasFeedback
                validateStatus={color_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Color"
                  value={color}
                  onChange={(value) => {
                    setColor(value);
                    updateValues("color", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Color.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("description") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Description"
                hasFeedback
                validateStatus={description_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Description"
                  value={description}
                  onChange={(value) => {
                    setDescription(value);
                    updateValues("description", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Description.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={description === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("how_to_use") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="How to Use"
                hasFeedback
                validateStatus={how_to_use_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  How to Use"
                  value={how_to_use}
                  onChange={(value) => {
                    setHow_to_use(value);
                    updateValues("how_to_use", value);
                  }}
                  style={{ width: 240 }}
                >
                  {How_to_use.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={how_to_use === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("key_ingredients") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Key Ingredients"
                hasFeedback
                validateStatus={key_ingredients_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Key Ingredients"
                  value={key_ingredients}
                  onChange={(value) => {
                    setKey_ingredients(value);
                    updateValues("key_ingredients", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Key_ingredients.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={key_ingredients === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("usage_period") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Usage Period"
                hasFeedback
                validateStatus={usage_period_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Usage Period"
                  value={usage_period}
                  onChange={(value) => {
                    setUsage_period(value);
                    updateValues("usage_period", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Usage_period.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={usage_period === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_family") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color Family"
                hasFeedback
                validateStatus={color_family_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Color Family"
                  value={color_family}
                  onChange={(value) => {
                    setColor_family(value);
                    updateValues("color_family", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Color.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_family === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_shade_name") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color Shade Name"
                hasFeedback
                validateStatus={color_shade_name_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Color Shade Name"
                  value={color_shade_name}
                  onChange={(value) => {
                    setColor_shade_name(value);
                    updateValues("color_shade_name", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Color_shade_name.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_shade_name === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Features"
                hasFeedback
                validateStatus={features_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Features"
                  value={features}
                  onChange={(value) => {
                    setFeatures(value);
                    updateValues("features", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Features.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type"
                hasFeedback
                validateStatus={type_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Type"
                  value={type}
                  onChange={(value) => {
                    setType(value);
                    updateValues("type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("fragrance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Fragrance"
                hasFeedback
                validateStatus={fragrance_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select  Fragrance"
                  value={fragrance}
                  onChange={(value) => {
                    setFragrance(value);
                    updateValues("fragrance", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Fragrance.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={fragrance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("strength") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Strength"
                hasFeedback
                validateStatus={strength_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Strength"
                  value={strength}
                  onChange={(value) => {
                    setStrength(value);
                    updateValues("strength", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Strength.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={strength === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("spf") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Spf"
                hasFeedback
                validateStatus={spf_error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Spf"
                  value={spf}
                  onChange={(value) => {
                    setSpf(value);
                    updateValues("spf", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Spf.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={spf === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

      </Row>





    </>
  );
};

export default BeautySupercat;

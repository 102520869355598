import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { AiFillLock } from "react-icons/ai";
import axios from "axios";
import { Baseurl } from "../config/BaseUrl";
import {
  authActions,
  send_sms_through_backend,
} from "../redux/Athentication/AuthSlice";
import { useSelector, useDispatch } from "react-redux";
const MobileLogin = () => {
  const { isAuth } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [number, setNumber] = useState("");
  const [confirm_number, setconfirm_Number] = useState("");
  const [errormassage, setErrormassage] = useState("");

  const [buttonPress, setbuttonPress] = useState(false);

  useEffect(() => {
    if (buttonPress) {
      if (confirm_number === "") {
        setErrormassage("Required");
      } else {
        setErrormassage("");
      }
    }
  }, [buttonPress, confirm_number]);

  useEffect(() => {
    if (isAuth === true) {
      navigate("/admin");
    }
  }, [isAuth]);

  const mobilenumberChange = async (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
    if (value.length === 10) {
      setconfirm_Number(value);
      setErrormassage("");
    } else {
      setconfirm_Number("");
      setErrormassage("Required");
    }
  };

  const sendOptPress = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    if (confirm_number === "") {
      setErrormassage("Required");
    } else {
      setErrormassage("");
    }

    if (confirm_number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }

      await dispatch(authActions.LoginOtp({ number, OTP }));
      console.log("otp",OTP);

      const formdata = {
        mobile: number,
        OTP: OTP,
      };
      await dispatch(send_sms_through_backend(formdata));
      dispatch(authActions.set_checkout_authentication_status(1));
    }
  };
  return (
    <>
      {/* <div className="hold-transition register-page">
        <div className="container">
          <div className="login-register pt-2">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                <div className="inner h-100">
                  <form method="post" action="#" className="customer-form">
                    <h2 className="text-center fs-4 mb-3">
                      Registered Customers
                    </h2>
                    <p className="text-center mb-4">
                      If you have an account with us, please log in.
                    </p>
                    <div className="form-row">
                      <div className="form-group col-12">
                        <label htmlFor="CustomerEmail" className="d-block">
                          Mobile Number <span className="required">*</span>
                        </label>
                        <input
                          type="Text"
                          name="customer[email]"
                          placeholder=" Mobile Number"
                          id="CustomerEmail"
                          className={
                            errormassage !== ""
                              ? "form-control error_show"
                              : "form-control "
                          }
                          value={number}
                          onChange={(e) => mobilenumberChange(e)}
                          minLength={10}
                          maxLength={10}
                        />
                      </div>

                      <div className="form-group col-12 mb-0">
                        <input
                          type="submit"
                          className="btn btn-primary btn-lg w-100"
                          defaultValue="Sign In"
                          onClick={(e) => sendOptPress(e)}
                        />
                      </div>
                    </div>
                    <div className="login-divide">
                      <span className="login-divide-text">OR</span>
                    </div>

                    <div className="login-signup-text mt-4 mb-2 fs-6 text-center text-muted">
                      Don't have an account?{" "}
                      <span
                        className="btn-link"
                        onClick={() =>
                          dispatch(
                            authActions.set_checkout_authentication_status(2)
                          )
                        }
                      >
                        Sign up now
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="hold-transition register-page">
        <div className="register-box col-sm-5">
          <div className="card card-outline card-primary">
            <div className="card-header text-center">
              <Link to="." className="h1">
                <b>EWShopping Super Admin</b>
              </Link>
            </div>
            <div className="card-body">
              <p className="login-box-msg d-flex justify-content-center">
                Sign in to start your session
              </p>
              <form onSubmit={sendOptPress}>
                <label htmlFor="CustomerEmail" className="d-block">
                  Mobile Number <span className="required">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    type="Text"
                    className={
                      errormassage !== ""
                        ? "form-control error_class"
                        : "form-control"
                    }
                    placeholder=" Mobile Number"
                    value={number}
                    onChange={(e) => mobilenumberChange(e)}
                    minLength={10}
                    maxLength={10}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <BsFillEnvelopeFill />
                    </div>
                  </div>
                </div>
                {/* {confirm_number !== "" ? (
                  <>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        // className={
                        //   passwordError !== ""
                        //     ? "form-control error_class"
                        //     : "form-control"
                        // }
                        placeholder="Enter otp"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <AiFillLock />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )} */}
                <div className="row">
                  <div className="col-2">
                    <button type="submit" className="btn btn-primary ">
                      Verify
                    </button>
                  </div>
                  {/* <div className="mt-2">
                    <p style={{ color: errormsgClr, fontWeight: 700 }}>
                      {errormsg}
                    </p>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLogin;

import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { MenuItem, TextField } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { FaInfoCircle } from "react-icons/fa";
import {
  packsizeImages,
  productPost,
  validateProductSlugUrl,
} from "../../../redux/product/productSlice";
import { useToasts } from "react-toast-notifications";
import { Tooltip as ReactTooltip } from "react-tooltip";
import colorNamer from "color-namer";
import MainSupercatProduct from "./supercategory/MainSupercatProduct";
import { values } from "pdf-lib";

const AddProduct = () => {
  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { brandShow } = useSelector((store) => store.brand);
  const { categoryTagShow, categoryTagTotal } = useSelector(
    (store) => store.categorytag
  );




  const {
    romanTypeMessurements,
    yearsTypeMessurements,
    RegularTypeMessurements,
    innerTypeMessurements,
  } = useSelector((store) => store.Messurents);

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [brandId, setBrandId] = useState("");
  const [categoryTagsShow, setCategoryTagsShow] = useState([]);
  const [categoryTag, setCategoryTag] = useState("");
  const [categoryTagid, setCategoryTagid] = useState("");

  const [productname, setProductname] = useState("");
  const [checkUrl, setCheckUrl] = useState("");
  const [imageLoading, setimageLoading] = useState(true);
  const [sameInamgeForAll, setsameInamgeForAll] = useState(true);

  const [packsize, setPacksize] = useState([
    {
      packSizeName: "",
      PsPrime: true,
      enabled: false,
      thumbnail: "",
      slider: [],
      PackSizeOptions: [
        {
          packSizeOptionName: "",
          PsnPrime: true,
          Mrp: "",
          Price: "",
          Discount: "",
          enabled: false,
        },
      ],
    },
  ]);

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [brandError, setbrandError] = useState("");
  const [slugUrlError, setSlugUrlError] = useState("");
  const [categoryTagError, setcategoryTagError] = useState("");

  const [packsizeError, setpacksizeError] = useState("");
  const [imageError, setimageError] = useState("");
  const [packsizeIndex, setpacksizeIndex] = useState("");
  const [optionSizePress, setoptionSizePress] = useState(false);

  const [optionNameError, setoptionNameError] = useState("");
  const [mrpError, setmrpError] = useState("");
  const [PriceError, setPriceError] = useState("");
  const [optionIndex, setoptionIndex] = useState("");
  const [optionPress, setoptionPress] = useState(false);
  const [currentPacksizeIndex, setcurrentPacksizeIndex] = useState("");
  const [currImageIndex, setcurrImageIndex] = useState("");

  const [errorcolor, setErrorcolor] = useState("red");
  const [error, setError] = useState("");

  const [ProductDetails, setProductDetails] = useState("");
  const [ProductDetailsError, setProductDetailsError] = useState("");

  const [propDerails, setpropDerails] = useState("");
  const [propDerailsStatus, setpropDerailsStatus] = useState(false);
  const [resetield, setresetield] = useState(false);

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercategory(superCatShow[0].name);
      setSupercategoryid(superCatShow[0]._id);
    }
    if (categoryShow.length === 1) {
      setCategory(categoryShow[0].name);
      setCategoryid(categoryShow[0]._id);
    }
    if (subcategoryShow.length === 1) {
      setSubcategory(subcategoryShow[0].name);
      setSubcategoryid(subcategoryShow[0]._id);
    }
    if (brandShow.length === 1) {
      setBrand(brandShow[0].name);
    }
  }, [superCatShow, categoryShow, subcategoryShow, brandShow]);

  useEffect(() => {
    if (buttonPress === true) {
      if (supercategoryid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (categoryTagid === "") {
        setcategoryTagError("Required");
      } else {
        setcategoryTagError("");
      }
      if (brand === "") {
        setbrandError("Required");
      } else {
        setbrandError("");
      }
      if (checkUrl === "") {
        setSlugUrlError("Required");
      } else {
        setSlugUrlError("");
      }
      if (ProductDetails === "") {
        setProductDetailsError("Required");
      } else {
        setProductDetailsError("");
      }
    }

    if (optionPress === true) {
      let optionList = [...packsize];
      const findPackSixeIndex = optionList.findIndex(
        (data) => data.enabled === false
      );
      const packOptions = optionList[findPackSixeIndex].PackSizeOptions;
      const lastIndex = packOptions.findIndex((data) => data.enabled === false);
      setoptionNameError("");
      setmrpError("");
      setPriceError("");
      setoptionIndex(lastIndex);
      setcurrentPacksizeIndex(findPackSixeIndex);
      if (packOptions[lastIndex].packSizeOptionName === "") {
        setoptionNameError("error");
      } else {
        setoptionNameError("");
      }
      if (packOptions[lastIndex].Mrp === "") {
        setmrpError("error");
      } else {
        setmrpError("");
      }
      if (packOptions[lastIndex].Price === "") {
        setPriceError("error");
      } else {
        setPriceError("");
      }
    }

    if (optionSizePress === true) {
      //   const lastIndex = packsize.length - 1;
      const lastIndex = packsize.findIndex((data) => data.enabled === false);
      //   setoptionSizePress(false);
      setpacksizeIndex(lastIndex);
      setimageError("");
      setpacksizeError("");
      setcurrentPacksizeIndex(lastIndex);
      if (packsize[lastIndex].packSizeName === "") {
        setpacksizeError("error");
      } else {
        setpacksizeError("");
      }

      if (packsize[lastIndex].slider.length === 0) {
        setimageError("error");
      } else {
        setimageError("");
      }
    }
  }, [
    buttonPress,
    subcategoryid,
    categoryid,
    supercategoryid,
    brand,
    checkUrl,
    optionPress,
    packsize,
    currentPacksizeIndex,
    optionSizePress,
    ProductDetails,
    categoryTagid,
  ]);

  const getPropDetails = (values, status) => {
    if (status === true) {
      setpropDerails(values);
      setpropDerailsStatus(status);
    }
  };
  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    setSupercategoryid(value);
    console.log(value, "value");

    const textValue = superCatShow.find(
      (indCatShow) => indCatShow._id === value
    );
    if (textValue) {
      console.log(textValue, "textValue");
      console.log(textValue.name, "textValue.name");

      setSupercategory(textValue.name);
      console.log(textValue.name);

    }
    setpropDerails("");
    setpropDerailsStatus(false);
  };
  const selectCategory = (evt) => {
    const { value } = evt.target;
    setCategoryid(value);
    const textValue = catbysuper.find((indsubShow) => indsubShow._id === value);
    if (textValue) {
      setCategory(textValue.name);
    }
    setpropDerails("");
    setpropDerailsStatus(false);
  };

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
  }, [supercategoryid, categoryShow]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    setSubcategoryid(value);
    const textValue = subCat.find((indSubCat) => indSubCat._id === value);
    if (textValue) {
      setSubcategory(textValue.name);
    }
    setpropDerails("");
    setpropDerailsStatus(false);
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const selectCategoryTag = (evt) => {
    const { value } = evt.target;
    setCategoryTagid(value);
    console.log(value, "value");


    const textValue = categoryTagsShow.find(
      (indTagsCat) => indTagsCat._id === value
    );

    if (textValue) {
      console.log(textValue, "textValue");
      console.log(textValue.name, "textValue.name");

      setCategoryTag(textValue.name);
    }
    setpropDerails("");
    setpropDerailsStatus(false);
    setresetield(false);
  };

  useEffect(() => {
    const catTagbySubcat = categoryTagShow.filter(
      (subcat) => subcat.SubCategoryId === subcategoryid
    );
    setCategoryTagsShow(catTagbySubcat);

  }, [subcategoryid, categoryTagShow]);

  const selectBrand = (evt) => {
    const { value } = evt.target;
    setBrandId(value);
    const textValue = brandShow.find(
      (indBrandCat) => indBrandCat._id === value
    );
    if (textValue) {
      setBrand(textValue.name);
    }
  };

  const verifyUrl = async (e) => {
    const value = e.target.value;
    setProductname(value);
    setCheckUrl("");
    const groceryUrl = value
      .trim()
      .toLowerCase()
      .replace(" ", "-")
      .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
      .replace("--", "-")
      .replace("---", "-")
      .replace("----", "-")
      .replace("/", "-")
      .replace("//", "-")
      .replace("///", "-");

    if (groceryUrl !== "") {
      let response = await dispatch(validateProductSlugUrl(groceryUrl));
      if (response.payload.success === true) {
        setError("Product Already exist");
        setErrorcolor("red");
        setCheckUrl("");
      } else {
        setErrorcolor("");
        setError("");
        setCheckUrl(groceryUrl);
      }
    }
  };

  const handlePackCheck = (
    index,
    e,
    selected,
    psoptionStatus,
    psIndex,
    psName
  ) => {
    let temp = [...packsize];
    if (selected === "slider") {
      const files = Array.from(e.target.files);
      setimageLoading(false);
      setcurrImageIndex(index);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.readyState === 2) {
            const getImage = await dispatch(
              packsizeImages({ packsizeImage: reader.result })
            );
            if (getImage.payload.success) {
              let image = `${getImage.payload.packsizeImages}`;
              let getImages = [];
              getImages = [...getImages, image];
              const imageData = [...temp[index][selected], ...getImages];
              temp[index][selected] = imageData;
              temp[index].thumbnail = imageData[0];
            }
            setimageLoading(true);
          }
        };
        reader.readAsDataURL(file);
      });
    } else if (selected === "thumbnail") {
      temp[index][selected] = e;
    } else if (selected === "PsPrime") {
      if (temp.length > 1) {
        temp = temp.map((data) => {
          data.PsPrime = false;
          return data;
        });
        temp[index][selected] = true;
      }
    } else if (psoptionStatus === true) {
      temp = temp.map((data, cuurentIndex) => {
        if (cuurentIndex === index) {
          if (psName === "packSizeOptionName") {
            data.PackSizeOptions[psIndex][psName] = e.target.value;
          } else if (psName === "PsnPrime") {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                optiondata.PsnPrime = false;
                return optiondata;
              }
            );
            data.PackSizeOptions[psIndex][psName] = true;
          } else {
            if (e.target.value.startsWith(0)) {
              if (psName === "Mrp") {
                data.PackSizeOptions[psIndex][psName] = e.target.value
                  .replace(/\D/g, "")
                  .trim()
                  .slice(1);
                data.PackSizeOptions[psIndex].Price = ""
                  .replace(/\D/g, "")
                  .trim();
              } else {
                if (data.PackSizeOptions[psIndex].Mrp === "") {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                  data.PackSizeOptions[psIndex].Mrp = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                } else {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim()
                    .slice(1);
                }
              }
              if (
                data.PackSizeOptions[psIndex].Mrp !== "" &&
                data.PackSizeOptions[psIndex].Price !== "" &&
                Number(data.PackSizeOptions[psIndex].Mrp) >=
                Number(data.PackSizeOptions[psIndex].Price)
              ) {
                handleDiscount(cuurentIndex, psIndex);
              }
            } else {
              if (psName === "Mrp") {
                data.PackSizeOptions[psIndex][psName] = e.target.value
                  .replace(/\D/g, "")
                  .trim();
                data.PackSizeOptions[psIndex].Price = ""
                  .replace(/\D/g, "")
                  .trim();
              } else {
                if (data.PackSizeOptions[psIndex].Mrp === "") {
                  data.PackSizeOptions[psIndex].Mrp = e.target.value
                    .replace(/\D/g, "")
                    .trim();
                } else {
                  data.PackSizeOptions[psIndex][psName] = e.target.value
                    .replace(/\D/g, "")
                    .trim();
                }
              }

              if (
                data.PackSizeOptions[psIndex].Mrp !== "" &&
                data.PackSizeOptions[psIndex].Price !== "" &&
                Number(data.PackSizeOptions[psIndex].Mrp) >=
                Number(data.PackSizeOptions[psIndex].Price)
              ) {
                handleDiscount(cuurentIndex, psIndex);
              }
            }
          }
        }
        return data;
      });
      temp = temp;
    } else if (
      selected !== "slider" &&
      selected !== "thumbnail" &&
      selected !== "PsPrime" &&
      psoptionStatus === false
    ) {
      temp[index][selected] = e.target.value;
    }
    setPacksize(temp);
  };

  const handleDiscount = (lastIndex, findOptionIndex) => {
    let optionList = [...packsize];
    const Discount = Math.round(
      ((Number(optionList[lastIndex].PackSizeOptions[findOptionIndex].Mrp) -
        Number(optionList[lastIndex].PackSizeOptions[findOptionIndex].Price)) *
        100) /
      Number(optionList[lastIndex].PackSizeOptions[findOptionIndex].Mrp)
    );
    optionList[lastIndex].PackSizeOptions[findOptionIndex].Discount =
      String(Discount);
    setPacksize(optionList);
  };

  const handleNewPackOption = (e, currentIndex) => {
    let optionList = [...packsize];
    const packOptions = optionList[currentIndex].PackSizeOptions;
    // const lastIndex = packOptions.length - 1;
    const lastIndex = packOptions.findIndex((data) => data.enabled === false);
    setcurrentPacksizeIndex(currentIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(lastIndex);
    setoptionPress(true);

    if (packOptions[lastIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[lastIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[lastIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }

    if (
      packOptions[lastIndex].packSizeOptionName !== "" &&
      packOptions[lastIndex].Mrp !== "" &&
      packOptions[lastIndex].Price !== ""
    ) {
      if (
        Number(packOptions[lastIndex].Mrp) >=
        Number(packOptions[lastIndex].Price)
      ) {
        optionList = optionList.map((data, index) => {
          if (index === currentIndex) {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                optiondata.enabled = true;
                return optiondata;
              }
            );

            data.PackSizeOptions = [
              ...data.PackSizeOptions,
              {
                packSizeOptionName: "",
                PsnPrime: false,
                Mrp: "",
                Price: "",
                Discount: "",
                enabled: false,
              },
            ];
          }

          return data;
        });

        setPacksize(optionList);
        setoptionPress(false);
      } else {
        optionList = optionList.map((data, index) => {
          if (index === currentIndex) {
            data.PackSizeOptions = data.PackSizeOptions.map(
              (optiondata, opIndex) => {
                if (opIndex === lastIndex) {
                  optiondata.Price = "";
                }

                return optiondata;
              }
            );
          }
          return data;
        });
        setPacksize(optionList);
        setoptionPress(true);
      }
    } else {
      setoptionPress(true);
    }
  };

  const removeProductOptionClick = (e, crrentindex, curroptionIndex) => {
    let optionList = [...packsize];

    const currEditPackSizeIndex = optionList.findIndex(
      (data) => data.enabled === false
    );
    optionList = optionList.map((data, Index) => {
      if (Index === currEditPackSizeIndex) {
        data.PackSizeOptions = data.PackSizeOptions.filter(
          (data, optionindex) => optionindex !== curroptionIndex
        );
        const PackOptionLastIndex = data.PackSizeOptions.length - 1;
        data.PackSizeOptions[PackOptionLastIndex].enabled = false;
        const findprime = data.PackSizeOptions.find(
          (data) => data.PsnPrime === true
        );
        if (!findprime) {
          data.PackSizeOptions[PackOptionLastIndex].PsnPrime = true;
        }
      }
      return data;
    });
    setPacksize(optionList);
  };

  const editProductOptionClick = (e, currentIndex, curroptionIndex) => {
    let optionList = [...packsize];
    const packOptions = optionList[currentIndex].PackSizeOptions;
    const lastIndex = packOptions.length - 1;
    setcurrentPacksizeIndex(currentIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(lastIndex);
    setoptionPress(true);

    if (packOptions[lastIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[lastIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[lastIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }

    if (
      packOptions[lastIndex].packSizeOptionName !== "" &&
      packOptions[lastIndex].Mrp !== "" &&
      packOptions[lastIndex].Price !== ""
    ) {
      optionList = optionList.map((data, index) => {
        if (index === currentIndex) {
          data.PackSizeOptions = data.PackSizeOptions.map(
            (optiondata, opIndex) => {
              optiondata.enabled = true;
              return optiondata;
            }
          );
          data.PackSizeOptions[curroptionIndex].enabled = false;
        }

        return data;
      });
      setPacksize(optionList);
      setoptionPress(false);
    }
  };

  const handleNewPackSize = () => {
    // const lastIndex = packsize.length - 1;
    const lastIndex = packsize.findIndex((data) => data.enabled === false);
    let optionList = [...packsize];
    const packOptions = optionList[lastIndex].PackSizeOptions;
    const findOptionIndex = packOptions.findIndex(
      (data) => data.enabled === false
    );
    setoptionSizePress(true);
    setpacksizeIndex(lastIndex);
    setimageError("");
    setpacksizeError("");
    setcurrentPacksizeIndex(lastIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(findOptionIndex);
    setoptionPress(true);

    if (packsize[lastIndex].packSizeName === "") {
      setpacksizeError("error");
    } else {
      setpacksizeError("");
    }
    if (packsize[lastIndex].slider.length === 0) {
      setimageError("error");
    } else {
      setimageError("");
    }

    if (packOptions[findOptionIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[findOptionIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[findOptionIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }
    if (
      packsize[lastIndex].packSizeName !== "" &&
      packsize[lastIndex].slider.length !== 0 &&
      packOptions[findOptionIndex].packSizeOptionName !== "" &&
      packOptions[findOptionIndex].Mrp !== "" &&
      packOptions[findOptionIndex].Price !== ""
    ) {
      if (
        Number(packOptions[findOptionIndex].Mrp) >=
        Number(packOptions[findOptionIndex].Price)
      ) {
        optionList = optionList.map((data) => {
          data.enabled = true;
          data.PackSizeOptions = data.PackSizeOptions.map((optionPack) => {
            optionPack.enabled = true;
            return optionPack;
          });

          return data;
        });
        if (sameInamgeForAll === true) {
          setPacksize([
            ...packsize,
            {
              packSizeName: "",
              PsPrime: false,
              enabled: false,
              thumbnail: packsize[lastIndex].thumbnail,
              slider: packsize[lastIndex].slider,
              PackSizeOptions: [
                {
                  packSizeOptionName: "",
                  PsnPrime: true,
                  Mrp: "",
                  Price: "",
                  Discount: "",
                  enabled: false,
                },
              ],
            },
          ]);
        } else {
          setPacksize([
            ...packsize,
            {
              packSizeName: "",
              PsPrime: false,
              enabled: false,
              thumbnail: "",
              slider: [],
              PackSizeOptions: [
                {
                  packSizeOptionName: "",
                  PsnPrime: true,
                  Mrp: "",
                  Price: "",
                  Discount: "",
                  enabled: false,
                },
              ],
            },
          ]);
        }

        setoptionSizePress(false);
        setoptionPress(false);
      } else {
        optionList = optionList.map((data, index) => {
          if (index === lastIndex) {
            data.PackSizeOptions[findOptionIndex].Price = "";
          }
          return data;
        });
        setPacksize(optionList);
        setoptionPress(true);
        setoptionSizePress(true);
      }
    } else {
      setoptionPress(true);
      setoptionSizePress(true);
    }
  };

  const removeImageClick = (packindex, imageindex, image) => {
    let packOption = [...packsize];
    packOption = packOption.map((data, index) => {
      if (packindex === index) {
        if (data.thumbnail === image) {
          data.thumbnail = "";
        }
        data.slider = data.slider.filter(
          (imgData, imgindex) => imgindex !== imageindex
        );
        if (data.thumbnail === "" && data.slider.length > 0) {
          data.thumbnail = data.slider[0];
        }
      }

      return data;
    });
    setPacksize(packOption);
  };

  const removePackSize = (currentPackindex) => {
    let optionList = [...packsize];
    if (packsize.length > 1) {
      const currEditPackSizeIndex = optionList.findIndex(
        (data) => data.enabled === false
      );
      if (currEditPackSizeIndex === currentPackindex) {
        optionList = optionList.filter(
          (data, index) => index !== currentPackindex
        );
        const lastindex = optionList.length - 1;
        const lasrPackOptionindex =
          optionList[lastindex].PackSizeOptions.length - 1;
        optionList[lastindex].enabled = false;
        optionList[lastindex].PackSizeOptions[
          lasrPackOptionindex
        ].enabled = false;

        const findPrime = optionList.find((data) => data.PsPrime === true);

        if (!findPrime) {
          optionList[0].PsPrime = true;
        }
      } else {
        optionList = optionList.filter(
          (data, index) => index !== currentPackindex
        );
        const findPrime = optionList.find((data) => data.PsPrime === true);
        if (!findPrime) {
          optionList[0].PsPrime = true;
        }
      }
      setPacksize(optionList);
    }
  };
  const editPackSize = (currentselecPackIndex) => {
    const lastIndex = packsize.findIndex((data) => data.enabled === false);
    let optionList = [...packsize];
    const packOptions = optionList[lastIndex].PackSizeOptions;
    const findOptionIndex = packOptions.findIndex(
      (data) => data.enabled === false
    );
    setoptionSizePress(true);
    setpacksizeIndex(lastIndex);
    setimageError("");
    setpacksizeError("");
    setcurrentPacksizeIndex(lastIndex);
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(findOptionIndex);
    setoptionPress(true);

    if (packsize[lastIndex].packSizeName === "") {
      setpacksizeError("error");
    } else {
      setpacksizeError("");
    }
    if (packsize[lastIndex].slider.length === 0) {
      setimageError("error");
    } else {
      setimageError("");
    }
    if (packOptions[findOptionIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[findOptionIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[findOptionIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }
    if (
      packsize[lastIndex].packSizeName !== "" &&
      packsize[lastIndex].slider.length !== 0 &&
      packOptions[findOptionIndex].packSizeOptionName !== "" &&
      packOptions[findOptionIndex].Mrp !== "" &&
      packOptions[findOptionIndex].Price !== ""
    ) {
      optionList = optionList.map((data, index) => {
        data.enabled = true;
        data.PackSizeOptions = data.PackSizeOptions.map((optionPack) => {
          optionPack.enabled = true;
          return optionPack;
        });

        const lasrPackOptionindex =
          optionList[currentselecPackIndex].PackSizeOptions.length - 1;
        optionList[currentselecPackIndex].enabled = false;
        optionList[currentselecPackIndex].PackSizeOptions[
          lasrPackOptionindex
        ].enabled = false;

        return data;
      });
      setPacksize(optionList);
      setoptionSizePress(false);
      setoptionPress(false);
    }
  };

  const handlesubmit = async (e) => {
    console.log("datasendeding");

    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    setoptionSizePress(true);
    setoptionPress(true);
    if (supercategoryid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (categoryTagid === "") {
      setcategoryTagError("Required");
    } else {
      setcategoryTagError("");
    }
    if (brand === "") {
      setbrandError("Required");
    } else {
      setbrandError("");
    }
    if (checkUrl === "") {
      setSlugUrlError("Required");
    } else {
      setSlugUrlError("");
    }

    let optionList = [...packsize];
    const findPackSixeIndex = optionList.findIndex(
      (data) => data.enabled === false
    );
    const packOptions = optionList[findPackSixeIndex].PackSizeOptions;
    const lastIndex = packOptions.findIndex((data) => data.enabled === false);
    setcurrentPacksizeIndex(findPackSixeIndex);
    setpacksizeIndex(findPackSixeIndex);
    setimageError("");
    setpacksizeError("");
    setoptionNameError("");
    setmrpError("");
    setPriceError("");
    setoptionIndex(lastIndex);

    if (packsize[findPackSixeIndex].packSizeName === "") {
      setpacksizeError("error");
    } else {
      setpacksizeError("");
    }
    if (packsize[findPackSixeIndex].slider.length === 0) {
      setimageError("error");
    } else {
      setimageError("");
    }

    if (packOptions[lastIndex].packSizeOptionName === "") {
      setoptionNameError("error");
    } else {
      setoptionNameError("");
    }
    if (packOptions[lastIndex].Mrp === "") {
      setmrpError("error");
    } else {
      setmrpError("");
    }
    if (packOptions[lastIndex].Price === "") {
      setPriceError("error");
    } else {
      setPriceError("");
    }

    let findSupercatForm = "";

    let PrimeryTag = "";
    let PrimeryTagId = "";
    // if (subcategoryid !== "") {
    //   const findSubcat = subcategoryShow.find(
    //     (data) => data._id === subcategoryid
    //   );

    //   let createprimetag = findSubcat.category
    //     .trim()
    //     .toLowerCase()
    //     .replace(" ", "-")
    //     .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
    //     .replace("--", "-")
    //     .replace("---", "-")
    //     .replace("----", "-")
    //     .replace("/", "-")
    //     .replace("//", "-")
    //     .replace("///", "-");
    //   createprimetag = `${createprimetag}-${findSubcat.slugUrl}`;
    //   createprimetag = createprimetag
    //     .trim()
    //     .toLowerCase()
    //     .replace(" ", "-")
    //     .replace(/[.*+&?^ $@#%^!'{}()|[\]\\]/g, "-")
    //     .replace("--", "-")
    //     .replace("---", "-")
    //     .replace("----", "-")
    //     .replace("/", "-")
    //     .replace("//", "-")
    //     .replace("///", "-");
    //   PrimeryTag = createprimetag;
    //   if (createprimetag !== "") {
    //     const findPrimeId = categoryTagTotal.find(
    //       (data) => data.slugUrl === createprimetag

    //     );
    //     PrimeryTagId = findPrimeId._id
    //   
    //   }
    // }

    let getPackarrayLength = packsize.length;
    let satusMutli = false;
    if (getPackarrayLength > 1) {
      satusMutli = true;
    }

    let Clothing = "";
    let Beauty = "";
    let Electronics = "";
    let Grocery = "";
    let Medicines = "";
    let Home_and_furniture = "";
    let Footwear = "";
    let Toys_games = "";



    if (supercategory === "Clothing") {
      Clothing = propDerails;
    } else if (supercategory === "Beauty") {
      Beauty = propDerails;
    } else if (supercategory === "Electronics") {
      Electronics = propDerails;
    } else if (supercategory === "Grocery") {
      Grocery = propDerails;
    } else if (supercategory === "Medicines") {
      Medicines = propDerails;
    } else if (supercategory === "Home and furniture") {
      Home_and_furniture = propDerails;
    } else if (supercategory === "Footwear") {
      Footwear = propDerails;
    } else if (supercategory === "Toys & games") {
      Toys_games = propDerails;
    }

    console.log(propDerailsStatus, "propDerailsStatus");

    if (
      supercategoryid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      checkUrl !== "" &&
      brand !== "" &&
      categoryTagid !== "" &&
      // PrimeryTagId !== "" &&
      propDerailsStatus !== false &&
      packsize[findPackSixeIndex].packSizeName !== "" &&
      packsize[findPackSixeIndex].slider.length !== 0 &&
      packOptions[lastIndex].packSizeOptionName !== "" &&
      packOptions[lastIndex].Mrp !== "" &&
      packOptions[lastIndex].Price !== ""
    ) {
      if (
        Number(packOptions[lastIndex].Mrp) >=
        Number(packOptions[lastIndex].Price)
      ) {
        const formData = {
          name: productname,
          slugUrl: checkUrl,
          superCategoryId: supercategoryid,
          superCategory: supercategory,
          category: category,
          categoryId: categoryid,
          SubCategory: subcategory,
          SubCategoryId: subcategoryid,
          CategoryTag: categoryTag,
          CategoryTagId: categoryTagid,
          PrimeryTag: categoryTag,
          PrimeryTagId: categoryTagid,
          multi: satusMutli,
          packSize: packsize,
          brand: brand,
          metaTitle: productname,
          metaKeyword: productname,
          metaDesc: productname,
          clothing: Clothing,
          homeandfurniture: Home_and_furniture,
          electronics: Electronics,
          medicine: Medicines,
          beauty: Beauty,
          footwear: Footwear,

        };

        console.log(formData, "formData");


        const data = await dispatch(productPost(formData));

        if (data.payload.success) {
          alert("Product Added");
          // addToast("Product Added", {
          //   appearance: "success",
          //   autoDismiss: true,
          //   autoDismissTimeout: 2000,
          //   placement: "bottom-right",
          //   style: { zIndex: 99999 },
          // });
          setresetield(true);
          setbuttonPress(false);
          setbuttonLoading(false);
          setoptionSizePress(false);
          setoptionPress(false);

          setPacksize([
            {
              packSizeName: "",
              PsPrime: true,
              thumbnail: "",
              slider: [],
              enabled: false,
              PackSizeOptions: [
                {
                  packSizeOptionName: "",
                  PsnPrime: true,
                  Mrp: "",
                  Price: "",
                  Discount: "",
                  enabled: false,
                },
              ],
            },
          ]);

          if (superCatShow.length > 1) {
            setSupercategory("");
            setSupercategoryid("");
          }
          if (categoryShow.length > 1) {
            setCategory("");
            setCategoryid("");
          }
          if (subcategoryShow.length > 1) {
            setSubcategory("");
            setSubcategoryid("");
          }
          if (categoryTagShow.length > 1) {
            setCategoryTag("");
            setCategoryTagid("");
          }
          if (brandShow.length > 1) {
            setBrand("");
          }
          setSubcategory("");
          setSubcategoryid("");
          setProductname("");
          setCheckUrl("");
          setSubcategory("");
          setCategory("");
          setProductDetails("");
          setCategoryid("");
          setpropDerails("");
          setBrand("");
          setCheckUrl("");
          setSupercategory("");
          setSupercategoryid("");
          setCategoryTag("");
          setBrandId("");
          setCategoryTagid("");
          setpropDerailsStatus(false);
        } else {
          alert("product is not added");
          setbuttonLoading(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      } else {
        optionList = optionList.map((data, index) => {
          if (index === findPackSixeIndex) {
            data.PackSizeOptions[lastIndex].Price = "";
          }
          return data;
        });
        setPacksize(optionList);
        setbuttonLoading(false);
        setoptionSizePress(true);
        setoptionPress(true);
      }
    } else {
      setbuttonLoading(false);
      setoptionSizePress(true);
      setoptionPress(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Product</h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          <FormControl
                            fullWidth
                            style={{ marginTop: "12px" }}
                            className="textfield"
                          >
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Super Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              // value={superCatShow._id}
                              value={supercategoryid}
                              className={
                                supcategroyError !== "" ? "error_class" : ""
                              }
                              label="Select Super Category"
                              onChange={(e) => selectsupCategory(e)}
                              style={{ height: "35px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {superCatShow.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={test._id}
                                  selected={supercategoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                    {categoryShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          <FormControl
                            fullWidth
                            style={{ marginTop: "12px" }}
                            className="textfield"
                          >
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              // value={catbysuper._id}
                              value={categoryid}
                              label="Select Category"
                              onChange={(e) => selectCategory(e)}
                              style={{ height: "35px" }}
                              className={
                                categroyError !== "" ? "error_class" : ""
                              }
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {catbysuper.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={test._id}
                                  name={test.name}
                                  selected={categoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}

                    {subcategoryShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          <FormControl
                            fullWidth
                            style={{ marginTop: "12px" }}
                            className="textfield"
                          >
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Sub Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              value={subcategoryid}
                              label=" Select Sub Category"
                              onChange={(e) => selectSubCategory(e)}
                              style={{ height: "35px" }}
                              className={
                                subcategroyError !== "" ? "error_class" : ""
                              }
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {subCat.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={test._id}
                                  name={test.name}
                                  selected={subcategoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                    {categoryTagShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          <FormControl
                            fullWidth
                            style={{ marginTop: "12px" }}
                            className="textfield"
                          >
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Tag
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Tag"
                              onChange={(e) => {
                                selectCategoryTag(e);
                              }}
                              // value={categoryTagsShow._id}
                              value={categoryTagid}
                              style={{ height: "35px" }}
                              className={
                                categoryTagError !== "" ? "error_class" : ""
                              }
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {categoryTagsShow.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={test._id}
                                  name={test.name}
                                  selected={categoryTagid === test.name}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}

                    {brandShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          <FormControl
                            fullWidth
                            style={{ marginTop: "12px" }}
                            className="textfield"
                          >
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Brand
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Brand"
                              onChange={(e) => {
                                selectBrand(e);
                              }}
                              value={brandId}
                              style={{ height: "35px" }}
                              className={brandError !== "" ? "error_class" : ""}
                              inputProps={{ style: { textAlign: "center" } }}
                            >
                              {brandShow.map((test, index) => (
                                <MenuItem
                                  value={test._id}
                                  key={test._id}
                                  name={test.name}
                                  selected={brand === test.name}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="g-2 m-2"></Row>
                  <Row className="g-2  m-2">
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Product Name<span style={{ color: "red" }}>*</span>
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter product name"
                            variant="outlined"
                            className={slugUrlError !== "" ? "error_class" : ""}
                            style={{ width: "100%" }}
                            type="text"
                            id="text"
                            value={productname}
                            onChange={(e) => verifyUrl(e)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md>
                      <div className="space-y-1">
                        {/* <label
                          htmlFor="fName"
                          className="text-sm font-medium text-default-900"
                          style={{ fontWeight: '500', color: '#000066' }}
                        >
                          Product Details<span style={{ color: "red" }}>*</span>
                        </label> */}
                        <div className="textfieldcontainer">
                          <TextField
                            label="Enter product Details"
                            variant="outlined"
                            className={slugUrlError !== "" ? "error_class" : ""}
                            style={{ width: "100%" }}
                            type="text"
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            // defaultValue="Default Value"
                            value={ProductDetails}
                            onChange={(e) => setProductDetails(e.target.value)}
                          // className={classes.textfield}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <MainSupercatProduct
                    supercategory={supercategory}
                    category={category}
                    subcategory={subcategory}
                    categoryTag={categoryTag}
                    buttonPress={buttonPress}
                    subcategoryid={subcategoryid}
                    categoryid={categoryid}
                    supercategoryid={supercategoryid}
                    categoryTagid={categoryTagid}
                    resetield={resetield}
                    getPropDetails={getPropDetails}
                  />
                  <div
                    className="card-footer mt-4"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <h3 className="text-center">Product Sizes</h3>
                    {packsize[0].thumbnail !== "" && (
                      <>
                        <input
                          type="checkbox"
                          className="me-1 mx-4"
                          value="trending"
                          style={{ cursor: "pointer" }}
                          checked={sameInamgeForAll === true}
                          onChange={(e) =>
                            setsameInamgeForAll(!sameInamgeForAll)
                          }
                        />
                        ({" "}
                        <h3 className="text-center">
                          Use last Pack Option Images
                        </h3>
                        )
                      </>
                    )}
                  </div>

                  <div>
                    {packsize.map((packDetails, index) => (
                      <div>
                        <>
                          <Row className="g-2  m-2" key={index}>
                            {/* <Col md>
                              <Form.Label htmlFor="disabledTextInput">
                                {index + 1}) Product Size Name{" "}
                                <span
                                  data-tooltip-id="my-tooltip-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaInfoCircle color="blue" />
                                </span>
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={packDetails?.packSizeName}
                                disabled={packDetails?.enabled === true}
                                className={
                                  packsizeError !== "" &&
                                    packsizeIndex === index
                                    ? "error_class"
                                    : ""
                                }
                                onChange={(e) =>
                                  handlePackCheck(
                                    index,
                                    e,
                                    "packSizeName",
                                    false,
                                    "",
                                    ""
                                  )
                                }
                              />
                            </Col> */}
                            <Col md>
                              <div className="space-y-1" key={index}>
                                <label
                                  htmlFor="fName"
                                  className="text-sm font-medium text-default-900"
                                  style={{
                                    fontWeight: "500",
                                    color: "#000066",
                                  }}
                                >
                                  Product Size Name{" "}
                                </label>
                                &nbsp;&nbsp;
                                <span
                                  data-tooltip-id="my-tooltip-1"
                                  style={{ cursor: "pointer" }}
                                >
                                  <FaInfoCircle color="blue" />
                                </span>
                                <span style={{ color: "red" }}>*</span>
                                <div className="textfieldcontainer">
                                  <TextField
                                    label="Product Size Name"
                                    variant="outlined"
                                    disabled={packDetails?.enabled === true}
                                    className={
                                      packsizeError !== "" &&
                                        packsizeIndex === index
                                        ? "error_class"
                                        : ""
                                    }
                                    style={{ width: "100%" }}
                                    type="text"
                                    id="text"
                                    value={packDetails?.packSizeName}
                                    onChange={(e) =>
                                      handlePackCheck(
                                        index,
                                        e,
                                        "packSizeName",
                                        false,
                                        "",
                                        ""
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md>
                              <Form.Label>
                                {" "}
                                Product Size Image
                                <span style={{ color: "red" }}>*</span>
                              </Form.Label>
                              <Form.Control
                                type="file"
                                placeholder="No File Choosen"
                                disabled={packDetails?.enabled === true}
                                className={
                                  imageError !== "" && packsizeIndex === index
                                    ? "error_class"
                                    : ""
                                }
                                onChange={(e) =>
                                  handlePackCheck(
                                    index,
                                    e,
                                    "slider",
                                    false,
                                    "",
                                    ""
                                  )
                                }
                                multiple
                              />
                              <p>
                                * The image width and height should be 500px *
                                500px
                              </p>
                              <div>
                                {!imageLoading && currImageIndex === index ? (
                                  <div>
                                    <h6>Image Loading...</h6>
                                  </div>
                                ) : (
                                  <>
                                    <div className="imagecontainer">
                                      {packDetails?.slider.map(
                                        (image, imgindex) => (
                                          <>
                                            <div className="imageBox">
                                              <img
                                                src={image}
                                                height={100}
                                                alt="img"
                                                key={imgindex}
                                                className="mx-1 my-1"
                                                onClick={(e) =>
                                                  handlePackCheck(
                                                    index,
                                                    image,
                                                    "thumbnail",
                                                    false,
                                                    "",
                                                    ""
                                                  )
                                                }
                                                style={
                                                  image ===
                                                    packDetails?.thumbnail
                                                    ? {
                                                      border:
                                                        "2px solid green",
                                                      cursor: "pointer",
                                                      // position: "relative",
                                                    }
                                                    : {
                                                      cursor: "pointer",
                                                      // position: "relative",
                                                    }
                                                }
                                              />
                                              {packDetails?.enabled === true ? (
                                                <></>
                                              ) : (
                                                <>
                                                  <div
                                                    className="imgremBtn"
                                                    onClick={() =>
                                                      removeImageClick(
                                                        index,
                                                        imgindex,
                                                        image
                                                      )
                                                    }
                                                  >
                                                    X
                                                  </div>
                                                </>
                                              )}
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </Col>
                          </Row>
                          <Row className="g-2  m-2">
                            <Col>
                              <input
                                type="checkbox"
                                className="me-1"
                                value="trending"
                                disabled={packDetails?.enabled === true}
                                checked={packDetails?.PsPrime === true}
                                onChange={(e) =>
                                  handlePackCheck(
                                    index,
                                    true,
                                    "PsPrime",
                                    false,
                                    "",
                                    ""
                                  )
                                }
                              />
                              <Form.Label>Product Pack Size Prime</Form.Label>
                            </Col>
                          </Row>
                          {packDetails?.PackSizeOptions.map(
                            (otion, optionindex) => (
                              <>
                                <div key={optionindex}>
                                  <Row className="g-2  m-2">
                                    <Col md>
                                      <div className="space-y-1">
                                        {/* <label
                                          htmlFor="fName"
                                          className="text-sm font-medium text-default-900"
                                          style={{ fontWeight: '500', color: '#000066' }}
                                        >
                                          Option Name<span style={{ color: "red" }}>*</span>
                                        </label> */}
                                        <div className="textfieldcontainer">
                                          <TextField
                                            label=" Option Name"
                                            variant="outlined"
                                            className={
                                              optionNameError !== "" &&
                                                currentPacksizeIndex === index &&
                                                optionIndex === optionindex
                                                ? "error_class"
                                                : ""
                                            }
                                            type="text"
                                            id="text"
                                            disabled={otion?.enabled === true}
                                            value={otion?.packSizeOptionName}
                                            onChange={(e) =>
                                              handlePackCheck(
                                                index,
                                                e,
                                                "PackSizeOptions",
                                                true,
                                                optionindex,
                                                "packSizeOptionName"
                                              )
                                            }
                                          // className={classes.textfield}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md>
                                      <div className="space-y-1">
                                        {/* <label
                                          htmlFor="fName"
                                          className="text-sm font-medium text-default-900"
                                          style={{ fontWeight: '500', color: '#000066' }}
                                        >
                                          Mrp  <span style={{ color: "red" }}>*</span>
                                        </label> */}
                                        <div className="textfieldcontainer">
                                          <TextField
                                            label="Mrp"
                                            variant="outlined"
                                            className={
                                              mrpError !== "" &&
                                                currentPacksizeIndex === index &&
                                                optionIndex === optionindex
                                                ? "error_class"
                                                : ""
                                            }
                                            value={otion?.Mrp}
                                            disabled={otion?.enabled === true}
                                            type="text"
                                            id="text"
                                            onChange={(e) =>
                                              handlePackCheck(
                                                index,
                                                e,
                                                "PackSizeOptions",
                                                true,
                                                optionindex,
                                                "Mrp"
                                              )
                                            }

                                          // className={classes.textfield}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md>
                                      <div className="space-y-1">
                                        {/* <label
                                          htmlFor="fName"
                                          className="text-sm font-medium text-default-900"
                                          style={{ fontWeight: '500', color: '#000066' }}
                                        >
                                          Price
                                          <span style={{ color: "red" }}>*</span>
                                        </label> */}
                                        <div className="textfieldcontainer">
                                          <TextField
                                            label="Price"
                                            variant="outlined"
                                            className={
                                              PriceError !== "" &&
                                                currentPacksizeIndex === index &&
                                                optionIndex === optionindex
                                                ? "error_class"
                                                : ""
                                            }
                                            type="text"
                                            id="text"
                                            value={otion?.Price}
                                            disabled={otion?.enabled === true}
                                            max={otion?.Mrp}
                                            min="0"
                                            onChange={(e) =>
                                              handlePackCheck(
                                                index,
                                                e,
                                                "PackSizeOptions",
                                                true,
                                                optionindex,
                                                "Price"
                                              )
                                            }
                                          // className={classes.textfield}
                                          />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div style={{ marginTop: "6vh" }}>
                                        <input
                                          type="checkbox"
                                          className="me-1"
                                          value="trending"
                                          disabled={otion?.enabled === true}
                                          checked={otion?.PsnPrime === true}
                                          onChange={(e) => {
                                            handlePackCheck(
                                              index,
                                              true,
                                              "PackSizeOptions",
                                              true,
                                              optionindex,
                                              "PsnPrime"
                                            );
                                          }}
                                        />
                                        <Form.Label>Prime</Form.Label>
                                      </div>
                                    </Col>
                                    {packDetails?.PackSizeOptions.length !==
                                      1 && packDetails?.enabled === false ? (
                                      <>
                                        <Col md>
                                          <Button
                                            className="btn btn-danger"
                                            style={{ marginTop: "5vh" }}
                                            onClick={(e) =>
                                              removeProductOptionClick(
                                                e,
                                                index,
                                                optionindex
                                              )
                                            }
                                          >
                                            <div>
                                              <MdOutlineRemove />
                                            </div>
                                          </Button>
                                          <Button
                                            className="btn btn-primary mx-3"
                                            style={{
                                              marginTop: "5vh",
                                            }}
                                            onClick={(e) =>
                                              editProductOptionClick(
                                                e,
                                                index,
                                                optionindex
                                              )
                                            }
                                          >
                                            <div>
                                              <CiEdit
                                                style={{ fontSize: "18px" }}
                                              />
                                            </div>
                                          </Button>
                                        </Col>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Row>
                                </div>
                              </>
                            )
                          )}
                          {packDetails?.enabled === false ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                {packsize.length > 1 ? (
                                  <>
                                    <Button
                                      className="btn  btn-danger m-3"
                                      onClick={() => removePackSize(index)}
                                    >
                                      Remove Product Size
                                    </Button>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <Button
                                  className="btn  btn-primary m-3"
                                  onClick={(e) => handleNewPackOption(e, index)}
                                // style={{ background: "#000066", borderColor: "#000066" }}
                                >
                                  Add Option
                                </Button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                <Button
                                  className="btn  btn-danger m-3"
                                  onClick={() => removePackSize(index)}
                                >
                                  Remove Product Size
                                </Button>
                                <Button
                                  className="btn  btn-primary m-3"
                                  onClick={() => editPackSize(index)}
                                >
                                  Edit Product Size
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    ))}

                    <ReactTooltip
                      id="my-tooltip-1"
                      place="top"
                      variant="info"
                      content="Academic match is provided on a rating scale from 0-100 (100 is the best match) "
                      style={{
                        width: "50vh",
                      }}
                    />

                    <Button
                      className="btn  btn-primary m-3 "
                      onClick={(e) => handleNewPackSize(e)}
                    // style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add More Product Pack size
                    </Button>
                  </div>
                </div>
                <div className="card-footer">
                  {buttonLoading ? (
                    <button
                      className="btn btn-danger"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Product adding...
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger"
                      type="submit"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add Product
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddProduct;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  romanTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "3XS" },
    { label: "XXS" },
    { label: "XS" },
    { label: "XS/S" },
    { label: "S" },
    { label: "XS/M" },
    { label: "S/M" },
    { label: "M" },
    { label: "M/L" },
    { label: "L" },
    { label: "S/L" },
    { label: "L/XL" },
    { label: "XL" },
    { label: "XL/XXL" },
    { label: "XXL" },
    { label: "3XL" },
    { label: "4XL" },
    { label: "4XL/5XL" },
    { label: "5XL" },
    { label: "6XL" },
    { label: "7XL" },
    { label: "8XL" },
    { label: "9XL" },
    { label: "10XL" },
  ],

  yearsTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "1-2Y" },
    { label: "2-3Y" },
    { label: "3-4Y" },
    { label: "4-5Y" },
    { label: "5-6Y" },
    { label: "6-7Y" },
    { label: "7-8Y" },
    { label: "8-9Y" },
    { label: "9-10Y" },
    { label: "10-11Y" },
    { label: "11-12Y" },
    { label: "12-13Y" },
    { label: "13-14Y" },
    { label: "14-15Y" },
    { label: "15-16Y" },
  ],
  RegularTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "24" },
    { label: "25" },
    { label: "26" },
    { label: "27" },
    { label: "28" },
    { label: "29" },
    { label: "30" },
    { label: "31" },
    { label: "32" },
    { label: "33" },
    { label: "34" },
    { label: "35" },
    { label: "36" },
    { label: "37" },
    { label: "38" },
    { label: "39" },
    { label: "40" },
    { label: "41" },
    { label: "42" },
    { label: "43" },
    { label: "44" },
    { label: "45" },
    { label: "46" },
    { label: "47" },
    { label: "48" },
    { label: "49" },
    { label: "50" },
    { label: "51" },
    { label: "52" },
  ],
  innerTypeMessurements: [
    { label: "ONESIZE" },
    { label: "PACK" },
    { label: "28A" },
    { label: "28AA" },
    { label: "28B" },
    { label: "28C" },
    { label: "28D" },
    { label: "28E" },
    { label: "30A" },
    { label: "30AA" },
    { label: "30B" },
    { label: "30C" },
    { label: "30D" },
    { label: "30DD" },
    { label: "30E" },
    { label: "30F" },
    { label: "30FF" },
    { label: "30G" },
    { label: "30J" },
    { label: "30JJ" },
    { label: "30K" },
    { label: "32A" },
    { label: "32AA" },
    { label: "32B" },
    { label: "32C" },
    { label: "32D" },
    { label: "32DD" },
    { label: "32DDD" },
    { label: "32E" },
    { label: "32F" },
    { label: "32FF" },
    { label: "32G" },
    { label: "32H" },
    { label: "32J" },
    { label: "32JJ" },
    { label: "32K" },
    { label: "32Z" },
    { label: "34A" },
    { label: "34B" },
    { label: "34C" },
    { label: "34D" },
    { label: "34DD" },
    { label: "34DDD" },
    { label: "34E" },
    { label: "34F" },
    { label: "34FF" },
    { label: "34G" },
    { label: "34GG" },
    { label: "34H" },
    { label: "34JJ" },
    { label: "34K" },
    { label: "34Z" },
    { label: "36A" },
    { label: "36B" },
    { label: "36C" },
    { label: "36D" },
    { label: "36DD" },
    { label: "36DDD" },
    { label: "36E" },
    { label: "36F" },
    { label: "36FF" },
    { label: "36G" },
    { label: "36GG" },
    { label: "36H" },
    { label: "36J" },
    { label: "36JJ" },
    { label: "36K" },
    { label: "36Z" },
    { label: "38A" },
    { label: "38B" },
    { label: "38C" },
    { label: "38D" },
    { label: "38DD" },
    { label: "38E" },
    { label: "38F" },
    { label: "38G" },
    { label: "38H" },
    { label: "38HH" },
    { label: "38J" },
    { label: "38JJ" },
    { label: "38K" },
    { label: "38Z" },
    { label: "39B" },
    { label: "40A" },
    { label: "40B" },
    { label: "40C" },
    { label: "40D" },
    { label: "40DD" },
    { label: "40E" },
    { label: "40F" },
    { label: "40FF" },
    { label: "40G" },
    { label: "40GG" },
    { label: "40H" },
    { label: "40HH" },
    { label: "40JJ" },
    { label: "40K" },
    { label: "40Z" },
    { label: "42A" },
    { label: "42B" },
    { label: "42C" },
    { label: "42D" },
    { label: "42DD" },
    { label: "42E" },
    { label: "42F" },
    { label: "42FF" },
    { label: "42G" },
    { label: "42GG" },
    { label: "42H" },
    { label: "42Z" },
    { label: "43B" },
    { label: "44A" },
    { label: "44B" },
    { label: "44C" },
    { label: "44D" },
    { label: "44DD" },
    { label: "44E" },
    { label: "44F" },
    { label: "44FF" },
    { label: "44G" },
    { label: "44GG" },
    { label: "44H" },
    { label: "44Z" },
    { label: "46A" },
    { label: "46B" },
    { label: "46C" },
    { label: "46D" },
    { label: "46DD" },
    { label: "46E" },
    { label: "46F" },
    { label: "46G" },
    { label: "48A" },
    { label: "48B" },
    { label: "48C" },
    { label: "48D" },
    { label: "48DD" },
    { label: "48E" },
    { label: "50A" },
    { label: "50B" },
    { label: "50C" },
    { label: "50D" },
    { label: "50DD" },
    { label: "50E" },
  ],

  sleevestype: [
    { label: "3/4 Sleeve" },
    { label: "Full Sleeve" },
    { label: "Half Sleeve" },
    { label: "Short Sleeve" },
    { label: "Sleeveless" },
  ],

  sleeve_styles: [
    { label: "Set-In Sleeve" },
    { label: "Raglan Sleeve" },
    { label: "Kimono Sleeve" },
    { label: "Bishop Sleeve" },
    { label: "Bell Sleeve" },
    { label: "Puff Sleeve" },
    { label: "Cold Shoulder Sleeve" },
    { label: "Off-the-Shoulder Sleeve" },
    { label: "Cape Sleeve" },
  ],
  top_shapes: [
    { label: "T-shirt" },
    { label: "Blouse" },
    { label: "Tank Top" },
    { label: "Crop Top" },
    { label: "Peplum Top" },
    { label: "Off-the-Shoulder Top" },
    { label: "Wrap Top" },
    { label: "Halter Top" },
    { label: "Bodysuit" },
    { label: "Tube Top" },
    { label: "High-Low Top" },
    { label: "Poncho Top" },
  ],
  top_types: [
    { label: "Indo-Western Suits" },
    { label: "Saree" },
    { label: "Salwar Kameez" },
    { label: "Anarkali Suit" },
    { label: "Patiala Suit" },
    { label: "Palazzo Suit" },
    { label: "Kurti with Cigarette Pants" },
    { label: "Cape top Saree" },
    { label: "Lehengas" },
    { label: "Kurti and Churidar" },
    { label: "Women’s Sherwani" },
    { label: "Indian Gowns" },
    { label: "Lancha" },
    { label: "Sharara and Gharara Suits" },
    { label: "Maxi Gown" },
    { label: "Straight" },
    { label: "Denim Jacket" },
  ],
  dress_designs: [
    { label: "A-line Dress" },
    { label: "Shift Dress" },
    { label: "Sheath Dress" },
    { label: "Wrap Dress" },
    { label: "Fit and Flare Dress" },
    { label: "Maxi Dress" },
    { label: "Bodycon Dress" },
    { label: "High-Low Dress" },
    { label: "Mermaid Dress" },
    { label: "Empire Waist Dress" },
    { label: "T-shirt Dress" },
    { label: "Pinafore Dress" },
  ],

  necktype: [
    { label: "Asymmetrical" },
    { label: "Boat neckline" },
    { label: "Collar" },
    { label: "Cowl neck" },
    { label: "Halter neckline" },
    { label: "Illusion" },
    { label: "Jewel" },
    { label: "Keyhole neckline" },
    { label: "Off-the-shoulder" },
    { label: "One shoulder" },
    { label: "Plunging" },
    { label: "Queen Anne" },
    { label: "Round neck or Crew neck" },
    { label: "Scalloped neckline" },
    { label: "Scoop neckline" },
    { label: "Spaghetti straps" },
    { label: "Split Crew neck" },
    { label: "Square neck" },
    { label: "Strapless" },
    { label: "Surplice" },
    { label: "Sweetheart neckline" },
    { label: "Tie Bow neckline" },
    { label: "Turtleneck (High Neckline)" },
    { label: "U-Shaped neckline" },
    { label: "V-neck" },
  ],
  pritsandPatterns: [
    { label: "Animal Print" },
    { label: "Applique" },
    { label: "Blocked Printed" },
    { label: "Checkered" },
    { label: "Color Block" },
    { label: "Digital Print" },
    { label: "Dyed" },
    { label: "Embellished" },
    { label: "Embroidered" },
    { label: "Floral Print" },
    { label: "Geometric Print" },
    { label: "Graphic Print" },
    { label: "Hand Painted" },
    { label: "Ombre" },
    { label: "Paisley" },
    { label: "Polka Print" },
    { label: "Printed" },
    { label: "Self Design" },
    { label: "Solid/Plain" },
    { label: "Striped" },
    { label: "Temple Border" },
    { label: "Woven" },
    { label: "Yoke Design" },
  ],
  slit_styles: [
    { label: "Side mini slit" },
    { label: "Multiple slit" },
    { label: "Back high slit" },
    { label: "Back mini slit" },
    { label: "Front mini slit" },
    { label: "Front high slit" },
    { label: "M-slit/Double slit" },
    { label: "Waist slit" },
    { label: "Ruche slit" },
    { label: "Flouncing slit" },
    { label: "Asymmetrical slit" },
    { label: "Symmetrical slit" },
    { label: "Lace up slit" },
    { label: "Tape slit" },
  ],
  length_styles: [
    { label: "ankle-length" },
    { label: "knee-length" },
    { label: "capri" },
    { label: "mid-calf" },
    { label: "stirrup" },
    { label: "athleisure" },
    { label: "faux-leather" },
    { label: "footed" },
    { label: "rugged" },
    { label: "woolen" },
    { label: "Regular" },
    { label: "Calf Length" },
  ],
  hemline_styles: [
    { label: "A-line Hem" },
    { label: "Asymmetrical Hemline" },
    { label: "Bubble Hem" },
    { label: "Fishtail hem" },
    { label: "Flared hemline" },
    { label: "Handkerchief hem" },
    { label: "High-Low hem" },
    { label: "Raw edge hem" },
    { label: "Ruffled hemline" },
    { label: "Sharkbite hem" },
    { label: "Shirt tail hemline" },
    { label: "Slant hemline" },
    { label: "Slit hemline" },
    { label: "Step hem" },
    { label: "Tiered Bottom Hem" },
  ],
  occasion_styles: [
    { label: "Casual" },
    { label: "Party & Festive" },
    { label: "Wedding" },
    { label: "Wedding & Festive" },
    { label: "Ethnic" },
  ],
  fabric_types: [
    { label: "Art Silk" },
    { label: "Brasso" },
    { label: "Chanderi" },
    { label: "Chiffon" },
    { label: "Cotton Blend" },
    { label: "Cotton Jute" },
    { label: "Cotton Linen" },
    { label: "Cotton Silk" },
    { label: "Crepe" },
    { label: "Dupion Silk" },
    { label: "Georgette" },
    { label: "Jacquard" },
    { label: "Jute Silk" },
    { label: "Lace" },
    { label: "Linen" },
    { label: "Lycra Blend" },
    { label: "Muslin" },
    { label: "Net" },
    { label: "Nylon" },
    { label: "Organza" },
    { label: "Polyester" },
    { label: "Pure Cotton" },
    { label: "Pure Silk" },
    { label: "Raw Silk" },
    { label: "Satin" },
    { label: "Silk Blend" },
    { label: "Supernet" },
    { label: "Tissue" },
    { label: "Tussar Silk" },
    { label: "Velvet" },
    { label: "Elastane" },
    { label: "Cotton Rayon" },
    { label: "Viscose Rayon" },
    { label: "Acrylic Blend" },
    { label: "Blended Cotton" },
    { label: "Khadi Cotton" },
    { label: "Khadi Silk" },
    { label: "Hemp" },
    { label: "Hosiery" },
    { label: "Modal" },
    { label: "Polycotton" },
    { label: "Pure Linen" },
    { label: "Pure Wool" },
    { label: "Rayon" },
    { label: "Wool" },
    { label: "Wool Blend" },
  ],
  saree_types: [
    { label: "Arani Pattu" },
    { label: "Assam Silk" },
    { label: "Baluchari" },
    { label: "Banarasi" },
    { label: "Bandhani" },
    { label: "Berhampuri" },
    { label: "Bhagalpuri" },
    { label: "Bollywood" },
    { label: "Chanderi" },
    { label: "Chettinadu" },
    { label: "Chinnalapattu" },
    { label: "Daily Wear" },
    { label: "Dharmavaram" },
    { label: "Dokhona" },
    { label: "Fashion" },
    { label: "Gadwal" },
    { label: "Garad" },
    { label: "Hand Batik" },
    { label: "Handloom" },
    { label: "Ikkat" },
    { label: "Ilkal" },
    { label: "Jamdani" },
    { label: "Kalamkari" },
    { label: "Kanjivaram" },
    { label: "Kantha" },
    { label: "Kasavu" },
    { label: "Konrad" },
    { label: "Kota Doria" },
    { label: "Kovai" },
    { label: "Leheria" },
    { label: "Lucknow Chikankari" },
    { label: "Madhubani" },
    { label: "Madurai" },
    { label: "Maheshwari" },
    { label: "Mangalagiri" },
    { label: "Manipuri" },
    { label: "Mekhela Chador" },
    { label: "Mundum Neriyathum" },
    { label: "Murshidabad" },
    { label: "Mysore" },
    { label: "Narayanpet" },
    { label: "Paithani" },
    { label: "Patola" },
    { label: "Pochampally" },
    { label: "Sambalpuri" },
    { label: "Tant" },
    { label: "Venkatagiri" },
    { label: "Regular" },
  ],
  saree_styles: [
    { label: "9 Yards Sari" },
    { label: "Half & Half Sari" },
    { label: "Lehenga Sari" },
    { label: "Ready to Wear" },
    { label: "Regular Sari" },
    { label: "Stitched Sari" },
  ],
  blouse_styles: [{ label: "Blouse Piece" }, { label: "Unstitched" }],
  saree_border_styles: [
    { label: "Embellished" },
    { label: "Temple Saree Border" },
    { label: "Contrast Saree Border" },
    { label: "Mirror work Saree Border" },
    { label: "Scallop Saree Border" },
    { label: "Check Saree Border" },
    { label: "Velvet Saree Border" },
    { label: "Floral Saree Border" },
    { label: "Taping" },
  ],
  embellishment_types: [
    { label: "lace" },
    { label: "stone" },
    { label: "sequins" },
    { label: "beads" },
    { label: "ribbon" },
    { label: "metallic trims" },
    { label: "Gotta Patti" },
  ],
  fabric_care_instructions: [
    { fabric: "Acetate", label: "Hand Wash and Air Dry." },
    { fabric: "Cotton", label: "Hot Water and Warm Tumble Dry." },
    { fabric: "Linen", label: "Cool Water and Air Dry." },
    { fabric: "Polyester", label: "Cool Wash and Tumble Dry or Air Dry." },
    { fabric: "Silk", label: "Hand Wash/Cool and Cool Tumble Dry or Air Dry." },
    { fabric: "Wool", label: "Hand Wash and Air Dry." },
  ],
  kurti_styles: [
    { label: "Anarkali Kurti" },
    { label: "Straight Cut Kurti" },
    { label: "A-Line Kurti" },
    { label: "Lace Kurti" },
    { label: "Asymmetrical Kurti" },
    { label: "Kaftan Style Kurti" },
    { label: "Shirt Collar Kurti" },
    { label: "Overlay Kurti" },
    { label: "Jacket Style Kurti" },
    { label: "Cape Style Kurti" },
    { label: "Indo-Western Kurti" },
    { label: "Floor Length/Gown Style Kurti" },
    { label: "Color Block Kurti" },
    { label: "Printed Kurti" },
    { label: "Denim Kurti" },
    { label: "Chikankari Kurti" },
    { label: "Angrakha Kurti" },
    { label: "Dhoti Style Kurti" },
    { label: "Layered Kurti" },
    { label: "Flared Kurti" },
    { label: "Tie Dye Kurti" },
    { label: "Bandhani Kurti" },
    { label: "Kashmiri Kurti" },
    { label: "Gathered Kurti" },
    { label: "Peplum Kurti" },
    { label: "Tiered Kurti" },
    { label: "Boat Neck Kurti" },
    { label: "Embroidered Kurta" },
    { label: "Ikat Kurti" },
    { label: "Short Kurtis" },
    { label: "Cowel Neck Kurti" },
  ],
  bottom_styles_with_kurti: [
    { label: "Patiala Pants" },
    { label: "Palazzo or Parallel Pants" },
    { label: "Churidar Bottoms" },
    { label: "Straight Pants" },
    { label: "Sharara Pants" },
    { label: "Salwar" },
    { label: "Dhoti Pants" },
    { label: "Cigarette Pants" },
    { label: "Flared Skirt" },
  ],
  stitching_options: [{ label: "Stitched" }, { label: "Unstitched" }],
  Dupattaavailable: [{ label: "With Dupatta" }, { label: "Without Dupatta" }],
  Blouseavailable: [{ label: "With Blouse" }, { label: "Without Blouse" }],

  fastening_types: [
    { label: "buttons" },
    { label: "snap fasteners" },
    { label: "zippers" },
    { label: "toggle fasteners" },
    { label: "loop closures" },
  ],
  wave_patterns: [
    { label: "Horizontal Waves" },
    { label: "Vertical Waves" },
    { label: "Diagonal Waves" },
    { label: "Chevron Waves" },
    { label: "Curly Waves" },
    { label: "Abstract Waves" },
    { label: "Ocean Waves" },
    { label: "Ripple Waves" },
    { label: "Geometric Waves" },
    { label: "Floral Waves" },
    { label: "Tribal Waves" },
    { label: "Gradient Waves" },
  ],
  wave_types: [
    { label: "Sine Wave" },
    { label: "Sinusoidal Wave" },
    { label: "Square Wave" },
    { label: "Sawtooth Wave" },
    { label: "Triangle Wave" },
    { label: "Pulse Wave" },
    { label: "Cosine Wave" },
    { label: "Pulse Train" },
    { label: "Square Pulse Train" },
    { label: "Damped Wave" },
    { label: "Standing Wave" },
  ],
  pattern_coverage: [
    { label: "All-Over Print" },
    { label: "Placement Print" },
    { label: "Border Print" },
    { label: "Scattered Print" },
    { label: "Stripe Print" },
    { label: "Geometric Print" },
    { label: "Floral Print" },
    { label: "Animal Print" },
    { label: "Abstract Print" },
    { label: "Tribal Print" },
    { label: "Polka Dot Print" },
    { label: "Check Print" },
  ],
  no_of_item: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  transparency_levels: [
    { label: "Opaque" },
    { label: "Semi-Transparent" },
    { label: "Sheer" },
    { label: "Mesh" },
    { label: "Lace" },
    { label: "Chiffon" },
    { label: "Organza" },
    { label: "Tulle" },
    { label: "Voile" },
    { label: "Gauze" },
    { label: "Burnout" },
    { label: "Netting" },
  ],
  main_trends: [
    { label: "Bohemian" },
    { label: "Minimalist" },
    { label: "Athleisure" },
    { label: "Vintage-Inspired" },
    { label: "Eclectic" },
    { label: "Feminine" },
    { label: "Streetwear" },
    { label: "Sustainable Fashion" },
    { label: "Modern Classic" },
    { label: "Avant-Garde" },
    { label: "Global-Inspired" },
    { label: "Techwear" },
  ],

  //electronics
  washing_machine_types: [
    { label: "Fully automatic front load" },
    { label: "Semi automatic top load" },
    { label: "Fully automatic top load" },
  ],
  washing_modes: [{ label: "Gentle wash" }, { label: "Pulsator wash" }],
  yes_r_no: [{ label: "Yes" }, { label: "No" }],
  tub_materials: [
    { label: "Stainless Steel Tub" },
    { label: "Porcelain-Coated Steel Tub" },
    { label: "Plastic Tub" },
  ],
  Energy_Rating: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Maximum_Spin_Speed: [
    { label: "400" },
    { label: "500" },
    { label: "600" },
    { label: "700" },
    { label: "800" },
    { label: "900" },
    { label: "1000" },
    { label: "1100" },
    { label: "1200" },
    { label: "1300" },
    { label: "1400" },
    { label: "1500" },
    { label: "1600" },
  ],
  Washing_Capacity: [
    { label: "1" },
    { label: "1.5" },
    { label: "2" },
    { label: "2.5" },
    { label: "3" },
    { label: "3.5" },
    { label: "4" },
    { label: "4.5" },
    { label: "5" },
    { label: "5.5" },
    { label: "6" },
    { label: "6.5" },
    { label: "7" },
    { label: "7.5" },
    { label: "8" },
    { label: "8.5" },
    { label: "9" },
    { label: "9.5" },
    { label: "10" },
    { label: "10.5" },
    { label: "11" },
    { label: "11.5" },
    { label: "12" },
    { label: "12.5" },
    { label: "13" },
  ],
  Dryer_Capacity: [
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  wash_program_types: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],

  dryer_types: [
    { label: "Vented Dryer" },
    { label: "Condenser Dryer" },
    { label: "Heat Pump Dryer" },
    { label: "Gas Dryer" },
    { label: "Compact Dryer" },
    { label: "Stackable Dryer" },
    { label: "Portable Dryer" },
    { label: "Electric Dryer" },
  ],
  outer_body_materials: [
    { label: "Plastic" },
    { label: "Stainless Steel" },
    { label: "Enamel-Coated Steel" },
  ],
  power_ratings: [
    { label: "300" },
    { label: "750" },
    { label: "1400" },
    { label: "1500" },
    { label: "2000" },
    { label: "2250" },
    { label: "2500" },
    { label: "3000" },
    { label: "3500" },
    { label: "4000" },
    { label: "4500" },
    { label: "5000" },
    { label: "5500" },
  ],
  sound_levels: [
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
  ],
  Width: [{ label: "60" }, { label: "65" }, { label: "70" }, { label: "75" }],
  height: [
    { label: "80" },
    { label: "85" },
    { label: "90" },
    { label: "95" },
    { label: "100" },
    { label: "105" },
    { label: "110" },
    { label: "115" },
    { label: "120" },
  ],
  weight: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
  ],
  Depth: [
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
    { label: "85" },
    { label: "90" },
  ],
  spin_cycle_duration: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],

  Wash_cycle_duration: [
    { label: "20" },
    { label: "25" },
    { label: "30" },
    { label: "35" },
    { label: "40" },
    { label: "45" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "65" },
    { label: "70" },
    { label: "75" },
    { label: "80" },
    { label: "85" },
    { label: "90" },
    { label: "95" },
    { label: "100" },
    { label: "105" },
    { label: "110" },
    { label: "115" },
    { label: "120" },
  ],

  air_conditioner_types: [
    { label: "Window" },
    { label: "Split" },
    { label: "Ductless Mini-Split" },
    { label: "Central" },
    { label: "Portable" },
    { label: "Packaged Terminal" },
    { label: "Packaged" },
  ],
  Capacity_in_tons: [
    { label: "1" },
    { label: "1.5" },
    { label: "2" },
    { label: "2.5" },
    { label: "3" },
    { label: "3.5" },
    { label: "4" },
    { label: "4.5" },
    { label: "5" },
  ],
  compressor_types: [
    { label: "Reciprocating" },
    { label: "Rotary" },
    { label: "Scroll" },
    { label: "Centrifugal" },
    { label: "Inverter" },
  ],
  refrigerant_types: [
    { label: "R-410A" },
    { label: "R-22 (Freon)" },
    { label: "R-134a" },
    { label: "R-32" },
    { label: "R-407C" },
    { label: "R-290 (Propane)" },
    { label: "R-404A" },
    { label: "R-407A" },
  ],
  Number_of_doors: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Outer_body_materials: [
    { label: "Stainless Steel" },
    { label: "Plastic" },
    { label: "Aluminum" },
    { label: "Glass" }
  ],

  Cooling_coverage_area: [
    { label: "10 sq. meters" },
    { label: "20 sq. meters" },
    { label: "30 sq. meters" },
    { label: "40 sq. meters" },
    { label: "50 sq. meters" },
    { label: "60 sq. meters" }
  ],

  Speed_control: [
    { label: "Manual" },
    { label: "Electronic" },
    { label: "Remote" },
    { label: "Touch" },
    { label: "Automatic" },
    { label: "Voice" }
  ],

  operating_modess: [
    { label: "Cooling Mode" },
    { label: "Heating Mode (Heat Pump Models)" },
    { label: "Fan Mode" },
    { label: "Dehumidification Mode" },
    { label: "Auto Mode" },
    { label: "Sleep Mode" },
    { label: "Eco Mode" },
    { label: "Turbo Mode" },
    { label: "Timer Mode" },
    { label: "Swing Mode" },
    { label: "Quiet Mode" },
    { label: "Smart Mode" },
    { label: "Auto Clean Mode" },
    { label: "Dry Mode (for reducing humidity without cooling)" },
    { label: "Energy Saver Mode" },
  ],
  condenser_coil_types: [
    { label: "Copper" },
    { label: "Aluminum" },
    { label: "Coated Aluminum" },
  ],
  panel_display_types: [
    { label: "LCD" },
    { label: "LED" },
    { label: "Touch screen" },
    { label: "Digital" },
    { label: "Backlit" },
    { label: "Alphanumeric" },
    { label: "Dot matrix" },
    { label: "Segment display" },
  ],
  condenser_fin_types: [
    { label: "Bare" },
    { label: "Slit" },
    { label: "Wavy" },
    { label: "Microchannel" },
    { label: "Corrugated" },
    { label: "Ripple" },
    { label: "Serrated" },
    { label: "Flat" },
    { label: "Elliptical" },
    { label: "Coated" },
  ],
  Control_types: [
    { label: "Touch" },
    { label: "Button" },
    { label: "Knob" },
    { label: "Dial" },
    { label: "Push" },
    { label: "Remote" },
    { label: "Voice" },
  ],
  Power_consumption: [
    { label: "220V" },
    { label: "230V" },
    { label: "240V" },
    { label: "110V" },
    { label: "120V" },
    { label: "250V" },
  ],
  Cavity_Material_type: [
    { label: "Stainless steel" },
    { label: "Enamel" },
    { label: "Ceramic" },
    { label: "Aluminum" },
    { label: "Non-stick" },
    { label: "Porcelain" },
  ],
  Temparature_control_range: [
    { label: "100°C to 200°C" },
    { label: "50°C to 250°C" },
    { label: "70°C to 230°C" },
    { label: "90°C to 220°C" },
    { label: "80°C to 240°C" },
    { label: "60°C to 210°C" }
  ],

  Number_of_auto_menu: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" }
  ],
  Number_of_devices_supported: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" }
  ],
  Video_resolution: [
    { label: "480p" },
    { label: "720p" },
    { label: "1080p" },
    { label: "1440p" },
    { label: "4K" },
    { label: "8K" }
  ],

  Heating_element: [
    { label: "Quartz" },
    { label: "Ceramic" },
    { label: "Metal" },
    { label: "Infrared" },
    { label: "Halogen" },
    { label: "Coil" }
  ],
  Cord_length: [
    { label: "1 meter" },
    { label: "1.5 meters" },
    { label: "2 meters" },
    { label: "2.5 meters" },
    { label: "3 meters" },
    { label: "3.5 meters" }
  ],


  Rotisserie_types: [
    { label: "Manual" },
    { label: "Automatic" },
    { label: "Vertical" },
    { label: "Horizontal" },
    { label: "Spit" },
    { label: "Basket" },
  ],
  Mount_types: [
    { label: "Wall-mounted" },
    { label: "Countertop" },
    { label: "Built-in" },
    { label: "Under-cabinet" },
    { label: "Freestanding" },
    { label: "Ceiling-mounted" },
    { label: "Over-the-range" },
  ],
  Air_flow: [
    { label: "One-way" }, { label: "Two-way" }
  ],
  Total_pan_capacity: [
    { label: "1 liter" },
    { label: "2 liters" },
    { label: "3 liters" },
    { label: "4 liters" },
    { label: "5 liters" },
    { label: "6 liters" }
  ],

  Motor_speed: [
    { label: "1000 RPM" },
    { label: "1500 RPM" },
    { label: "2000 RPM" },
    { label: "2500 RPM" },
    { label: "3000 RPM" },
    { label: "3500 RPM" }
  ],

  Number_of_stones: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" }
  ],

  Attachment_present: [
    { label: "Yes" },
    { label: "No" }
  ],

  Filter_type: [
    { label: "HEPA" },
    { label: "Activated carbon" },
    { label: "UV" },
    { label: "Pre-filter" },
    { label: "Ionizer" },
    { label: "Water filter" }
  ],

  Membrane_type: [
    { label: "RO" },
    { label: "UF" },
    { label: "UV" },
    { label: "Activated carbon" },
    { label: "Composite" },
    { label: "Ceramic" }
  ],

  Filtration_capacity: [
    { label: "1 liter/hour" },
    { label: "2 liters/hour" },
    { label: "3 liters/hour" },
    { label: "4 liters/hour" },
    { label: "5 liters/hour" },
    { label: "6 liters/hour" }
  ],

  Purification_capacity: [
    { label: "1 liter/day" },
    { label: "2 liters/day" },
    { label: "3 liters/day" },
    { label: "4 liters/day" },
    { label: "5 liters/day" },
    { label: "6 liters/day" }
  ],

  Number_of_blades: [
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" }
  ],

  Blade_sweep: [
    { label: "200 mm" },
    { label: "300 mm" },
    { label: "400 mm" },
    { label: "500 mm" },
    { label: "600 mm" },
    { label: "700 mm" }
  ],

  air_flow_directions: [{ label: "One-way" }, { label: "Two-way" }],
  Annual_electricity_consumption: [
    { label: "860" },
    { label: "900" },
    { label: "950" },
    { label: "980" },
    { label: "1000" },
  ],
  Operating_current: [
    { label: "5" },
    { label: "10" },
    { label: "15" },
    { label: "20" },
    { label: "25" },
    { label: "30" },
  ],
  Battery_type: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  refrigerator_types: [
    { label: "Single Door" },
    { label: "Double Door" },
    { label: "Triple Door" },
  ],
  refrige_types: [
    { label: "Top" },
    { label: "Bottom" },
    { label: "Side-by-Side" },
    { label: "French Door" },
    { label: "Compact / Mini" },
    { label: "Built-in" },
    { label: "Counter-depth" },
    { label: "Freezerless" },
    { label: "Smart" },
    { label: "Retro / Vintage" },
  ],
  mobiles_types: [{ label: "wire" }, { label: "wireless" }],
  mobiles_Usage: [{ label: "gaming" }, { label: "entertainment" }],
  Browse_Type: [{ label: "chrome" }, { label: "safari" }],
  SIM_Type: [
    { label: "nanao" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  mobile_Display_Size: [
    { label: "5.5" },
    { label: "6.0" },
    { label: "6.5" },
    { label: "5.8" },
    { label: "6.7" },
    { label: "6.3" },
    { label: "6.4" },
    { label: "6.1" },
    { label: "5.7" },
  ],
  mobile_Resolution: [
    { label: "720p" },
    { label: "1080p" },
    { label: "1440p" },
    { label: "2160p" },
    { label: "4k" },
  ],
  mobile_Gpu: [{ label: "Adreno" }, { label: "Mali" }, { label: "Qualcomm" }],
  mobile_Display_Type: [
    { label: "Lcd" },
    { label: "Oled" },
    { label: "Amoled" },
    { label: "IPS" },
    { label: "P-oled" },
    { label: "super Amoled" },
  ],

  mobile_operating_system: [
    { label: "Android" },
    { label: "iOS" },
    { label: "Qualcomm" },
  ],
  mobile_Processor_brand: [
    { label: "Qualcomm" },
    { label: "apple" },
    { label: "MediaTek" },
    { label: "Samsung" },
    { label: "Exynos" },
    { label: "snapdragon" },
  ],
  mobile_Processor_core: [
    { label: "Single-core" },
    { label: "Dual-core" },
    { label: "Quad-core" },
    { label: "Hexa-core" },
    { label: "Octa-core" },
  ],
  Number_of_layers: [
    { label: "Single-core" },
    { label: "Dual-core" },
    { label: "Quad-core" },
    { label: "Hexa-core" },
    { label: "Octa-core" },
  ],
  mobile_primary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  mobile_secondary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  mobile_tertiary_clock_speed: [
    { label: "1 GHz" },
    { label: "1.2 GHz" },
    { label: "1.3 GHz" },
    { label: "1.4 GHz" },
    { label: "1.5 GHz" },
    { label: "2 GHz" },
    { label: "2.2 GHz" },
    { label: "2.4 GHz" },
    { label: "3 GHz" },
    { label: "3.3 GHz" },
    { label: "4 GHz" },
    { label: "5 GHz" },
  ],
  Internal_storage: [
    { label: "16 GB" },
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1TB" },
  ],
  mobile_ram: [
    { label: "1 GB" },
    { label: "2 GB" },
    { label: "3 GB" },
    { label: "4 GB" },
    { label: "6 GB" },
    { label: "8 GB" },
    { label: "12 GB" },
  ],
  Total_memory: [
    { label: "16 GB" },
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1TB" },
  ],
  Secodary_camera: [
    { label: "5Mp" },
    { label: "8Mp" },
    { label: "8Mp" },
    { label: "12Mp" },
    { label: "16Mp" },
    { label: "32Mp" },
    { label: "48Mp" },
    { label: "64Mp" },
    { label: "50Mp" },
  ],
  Primary_camera: [
    { label: "5Mp" },
    { label: "8Mp" },
    { label: "12Mp" },
    { label: "12.5Mp" },
    { label: "16Mp" },
    { label: "32Mp" },
    { label: "48Mp" },
    { label: "64Mp" },
    { label: "50Mp" },
    { label: "108Mp" },
    { label: "200Mp" },
  ],
  Flash: [{ label: "Front" }, { label: "Rear" }],
  Frame_rate: [
    { label: "30 Hz" },
    { label: "60 Hz" },
    { label: "90 Hz" },
    { label: "120 Hz" },
    { label: "144 Hz" },
    { label: "240 Hz" },
    { label: "360 Hz" },
    { label: "480 Hz" },
    { label: "960 Hz" },
  ],
  Dual_lens: [{ label: "primary" }, { label: "secondary" }],
  Network_type: [
    { label: "2G" },
    { label: "3G" },
    { label: "4G VoLTE" },
    { label: "4G" },
    { label: "5G" },
  ],
  Supported_networks: [
    { label: "4G LTE" },
    { label: "4G VoLTE" },
    { label: "5G" },
    { label: "GSM" },
    { label: "WCDMA" },
  ],
  Internet_connectivity: [
    { label: "4G" },
    { label: "5G" },
    { label: "3G" },
    { label: "WIFI" },
    { label: "EDGE" },
    { label: "GPRS" },
  ],
  Bluetooth_version: [
    { label: "4" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "5" },
    { label: "5.2" },
    { label: "5.3" },
    { label: "5.3" },
  ],
  Map_support: [{ label: "Google Maps" }, { label: "Maps" }],
  Touchscreen_type: [
    { label: "capacitive" },
    { label: "resistive" },
    { label: "infrared" },
    { label: "SAW" },
    { label: "optical imaging" },
    { label: "P-CAP" },
    { label: "Multi-Touch" },
    { label: "single-Touch" },
  ],
  Sim_size: [
    { label: "standard sim" },
    { label: "micro sim" },
    { label: "nano sim" },
    { label: "e sim" },
  ],
  User_interface: [
    { label: "One UI" },
    { label: "Oxygen OS" },
    { label: "MIUI" },
    { label: "colorOS" },
    { label: "funtouchOS" },
    { label: "Realme UI" },
    { label: "hyperOS" },
  ],
  Sensors: [
    { label: "Proximity sensor" },
    { label: "Ambient light sensor" },
    { label: "Accelerometer" },
    { label: "Gyroscope" },
    { label: "Magnetometer" },
    { label: "Barometer" },
    { label: "Infrared sensor" },
    { label: "Face recognition sensor" },
    { label: "Heart rate sensor" },
  ],
  Browser: [{ label: "Google chrome" }, { label: "safari" }],
  GPS_type: [
    { label: "GPS" },
    { label: "AGPS" },
    { label: "GLONASS" },
    { label: "BEIDOU" },
    { label: "GALILEO" },
  ],
  Battery_capacity: [
    { label: "2000 mAh" },
    { label: "4000 mAh" },
    { label: "5000 mAh" },
    { label: "6000 mAh" },
    { label: "8000 mAh" },
    { label: "10000 mAh" },
    { label: "12000 mAh" },
  ],
  Mobile_Battery_type: [
    { label: "Lithium-ion[Li-ion]" },
    { label: "Lithium-polymer[Li-po]" },
    { label: "Nickel-metal hydrate" },
    { label: "nickel-cadmium" },
  ],
  mobile_width: [
    { label: "50mm" },
    { label: "55mm" },
    { label: "60mm" },
    { label: "65mm" },
    { label: "68mm" },
    { label: "70mm" },
    { label: "75mm" },
    { label: "80mm" },
    { label: "85mm" },
    { label: "90mm" },
    { label: "95mm" },
    { label: "100mm" },
  ],
  mobile_height: [
    { label: "70mm" },
    { label: "75mm" },
    { label: "80mm" },
    { label: "85mm" },
    { label: "90mm" },
    { label: "95mm" },
    { label: "100mm" },
    { label: "105mm" },
    { label: "110mm" },
    { label: "105mm" },
    { label: "110mm" },
    { label: "150mm" },
    { label: "160mm" },
    { label: "170mm" },
    { label: "180mm" },
    { label: "190mm" },
    { label: "200mm" },
  ],
  mobile_depth: [
    { label: "5mm" },
    { label: "8mm" },
    { label: "10mm" },
    { label: "14mm" },
    { label: "16mm" },
    { label: "12mm" },
    { label: "18mm" },
    { label: "20mm" },
  ],
  mobile_weight: [
    { label: "100g" },
    { label: "150g" },
    { label: "186g" },
    { label: "246g" },
    { label: "283g" },
    { label: "256g" },
    { label: "263g" },
    { label: "293g" },
  ],
  Laptop_Type: [
    { label: "Gaming" },
    { label: "Ultrabook " },
    { label: "Business" },
    { label: "Convertible" },
    { label: "Chrommobook " },
    { label: "Thin and Light " },
    { label: "2-in-1" },
  ],
  Suitable_for: [
    { label: "processing" },
    { label: "multitasking" },
    { label: "programming" },
    { label: "multimedia" },
    { label: "travel" },
    { label: "graphic" },
    { label: "design" },
    { label: "everyday use" },
  ],
  Ice_cube_tray_type: [
    { label: "Flexible silicone" },
    { label: "Plastic" },
    { label: "Metal" },
    { label: "Stackable" },
    { label: "Quick-freeze" },
    { label: "Collapsible" }
  ],


  laptop_battery_backup: [
    { label: "upto 4hr" },
    { label: "upto 5hr" },
    { label: "upto 6hr" },
    { label: "upto 7hr" },
    { label: "upto 8hr" },
    { label: "upto 9hr" },
    { label: "upto 10hr" },
  ],

  laptop_Processor_brand: [{ label: "Intel" }, { label: "AMD" }],
  laptop_Processor_name: [
    { label: "Intel Core i3" },
    { label: "Intel Core i5" },
    { label: "Intel Core i7" },
    { label: "Intel Core i9" },
    { label: "AMD Ryzen 3" },
    { label: "AMD Ryzen 5" },
    { label: "AMD Ryzen 7" },
    { label: "AMD Ryzen 9 " },
    { label: "Qualcomm snapdragon" },
    { label: "Apple M1”" },
  ],
  laptop_Ram: [
    { label: "4" },
    { label: "8" },
    { label: "16" },
    { label: "32" },
    { label: "64" },
    { label: "128" },
  ],
  laptop_Ram_type: [{ label: "DDR3" }, { label: "DDR4" }, { label: "DDR5" }],
  laptop_Graphic_processor: [
    { label: "Intel HD Graphics" },
    { label: "Intel Iris Xe Graphics" },
    { label: "Intel UHD Graphics" },
    { label: "NVIDIA GeForce GTX" },
    { label: "NVIDIA GeForce RTX" },
    { label: "AMD Radeon RX" },
    { label: "AMD Radeon Pro" },
    { label: " Intel Integrated Graphics" },
    { label: " NVIDIA Quadro" },
    { label: "AMD Radeon Vega" },
  ],
  laptop_Clock_speed: [
    { label: "1GHz" },
    { label: "1.2GHz" },
    { label: "1.4GHz" },
    { label: "2.1GHz" },
    { label: "2.3GHz" },
    { label: "2.5GHz" },
    { label: "2.8GHz" },
    { label: "3Ghz" },
  ],
  laptop_Expandable_ssd_capacity: [
    { label: "upto 512GB" },
    { label: "upto 1TB" },
    { label: "upto 2TB" },
  ],
  laptop_Operating_system: [
    { label: "Windows" },
    { label: "macOs" },
    { label: "Linux" },
    { label: "Chrome OS" },
  ],
  System_archietecture: [{ label: "32-bit" }, { label: "64- bit" }],
  Screen_Size: [
    { label: "11 inch" },
    { label: "12 inch" },
    { label: "13 inch" },
    { label: "14 inch" },
    { label: "15 inch" },
    { label: "16 inch" },
    { label: "17 inch" },
  ],

  laptop_Screen_type: [
    { label: "LCD" },
    { label: "LED" },
    { label: "OLED" },
    { label: "IPS" },
    { label: "TN" },
  ],
  laptop_Sound_properties: [
    { label: "stereo" },
    { label: "built-in speakers" },
    { label: "dolby speakers" },
    { label: "harman Kardon" },
    { label: "noise concellation" },
    { label: "Audio jack" },
  ],
  Dimensions: [
    { label: "250mm  x 150 mm x 10mm" },
    { label: "300mm x 200mm x 20mm" },
    { label: "350mm x 250mm x 30mm" },
    { label: "400mm x 300mm x 50mm" },
  ],
  Weight: [
    { label: "1kg" },
    { label: "2kg" },
    { label: "3kg" },
    { label: "4kg" },
    { label: "5kg" },
  ],
  Web_camera: [
    { label: "HD" },
    { label: "FULL hd" },
    { label: "720 p" },
    { label: "1080p" },
    { label: "4k" },
  ],
  Emmc_storage_capacity: [
    { label: "32gb" },
    { label: "64gb" },
    { label: "128gb" },
  ],
  Dedicated_graphic_memory_type: [
    { label: "GDDR5" },
    { label: "GDDR6X" },
    { label: "GDDR6" },
    { label: "HBM" },
    { label: "HBM2" },
    { label: "HBM2E" },
    { label: "HBM3" },
    { label: "GDDR5X" },
    { label: "GDDR6L" },
    { label: "GDDR7" },
  ],
  Dedicated_graphic_memory_capacity: [
    { label: "2 GB" },
    { label: "4 GB" },
    { label: "6 GB" },
    { label: "8 GB" },
    { label: "16 GB" },
  ],
  laptop_Ssd_capacity: [
    { label: "128GB" },
    { label: "256GB" },
    { label: "512GB" },
    { label: "1TB" },
    { label: "2 TB" },
  ],
  Lock_port: [{ label: "Kensington Lock Slot" }],

  Ideal_usage: [
    { label: "Entertainment" },
    { label: "Gaming" },
    { label: "esim" },
    { label: "Reading " },
    { label: "Browsing" },
    { label: "For Kids " },
    { label: "Business" },
  ],
  Connectivity: [
    { label: "wifi" },
    { label: "Bluetooth" },
    { label: "NFC" },
    { label: "4G/LTE" },
    { label: "5G" },
  ],
  tablets_Os: [{ label: "Android" }, { label: "iOS" }],
  tablets_ram: [
    { label: "2GB" },
    { label: "3GB" },
    { label: "4GB" },
    { label: "6GB" },
    { label: " 8GB" },
  ],
  Supported_network: [
    { label: "Bluetooth" },
    { label: "wifi" },
    { label: "5g" },
    { label: "gsm" },
    { label: "cdma" },
    { label: "hspa" },
    { label: "volte" },
    { label: "dual" },
    { label: "sim" },
  ],
  Processor_type: [
    { label: "Qualcomm Snapdragon" },
    { label: "Apple A-series (iPad)" },
    { label: "Samsung Exynos" },
    { label: " MediaTek" },
  ],
  tablet_Display_size: [
    { label: "7 inches" },
    { label: "8 inches" },
    { label: "9 inches" },
    { label: "10 inches" },
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
  ],
  Display_resolution: [
    { label: "1280 x 720p" },
    { label: "1920 X 1080p" },
    { label: "2560 X 1440p" },
    { label: "3840 X 2160p" },
  ],
  Washing_modes: [
    { label: "Quick Wash" },
    { label: "Delicate" },
    { label: "Heavy" },
    { label: "Eco" },
    { label: "Rinse" }
  ],
  Tub_materials: [
    { label: "Stainless Steel" },
    { label: "Plastic" },
    { label: "Porcelain" },
    { label: "Ceramic" }
  ],
  Spin_cycle_duration: [
    { label: "10 minutes" },
    { label: "15 minutes" },
    { label: "20 minutes" },
    { label: "30 minutes" }
  ],

  Wash_program_types: [
    { label: "Cotton" },
    { label: "Wool" },
    { label: "Synthetics" },
    { label: "Delicates" },
    { label: "Sportswear" }
  ],

  Used_for: [
    { label: "Cooking" },
    { label: "Heating" },
    { label: "Baking" },
    { label: "Frying" },
    { label: "Blending" },
    { label: "Cleaning" }
  ],

  Minimum_input_power: [
    { label: "100W" },
    { label: "200W" },
    { label: "300W" },
    { label: "400W" },
    { label: "500W" },
    { label: "600W" }
  ],

  Temperature: [
    { label: "Low" },
    { label: "Medium" },
    { label: "High" },
    { label: "Adjustable" }
  ],

  Heater_material: [
    { label: "Stainless steel" },
    { label: "Aluminum" },
    { label: "Ceramic" },
    { label: "Copper" },
    { label: "Plastic" }
  ],

  Heater_element_type: [
    { label: "Coil" },
    { label: "Quartz" },
    { label: "Ceramic" },
    { label: "Metal" },
    { label: "Infrared" }
  ],

  Power_output_rms: [
    { label: "10W" },
    { label: "20W" },
    { label: "30W" },
    { label: "40W" },
    { label: "50W" },
    { label: "60W" }
  ],

  Maximum_brightness: [
    { label: "500 lumens" },
    { label: "1000 lumens" },
    { label: "1500 lumens" },
    { label: "2000 lumens" },
    { label: "2500 lumens" },
    { label: "3000 lumens" }
  ],

  Remote_features: [
    { label: "Volume control" },
    { label: "Channel control" },
    { label: "Power on/off" },
    { label: "Mute" },
    { label: "Input selection" },
    { label: "Backlight" }
  ],

  tablet_Primary_camera: [
    { label: "8Mp" },
    { label: "9Mp" },
    { label: "10Mp" },
    { label: "11Mp" },
    { label: "12Mp" },
    { label: "13Mp" },
    { label: "14Mp" },
    { label: "15Mp" },
    { label: "16Mp" },
  ],
  tablet_Processor_speed: [
    { label: "1.2GHzz" },
    { label: "1.4GHzz" },
    { label: "1.6GHzz" },
    { label: "2GHzz" },
    { label: "2.2GHzz" },
    { label: "2.4GHzz" },
    { label: "3GHzz" },
  ],
  tablet_Internal_storage: [
    { label: " 32 GB" },
    { label: " 64 GB" },
    { label: " 128 GB" },
    { label: " 256 GB " },
    { label: " 512 GB" },
    { label: " 1 TB" },
  ],
  Sensors: [
    { label: "Accelerometer" },
    { label: "Fingerprint Sensor" },
    { label: "Gyro Sensor" },
    { label: "Geomagnetic Sensor" },
    { label: "Hall Sensor" },
    { label: "RGB Light Sensor" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  tablet_Sim_type: [
    { label: "single" },
    { label: "single/Dual" },
    { label: "Dual" },
  ],
  browser: [{ label: "chrome" }, { label: "safari" }],
  tablet_Rom: [
    { label: "32 GB" },
    { label: "64 GB" },
    { label: "128 GB" },
    { label: "256 GB" },
    { label: "512 GB" },
    { label: "1 TB" },
  ],
  Battery_capapcity: [
    { label: "3000-5000mAh" },
    { label: "5000-7000mah" },
    { label: "7000mAh-10000mAh" },
    { label: "above 10000mAh" },
  ],
  Battery_type: [
    { label: "Lithium-ion (Li-ion)" },
    { label: "Lithium-polymer (Li-po)" },
  ],
  Bluetooth_version: [{ label: "4" }, { label: "5" }, { label: "6" }],
  Usb_version: [{ label: "4" }, { label: "5" }, { label: "6" }],
  Display_type: [
    { label: "Hd" },
    { label: "FHD" },
    { label: "Lcd" },
    { label: "oled" },
    { label: "amoled" },
    { label: "Lcd" },
    { label: "ips" },
    { label: "tft" },
    { label: "retinal display" },
  ],
  Video_formats_supported: [{ label: "MP4" }],
  Gps_type: [
    { label: "GPS" },
    { label: "Glonass" },
    { label: "Beidou" },
    { label: "Galileo" },
    { label: "QZSS" },
  ],
  mouse_system_requiremnets: [{ label: "laptop" }],
  laptop_bag_pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  No_of_slices: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" }
  ],

  Pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  laptop_bag_type: [
    { label: "backpack" },
    { label: "messenger bag" },
    { label: "shoulder bag" },
  ],
  Ideal_for: [{ label: "Men /Women" }],
  Compatible_laptop_size: [
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
  ],
  Star_rating: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  Number_of_speeds: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" }
  ],

  Blower: [
    { label: "Yes" },
    { label: "No" }
  ],

  Material: [
    { label: "nylon" },
    { label: "polyster" },
    { label: "Leather" },
    { label: "Canvas " },
    { label: "neoprene" },
    { label: "PU Leatehr" },
    { label: "Microfiber" },
  ],
  Number_of_compartments: [{ label: "1" }, { label: "2" }, { label: "3" }],
  Pattern: [
    { label: "solid" },
    { label: "striped" },
    { label: "checkered" },
    { label: "floral" },
    { label: "geometric" },
  ],
  Capacity: [
    { label: "10-15L" },
    { label: "15-20L" },
    { label: "20-25L" },
    { label: "25-30L" },
    { label: "30-35L" },
    { label: "35-40L" },
    { label: "40-45L" },
    { label: "45-50L" },
  ],
  laptop_bag_Dimensions: [
    { label: "15 inches x 10 inches x 3 inches" },
    { label: "18 inches x 12 inches x 4 inches" },
    { label: "20 inches x 14 inches x 6 inches" },
    { label: "22 inches x 16 inches x 9 inches " },
    { label: "25 inches x 18 inches x 18 inches" },
  ],
  pc_speaker_type: [
    { label: "Laptop" },
    { label: "desktop" },
    { label: "Speaker" },
    { label: "stereo" },
    { label: "Bluetooth" },
  ],
  Configuration: [
    { label: "2.0" },
    { label: "2.1" },
    { label: "2.2" },
    { label: "2.3" },
    { label: "2.4" },
    { label: "2.5" },
    { label: "2.6" },
    { label: "2.7" },
    { label: "2.8" },
    { label: "2.9" },
    { label: "3" },
    { label: "3.1" },
    { label: "3.2" },
    { label: "3.3" },
    { label: "3.4" },
    { label: "3.5" },
    { label: "3.6" },
    { label: "3.7" },
    { label: "3.8" },
    { label: "3.9" },
    { label: "4" },
    { label: "4.1" },
    { label: "4.2" },
    { label: "4.3" },
    { label: "4.4" },
    { label: "4.5" },
  ],
  Power_source: [{ label: "usb" }, { label: "Ac adaptor" }],
  Output_wattage: [{ label: "usb" }, { label: "Ac adaptor" }],
  Power_output: [
    { label: "2w" },
    { label: "5w" },
    { label: "10w" },
    { label: "15w" },
    { label: "20w" },
    { label: "30w" },
    { label: "40w" },
    { label: "50w" },
    { label: "100w" },
    { label: "150w" },
    { label: "180w" },
    { label: "200w" },
    { label: "300w" },
    { label: "400w" },
    { label: "500w" },
  ],
  Frequency_response: [
    { label: "20 Hz - 200 Hz" },
    { label: "200 Hz - 2,000 Hz" },
    { label: "2,000 Hz - 20,000 Hz " },
  ],
  Impedence: [
    { label: "2O" },
    { label: "2.1O" },
    { label: "2.2 O" },
    { label: "2.3 O" },
    { label: "2.4 O" },
    { label: "2.5 O" },
    { label: "2.6 O" },
    { label: "2.7 O" },
    { label: "2.8 O" },
    { label: "2.9 O" },
    { label: "3 O" },
    { label: "3.1 O" },
    { label: "3.2 O" },
    { label: "3.3 O" },
    { label: "3.4 O" },
    { label: "3.5 O" },
    { label: "3.6 O" },
    { label: "3.7 O" },
    { label: "3.8 O" },
    { label: "3.9 O" },
    { label: "4 O" },
    { label: "4.1 O" },
    { label: "4.2 O" },
    { label: "4.3 O" },
    { label: "4.4 O" },
    { label: "4.5 O" },
    { label: "4.6 O" },
    { label: "4.7 O" },
    { label: "4.8 O" },
    { label: "4.9 O" },
    { label: "5 O" },
    { label: "5.1 O" },
    { label: "5.2 O" },
    { label: "5.3 O" },
    { label: "5.4 O" },
    { label: "5.5 O" },
    { label: "5.6 O" },
    { label: "5.7 O" },
    { label: "5.8 O" },
    { label: "5.9 O" },
    { label: "6 O" },
    { label: "6.1 O" },
    { label: "6.2 O" },
    { label: "6.3 O" },
    { label: "6.4 O" },
    { label: "6.5 O" },
    { label: "6.6 O" },
    { label: "6.7 O" },
    { label: "6.8 O" },
    { label: "6.9 O" },
    { label: " 7 O" },
    { label: "7.1 O" },
    { label: "7.2 O" },
    { label: "7.3 O" },
    { label: "7.4 O" },
    { label: "7.5 O" },
    { label: "7.6 O" },
    { label: "7.7 O" },
    { label: "7.8 O" },
    { label: "7.9 O" },
    { label: "8 O" },
    { label: "8.1 O" },
    { label: "8.2 O" },
    { label: "8.3 O" },
    { label: "8.4 O" },
    { label: "8.5 O" },
    { label: "8.6 O" },
    { label: "8.7 O" },
    { label: "8.8 O" },
    { label: "8.9 O" },
    { label: "9 O" },
    { label: "9.1 O" },
    { label: "9.2 O" },
    { label: "9.3 O" },
    { label: "9.4 O" },
    { label: "9.5 O" },
    { label: "9.6 O" },
    { label: "9.7 O" },
    { label: "9.8 O" },
    { label: "9.9 O" },
    { label: "10 O" },

    { label: "10.1 O" },
    { label: "10.2 O" },
    { label: "10.3 O" },
    { label: "10.4 O" },
    { label: "10.5 O" },
    { label: "10.6 O" },
    { label: "10.7 O" },
    { label: "10.8 O" },
    { label: "10.9 O" },
    { label: "11 O" },
    { label: "11.1 O" },
    { label: "11.2 O" },
    { label: "11.3 O" },
    { label: "11.4 O" },
    { label: "11.5 O" },
    { label: "11.6 O" },
    { label: "11.7  O" },
    { label: "11.8 O" },
    { label: "11.9 O" },
    { label: "12 O" },
    { label: "12.1 O" },
    { label: "12.2 O" },
    { label: "12.3 O" },
    { label: "12.4 O" },
    { label: "12.5 O" },
    { label: "12.6 O" },
    { label: "12.7 O" },
    { label: "12.8 O" },
    { label: "12.9 O" },
    { label: "13 O" },
    { label: "13.1 O" },
    { label: "13.2 O" },
    { label: "13.3 O" },
    { label: "13.4 O" },
    { label: "13.5 O" },
    { label: "13.6 O" },
    { label: "13.7 O" },
    { label: "13.8 O" },
    { label: "13.9 O" },
    { label: "14 O" },
    { label: "14.1 O" },
    { label: "14.2 O" },
    { label: "14.3 O" },
    { label: "14.4 O" },
    { label: "14.5 O" },
    { label: "14.6 O" },
    { label: "14.7 O" },
    { label: "14.8 O" },
    { label: "14.9 O" },
    { label: "15 O" },
    { label: "15.1 O" },
    { label: "15.2 O" },
    { label: "15.3 O" },
    { label: "15.4 O" },
    { label: "15.5 O" },
    { label: "15.6 O" },
    { label: "15.7 O" },
    { label: "15.8 O" },
    { label: "15.9 O" },
    { label: "16 O" },
    { label: "16.1 O" },
    { label: "16.2 O" },
    { label: "16.3 O" },
    { label: "16.4 O" },
    { label: "16.5 O" },
    { label: "16.6 O" },
    { label: "16.7 O" },
    { label: "16.8 O" },
    { label: "16.9 O" },
    { label: "17 O" },
    { label: "17.1 O" },
    { label: "17.2 O" },
    { label: "17.3 O" },
    { label: "17.4 O" },
    { label: "17.5 O" },
    { label: "17.6 O" },
    { label: "17.7 O" },
    { label: "17.8 O" },
    { label: "17.9 O" },
    { label: "18 O" },
    { label: "18.1 O" },
    { label: "18.2 O" },
    { label: "18.3 O" },
    { label: "18.4 O" },
    { label: "18.5 O" },
    { label: "18.6 O" },
    { label: "18.7 O" },
    { label: "18.8 O" },
    { label: "18.9 O" },
    { label: "19 O" },
    { label: "19.1 O" },
    { label: "19.2 O" },
    { label: "19.3 O" },
    { label: "19.4 O" },
    { label: "19.5 O" },
    { label: "19.6 O" },
    { label: "19.7 O" },
    { label: "19.8 O" },
    { label: "19.9 O" },
    { label: "20 O" },
  ],
  Wired_wireless: [{ label: "Wired" }, { label: "wireless" }],
  Compatitle_devices: [
    { label: "Pc" },
    { label: "Laptop" },
    { label: "Smartphone" },
    { label: "Laptop " },
    { label: "TV" },
    { label: "MP3 Player" },
    { label: "DVD Player " },
  ],
  Controls: [
    { label: "Volume" },
    { label: "Bass" },
    { label: "Treblle" },
    { label: "Mode" },
    { label: "Input Select" },
  ],
  Width: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
  ],
  height: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
    { label: "21cm" },
    { label: "22cm" },
    { label: "23cm" },
    { label: "24cm" },
    { label: "25cm" },
    { label: "26cm" },
    { label: "27cm" },
    { label: "28cm" },
    { label: "29cm" },
    { label: "30cm" },
  ],
  depth: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
  ],
  pendrive_Opening_mechanism: [
    { label: "nanao" },
    { label: "micro" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  Read_speed: [
    { label: "10MB/S" },
    { label: "20MB/S" },
    { label: "30MB/S" },
    { label: "40MB/S" },
    { label: "50MB/S" },
    { label: "60MB/S" },
    { label: "70MB/S" },
    { label: "80MB/S" },
    { label: "90MB/S" },
    { label: "100MB/S" },
  ],
  pendrive_width: [{ label: "1 cm" }, { label: "2 cm" }, { label: "3 cm" }],
  pendrive_height: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
    { label: "4 cm" },
    { label: "5 cm" },
  ],
  pendrive_depth: [{ label: "1 cm" }, { label: "2 cm" }],
  pendrive_weight: [
    { label: "3 g" },
    { label: "4 g" },
    { label: "5 g" },
    { label: "6 g" },
    { label: "7 g" },
    { label: "8 g" },
    { label: "9 g" },
    { label: "10 g" },
  ],
  Defrosting_Type: [
    { label: "Manual" },
    { label: "Automatic" },
    { label: "Frost-free" },
    { label: "No-frost" },
    { label: "Fan-assisted" },
    { label: "Compressor" }
  ],
  Maximum_current_rating: [
    { label: "5A" },
    { label: "10A" },
    { label: "15A" },
    { label: "20A" },
    { label: "25A" },
    { label: "30A" }
  ],

  Net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  hard_drive_type: [{ label: "HDD" }, { label: "SDD" }],
  Capacity: [
    { label: "256GB" },
    { label: "512GB" },
    { label: "1TB" },
    { label: "8TB " },
    { label: "16 TB" },
    { label: "32 TB" },
  ],
  System_requirements: [{ label: "Above Windows 10" }],
  Form_factor: [
    { label: "2.5 – 5 inch " },
    { label: "M.2 " },
    { label: "Portable" },
    { label: "dual sim" },
    { label: "single" },
  ],





  // -------------Nishitha ..🤞
  Skin_type: [
    { label: "Dry" },
    { label: "Oily" },
    { label: "Combination" },
    { label: "Sensitive" },
    { label: "Normal" }
  ],

  Concern: [
    { label: "Acne" },
    { label: "Wrinkles" },
    { label: "Dark Spots" },
    { label: "Pores" },
    { label: "Redness" }
  ],

  Pack_size: [
    { label: "Small" },
    { label: "Medium" },
    { label: "Large" },
    { label: "Family Pack" },
    { label: "Travel Size" }
  ],
  Coverage: [
    { label: "Light" },
    { label: "Medium" },
    { label: "Full" }
  ],

  Finish: [
    { label: "Matte" },
    { label: "Dewy" },
    { label: "Natural" }
  ],

  Formulation: [
    { label: "Liquid" },
    { label: "Cream" },
    { label: "Powder" }
  ],

  Preference: [
    { label: "Vegan" },
    { label: "Cruelty-Free" },
    { label: "Organic" }
  ],

  Skin_tone: [
    { label: "Fair" },
    { label: "Medium" },
    { label: "Dark" }
  ],

  Sustainable: [
    { label: "Eco-Friendly" },
    { label: "Recyclable" }
  ],

  Concious: [
    { label: "Ethical" },
    { label: "Sustainable" }
  ],

  Benefits: [
    { label: "Hydrating" },
    { label: "Anti-Aging" },
    { label: "Brightening" }
  ],

  Pack_of: [
    { label: "1" },
    { label: "2" },
    { label: "3" }
  ],

  Ingredients: [
    { label: "Aloe Vera" },
    { label: "Vitamin C" },
    { label: "Hyaluronic Acid" }
  ],
  Strength: [
    { label: "Mild" },
    { label: "Moderate" },
    { label: "Strong" },
    { label: "Extra Strong" }
  ],
  
  Gender: [
    { label: "Men" },
    { label: "Women" },
    { label: "Unisex" }
  ],

  Country_origin: [
    { label: "India" },
    { label: "USA" },
    { label: "China" }
  ],

  Description: [
    { label: "Moisturizing" },
    { label: "Long-Lasting" }
  ],

  How_to_use: [
    { label: "Apply Evenly" },
    { label: "Use Daily" }
  ],

  Key_ingredients: [
    { label: "Shea Butter" },
    { label: "Argan Oil" }
  ],

  Usage_period: [
    { label: "6 Months" },
    { label: "12 Months" }
  ],


  Color_shade_name: [
    { label: "Crimson" },
    { label: "Rose" },
    { label: "Beige" }
  ],

  Features: [
    { label: "Waterproof" },
    { label: "Sweat-Resistant" }
  ],

  Type: [
    { label: "Liquid" },
    { label: "Stick" }
  ],

  Fragrance: [
    { label: "Floral" },
    { label: "Citrus" }
  ],

  Spf: [
    { label: "15" },
    { label: "30" },
    { label: "50" }
  ],



  // ------------
  Wired_wireless: [{ label: "wired" }, { label: "wireless" }],
  hard_drive_width: [
    { label: "1 cm" },
    { label: "2 cm" },
    { label: "3 cm" },
    { label: "4 cm" },
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
  ],
  hard_drive_height: [
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
    { label: "11 cm" },
    { label: "12 cm" },
    { label: "13 cm" },
    { label: "14 cm" },
    { label: "15 cm" },
    { label: "16 cm" },
    { label: "17 cm" },
    { label: "18 cm" },
    { label: "19 cm" },
    { label: "20 cm" },
  ],
  hard_drive_depth: [
    { label: "5 cm" },
    { label: "6 cm" },
    { label: "7 cm" },
    { label: "8 cm" },
    { label: "9 cm" },
    { label: "10 cm" },
  ],
  hard_drive_weight: [
    { label: "50 g" },
    { label: "60 g" },
    { label: "70 g" },
    { label: "80 g" },
    { label: "90 g" },
    { label: "100 g" },
    { label: "110 g" },
    { label: "120 g" },
    { label: "130 g" },
    { label: "140 g" },
    { label: "150 g" },
    { label: "160 g" },
    { label: "170 g" },
    { label: "180 g" },
    { label: "190 g" },
    { label: "200 g" },
    { label: "210 g" },
    { label: "220 g" },
    { label: "230 g" },
    { label: "240 g" },
    { label: "250 g" },
    { label: "260 g" },
    { label: "270 g" },
    { label: "280 g" },
    { label: "290 g" },
    { label: "300 g" },
  ],
  // Network storage drives:
  Network_storage_Type: [{ label: "with modem" }, { label: "without modem" }],
  net_quantity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
  ],
  operating_system: [
    { label: "above windows 7" },
    { label: "MacOS" },
    { label: "esim" },
    { label: "dual sim" },
    { label: "single" },
  ],
  Operating_range: [
    { label: "10m" },
    { label: "20m" },
    { label: "30m" },
    { label: "40m" },
    { label: "50m" },
    { label: "60m" },
    { label: "70m" },
    { label: "80m" },
    { label: "90m" },
    { label: "100m" },
  ],
  Frequency: [{ label: "2.4 GHz " }, { label: "5GHz" }, { label: "Dual band" }],
  Frequency_band: [{ label: "2.4 GHz" }, { label: "5GHz" }],
  Wireless_speed: [
    { label: "1Mbps" },
    { label: "10 Mbps" },
    { label: "100 Mbps" },
    { label: "1 Gbps (1000 Mbps)" },
    { label: "10 Gbps" },
  ],

  Dsl_modem_speed: [
    { label: "0Mbps" },
    { label: "10Mbps" },
    { label: "15Mbps" },
    { label: "20Mbps" },
    { label: "30Mbps" },
    { label: "40Mbps" },
    { label: "50Mbps" },
    { label: "60Mbps" },
    { label: "70Mbps" },
    { label: "80Mbps" },
    { label: "90Mbps" },
    { label: "100 Mbps" },
  ],
  Lan_wan: [{ label: "1-100 Mbps" }, { label: "10-100 Mbps" }],

  Antennae: [{ label: "External" }, { label: "Internal" }],
  Number_of_usb_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],

  Keyboard_Type: [
    { label: "desktop" },
    { label: "keyboard" },
    { label: "gaming keyboard" },
    { label: "wireless keyboard" },
    { label: "touchscreen keyboard" },
    { label: "compact keyboard" },
    { label: "mechanical keyboard" },
  ],
  // Solid State Drives:
  solid_state_Type: [
    { label: "SSD" },
    { label: "M.2SATA" },
    { label: "M.2 NVMe" },
    { label: " PCIe " },
    { label: "U.2" },
  ],
  Capacity: [
    { label: "120 GB" },
    { label: "240 GB" },
    { label: "256 GB" },
    { label: "480 GB" },
    { label: "500 GB" },
    { label: "512 GB" },
    { label: "1 TB" },
    { label: "2 TB" },
    { label: "4 TB" },
    { label: "8 TB" },
  ],
  System_Requirements: [
    { label: "Windows" },
    { label: "macOS" },
    { label: "linux" },
    { label: "Android" },
  ],
  Form_factor: [{ label: "Portable" }],
  Number_of_ports: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Connector_Type: [
    { label: "Type c" },
    { label: "PCIe Connector" },
    { label: "U.2 " },
    { label: "M.2 key M coonector" },
    { label: "SATA data connector" },
    { label: " M.2 Key B connector " },
  ],
  Cable_type: [
    { label: "Type c" },
    { label: "PCIe Connector" },
    { label: "U.2 " },
    { label: "M.2 key M coonector" },
    { label: "SATA data connector" },
    { label: " M.2 Key B connector " },
  ],
  Aspect_ratio: [
    { label: "4:3" },
    { label: "16:9" },
    { label: "16:10" },
    { label: "21:9" },
    { label: "32:9" }
  ],

  Hd_technology_resolution: [
    { label: "HD" },
    { label: "Full HD" },
    { label: "Quad HD" },
    { label: "4K UHD" },
    { label: "8K UHD" }
  ],

  Wifi_type: [
    { label: "Wi-Fi 4" },
    { label: "Wi-Fi 5" },
    { label: "Wi-Fi 6" },
    { label: "Wi-Fi 6E" }
  ],

  View_angle: [
    { label: "60 degrees" },
    { label: "120 degrees" },
    { label: "160 degrees" },
    { label: "180 degrees" }
  ],

  Led_display_type: [
    { label: "LCD" },
    { label: "LED" },
    { label: "OLED" },
    { label: "QLED" },
    { label: "Mini-LED" }
  ],

  Refresh_rate: [
    { label: "60 Hz" },
    { label: "120 Hz" },
    { label: "144 Hz" },
    { label: "240 Hz" },
    { label: "360 Hz" }
  ],

  Number_of_speakers: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" }
  ],

  Speaker_type: [
    { label: "Built-in" },
    { label: "External" },
    { label: "Surround" },
    { label: "Stereo" },
    { label: "Subwoofer" }
  ],

  Sound_mode: [
    { label: "Stereo" },
    { label: "Mono" },
    { label: "Surround" },
    { label: "Dolby" },
    { label: "DTS" }
  ],

  Supported_Mobile_operating_system: [
    { label: "Android" },
    { label: "iOS" },
    { label: "Windows" },
    { label: "HarmonyOS" },
    { label: "KaiOS" }
  ],

  Supported_devices_for_casting: [
    { label: "Chromecast" },
    { label: "Apple AirPlay" },
    { label: "Miracast" },
    { label: "DLNA" },
    { label: "Roku" }
  ],

  Power_requirement: [
    { label: "110V" },
    { label: "220V" },
    { label: "230V" },
    { label: "240V" },
    { label: "250V" }
  ],

  Number_of_charger_pins: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" }
  ],
  Headphone_type: [
    { label: "Over-ear" },
    { label: "On-ear" },
    { label: "In-ear" },
    { label: "True wireless" },
    { label: "Bone conduction" }
  ],

  Headphone_design: [
    { label: "Closed-back" },
    { label: "Open-back" },
    { label: "Semi-open" },
    { label: "Earbuds" },
    { label: "Headset" }
  ],

  Power_supply: [
    { label: "Battery" },
    { label: "USB" },
    { label: "AC adapter" },
    { label: "Rechargeable" },
    { label: "Solar" }
  ],

  Video_player_type: [
    { label: "Blu-ray" },
    { label: "DVD" },
    { label: "Streaming" },
    { label: "Media player" },
    { label: "Portable" }
  ],

  Operating_system_supported: [
    { label: "Windows" },
    { label: "macOS" },
    { label: "Android" },
    { label: "iOS" },
    { label: "Linux" }
  ],

  Type_of_tracking: [
    { label: "Optical" },
    { label: "Laser" },
    { label: "Infrared" },
    { label: "Gyroscopic" },
    { label: "Magnetic" }
  ],


  Output_current: [
    { label: "1A" },
    { label: "2A" },
    { label: "3A" },
    { label: "4A" },
    { label: "5A" }
  ],

  Connector_1: [
    { label: "Type c" },
    { label: "PCIe Connector" },
    { label: "U.2 " },
    { label: "M.2 key M coonector" },
    { label: "SATA data connector" },
    { label: " M.2 Key B connector " },
  ],

  Connector_2: [
    { label: "Type c" },
    { label: "PCIe Connector" },
    { label: "U.2 " },
    { label: "M.2 key M coonector" },
    { label: "SATA data connector" },
    { label: " M.2 Key B connector " },
  ],

  Case_material: [
    { label: "Aluminum " },
    { label: "Plastc " },
    { label: "Metal Alloys" },
    { label: "Composite Materials" },
  ],
  solid_state_width: [
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
  ],
  solid_state_height: [
    { label: "2mm " },
    { label: "4mm " },
    { label: "6mm " },
    { label: "8mm " },
    { label: "10mm " },
    { label: "12mm " },
    { label: "14mm " },
    { label: "16mm " },
    { label: "18mm " },
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
    { label: "72mm " },
    { label: "74mm " },
    { label: "76mm " },
    { label: "78mm " },
    { label: "80mm " },
    { label: "82mm " },
    { label: "84mm " },
    { label: "86mm " },
    { label: "88mm " },
    { label: "90mm " },
    { label: "92mm " },
    { label: "94mm " },
    { label: "96mm " },
    { label: "98mm " },
    { label: "100mm " },
  ],
  solid_state_depth: [
    { label: "2mm " },
    { label: "4mm " },
    { label: "6mm " },
    { label: "8mm " },
    { label: "10mm " },
    { label: "12mm " },
    { label: "14mm " },
    { label: "16mm " },
    { label: "18mm " },
    { label: "20mm " },
    { label: "22mm " },
    { label: "24mm " },
    { label: "26mm " },
    { label: "28mm " },
    { label: "30mm " },
    { label: "32mm " },
    { label: "34mm " },
    { label: "36mm " },
    { label: "38mm " },
    { label: "40mm " },
    { label: "42mm " },
    { label: "44mm " },
    { label: "46mm " },
    { label: "48mm " },
    { label: "50mm " },
    { label: "52mm " },
    { label: "54mm " },
    { label: "56mm " },
    { label: "58mm " },
    { label: "60mm " },
    { label: "62mm " },
    { label: "64mm " },
    { label: "66mm " },
    { label: "68mm " },
    { label: "70mm " },
    { label: "72mm " },
    { label: "74mm " },
    { label: "76mm " },
    { label: "78mm " },
    { label: "80mm " },
  ],
  solid_state_weight: [
    { label: "5 g" },
    { label: "10 g" },
    { label: "15 g" },
    { label: "20 g" },
    { label: "25 g" },
    { label: "30 g" },
    { label: "35 g" },
    { label: "40 g" },
    { label: "45 g" },
    { label: "50 g" },
    { label: "55 g" },
  ],
  // ------------FootWear------------------------
  Outer_material: [
    { label: "mesh" },
    { label: "polyester" },
    { label: "nylon" },
  ],
  Inner_lining: [
    { label: "Textile" },
    { label: "Leather" },
    { label: "Synthetic" },
    { label: "Foam" },
  ],
  Removable_Insole: [
    { label: "Textile" },
    { label: "Leather" },
    { label: "Synthetic" },
    { label: "Foam" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "jogging" },
    { label: "walking" },
    { label: " gym workouts" },
  ],
  Occasion: [
    { label: "casual outings" },
    { label: "exercise" },
    { label: "sports activities" },
    { label: "informal occasions" },
  ],
  Type_of_sports: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "hiking" },
    { label: "weightlifting" },
    { label: "cross-training" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "zippers" },
    { label: "slip-on designs" },
  ],
  Upper_pattern: [
    { label: "mesh panels" },
    { label: "overlays" },
    { label: "stitching patterns" },
    { label: "designed to provide support" },
    { label: "breathability" },
    { label: "flexibility" },
    { label: "aesthetic appeal" },
  ],
  Season: [{ label: "summer" }, { label: "rainy" }, { label: "snow" }],
  // ----------------------Casual Shoes----------------------------------
  Outer_material: [
    { label: "leather" },
    { label: "canvas" },
    { label: "suede" },
    { label: "synthetic fabrics" },
  ],
  Ideal_for: [
    { label: "everyday wear" },
    { label: "social gatherings" },
    { label: "informal outings" },
    { label: "relaxed settings" },
  ],
  Occasion: [
    { label: "hanging out with friends" },
    { label: "going to casual dinners or parties" },
    { label: "running errands" },
    { label: "simply enjoying leisure time" },
  ],
  Type_of_casual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "moccasins" },
    { label: "slip-ons" },
    { label: "sandals" },
    { label: "espadrilles" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
    { label: "synthetic compounds" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "buckles" },
    { label: "elastic bands" },
    { label: "slip-on designs" },
  ],

  Upper_pattern: [
    { label: "stitching patterns" },
    { label: "overlays" },
    { label: "perforations" },
    { label: "embossed designs" },
  ],
  Tip_shape: [{ label: "rounded to pointed" }, { label: "even square" }],

  // ---------------formal shoes--------------------------------------

  Outer_material: [{ label: "leather" }, { label: "synthetic leather" }],
  Ideal_for: [
    { label: "professional environments" },
    { label: "business meetings" },
    { label: "formal events" },
  ],
  Occasion: [
    { label: "weddings" },
    { label: "business meetings" },
    { label: "job interviews" },
    { label: "gala dinners" },
  ],
  Type_of_formal: [
    { label: "Oxford shoes" },
    { label: "Derby shoes" },
    { label: "Brogues" },
    { label: "Monk straps" },
    { label: "Loafers" },
    { label: "Dress boots" },
  ],
  Closure: [
    { label: "laces" },
    { label: "buckles" },
    { label: "slip-on designs" },
  ],
  Season: [{ label: "summer" }, { label: "rainy" }, { label: "winter" }],

  //-----------------------------Sandles & Floaters ---------------------------

  Type: [
    { label: "Flip-flops" },
    { label: "Slides" },
    { label: "Gladiator sandals" },
    { label: "Espadrilles" },
    { label: "Sport sandals" },
    { label: "Floaters" },
  ],
  Closure: [
    { label: "Straps with buckles" },
    { label: " Velcro straps" },
    { label: "slip-on designs" },
    { label: "Elastic bands" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "synthetic compounds" },
  ],

  // ------------------------Flip- flops  -----------------------------------

  Ideal_for: [
    { label: "casual wear" },
    { label: "beach outings" },
    { label: "poolside relaxation" },
    { label: "any laid-back occasion" },
  ],

  // -------------------------------Loafers  ------------------------------

  Outer_material: [{ label: "leather" }, { label: "suede" }],
  Type_of_casual: [
    { label: "slip-on design" },
    { label: "low heels" },
    { label: "a moccasin-style construction" },
  ],
  Ideal_for: [
    { label: "social gatherings" },
    { label: "weekend outings" },
    { label: "relaxed workplaces" },
  ],
  Occasion: [
    { label: "Casual Fridays" },
    { label: "Weekend outings" },
    { label: "Barbecues or picnics" },
    { label: "Casual dinners or dates" },
    { label: "Shopping trips or errands" },
    { label: "Traveling" },
    { label: "sightseeing" },
  ],

  // -------------------------Boots------------------------------------

  Outer_material: [
    { label: "leather genuine" },
    { label: "synthetic leather" },
    { label: "suede" },
    { label: "canvas" },
    { label: "rubber" },
  ],
  Ideal_for: [{ label: "hiking" }, { label: "camping" }, { label: "hunting" }],
  Occasion: [
    { label: "Casual outings" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Work" },
  ],
  Type_of_casual: [
    { label: "Chelsea boots" },
    { label: "Chukka boots" },
    { label: "Desert boots" },
    { label: "Ankle boots" },
    { label: "Combat boots" },
    { label: "Hiking boots" },
    { label: "Work boots" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "polyurethane (PU)" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "thermoplastic polyurethane (TPU)" },
    { label: "tleather" },
  ],

  // ----------------------Running Shoes-----------------------------------

  Outer_material: [
    { label: "mesh" },
    { label: "polyester" },
    { label: "nylon" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "jogging" },
    { label: "walking" },
    { label: " gym workouts" },
  ],
  Occasion: [
    { label: "casual outings" },
    { label: "exercise" },
    { label: "sports activities" },
    { label: "informal occasions" },
  ],
  Type_of_sports: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "hiking" },
    { label: "weightlifting" },
    { label: "cross-training" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "zippers" },
    { label: "slip-on designs" },
  ],
  Upper_pattern: [
    { label: "mesh panels" },
    { label: "overlays" },
    { label: "stitching patterns" },
    { label: "designed to provide support" },
    { label: "breathability" },
    { label: "flexibility" },
    { label: "aesthetic appeal" },
  ],
  Weight: [
    { label: "100g" },
    { label: "200g" },
    { label: "300g" },
    { label: "400g" },
    { label: "500g" },
    { label: "600g" },
    { label: "700g" },
  ],
  Season: [{ label: "summer" }, { label: "rainy" }, { label: "snow" }],

  // ------------------------Sneakers---------------------------------

  Outer_material: [
    { label: "leather" },
    { label: "canvas" },
    { label: "suede" },
    { label: "synthetic fabrics" },
  ],
  Ideal_for: [
    { label: "everyday wear" },
    { label: "social gatherings" },
    { label: "informal outings" },
    { label: "relaxed settings" },
  ],
  Occasion: [
    { label: "hanging out with friends" },
    { label: "going to casual dinners or parties" },
    { label: "running errands" },
    { label: "simply enjoying leisure time" },
  ],
  Type_of_casual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "moccasins" },
    { label: "slip-ons" },
    { label: "sandals" },
    { label: "espadrilles" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA (ethylene-vinyl acetate)" },
    { label: "TPU (thermoplastic polyurethane)" },
    { label: "synthetic compounds" },
  ],
  Closure: [
    { label: "laces" },
    { label: "hook-and-loop straps (Velcro)" },
    { label: "buckles" },
    { label: "slip-on designs" },
    { label: "elastic bands" },
  ],
  Upper_pattern: [
    { label: "stitching patterns" },
    { label: "overlays" },
    { label: "stitching patterns" },
    { label: "perforations" },
    { label: "embossed designs" },
  ],
  Tip_shape: [{ label: "rounded to pointed" }, { label: "even square" }],

  // -------------------------WOMENS-FLATS---------------------------------

  Type: [
    { label: "Ballet flats" },
    { label: "loafers" },
    { label: "espadrilles" },
    { label: "moccasins" },
    { label: "D’Orsay Flats" },
    { label: "Pointed-Toe Flats" },
    { label: "Mary jane flats" },
    { label: "slingback flates" },
    { label: "mules" },
    { label: "T-strap Flats" },
  ],
  Type_of_flats: [
    { label: "Ballet flats" },
    { label: "loafers" },
    { label: "espadrilles" },
    { label: " moccasins D’orsay" },
    { label: "pointed –Toe flats" },
    { label: "Mary jane flats" },
    { label: "slingback flats" },
    { label: "Mules" },
    { label: "T-strap flats" },
  ],

  // ----------------------------------Heels--------------------------

  Type: [
    { label: "Stiletto" },
    { label: "block" },
    { label: "kitten" },
    { label: "wedge" },
    { label: "platform" },
    { label: "cone" },
    { label: "slingback" },
    { label: "peep-top" },
    { label: "ankle strap" },
    { label: "chunky heels" },
    { label: "sandals" },
  ],
  Type_of_heels: [
    { label: "1 inch" },
    { label: "1.5 inch" },
    { label: "2 inch" },
    { label: "2.5 inch" },
    { label: "3 inch" },
    { label: "3.5 inch" },
    { label: "4 inch" },
    { label: "4.5 inch" },
    { label: "5 inch" },
    { label: "5.5 inch" },
    { label: "6 inch" },
  ],
  Upper_pattern: [
    { label: "solid" },
    { label: "plain" },
    { label: "textured" },
    { label: "printed" },
    { label: "embellished" },
    { label: "perforated" },
    { label: "cut-out" },
    { label: "striped" },
    { label: "checkered/plaid" },
  ],

  // ----------------------------------Wedges-----------------------------------

  Type: [
    { label: "pumps" },
    { label: "platform" },
    { label: "cork" },
    { label: "espadrille" },
    { label: "wood" },
    { label: "peep-top" },
    { label: "slingback" },
    { label: "ankle" },
    { label: "lace-up" },
    { label: "T-Strap" },
  ],
  Type_of_Wedges: [
    { label: "Stiletto" },
    { label: "block" },
    { label: "kitten" },
    { label: "wedge" },
    { label: "platform" },
    { label: "cone" },
    { label: "slingback" },
    { label: "peep-top" },
    { label: "ankle strap" },
    { label: "chunky heels" },
    { label: "sandals" },
  ],

  // ------------------------------------Sports Shoes------------------------------

  Type: [
    { label: "Running" },
    { label: "training" },
    { label: "basketball" },
    { label: "Soccer cleats" },
    { label: "Tennis" },
    { label: "Golf" },
    { label: "Cross-training" },
    { label: "trail running" },
    { label: "walkin" },
    { label: "hikin boots" },
  ],
  Outer_Material: [
    { label: "Mesh" },
    { label: "synthetic leather" },
    { label: "leather" },
    { label: "knit" },
    { label: "synthetic" },
    { label: "textile" },
    { label: "rubber" },
    { label: "nylon" },
    { label: "polyster" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "tennsi" },
    { label: "golf" },
    { label: "crodd –training" },
    { label: "trail running" },
    { label: "walking shoes" },
    { label: "hiking boots" },
  ],
  Occasion: [
    { label: "regular" },
    { label: "everyday" },
    { label: "athletic" },
    { label: "outdoor" },
    { label: "casual" },
    { label: "formal occasions" },
  ],
  Type_of_sports: [
    { label: "Basketball" },
    { label: "soccer" },
    { label: "tennis" },
    { label: "golf" },
    { label: "running" },
    { label: "cycling" },
    { label: "swimming" },
    { label: "volleyball" },
    { label: "baseball" },
    { label: "surfing" },
    { label: "gymnastics" },
    { label: "skiing" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "TPU" },
    { label: "Phylon" },
    { label: "Carbon Fiber" },
    { label: "Gum Rubber" },
  ],
  Closure: [
    { label: "laces" },
    { label: "Velcro starps" },
    { label: "zippers" },
    { label: "buckles" },
    { label: "slip –on" },
    { label: "boa system" },
    { label: "Elastic" },
  ],
  Season: [
    { label: "Spring" },
    { label: "summer" },
    { label: "fall" },
    { label: "snow" },
    { label: "year-round" },
  ],

  // --------------------------------Causual Shoes---------------------------------

  Outer_material: [
    { label: "Leather" },
    { label: "Canvas" },
    { label: "suede" },
    { label: "synthetic materials" },
    { label: "mesh" },
    { label: "textile" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "tennsi" },
    { label: "golf" },
    { label: "crodd –training" },
    { label: "trail running" },
    { label: "walking shoes" },
    { label: "hiking boots" },
  ],
  Occasion: [
    { label: "regular" },
    { label: "everyday" },
    { label: "athletic" },
    { label: "outdoor" },
    { label: "casual" },
    { label: "formal occasions" },
  ],
  Type_of_causual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "espadrilles" },
    { label: "moccasins" },
    { label: "derby shoes" },
  ],
  Upper_pattern: [
    { label: "solid" },
    { label: "plain" },
    { label: "textured" },
    { label: "printed" },
    { label: "embellished" },
    { label: "perforated" },
  ],
  Tip_shape: [
    { label: "Round" },
    { label: "square" },
    { label: "pointed" },
    { label: "almond" },
    { label: "chisel" },
  ],

  // -------------------------------Boots-------------------------------

  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "fabric" },
    { label: "rubber" },
    { label: "suede" },
    { label: "gore-tex" },
    { label: "textile" },
  ],
  Ideal_for: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "tennsi" },
    { label: "golf" },
    { label: "crodd –training" },
    { label: "trail running" },
    { label: "walking shoes" },
    { label: "hiking boots" },
  ],
  Occasion: [
    { label: "regular" },
    { label: "everyday" },
    { label: "athletic" },
    { label: "outdoor" },
    { label: "casual" },
    { label: "formal occasions" },
  ],
  Type_of_causual: [
    { label: "sneakers" },
    { label: "loafers" },
    { label: "boat shoes" },
    { label: "espadrilles" },
    { label: "moccasins" },
    { label: "derby shoes" },
  ],
  Season: [
    { label: "Spring" },
    { label: "summer" },
    { label: "fall" },
    { label: "snow" },
    { label: "year-round" },
  ],

  //---------------------- KIDS WEAR-Boys Footwear----------------------------
  //--------------------------------Sandals-------------------------------------

  Number_of_pairs: [
    { label: "1 pairs" },
    { label: "2 pairs" },
    { label: "3 pairs" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Base_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "leather" },
    { label: "PU" },
    { label: "Foam" },
  ],
  Strap_material: [
    { label: "leather" },
    { label: "synthetic leather" },
    { label: "fabric" },
    { label: "nylon" },
    { label: "rubber" },
    { label: "PU" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],

  // --------------------------------Sports shoes----------------------------------

  Number_of_pairs: [
    { label: "1 pairs" },
    { label: "2 pairs" },
    { label: "3 pairs" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Type: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "football" },
    { label: "tennis" },
    { label: "hiking" },
    { label: "shoes" },
    { label: "gym shoes" },
  ],
  Sub_type: [
    { label: "Trail Running shoes" },
    { label: "Cross Training" },
    { label: "Racing Flats" },
    { label: "track spikes" },
    { label: "cycling shoes" },
    { label: "wrestling shoes" },
    { label: "golf shoes" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "fabric" },
    { label: "rubber" },
    { label: "suede" },
    { label: "gore-tex" },
    { label: "textile" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],

  // ---------------------Girls footwear Flats & bellies---------------------------

  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Heel_type: [
    { label: "stiletto" },
    { label: "Block" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "platform" },
    { label: "cone" },
    { label: "spool" },
    { label: "louis" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Length: [
    { label: "1 years" },
    { label: "2 years" },
    { label: "3 years" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],

  //----------------------------Sports shoes------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Type: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "football" },
    { label: "tennis" },
    { label: "hiking" },
    { label: "shoes" },
    { label: "gym shoes" },
  ],
  Sub_type: [
    { label: "Trail Running shoes" },
    { label: "Cross Training" },
    { label: "Racing Flats" },
    { label: "track spikes" },
    { label: "cycling shoes" },
    { label: "wrestling shoes" },
    { label: "golf shoes" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "mesh" },
    { label: "canvas" },
    { label: "suede" },
    { label: "rubber" },
    { label: "knit" },
    { label: "textile" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Heel_design: [
    { label: "classic pump" },
    { label: "chuncky" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "spool" },
    { label: "Louis" },
    { label: "cone" },
  ],
  Heel_type: [
    { label: "stiletto" },
    { label: "Block" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "platform" },
    { label: "cone" },
    { label: "spool" },
    { label: "louis" },
  ],

  //-------------------------------Character shoes------------------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],
  Size: [
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Base_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "leather" },
    { label: "PU" },
    { label: "Foam" },
  ],
  Length: [
    { label: "1 years" },
    { label: "2 years" },
    { label: "3 years" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],

  //-----------------------------------Sports shoes--------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],

  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Type: [
    { label: "running" },
    { label: "basketball" },
    { label: "soccer" },
    { label: "football" },
    { label: "tennis" },
    { label: "hiking" },
    { label: "shoes" },
    { label: "gym shoes" },
  ],
  Sub_type: [
    { label: "Trail Running shoes" },
    { label: "Cross Training" },
    { label: "Racing Flats" },
    { label: "track spikes" },
    { label: "cycling shoes" },
    { label: "wrestling shoes" },
    { label: "golf shoes" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "mesh" },
    { label: "canvas" },
    { label: "suede" },
    { label: "rubber" },
    { label: "knit" },
    { label: "textile" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Heel_design: [
    { label: "classic pump" },
    { label: "chuncky" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "spool" },
    { label: "Louis" },
    { label: "cone" },
  ],
  Heel_type: [
    { label: "stiletto" },
    { label: "Block" },
    { label: "wedge" },
    { label: "kitten" },
    { label: "platform" },
    { label: "cone" },
    { label: "spool" },
    { label: "louis" },
  ],

  //-------------------------------Character shoes-----------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],

  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Base_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "leather" },
    { label: "PU" },
    { label: "Foam" },
  ],
  Length: [
    { label: "3 years" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
  ],

  //-----------------------------------Infant footwear-------------------------

  Number_of_pairs: [
    { label: "1 pair" },
    { label: "2 pair" },
    { label: "3 pair" },
  ],
  Size: [
    { label: "1 Month" },
    { label: "2 Month" },
    { label: "3 Month" },
    { label: "4 Month" },
    { label: "5 Month" },
    { label: "6 Month" },
    { label: "7 Month" },
    { label: "8 Month" },
    { label: "9 Month" },
    { label: "10 Month" },
    { label: "11 Month" },
    { label: "1 year" },
    { label: "2 year" },
    { label: "3 year" },
    { label: "4 years" },
    { label: "5 years" },
    { label: "6 years" },
    { label: "7 years" },
    { label: "8 years" },
    { label: "9 years" },
    { label: "10 years" },
    { label: "11 years" },
    { label: "12 years" },
    { label: "13 years" },
    { label: "14 years" },
    { label: "15 years" },
    { label: "16 years" },
  ],
  Ideal_for: [
    { label: "Everyday wear" },
    { label: "Sports and athletics" },
    { label: "Formal events" },
    { label: "Outdoor activities" },
    { label: " Water activities" },
  ],
  Closure_type: [
    { label: "velcro" },
    { label: "buckle" },
    { label: "slip-on" },
    { label: "elastic" },
    { label: "zipper" },
    { label: "lace up" },
  ],
  Type: [
    { label: "soft-soled" },
    { label: "crib" },
    { label: "booties" },
    { label: "pre-walker" },
    { label: "sandal style" },
  ],
  Sub_type: [
    { label: "Booties" },
    { label: "Crib shoes" },
    { label: "Soft-soled shoes" },
    { label: "Pre-walkers" },
    { label: "Sandals" },
  ],
  Outer_material: [
    { label: "Leather" },
    { label: "Synthetic Leather" },
    { label: "canvas" },
    { label: "textile" },
    { label: "mesh" },
    { label: "suede" },
  ],
  Sole_material: [
    { label: "rubber" },
    { label: "EVA" },
    { label: "PU" },
    { label: "TPR" },
  ],
  Heel_design: [
    { label: "Flat heel" },
    { label: "soft sole" },
    { label: "elastic heel" },
    { label: "no heel" },
  ],
  Insole_material: [
    { label: "soft fabric" },
    { label: "cotton" },
    { label: "memory foam" },
    { label: "EVA" },
    { label: "Leather" },
    { label: "textile" },
  ],
  Character: [
    { label: "low heel" },
    { label: "straps" },
    { label: "sturdy construction" },
    { label: "variety of styles" },
  ],
  Length: [
    { label: "1 Month" },
    { label: "2 Month" },
    { label: "3 Month" },
    { label: "4 Month" },
    { label: "5 Month" },
    { label: "6 Month" },
    { label: "7 Month" },
    { label: "8 Month" },
    { label: "9 Month" },
    { label: "10 Month" },
    { label: "11 Month" },
    { label: "12 Month" },
    { label: "13 Month" },
    { label: "14 Month" },
    { label: "15 Month" },
    { label: "16 Month" },
    { label: "17 Month" },
    { label: "18 Month" },
    { label: "19 Month" },
    { label: "20 Month" },
    { label: "21 Month" },
    { label: "22 Month" },
    { label: "23 Month" },
    { label: "24 Month" },
  ],
  Warranty: [
    { label: "1 Month" },
    { label: "2 Month" },
    { label: "3 Month" },
    { label: "4 Month" },
    { label: "5 Month" },
    { label: "6 Month" },
    { label: "1 Year" },
  ],









  // ----------------------🤞----Nishitha  🤞---------------------------------------//
  // home and  furniture ::----
  Size: [
    { label: "Small" },
    { label: "Medium" },
    { label: "Large" },
    { label: "Extra Large" }
  ],
  Temperature_glass_lid: [
    { label: "Heat-resistant" },
    { label: "Tempered" },
    { label: "Borosilicate" },
    { label: "Shatterproof" },
    { label: "Standard" }
  ],

  Processing_bowl: [
    { label: "Plastic" },
    { label: "Glass" },
    { label: "Stainless steel" },
    { label: "Ceramic" },
    { label: "Aluminum" }
  ],

  Processing_jar: [
    { label: "Plastic" },
    { label: "Glass" },
    { label: "Stainless steel" },
    { label: "Ceramic" },
    { label: "Metal" }
  ],
  Anti_drip_value: [
    { label: "Yes" },
    { label: "No" }
  ],

  Brew_strength_control: [
    { label: "Mild" },
    { label: "Medium" },
    { label: "Strong" },
    { label: "Custom" }
  ],

  Removable_drip: [
    { label: "Yes" },
    { label: "No" }
  ],

  Frother: [
    { label: "Yes" },
    { label: "No" }
  ],

  Hot_plate: [
    { label: "Yes" },
    { label: "No" }
  ],

  Brewing_time: [
    { label: "5 minutes" },
    { label: "10 minutes" },
    { label: "15 minutes" },
    { label: "20 minutes" },
    { label: "30 minutes" }
  ],

  Removable_filter: [
    { label: "Yes" },
    { label: "No" }
  ],

  Filter_material: [
    { label: "Paper" },
    { label: "Metal" },
    { label: "Plastic" },
    { label: "Cloth" }
  ],

  Electric_type: [
    { label: "Corded" },
    { label: "Cordless" },
    { label: "Rechargeable" }
  ],
  Filter_life: [
    { label: "3 months" },
    { label: "6 months" },
    { label: "9 months" },
    { label: "12 months" },
    { label: "18 months" }
  ],

  Cold_water_capacity: [
    { label: "1 liter" },
    { label: "2 liters" },
    { label: "3 liters" },
    { label: "4 liters" },
    { label: "5 liters" }
  ],

  Hot_water_capacity: [
    { label: "1 liter" },
    { label: "2 liters" },
    { label: "3 liters" },
    { label: "4 liters" },
    { label: "5 liters" }
  ],

  Number_of_castor_wheels: [
    { label: "2" },
    { label: "3" },
    { label: "4" }
  ],

  Lcd_screen_display: [
    { label: "Yes" },
    { label: "No" }
  ],

  Bobbin_winder: [
    { label: "Manual" },
    { label: "Automatic" }
  ],

  Stop_start_button: [
    { label: "Yes" },
    { label: "No" }
  ],

  Stitch_out_time_indicator: [
    { label: "Yes" },
    { label: "No" }
  ],

  Led_needle_light: [
    { label: "Yes" },
    { label: "No" }
  ],
  Automatic_thread_cutter: [
    { label: "Yes" },
    { label: "No" }
  ],

  Water_ice_dispenser: [
    { label: "Yes" },
    { label: "No" }
  ],

  Removable_basket: [
    { label: "Yes" },
    { label: "No" }
  ],

  Foldable_or_collapsible: [
    { label: "Yes" },
    { label: "No" }
  ],

  Manual: [
    { label: "Included" },
    { label: "Not included" }
  ],

  Magnet_type: [
    { label: "Neodymium" },
    { label: "Ferrite" },
    { label: "Alnico" },
    { label: "Samarium Cobalt" }
  ],

  Driver_type: [
    { label: "Dynamic" },
    { label: "Balanced Armature" },
    { label: "Planar Magnetic" },
    { label: "Electrostatic" }
  ],

  Signal_to_noise_ratio: [
    { label: "80 dB" },
    { label: "85 dB" },
    { label: "90 dB" },
    { label: "95 dB" },
    { label: "100 dB" }
  ],

  Modulation: [
    { label: "AM" },
    { label: "FM" },
    { label: "PM" },
    { label: "QAM" }
  ],

  Minimum_frequency_response: [
    { label: "20 Hz" },
    { label: "50 Hz" },
    { label: "100 Hz" },
    { label: "200 Hz" }
  ],

  Other_sound_features: [
    { label: "Bass Boost" },
    { label: "Treble Control" },
    { label: "Surround Sound" },
    { label: "Noise Cancellation" },
    { label: "Equalizer" }
  ],

  Audio_codec: [
    { label: "AAC" },
    { label: "SBC" },
    { label: "aptX" },
    { label: "LDAC" },
    { label: "MP3" }
  ],

  Bluetooth_profile: [
    { label: "A2DP" },
    { label: "AVRCP" },
    { label: "HFP" },
    { label: "HSP" },
    { label: "PBAP" }
  ],

  Bluetooth_range: [
    { label: "10 meters" },
    { label: "20 meters" },
    { label: "30 meters" },
    { label: "50 meters" },
    { label: "100 meters" }
  ],

  Headphone_power_source: [
    { label: "Battery" },
    { label: "USB" },
    { label: "Rechargeable" }
  ],

  Battery_life: [
    { label: "5 hours" },
    { label: "10 hours" },
    { label: "15 hours" },
    { label: "20 hours" },
    { label: "30 hours" }
  ],

  Stand_by_time: [
    { label: "50 hours" },
    { label: "100 hours" },
    { label: "150 hours" },
    { label: "200 hours" }
  ],

  In_residue_free_removal: [
    { label: "Yes" },
    { label: "No" }
  ],

  Tinted: [
    { label: "Yes" },
    { label: "No" }
  ],

  One_touch_button: [
    { label: "Yes" },
    { label: "No" }
  ],

  Touch_control: [
    { label: "Yes" },
    { label: "No" }
  ],

  Number_of_contents_in_sales_pack: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" }
  ],

  Cache: [
    { label: "2 MB" },
    { label: "4 MB" },
    { label: "8 MB" },
    { label: "16 MB" },
    { label: "32 MB" }
  ],

  Number_of_cores: [
    { label: "Dual-core" },
    { label: "Quad-core" },
    { label: "Hexa-core" },
    { label: "Octa-core" }
  ],

  Os_architecture: [
    { label: "32-bit" },
    { label: "64-bit" }
  ],

  Multi_card_slot: [
    { label: "Yes" },
    { label: "No" }
  ],

  Hardware_interface: [
    { label: "USB 2.0" },
    { label: "USB 3.0" },
    { label: "HDMI" },
    { label: "Thunderbolt" },
    { label: "SATA" }
  ],

  Connector_type: [
    { label: "USB" },
    { label: "HDMI" },
    { label: "VGA" },
    { label: "Audio Jack" },
    { label: "Ethernet" }
  ],

  Picture_engine: [
    { label: "X-Reality Pro" },
    { label: "Crystal Processor" },
    { label: "P5 Perfect Picture Engine" },
    { label: "OLED Motion Pro" },
    { label: "Quantum Processor" }
  ],
  Analog_tv_reception: [
    { label: "PAL" },
    { label: "NTSC" },
    { label: "SECAM" }
  ],

  Supported_audio_features: [
    { label: "Dolby Atmos" },
    { label: "DTS" },
    { label: "Stereo" },
    { label: "Surround Sound" },
    { label: "Bass Boost" }
  ],

  Svr_variant: [
    { label: "Standard" },
    { label: "Premium" },
    { label: "Pro" }
  ],

  Brand_color: [
    { label: "Black" },
    { label: "White" },
    { label: "Silver" },
    { label: "Red" },
    { label: "Blue" }
  ],

  Effective_pixels: [
    { label: "12 MP" },
    { label: "16 MP" },
    { label: "20 MP" },
    { label: "24 MP" },
    { label: "48 MP" }
  ],

  Tripod_socket: [
    { label: "Yes" },
    { label: "No" }
  ],

  Sensor_type: [
    { label: "CMOS" },
    { label: "CCD" },
    { label: "BSI CMOS" }
  ],

  Image_sensor_size: [
    { label: "1 inch" },
    { label: "APS-C" },
    { label: "Full Frame" },
    { label: "Micro Four Thirds" },
    { label: "Medium Format" }
  ],

  Lens_mount: [
    { label: "Canon EF" },
    { label: "Nikon F" },
    { label: "Sony E" },
    { label: "Micro Four Thirds" },
    { label: "Leica L" }
  ],

  Dust_reduction: [
    { label: "Yes" },
    { label: "No" }
  ],

  Shutter_speed: [
    { label: "1/4000 sec" },
    { label: "1/2000 sec" },
    { label: "1/1000 sec" },
    { label: "1/500 sec" },
    { label: "1/250 sec" }
  ],

  Self_timer: [
    { label: "2 sec" },
    { label: "5 sec" },
    { label: "10 sec" }
  ],

  External_flash: [
    { label: "Yes" },
    { label: "No" }
  ],

  Image_format: [
    { label: "JPEG" },
    { label: "RAW" },
    { label: "TIFF" },
    { label: "PNG" }
  ],

  Video_quality: [
    { label: "1080p" },
    { label: "4K" },
    { label: "8K" },
    { label: "720p" }
  ],

  Compatible_cord: [
    { label: "USB Type-C" },
    { label: "Micro-USB" },
    { label: "HDMI" }
  ],

  Shooting_modes: [
    { label: "Auto" },
    { label: "Manual" },
    { label: "Portrait" },
    { label: "Landscape" },
    { label: "Night" }
  ],

  Image_sensor_type: [
    { label: "CMOS" },
    { label: "CCD" }
  ],

  Iso_rating: [
    { label: "100" },
    { label: "200" },
    { label: "400" },
    { label: "800" },
    { label: "1600" }
  ],

  Focal_length: [
    { label: "24mm" },
    { label: "35mm" },
    { label: "50mm" },
    { label: "85mm" },
    { label: "100mm" }
  ],

  Focus_mode: [
    { label: "Auto" },
    { label: "Manual" },
    { label: "Continuous" }
  ],

  Focus_range: [
    { label: "0.5 m" },
    { label: "1 m" },
    { label: "2 m" },
    { label: "Infinity" }
  ],

  Metering_model: [
    { label: "Spot" },
    { label: "Matrix" },
    { label: "Center-weighted" },
    { label: "Evaluative" }
  ],
  Image_processor: [
    { label: "DIGIC" },
    { label: "EXPEED" },
    { label: "BIONZ" },
    { label: "TruePic" },
    { label: "X-Processor" }
  ],

  Ports: [
    { label: "USB" },
    { label: "HDMI" },
    { label: "Micro-USB" },
    { label: "Audio Jack" },
    { label: "Ethernet" }
  ],

  Accessory_shoe: [
    { label: "Hot Shoe" },
    { label: "Cold Shoe" }
  ],

  Lens_type: [
    { label: "Prime" },
    { label: "Zoom" },
    { label: "Macro" },
    { label: "Telephoto" },
    { label: "Wide-angle" }
  ],

  Manual_focus: [
    { label: "Yes" },
    { label: "No" }
  ],

  View_finder: [
    { label: "Optical" },
    { label: "Electronic" },
    { label: "None" }
  ],

  Auto_focus: [
    { label: "Yes" },
    { label: "No" }
  ],

  Focus_points: [
    { label: "9" },
    { label: "11" },
    { label: "19" },
    { label: "39" },
    { label: "51" }
  ],

  White_balance: [
    { label: "Auto" },
    { label: "Daylight" },
    { label: "Cloudy" },
    { label: "Fluorescent" },
    { label: "Tungsten" }
  ],

  Scene_modes: [
    { label: "Portrait" },
    { label: "Landscape" },
    { label: "Night" },
    { label: "Sports" },
    { label: "Macro" }
  ],

  Exposure_mode: [
    { label: "Auto" },
    { label: "Manual" },
    { label: "Aperture Priority" },
    { label: "Shutter Priority" }
  ],

  Ae_lock_or_exposure_lock: [
    { label: "Yes" },
    { label: "No" }
  ],

  Exposure_bracketing: [
    { label: "Yes" },
    { label: "No" }
  ],

  Manual_exposure: [
    { label: "Yes" },
    { label: "No" }
  ],

  Flash_modes: [
    { label: "Auto" },
    { label: "Fill Flash" },
    { label: "Red-Eye Reduction" },
    { label: "Slow Sync" },
    { label: "Manual" }
  ],

  Flash_compensation: [
    { label: "-2 EV" },
    { label: "-1 EV" },
    { label: "0 EV" },
    { label: "+1 EV" },
    { label: "+2 EV" }
  ],

  Image_size: [
    { label: "Large" },
    { label: "Medium" },
    { label: "Small" }
  ],

  Wide_angle: [
    { label: "Yes" },
    { label: "No" }
  ],

  Red_eye_reduction: [
    { label: "Yes" },
    { label: "No" }
  ],

  Live_view_shooting: [
    { label: "Yes" },
    { label: "No" }
  ],
  Moisture_control: [
    { label: "Yes" },
    { label: "No" }
  ],

  Design: [
    { label: "Modern" },
    { label: "Classic" },
    { label: "Minimalist" },
    { label: "Industrial" }
  ],

  Door_finish: [
    { label: "Matte" },
    { label: "Glossy" },
    { label: "Textured" },
    { label: "Wooden" }
  ],

  Door_lock: [
    { label: "Manual" },
    { label: "Automatic" }
  ],

  Pointer_device: [
    { label: "Touchpad" },
    { label: "Trackball" },
    { label: "Stylus" },
    { label: "Mouse" }
  ],

  Ram_frequency: [
    { label: "2400 MHz" },
    { label: "2666 MHz" },
    { label: "3200 MHz" },
    { label: "3600 MHz" }
  ],

  Display_resolution_time: [
    { label: "60 Hz" },
    { label: "75 Hz" },
    { label: "120 Hz" },
    { label: "144 Hz" },
    { label: "240 Hz" }
  ],

  Display_support_network: [
    { label: "Wi-Fi" },
    { label: "Ethernet" },
    { label: "Bluetooth" }
  ],

  Sound_enhancements: [
    { label: "Dolby Atmos" },
    { label: "DTS:X" },
    { label: "Bass Boost" },
    { label: "Treble Control" }
  ],

  Optical_zoom: [
    { label: "2x" },
    { label: "4x" },
    { label: "5x" },
    { label: "10x" },
    { label: "20x" }
  ],

  Graphics_ppl: [
    { label: "Intel UHD" },
    { label: "NVIDIA GeForce" },
    { label: "AMD Radeon" }
  ],

  Important_apps: [
    { label: "Facebook" },
    { label: "YouTube" },
    { label: "WhatsApp" },
    { label: "Instagram" },
    { label: "Zoom" }
  ],

  Audio_formats: [
    { label: "MP3" },
    { label: "WAV" },
    { label: "FLAC" },
    { label: "AAC" }
  ],

  Video_formats: [
    { label: "MP4" },
    { label: "MKV" },
    { label: "AVI" },
    { label: "MOV" },
    { label: "WMV" }
  ],

  Color_code: [
    { label: "Red" },
    { label: "Blue" },
    { label: "Green" },
    { label: "Yellow" },
    { label: "Black" }
  ],

  Material_water_proof: [
    { label: "Yes" },
    { label: "No" }
  ],

  Number_of_packets: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" }
  ],

  Certifications: [
    { label: "CE" },
    { label: "FCC" },
    { label: "RoHS" },
    { label: "ISO 9001" },
    { label: "Energy Star" }
  ],

  Charging_time: [
    { label: "30 minutes" },
    { label: "1 hour" },
    { label: "2 hours" },
    { label: "3 hours" }
  ],
  Cable_interface: [
    { label: "USB Type-C" },
    { label: "Micro-USB" },
    { label: "HDMI" },
    { label: "Thunderbolt" }
  ],

  Platform: [
    { label: "Windows" },
    { label: "MacOS" },
    { label: "Linux" },
    { label: "Android" },
    { label: "iOS" }
  ],

  Write_speed: [
    { label: "100 MB/s" },
    { label: "200 MB/s" },
    { label: "400 MB/s" },
    { label: "500 MB/s" }
  ],

  Data_transfer_rate: [
    { label: "5 Gbps" },
    { label: "10 Gbps" },
    { label: "20 Gbps" },
    { label: "40 Gbps" }
  ],

  Rotational_speed: [
    { label: "5400 RPM" },
    { label: "7200 RPM" },
    { label: "10000 RPM" }
  ],

  Password_protection: [
    { label: "Yes" },
    { label: "No" }
  ],

  Size: [
    { label: "2.5 inch" },
    { label: "3.5 inch" },
    { label: "1 TB" },
    { label: "500 GB" }
  ],

  Water_proof: [
    { label: "Yes" },
    { label: "No" }
  ],

  Compatible_with: [
    { label: "Windows" },
    { label: "Mac" },
    { label: "Linux" },
    { label: "Smartphone" }
  ],

  Rpm: [
    { label: "5400" },
    { label: "7200" },
    { label: "10000" }
  ],

  Hdd_capacity: [
    { label: "500 GB" },
    { label: "1 TB" },
    { label: "2 TB" },
    { label: "4 TB" }
  ],

  Output_interface: [
    { label: "USB 3.0" },
    { label: "Thunderbolt" },
    { label: "eSATA" }
  ],

  App_store_type: [
    { label: "Google Play" },
    { label: "Apple App Store" },
    { label: "Windows Store" }
  ],

  Pre_installed_browser: [
    { label: "Chrome" },
    { label: "Safari" },
    { label: "Firefox" },
    { label: "Edge" }
  ],

  Content_providers: [
    { label: "Netflix" },
    { label: "Amazon Prime" },
    { label: "YouTube" },
    { label: "Disney+" }
  ],

  Teletext: [
    { label: "Yes" },
    { label: "No" }
  ],

  Mobile_high_definition_link: [
    { label: "Yes" },
    { label: "No" }
  ],

  _3g_dongle_plug_and_play: [
    { label: "Yes" },
    { label: "No" }
  ],

  Color_screen: [
    { label: "Yes" },
    { label: "No" }
  ],

  Ir_capable: [
    { label: "Yes" },
    { label: "No" }
  ],

  Dvr_control: [
    { label: "Yes" },
    { label: "No" }
  ],

  Remote_type: [
    { label: "Standard" },
    { label: "Smart" },
    { label: "Universal" }
  ],

  Without_stand: [
    { label: "Yes" },
    { label: "No" }
  ],

  With_stand: [
    { label: "Yes" },
    { label: "No" }
  ],

  Stand_features: [
    { label: "Tilt" },
    { label: "Swivel" },
    { label: "Height Adjustable" }
  ],

  Outdoor_usage: [
    { label: "Yes" },
    { label: "No" }
  ],

  Maximum_spl: [
    { label: "100 dB" },
    { label: "110 dB" },
    { label: "120 dB" },
    { label: "130 dB" }
  ],

  Processor_generation: [
    { label: "10th Gen" },
    { label: "11th Gen" },
    { label: "12th Gen" },
    { label: "13th Gen" }
  ],

  Chassis_material: [
    { label: "Aluminum" },
    { label: "Plastic" },
    { label: "Carbon Fiber" },
    { label: "Magnesium Alloy" }
  ],

  Adjustable_aspect_ratio: [
    { label: "Yes" },
    { label: "No" }
  ],

  Vertical_scanning_frequency: [
    { label: "50 Hz" },
    { label: "60 Hz" },
    { label: "75 Hz" },
    { label: "120 Hz" }
  ],

  Horizontal_scanning_frequency: [
    { label: "30 kHz" },
    { label: "50 kHz" },
    { label: "60 kHz" },
    { label: "80 kHz" }
  ],

  Signal_type: [
    { label: "Analog" },
    { label: "Digital" }
  ],

  Maximum_diagonal_projection_size: [
    { label: "100 inches" },
    { label: "120 inches" },
    { label: "150 inches" },
    { label: "200 inches" }
  ],

  Color_support: [
    { label: "16.7 million" },
    { label: "1.07 billion" }
  ],

  Output_per_speaker: [
    { label: "5W" },
    { label: "10W" },
    { label: "15W" },
    { label: "20W" }
  ],

  Audio_type: [
    { label: "Stereo" },
    { label: "Mono" }
  ],

  Component_hd_video_in: [
    { label: "Yes" },
    { label: "No" }
  ],

  Maximum_attitude: [
    { label: "5000 feet" },
    { label: "10000 feet" }
  ],

  Standby_noise_level: [
    { label: "20 dB" },
    { label: "25 dB" }
  ],

  Normal_noise_level: [
    { label: "30 dB" },
    { label: "35 dB" },
    { label: "40 dB" }
  ],

  Minimum_aperture: [
    { label: "f/2.8" },
    { label: "f/4" }
  ],

  Maximum_aperture: [
    { label: "f/16" },
    { label: "f/22" }
  ],

  Functions_modes: [
    { label: "Auto" },
    { label: "Manual" },
    { label: "Sports" },
    { label: "Portrait" }
  ],

  Number_of_osd_languages: [
    { label: "5" },
    { label: "10" },
    { label: "15" }
  ],

  Security: [
    { label: "Password Protection" },
    { label: "Kensington Lock" }
  ],

  Power_adapter: [
    { label: "65W" },
    { label: "90W" },
    { label: "120W" }
  ],

  Upgradeable_memory: [
    { label: "Yes" },
    { label: "No" }
  ],

  Prime_zoom: [
    { label: "Yes" },
    { label: "No" }
  ],

  Maximum_focal_length: [
    { label: "55mm" },
    { label: "85mm" },
    { label: "105mm" }
  ],

  Minimum_focal_length: [
    { label: "18mm" },
    { label: "24mm" },
    { label: "35mm" }
  ],

  Aperture_with_max_focal_length: [
    { label: "f/4" },
    { label: "f/5.6" }
  ],

  Aperture_with_min_focal_length: [
    { label: "f/2.8" },
    { label: "f/3.5" }
  ],

  Shutter_type: [
    { label: "Mechanical" },
    { label: "Electronic" }
  ],

  Number_of_channels: [
    { label: "2" },
    { label: "4" },
    { label: "5" },
    { label: "7" }
  ],

  Camera_type: [
    { label: "DSLR" },
    { label: "Mirrorless" },
    { label: "Point & Shoot" }
  ],


  Video_recording_frame_rate: [
    { label: "24 fps" },
    { label: "30 fps" },
    { label: "60 fps" },
    { label: "120 fps" }
  ],

  Hdd_available: [
    { label: "Yes" },
    { label: "No" }
  ],

  Recording_format: [
    { label: "MP4" },
    { label: "MOV" },
    { label: "AVI" },
    { label: "MPEG" }
  ],

  Record_modes: [
    { label: "Manual" },
    { label: "Scheduled" },
    { label: "Motion Detection" }
  ],

  Image_sensor: [
    { label: "CMOS" },
    { label: "CCD" }
  ],

  Image_resolution: [
    { label: "720p" },
    { label: "1080p" },
    { label: "4K" }
  ],

  Minimum_illumination: [
    { label: "0.01 Lux" },
    { label: "0.1 Lux" }
  ],

  Ir_distance: [
    { label: "10 meters" },
    { label: "20 meters" },
    { label: "50 meters" }
  ],

  Motion_triggered_recording: [
    { label: "Yes" },
    { label: "No" }
  ],

  Network_interface_time: [
    { label: "1 Gbps" },
    { label: "10 Gbps" }
  ],

  Number_of_ethernet_ports: [
    { label: "1" },
    { label: "2" },
    { label: "4" }
  ],

  Alarm_action: [
    { label: "Email" },
    { label: "Siren" },
    { label: "Push Notification" }
  ],

  Application_support: [
    { label: "Yes" },
    { label: "No" }
  ],

  Remote_connectivity: [
    { label: "Wi-Fi" },
    { label: "Ethernet" },
    { label: "Bluetooth" }
  ],

  Closure_mechanism: [
    { label: "Zipper" },
    { label: "Velcro" },
    { label: "Snap" }
  ],

  Inner_height: [
    { label: "10 inches" },
    { label: "15 inches" },
    { label: "20 inches" }
  ],

  Inner_width: [
    { label: "10 inches" },
    { label: "15 inches" },
    { label: "20 inches" }
  ],

  Head_type: [
    { label: "Ball Head" },
    { label: "Pan Head" }
  ],

  Leg_lock_type: [
    { label: "Twist Lock" },
    { label: "Flip Lock" }
  ],

  Independent_leg_spread: [
    { label: "Yes" },
    { label: "No" }
  ],

  Folded_length: [
    { label: "50 cm" },
    { label: "60 cm" },
    { label: "75 cm" }
  ],

  Number_of_charging_cells: [
    { label: "2" },
    { label: "4" }
  ],

  No_memory_effect: [
    { label: "Yes" },
    { label: "No" }
  ],

  Maximum_shelf_life: [
    { label: "2 years" },
    { label: "3 years" },
    { label: "5 years" }
  ],

  Magnification: [
    { label: "8x" },
    { label: "10x" },
    { label: "12x" }
  ],

  Apparent_field_of_view: [
    { label: "50 degrees" },
    { label: "60 degrees" }
  ],

  Brand: [
    { label: "Sony" },
    { label: "Canon" },
    { label: "Nikon" },
    { label: "Panasonic" }
  ],

  Manufacturing_year: [
    { label: "2021" },
    { label: "2022" },
    { label: "2023" },
    { label: "2024" }
  ],

  Display_frame_rate: [
    { label: "60 Hz" },
    { label: "75 Hz" },
    { label: "120 Hz" },
    { label: "144 Hz" }
  ],

  Liquidizing_jar_capacity: [
    { label: "1.5 L" },
    { label: "2 L" },
    { label: "2.5 L" }
  ],

  Chutney_jar_capacity: [
    { label: "0.3 L" },
    { label: "0.5 L" },
    { label: "0.7 L" }
  ],

  Present_cooking_menu: [
    { label: "Rice" },
    { label: "Soup" },
    { label: "Cake" },
    { label: "Steam" }
  ],

  Elements_on_cooktop: [
    { label: "2 Burners" },
    { label: "3 Burners" },
    { label: "4 Burners" }
  ],

  Phase: [
    { label: "Single Phase" },
    { label: "Three Phase" }
  ],

  In_built_oil_collector: [
    { label: "Yes" },
    { label: "No" }
  ],

  Model_let: [
    { label: "2021" },
    { label: "2022" }
  ],

  Working_plate: [
    { label: "Ceramic" },
    { label: "Glass" }
  ],

  Wide_slots_for_thick_breads: [
    { label: "Yes" },
    { label: "No" }
  ],

  High_lift_for_small_breads: [
    { label: "Yes" },
    { label: "No" }
  ],

  Auto_pop_up: [
    { label: "Yes" },
    { label: "No" }
  ],

  Removable_crumb_tray: [
    { label: "Yes" },
    { label: "No" }
  ],

  Toasting: [
    { label: "Yes" },
    { label: "No" }
  ],

  Warming: [
    { label: "Yes" },
    { label: "No" }
  ],

  Reheat: [
    { label: "Yes" },
    { label: "No" }
  ],

  Variable_browning: [
    { label: "Yes" },
    { label: "No" }
  ],

  Card_storage: [
    { label: "Yes" },
    { label: "No" }
  ],

  Capacity_in_cups: [
    { label: "4 cups" },
    { label: "6 cups" },
    { label: "8 cups" }
  ],

  Material_lid: [
    { label: "Plastic" },
    { label: "Stainless Steel" },
    { label: "Glass" }
  ],

  Generator_compatibility: [
    { label: "Yes" },
    { label: "No" }
  ],

  Minimum_operating: [
    { label: "220V" },
    { label: "230V" }
  ],

  Overload_protection: [
    { label: "Yes" },
    { label: "No" }
  ],

  Rated_pressure: [
    { label: "1 bar" },
    { label: "2 bar" },
    { label: "3 bar" }
  ],

  Planting: [
    { label: "Yes" },
    { label: "No" }
  ],

  Operating_modes: [
    { label: "Auto" },
    { label: "Manual" }
  ],

  Self_diagnosis: [
    { label: "Yes" },
    { label: "No" }
  ],
  Noise_level: [
    { label: "30 dB" },
    { label: "40 dB" },
    { label: "50 dB" },
    { label: "60 dB" }
  ],


  Electricity_consumption: [
    { label: "Low" },
    { label: "Medium" },
    { label: "High" }
  ],

  Number_of_attached_jar: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" }
  ],

  Color: [
    { label: "Red" },
    { label: "Blue" },
    { label: "Green" },
    { label: "Yellow" },
    { label: "Orange" },
    { label: "Purple" },
    { label: "Pink" },
    { label: "White" },
    { label: "Black" },
    { label: "Gray" },
    { label: "Brown" },
    { label: "Beige" },
    { label: "Cyan" },
    { label: "Magenta" },
    { label: "Teal" },
    { label: "Lime Green" },
    { label: "Navy Blue" },
    { label: "Maroon" },
    { label: "Gold" },
    { label: "Silver" },
    { label: "Bronze" }
  ],


  Number_of_scrubs: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4 " },
    { label: "5" },
    { label: "6 " },
    { label: "7" },
    { label: "8 " },
    { label: "9 " },
    { label: "10 " },
  ],
  Capacity: [
    { label: "5 Liters " },
    { label: "7 Liters " },
    { label: "10 Liters " },
  ],
  PanType: [
    { label: "Fry" },
    { label: "Saucepan" },
    { label: "Sauté Pan" },
    { label: "Stock Pot" },
    { label: "Dutch Oven" },
    { label: "Roasting Pan" },
    { label: "Wok" },
    { label: "Griddle" },
    { label: "Crepe" },

  ],
  Diameter: [
    { label: "20cm" },
    { label: "21cm" },
    { label: "22cm" },
    { label: "23cm" },
    { label: "24cm" },
    { label: "25cm" },
    { label: "26cm" },
    { label: "27cm" },
    { label: "28cm" },
    { label: "29cm" },
    { label: "30cm" },
    { label: "31cm" },
    { label: "32cm" },
    { label: "33cm" },
    { label: "34cm" },
    { label: "35cm" },
    { label: "36cm" },
    { label: "37cm" },
    { label: "38cm" },
    { label: "39cm" },
    { label: "40cm" },

  ],
  Lid_locking_mechanism: [
    { label: "Clamping Mechanism" },
    { label: "knob lock system " },
    { label: "Snap-Lock Mechanism" },
    { label: "Pressure Valve Locking" },
    { label: "Rotating Lock Mechanism" },
    { label: "Spring-Loaded Mechanism" },
    { label: "Sliding Lock Mechanism" },

  ],
  Shade: [
    { label: "Stainless Steel" },
    { label: "Black" },
    { label: "White" },
    { label: "Gray" },
    { label: "Red" },
    { label: "Blue" },
    { label: "Green" },

  ],
  Handled_features: [
    { label: "Heat-Resistant Handles" },
    { label: "Ergonomic Design" },
    { label: "Foldable Handles" },
    { label: "Removable Handles" },
    { label: "Metal Handles" },
    { label: "Stay-Cool Handles" },
    { label: "Riveted Handles" },

  ],
  Base_thickness: [
    { label: "1 mm " },
    { label: "2 mm " },
    { label: "3 mm " },
    { label: "4 mm " },
    { label: "5 mm " },
    { label: "6 mm " },
    { label: "7 mm " },
    { label: "8 mm " },
    { label: "9 mm " },
    { label: "10 mm " },

  ],
  Body_material: [
    { label: "Stainless Steel " },
    { label: "Cast Iron" },
    { label: "Aluminum" },
    { label: "Enamel-Coated Steel" },
    { label: "Glass" },
    { label: "Ceramic" },
    { label: "Brass" },
    { label: "Plastic" },
  ],
  Trivet_material: [
    { label: "Cast Iron" },
    { label: "Enamel-Coated Cast Iron" },
    { label: "Stainless Steel" },
    { label: "Aluminum" },
    { label: "Brass" },
    { label: "Ceramic" },
    { label: "Glass" },
    { label: "Composite Materials" },
  ],
  Burner_material: [
    { label: "Aluminum" },
    { label: "Brass" },
    { label: "Cast Iron" },
    { label: "Stainless Steel" },
    { label: "Enamel-Coated Steel" },
    { label: "Copper" },
    { label: "Ceramic" },
  ],
  Number_of_burners: [
    { label: "1 " },
    { label: "2" },
    { label: "3" },
    { label: "4 " },
    { label: "5" },
    { label: "6 " },
    { label: "7" },
    { label: "8 " },
    { label: "9 " },
    { label: "10 " },
  ],
  Burner_type: [
    { label: "Sealed Burners" },
    { label: "Open Burners" },
    { label: "Simmer  Burners" },
    { label: "High-Efficiency  Burners" },
    { label: "Dual Burners" },
    { label: "Rapid Burners" },
    { label: "Auxiliary Burners" },
    { label: "Multi-Ring Burners" },
    { label: "Infrared Burners" },
    { label: "Wok Burners" },

  ],
  Air_delivery: [
    { label: "100 CFM" },
    { label: "200 CFM" },
    { label: "300 CFM" },
    { label: "400 CFM" },
    { label: "500 CFM" },
    { label: "600 CFM" }
  ],

  Air_deflection: [
    { label: "Fixed" },
    { label: "Adjustable" },
    { label: "Oscillating" },
    { label: "Multi-directional" },
    { label: "Manual" },
    { label: "Automatic" }
  ],

  Blower_material: [
    { label: "Plastic" },
    { label: "Metal" },
    { label: "Aluminum" },
    { label: "Stainless steel" },
    { label: "Composite" },
    { label: "Ceramic" }
  ],

  Efficiency: [
    { label: "High" },
    { label: "Medium" },
    { label: "Low" },
    { label: "Energy-efficient" },
    { label: "Standard" },
    { label: "Premium" }
  ],

  Output_voltage: [
    { label: "110V" },
    { label: "120V" },
    { label: "220V" },
    { label: "230V" },
    { label: "240V" },
    { label: "250V" }
  ],

  Output_frequency: [
    { label: "50 Hz" },
    { label: "60 Hz" },
    { label: "100 Hz" },
    { label: "120 Hz" },
    { label: "200 Hz" },
    { label: "240 Hz" }
  ],
  Motor_suction_power: [
    { label: "100W" },
    { label: "200W" },
    { label: "300W" },
    { label: "400W" },
    { label: "500W" },
    { label: "600W" }
  ],

  Number_of_stitches: [
    { label: "10" },
    { label: "20" },
    { label: "30" },
    { label: "40" },
    { label: "50" },
    { label: "60" }
  ],

  Number_of_button_hole_styles: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" }
  ],

  Sewing_speed: [
    { label: "500 RPM" },
    { label: "1000 RPM" },
    { label: "1500 RPM" },
    { label: "2000 RPM" },
    { label: "2500 RPM" },
    { label: "3000 RPM" }
  ],


  Videorecordingresolution: [
    { label: "720p (HD): 1280 x 720 pixels" },
    { label: "1080p (Full HD): 1920 x 1080 pixels" },
    { label: "2K: 2560 x 1440 pixels" },
    { label: "4K (Ultra HD): 3840 x 2160 pixels" },
  ],
  Air_suction_capacity: [
    { label: "200 m³/h" },
    { label: "300 m³/h" },
    { label: "400 m³/h" },
    { label: "500 m³/h" },
    { label: "600 m³/h" },
    { label: "700 m³/h" }
  ],
  Speed_levels: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" }
  ],

  Shape: [
    { label: "Rectangular" },
    { label: "Square" },
    { label: "Round" },
    { label: "Corner" },
    { label: "Built-in" },
    { label: "Freestanding" },
    { label: "Island" },
    { label: "Compact" },
  ],
  Ignition_system: [
    { label: "Manual Ignition" },
    { label: "Piezoelectric  Ignition" },
    { label: "Battery-Powered  Ignition" },
    { label: "Electric  Ignition" },
    { label: "Automatic (Continuous Spark)  Ignition" },
    { label: "Flame Supervision Device (FSD)  Ignition" },
  ],
  Gas_inlet_nozzle_type: [
    { label: "Threaded Nozzle" },
    { label: "Quick-Connect  Nozzle" },
    { label: "Push-Fit  Nozzle" },
    { label: "Bayonet  Nozzle" },
    { label: "Barbed  Nozzle" },
    { label: "Flanged  Nozzle" },
  ],
  Control: [
    { label: "Touch" },
    { label: "Button" },
    { label: "Knob" },
    { label: "Dial" },
    { label: "Remote" },
    { label: "Voice" }
  ],
  Blade_material: [
    { label: "Stainless steel" },
    { label: "Titanium" },
    { label: "Carbon steel" },
    { label: "Ceramic" },
    { label: "Plastic" },
    { label: "Aluminum" }
  ],

  Control_features: [
    { label: "Control Knobs" },
    { label: "Electronic Controls" },
    { label: "Child Lock" },
    { label: "Auto Ignition" },
    { label: "Flame Failure Device (FFD)" },
    { label: "Simmer Control" },
    { label: "Timer" },
    { label: "Power Boost" },
    { label: "LED Indicators" },
    { label: "Smart Connectivity" },
  ],
  Support_mechanism: [
    { label: "Structural Supports" },
    { label: "Piping Supports" },
    { label: "Pressure Relief Systems" },
    { label: "Safety System" },
    { label: "Maintenance Protocols" },
  ],
  Opacity: [
    { label: "Opaque" },
    { label: "Translucent" },
    { label: "Transparent" },
    { label: "Semi-Transparent" },
  ],
  Handle_material: [
    { label: "Ceramic" },
    { label: "Metal" },
    { label: "Plastic" },
    { label: "Wood" },
    { label: "Glass" },
  ],
  Head_material: [
    { label: "Stainless Steel" },
    { label: "Copper" },
    { label: "Brass" },
    { label: "Aluminum" },
    { label: "Plastic" },
  ],
  Number_of_contents_in_sales_package: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
  ],

  Series: [
    { label: "Teso PRO" },
    { label: "Milton, Cello" },
    { label: "Borosil" },
    { label: "Signoraware" },
    { label: "Tupperware" },
  ],
  Charcter: [
    { label: "Durable" },
    { label: "Leak-Proof" },
    { label: "Insulated" },
    { label: "Lightweight" },
    { label: "Easy-to-Clean" },
    { label: "Compact" },
    { label: "Stylish" },
    { label: "Divided Compartments" },
  ],
  Leak_resistance: [
    { label: "Leak-Proof" },
    { label: "Not Leak-Proof" },
  ],
  Model_series_name: [
    { label: "Classic" },
    { label: "Eco" },
    { label: "Premium" },
    { label: "Travel" },
    { label: "Kids" },
    { label: "Compact" },
    { label: "Deluxe" },
    { label: "Modern" },
  ],
  Bed_size: [
    { label: "Twin" },
    { label: "Full" },
    { label: "Queen" },
    { label: "King" },
    { label: "California King" },
  ],
  Delivery_condition: [
    { label: "Assembled" },
    { label: "Flat-Pack" },
    { label: "Partially Assembled" },
    { label: "Unassembled" },
  ],
  Primary_color: [
    { label: "White" },
    { label: "Black" },
    { label: "Gray" },
    { label: "Brown" },
    { label: "Beige" },
  ],
  Secondary_color: [
    { label: "White" },
    { label: "Black" },
    { label: "Gray" },
    { label: "Brown" },
    { label: "Beige" },
  ],
  Recommended_mattress_length: [
    { label: "190 cm (75 inches)" },
    { label: "200 cm (79 inches)" },
    { label: "210 cm (83 inches)" },
    { label: "220 cm (87 inches)" },
  ],
  Recommended_mattress_breadth: [
    { label: "90 cm (36 inches)" },
    { label: "135 cm (54 inches)" },
    { label: "150 cm (60 inches)" },
    { label: "180 cm (72 inches)" },
  ],
  Bed_material: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Upholstered" },
    { label: "Plastic" },
    { label: "MDF (Medium-Density Fiberboard)" },
  ],
  Bed_material_sub_type: [
    { label: "Solid Wood" },
    { label: "Engineered Wood" },
    { label: "Stainless Steel" },
    { label: "Iron" },
    { label: "Faux Leather" },
    { label: "Velvet" },
    { label: "Rattan" },
  ],
  Headboard_material: [
    { label: "Wood" },
    { label: "Upholstered Fabric" },
    { label: "Leather" },
    { label: "Metal" },
    { label: "MDF (Medium-Density Fiberboard)" },
    { label: "Wicker" },
    { label: "Acrylic" },
  ],
  Headboar_material_subtype: [
    { label: "Solid Wood" },
    { label: "Plywood" },
    { label: "Faux Leather" },
    { label: "Velvet" },
    { label: "Metal Frame" },
    { label: "Rattan" },
    { label: "Fabric Upholstery" },
  ],
  Finish_type: [
    { label: "Matte" },
    { label: "Glossy" },
    { label: "Satin" },
    { label: "Textured" },
    { label: "Painted" },
    { label: "Stained" },
  ],
  Finish_color: [
    { label: "White" },
    { label: "Black" },
    { label: "Gray" },
    { label: "Brown" },
    { label: "Beige" },
    { label: "Blue" },
    { label: "Green" },
    { label: "red" },
  ],
  Storage_type: [
    { label: "Under-Bed Drawers" },
    { label: "Lift-Up Storage" },
    { label: "Side Drawers" },
    { label: "Headboard Storage" },
    { label: "Footboard Storage" },
    { label: "Open Shelves" },
    { label: "Built-In Cabinets" },
  ],
  Headboard_type: [
    { label: "Panel" },
    { label: "Tufted" },
    { label: "Wingback" },
    { label: "Sleigh" },
    { label: "upholstered" },
    { label: "Slatted" },
    { label: "Bookcase" },
    { label: "Arched" },
  ],
  Number_of_shelves: [
    { label: "0" },
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  Enclosure_type: [
    { label: "Open" },
    { label: "Closed" },
    { label: "Semi-Enclosed" },
  ],
  Mount_type: [
    { label: "Freestanding" },
    { label: "Wall-Mounted" },
    { label: "Floor-Mounted" },
  ],
  Number_of_rods: [
    { label: "0" },
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  Cabinet_type: [
    { label: "Built-In" },
    { label: "Freestanding" },
    { label: "Modular" },
    { label: "Sliding" },
    { label: "Hinged" },
  ],
  Support_layer: [
    { label: "Slats" },
    { label: "Box Spring" },
    { label: "Platform" },
    { label: "Metal Frame" },
    { label: "Plywood" },
  ],
  Comfort_layer: [
    { label: "Memory Foam" },
    { label: "Latex" },
    { label: "Innerspring" },
    { label: "Gel Foam" },
    { label: "Pillow Top" },
  ],
  Mattress_top: [
    { label: "Pillow Top" },
    { label: "Euro Top" },
    { label: "Plush" },
    { label: "Firm" },
    { label: "Quilted" },
  ],
  Towel_rack: [
    { label: "Wall-Mounted" },
    { label: "Freestanding" },
    { label: "Over-the-Door" },
    { label: "Tension Rod" },
    { label: "Built-In" },
  ],
  Number_of_tools: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
  ],
  Magazine_capacity: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
    { label: "16" },
    { label: "17" },
    { label: "18" },
    { label: "19" },
    { label: "20" },
  ],
  Fastener_gauge: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
    { label: "11" },
    { label: "12" },
    { label: "13" },
    { label: "14" },
    { label: "15" },
    { label: "16" },
    { label: "17" },
    { label: "18" },
    { label: "19" },
    { label: "20" },
  ],
  Fastener_length: [
    { label: "5cm" },
    { label: "6cm" },
    { label: "7cm" },
    { label: "8cm" },
    { label: "9cm" },
    { label: "10cm" },
    { label: "11cm" },
    { label: "12cm" },
    { label: "13cm" },
    { label: "14cm" },
    { label: "15cm" },
    { label: "16cm" },
    { label: "17cm" },
    { label: "18cm" },
    { label: "19cm" },
    { label: "20cm" },
    { label: "21cm" },
    { label: "22cm" },
    { label: "23cm" },
    { label: "24cm" },
    { label: "25cm" },
  ],
  Fastener_type: [
    { label: "Screws" },
    { label: "Bolts" },
    { label: "Nuts" },
    { label: "Rivets" },
    { label: "Nails" },
  ],
  Dial_color: [
    { label: "Black" },
    { label: "White" },
    { label: "Red" },
    { label: "Blue" },
    { label: "Green" },
  ],
  Number_of_battires: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
  ],
  Battery: [
    { label: "AA" },
    { label: "AAA" },
    { label: "Button cell" },
    { label: "Rechargeable Battery" },
  ],
  Pillow_cover_length: [
    { label: "40cm" },
    { label: "41cm" },
    { label: "42cm" },
    { label: "43cm" },
    { label: "44cm" },
    { label: "45cm" },
    { label: "46cm" },
    { label: "47cm" },
    { label: "48cm" },
    { label: "49cm" },
    { label: "50cm" },
    { label: "51cm" },
    { label: "52cm" },
    { label: "53cm" },
    { label: "54cm" },
    { label: "5cm" },
    { label: "550cm" },
    { label: "56cm" },
    { label: "57cm" },
    { label: "58cm" },
    { label: "59cm" },
    { label: "60cm" },
    { label: "61cm" },
    { label: "62cm" },
    { label: "63cm" },
    { label: "64cm" },
    { label: "65cm" },
    { label: "66cm" },
    { label: "67cm" },
    { label: "68cm" },
    { label: "69cm" },
    { label: "70cm" },
  ],
  Pillow_cover_width: [
    { label: "30cm" },
    { label: "31cm" },
    { label: "32cm" },
    { label: "33cm" },
    { label: "34cm" },
    { label: "35cm" },
    { label: "36cm" },
    { label: "37cm" },
    { label: "38cm" },
    { label: "39cm" },
    { label: "40cm" },
    { label: "41cm" },
    { label: "42cm" },
    { label: "43cm" },
    { label: "44cm" },
    { label: "45cm" },
    { label: "46cm" },
    { label: "47cm" },
    { label: "48cm" },
    { label: "49cm" },
    { label: "50cm" },
  ],
  Net_width: [
    { label: "5 inches" },
    { label: "6 inches" },
    { label: "7 inches" },
    { label: "8 inches" },
    { label: "9 inches" },
    { label: "10 inches" },
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
    { label: "19 inches" },
    { label: "20 inches" },
  ],
  Net_length: [
    { label: "10 inches" },
    { label: "11 inches" },
    { label: "12 inches" },
    { label: "13 inches" },
    { label: "14 inches" },
    { label: "15 inches" },
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
    { label: "19 inches" },
    { label: "20 inches" },
    { label: "21 inches" },
    { label: "22 inches" },
    { label: "23 inches" },
    { label: "24 inches" },
    { label: "25 inches" },
    { label: "26 inches" },
    { label: "27 inches" },
    { label: "28 inches" },
    { label: "29 inches" },
    { label: "30 inches" },
  ],
  Reversible: [
    { label: "Reversible Cat Beds" },
    { label: "Reversible Cat Blankets" },
    { label: "Reversible Cat Mats" },
    { label: "Reversible Cat Collars" },
    { label: "Reversible Cat Toys" },
  ],
  Blub_type: [
    { label: "Incandescent" },
    { label: "LED" },
    { label: "CFL" },
    { label: "Halogen" },
  ],
  Light_color: [
    { label: "Warm White" },
    { label: "Cool White" },
    { label: "Daylight" },
    { label: "Soft White" },
  ],
  Number_of_blubs: [
    { label: "1" },
    { label: "2" },
    { label: "3" },
    { label: "4" },
    { label: "5" },
    { label: "6" },
    { label: "7" },
    { label: "8" },
    { label: "9" },
    { label: "10" },
  ],
  Light_used: [
    { label: "Incandescent" },
    { label: "LED" },
    { label: "CFL" },
    { label: "Halogen" },
  ],
  Light_modes: [
    { label: "Constant Light" },
    { label: "Flashing" },
    { label: "SOS" },
    { label: "Strobe" },
  ],
  Indicators: [
    { label: "Power On/Off Indicator" },
    { label: "Battery Charge Indicator" },
    { label: "Battery Charge Indicator" },
    { label: "Battery Low Indicator" },
    { label: "Charging Status Indicator" },
  ],
  Charging_time: [
    { label: "4 hours" },
    { label: "5 hours" },
    { label: "6 hours" },
    { label: "7 hours" },
    { label: "8 hours" },
    { label: "9 hours" },
    { label: "10 hours" },
    { label: "11 hours" },
    { label: "12 hours" },
    { label: "13 hours" },
    { label: "14 hours" },
    { label: "15 hours" },
    { label: "16 hours" },
    { label: "17 hours" },
    { label: "18 hours" },
    { label: "20 hours" },
    { label: "21 hours" },
    { label: "22 hours" },
    { label: "23 hours" },
    { label: "24 hours" },
    { label: "25 hours" },
    { label: "26 hours" },
    { label: "27 hours" },
    { label: "28 hours" },
  ],
  Maximum_number_of_recharges: [
    { label: " 300 to 500 recharges " },
    { label: "500 to 800 recharges" },
    { label: "1,000 to 1,500 recharges" },
    { label: "1,500 to 2,000 recharges" },

  ],
  Battery_capacity: [
    { label: "1,000 to 2,000 mAh" },
    { label: "2,000 to 3,500 mAh" },
    { label: "3,500 to 5,000 mAh" },
    { label: "5,000 to 8,000 mAh" },
    { label: "8,000 to 10,000 mAh" },
  ],
  Pet_type: [
    { label: "Labrador Retriever" },
    { label: "German Shepherd" },
    { label: "Beagle" },
    { label: "Bulldog" },
    { label: "Poodle" },
  ],
  Activity_type: [
    { label: "Walking" },
    { label: "Running" },
    { label: "Playing Fetch" },
    { label: "Agility Training" },
    { label: "Swimming" },
  ],
  Chewable: [
    { label: "Chew Toys" },
    { label: "Dental Chews" },
    { label: "Chew Bones" },
    { label: "Rawhide Chews" },
    { label: "Rubber Toys" },
  ],
  Led_toy: [
    { label: "LED Fetch Balls" },
    { label: "LED Frisbees" },
    { label: "LED Tug Toys" },
    { label: "LED Squeaky Toys" },
    { label: "LED Light-Up " },
    { label: "Chew Toys" },
  ],
  Collor_type: [
    { label: "Breakaway Collars" },
    { label: "Adjustable  Collars" },
    { label: "Reflective  Collars" },
    { label: "Bell  Collars" },
    { label: "GPS  Collars" },
  ],
  Harness_type: [
    { label: "Step-In Harness" },
    { label: "H-Back Harness" },
    { label: "No-Pull Harness" },
    { label: "Front-Clip Harness" },
    { label: "Vest Harness" },
  ],
  Retractable: [
    { label: "Retractable Cat Window Perches" },
    { label: "Retractable Leashes" },
    { label: "Retractable Cat Toys" },
    { label: "Retractable Cat Scratching Posts" },
    { label: "Retractable Cat Carriers" },
  ],
  Reversible: [
    { label: "Reversible  Beds" },
    { label: "Reversible Blankets" },
    { label: "Reversible  Mats" },
    { label: "Reversible  Collars" },
    { label: "Reversible  Toys" },
  ],
  Mattress_features: [
    { label: "Memory Foam" },
    { label: "Gel-Infused" },
    { label: "Hypoallergenic" },
    { label: "Orthopedic" },
    { label: "Waterproof" },
    { label: "Adjustable" },
    { label: "Breathable" },
    { label: "Reversible" },
  ],

  Comfort_levels: [
    { label: "Soft" },
    { label: "Medium" },
    { label: "Firm" },
    { label: "Extra Firm" },
  ],
  Upholstery_material: [
    { label: "Knitted" },
    { label: "Jacquard" },
    { label: "Quilted" },
    { label: "Polyester" },
    { label: "Cotton" },
  ],
  Removable_cover: [
    { label: "Cotton" },
    { label: "Polyester" },
    { label: "Jacquard Fabric" },
    { label: "Knitted Fabric" },
    { label: "Terry Cloth" },
  ],
  Thickness: [
    { label: "6 inches" },
    { label: "14 inches" },
    { label: "12 inches" },
    { label: "8 inches" },
  ],
  Secondary_material: [
    { label: "Foam" },
    { label: "Plywood" },
    { label: "Metal" },
    { label: "Fiberfill" },
    { label: "Polyester" },
  ],
  Seconday_material_subtype: [
    { label: "High-Density Foam" },
    { label: "Plywood Panels" },
    { label: "Reinforced Plastic" },
    { label: "Metal Springs" },
  ],
  Configuration: [
    { label: "Straight Sofa" },
    { label: "L-Shaped Sofa" },
    { label: "U-Shaped Sofa" },
    { label: "Sectional Sofa" },
    { label: "Recliner Sofa" },
  ],
  Upholstery_material_subtype: [
    { label: "Leather" },
    { label: "Fabric" },
    { label: "Velvet" },
    { label: "Microfiber" },
    { label: "Suede" },
  ],
  Upholstery_color: [
    { label: "Beige" },
    { label: "Grey" },
    { label: "Black" },
    { label: "Navy Blue" },
    { label: "Charcoal" },
  ],
  Filling_material: [
    { label: "Foam" },
    { label: "Fiberfill" },
    { label: "Down Feathers" },
    { label: "Memory Foam" },
    { label: "Polyester" },
  ],
  Seating_capacity: [
    { label: "1-Seater" },
    { label: "2-Seater" },
    { label: "3-Seater" },
    { label: "4-Seater" },
    { label: "5-Seater" },
    { label: "6-Seater" },
    { label: "7-Seater" },
    { label: "8-Seater" },
    { label: "9-Seater" },
    { label: "10-Seater" },
  ],
  Mechanism_type: [
    { label: "Pull-Out" },
    { label: "Fold-Out" },
    { label: "Click-Clack" },
    { label: "Recliner" },
    { label: "Trundle" },
  ],
  Upholstery_type: [
    { label: "Fabric" },
    { label: "Leather" },
    { label: "Microfiber" },
    { label: "Velvet" },
    { label: "Suede" },
  ],
  Mattress_type: [
    { label: "Foam Mattress" },
    { label: "Memory Foam Mattress" },
    { label: "Innerspring Mattress" },
    { label: "Gel Mattress" },
    { label: "Hybrid Mattress" },
  ],
  Floor_clearance: [
    { label: "1 inches" },
    { label: "2 inches" },
    { label: "3 inches" },
    { label: "4 inches" },
    { label: "5 inches" },
    { label: "6 inches" },
    { label: "7 inches" },
    { label: "8 inches" },
    { label: "9 inches" },
    { label: "10 inches" },
    { label: "11 inches" },
    { label: "12 inches" },

  ],
  Removable_leg: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Plastic" },
    { label: "Chrome" },
    { label: "Brushed Nickel" },
  ],
  Frame_material: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Plywood" },
    { label: "Engineered Wood" },
    { label: "MDF (Medium-Density Fiberboard" },
  ],
  Frame_material_subtype: [

    { label: "Hardwood" },
    { label: "Softwood" },
    { label: "Steel" },
    { label: "Aluminum" },
    { label: "Plywood" },
  ],
  Origin_of_manufacture: [
    { label: "China" },
    { label: "India" },
    { label: "Italy" },
    { label: "USA" },
    { label: "Malaysia" },
  ],
  With_door: [
    { label: " Cabinet Doors" },
    { label: " Sliding Doors" },
    { label: "Hinged Doors" },
    { label: "Folding Doors" },
    { label: "Glass Doors" },
  ],
  Number_of_drawers: [
    { label: "1 drawers" },
    { label: "2 drawers" },
    { label: "3 drawers" },
    { label: "4 drawers" },
    { label: "5 drawers" },
    { label: "6 drawers" },
    { label: "7 drawers" },
    { label: "8 drawers" },
    { label: "9 drawers" },
    { label: "10 drawers" },

  ],
  Style: [
    { label: "Modern" },
    { label: "Traditional" },
    { label: "Contemporary" },
    { label: "Industrial" },
    { label: "Rustic" },
  ],
  Maximum_tv_size: [
    { label: "32 inches" },
    { label: "65 inches" },
    { label: "55 inches" },
    { label: "50 inches" },
    { label: "40 inches" },
  ],
  Unit_material: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Glass" },
    { label: "MDF (Medium-Density Fiberboard)" },
    { label: "Particle Board" },
  ],
  Unit_material_sub_type: [
    { label: "Solid Wood" },
    { label: "Engineered Wood" },
    { label: "Metal" },
    { label: "Plywood" },
    { label: "Aluminum" },
  ],
  Number_of_chairs: [
    { label: "1 chair" },
    { label: "2 chairs" },
    { label: "3 chairs" },
    { label: "4 chairs" },
    { label: "5 chairs" },
    { label: "6 chairs" },
    { label: "7 chairs" },
    { label: "8 chairs" },
    { label: "9 chairs" },
    { label: "10 chairs" },


  ],
  Table_shape: [
    { label: "Rectangular" },
    { label: "Round" },
    { label: "Square" },
    { label: "Oval" },
    { label: "Hexagonal" },
  ],
  Extendabale: [
    { label: "Drop-Leaf" },
    { label: "Butterfly Leaf" },
    { label: "Slide-Out Leaf" },
    { label: "Pull-Out Extension" },
    { label: "Foldable" },
  ],
  Foldable: [
    { label: "Drop-Leaf Table" },
    { label: "Expandable Foldable Table" },
    { label: "Wall-Mounted Foldable Table" },
    { label: "Folding Table with Hinges" },
    { label: "Accordion Style Table" }
  ],

  Table_frame_material: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Aluminum" },
    { label: "Glass" },
    { label: "Medium-Density Fiberboard" },
  ],
  Table_frame_materical_type: [
    { label: "Solid Wood" },
    { label: "Engineered Wood" },
    { label: "Steel" },
    { label: "Aluminum" },
    { label: "Iron" },
  ],
  Chair_material: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Plastic" },
    { label: "Upholstered Fabric" },
    { label: "Leather" },
  ],
  Chair_width: [
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
    { label: "19 inches" },
    { label: "20 inches" },
    { label: "21 inches" },
    { label: "22 inches" },
    { label: "23 inches" },
    { label: "24 inches" },
    { label: "25 inches" },
    { label: "26 inches" },
    { label: "27 inches" },
    { label: "28 inches" },
    { label: "29 inches" },
    { label: "30 inches" },

  ],
  Chair_depth: [
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
    { label: "19 inches" },
    { label: "20 inches" },
    { label: "21 inches" },
    { label: "22 inches" },
    { label: "23 inches" },
    { label: "24 inches" },
    { label: "25 inches" },
    { label: "26 inches" },
    { label: "27 inches" },
    { label: "28 inches" },
    { label: "29 inches" },
    { label: "30 inches" },

  ],
  Chair_height: [
    { label: "16 inches" },
    { label: "17 inches" },
    { label: "18 inches" },
    { label: "19 inches" },
    { label: "20 inches" },
    { label: "21 inches" },
    { label: "22 inches" },
    { label: "23 inches" },
    { label: "24 inches" },
    { label: "25 inches" },
    { label: "26 inches" },
    { label: "27 inches" },
    { label: "28 inches" },
    { label: "29 inches" },
    { label: "30 inches" },

  ],
  Chair_weight: [
    { label: "5 kg" },
    { label: "6 kg" },
    { label: "7 kg" },
    { label: "8 kg" },
    { label: "9 kg" },
    { label: "10 kg" },
    { label: "11 kg" },
    { label: "12 kg" },
    { label: "13 kg" },
    { label: "14 kg" },
    { label: "15 kg" },

  ],
  Table_width: [
    { label: "60cm" },
    { label: "70cm" },
    { label: "80cm" },
    { label: "90cm" },
    { label: "100cm" },
    { label: "110cm" },
    { label: "120cm" },
  ],
  Table_height: [
    { label: "28 inches (71 cm)" },
    { label: "29 inches (74 cm)" },
    { label: "30 inches (76 cm)" },
    { label: "31 inches (79 cm)" },
    { label: "32 inches (81 cm)" },
  ],
  Table_depth: [
    { label: "60 cm" },
    { label: "75 cm" },
    { label: "120 cm" },
    { label: "100 cm" },
    { label: "90 cm" },
  ],
  Table_weight: [
    { label: "10kg" },
    { label: "11kg" },
    { label: "12kg" },
    { label: "13kg" },
    { label: "14kg" },
    { label: "15kg" },
    { label: "16kg" },
    { label: "17kg" },
    { label: "18kg" },
    { label: "19kg" },
    { label: "20kg" },
    { label: "21kg" },
    { label: "22kg" },
    { label: "23kg" },
    { label: "24kg" },
    { label: "25kg" },
    { label: "26kg" },
    { label: "27kg" },
    { label: "28kg" },
    { label: "29kg" },
    { label: "30kg" },

  ],
  Table_Top_Material_sub_type: [
    { label: "Wood" },
    { label: "Glass" },
    { label: "Marble" },
    { label: "Laminate" },
    { label: "Granite" },
  ],
  Table_Top_Material: [
    { label: "Wood" },
    { label: "Glass" },
    { label: "Marble" },
    { label: "Granite" },
    { label: "Laminate" },
  ],
  Wall_mount_support: [
    { label: "Wall-Mounted Brackets" },
    { label: "Floating Shelves" },
    { label: " Wall-Mounted Hooks" },
    { label: "Wall-Mounted Rails" },
    { label: "Adjustable Wall-Mount Systems" },
  ],
  Number_of_shoe_pairs: [
    { label: "6 pairs" },
    { label: "7 pairs" },
    { label: "8 pairs" },
    { label: " 9 pairs" },
    { label: "10 pairs" },
    { label: "11 pairs" },
    { label: "12 pairs" },
    { label: "13 pairs" },
    { label: "14 pairs" },
    { label: "15 pairs" },
    { label: "16 pairs" },
    { label: "17 pairs" },
    { label: "18 pairs" },
    { label: "19 pairs" },
    { label: "20 pairs" },
    { label: "21 pairs" },
    { label: "22 pairs" },
    { label: "23 pairs" },
    { label: "24 pairs" },
    { label: "25 pairs" },
    { label: "26 pairs" },
    { label: "27 pairs" },
    { label: "28 pairs" },
    { label: "29 pairs" },
    { label: "30 pairs" },
    { label: "30 pairs" },
    { label: "31 pairs" },
    { label: "32 pairs" },
    { label: "33 pairs" },
    { label: "34 pairs" },
    { label: "35 pairs" },
    { label: "36 pairs" },
    { label: "37 pairs" },
    { label: "38 pairs" },
    { label: "39 pairs" },
    { label: "40 pairs" },

  ],
  Material_subtype: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Plastic" },
    { label: "Fabric" },
    { label: "Wicker" },
  ],
  Primaray_material: [
    { label: "Wood" },
    { label: "Metal" },
    { label: "Plastic" },
    { label: "Fabric" },
    { label: "Medium-Density Fiberboard" },
  ],
  Primary_material_subtype: [
    { label: "Steel" },
    { label: "Solid Wood" },
    { label: "Engineered Wood" },
    { label: "Plastic" },
    { label: "Acrylic" },
  ],
  Rollout_bed: [
    { label: "Trundle Bed" },
    { label: "Pop-Up Bed" },
    { label: "Under-Bed Rollout Mattress" },
    { label: "Slide-Out Bed" },
    { label: "Pull-Out Bed" },

  ],
  Built_in_ladder: [
    { label: "Bunk Beds" },
    { label: "Loft Beds" },
    { label: "Triple Bunk Beds" },
    { label: "Playhouse Beds" },
    { label: "Treehouse Beds" },
  ],
  Cover_type: [
    { label: "Slipcovers" },
    { label: "Fabric Upholstery" },
    { label: "Vinyl Covers" },
    { label: "Leather Covers" },
    { label: "Removable Covers" },
  ],
  Filling_type: [
    { label: "Foam" },
    { label: "Polyester Fiberfill" },
    { label: "Memory Foam" },
    { label: "Cotton Batting" },
    { label: "Down Feathers" },
  ],
  Number_of_bedsheets: [
    { label: "Single Bedsheet" },
    { label: "Set of 2 Bedsheets" },
    { label: "Set of 3 Bedsheets" },
    { label: "Set of 4 Bedsheets" },
    { label: "Set of 5 Bedsheets" }
  ],
  Thread_count: [
    { label: "200" },
    { label: "300" },
    { label: "400" },
    { label: "500" },
    { label: "600" },
    { label: "700" },
    { label: "800" },
    { label: "900" },
    { label: "1000" },
    { label: "1100" },
    { label: "1200" },
    { label: "1300" },
    { label: "1400" },
    { label: "1500" }
  ],
  Fabric_care: [
    { label: "Machine Washable" },
    { label: "Tumble Dry Low" },
    { label: "Iron if Needed" },
    { label: "Do Not Bleach" },
    { label: "Gentle Cycle" }
  ],
  Flat_sheet_width: [
    { label: "180cm" },
    { label: "230cm" },
    { label: "270cm" },
    { label: "300cm" },
    { label: "320cm" }
  ],
  Lining: [
    { label: "Interlining" },
    { label: "Standard Lining" },
    { label: "Blackout Lining" },
    { label: "Thermal Lining" },
    { label: "Sheer Lining" }
  ],
  Transparency: [
    { label: "Sheer" },
    { label: "Semi-Sheer" },
    { label: "Opaque" },
    { label: "Blackout" },
    { label: "Light Filtering" }
  ],

  Filling_amount: [
    { label: "1 kg" },
    { label: "2 kg" },
    { label: "3 kg" },
    { label: "4 kg" },
    { label: "5 kg" },
    { label: "6 kg" },
    { label: "7 kg" },
    { label: "8 kg" },
    { label: "9 kg" },
    { label: "10 kg" }
  ],
  Collection: [
    { label: "Luxury Collection" },
    { label: "Cotton Collection" },
    { label: "Flannel Collection" },
    { label: "Sateen Collection" },
    { label: "Bamboo Collection" }
  ],
  Fabric_care: [
    { label: "Machine Washable" },
    { label: "Tumble Dry Low" },
    { label: "Iron if Needed" },
    { label: "Do Not Bleach" },
    { label: "Gentle Cycle" }
  ],

  Flat_sheet_length: [
    { label: "180cm" },
    { label: "230cm" },
    { label: "250cm" },
    { label: "270cm" },
    { label: "300cm" },
    { label: "320cm" }
  ],



  External_material: [
    { label: "Cotton" },
    { label: "Linen" },
    { label: "Velvet" },
    { label: "Microfiber" },
    { label: "Leather" }
  ],


  Ideal_usage: [
    { label: "Bed Covering" },
    { label: "Couch Throw" },
    { label: "Outdoor Picnics" },
    { label: "Travel Blanket" },
    { label: "Layering in Cold Weather" }
  ],

  Top_rating: [
    { label: "High Warmth and Insulation" },
    { label: "Softness and Comfort" },
    { label: "Durability" },
    { label: "Ease of Care (Machine Washable)" },
    { label: "Breathability" }
  ],
  GSM: [
    { label: "150 GSM" },
    { label: "200 GSM" },
    { label: "250 GSM" },
    { label: "300 GSM" },
    { label: "350 GSM" },
    { label: "400 GSM" },
    { label: "450 GSM" },
    { label: "500 GSM" },
    { label: "550 GSM" },
    { label: "600 GSM" }
  ],
  Weave_type: [
    { label: "Fleece" },
    { label: "Knit" },
    { label: "Woven" },
    { label: "Plush" },
    { label: "Jacquard" }
  ],

  Outer_material: [
    { label: "Cotton" },
    { label: "Microfiber" },
    { label: "Fleece" },
    { label: "Wool" },
    { label: "Polyester" }
  ],

  Design: [
    { label: "Solid Color" },
    { label: "Striped" },
    { label: "Patterned" },
    { label: "Printed" },
    { label: "Textured" }
  ],

  Theme: [
    { label: "Modern" },
    { label: "Vintage" },
    { label: "Rustic" },
    { label: "Farmhouse" },
    { label: "Tropical" }
  ],

  Usage: [
    { label: "Everyday Dining" },
    { label: "Formal Dining" },
    { label: "Outdoor Dining" },
    { label: "Holiday or Seasonal Use" },
    { label: "Special Occasions" }
  ],
  Brand_color: [
    { label: "White" },
    { label: "Beige" },
    { label: "Gray" },
    { label: "Navy Blue" },
    { label: "Red" }
  ],
  Key_features: [
    { label: "Durability" },
    { label: "Stain Resistance" },
    { label: "Slip Resistance" },
    { label: "Easy Maintenance" },
    { label: "Comfort Underfoot" }
  ],

  Mobile_capability: [
    { label: "Remote Monitoring" },
    { label: "Real-Time Alerts" },
    { label: "Mobile App Control" },
    { label: "Live Video Streaming" },
    { label: "Two-Way Communication" }
  ],

  Lens_size: [
    { label: "2.8 mm" },
    { label: "3.6 mm" },
    { label: "4 mm" },
    { label: "6 mm" },
    { label: "8 mm" }
  ],

  Angular_view: [
    { label: "90°" },
    { label: "110°" },
    { label: "120°" },
    { label: "140°" },
    { label: "180°" }
  ],

  Mounting_type: [
    { label: "Wall Mount" },
    { label: "Ceiling Mount" },
    { label: "Desktop Mount" },
    { label: "Pole Mount" },
    { label: "Corner Mount" }
  ],

  Camera_type: [
    { label: "Dome Camera" },
    { label: "Bullet Camera" },
    { label: "Pan-Tilt-Zoom (PTZ) Camera" },
    { label: "IP Camera" },
    { label: "Wireless Camera" }
  ],

  Video_output: [
    { label: "HD (720p)" },
    { label: "Full HD (1080p)" },
    { label: "2K" },
    { label: "4K UHD" },
    { label: "Analog (CVBS)" }
  ],
  Recording_modes: [
    { label: "Continuous Recording" },
    { label: "Motion-Activated Recording" },
    { label: "Event-Based Recording" },
    { label: "Manual Recording" }
  ],

  Image_sensor: [
    { label: "CMOS (Complementary Metal-Oxide-Semiconductor)" },
    { label: "CCD (Charge-Coupled Device)" },
    { label: "Bayer Filter Sensor" },
    { label: "Full-Color Sensor" },
    { label: "Low-Light Sensor" }
  ],

  IR_distance: [
    { label: "10 to 20 meters" },
    { label: "20 to 30 meters" },
    { label: "30 to 40 meters" },
    { label: "40 to 50 meters" },
    { label: "50 to 60 meters" }
  ],

  Number_of_IR_LEDS: [
    { label: "6 to 12 LEDs" },
    { label: "12 to 24 LEDs" },
    { label: "24 to 36 LEDs" },
    { label: "36 to 48 LEDs" },
    { label: "48 to 60 LEDs" }
  ],
  Motion_triggered_recording: [
    { label: "Automatic Activation" },
    { label: "Adjustable Sensitivity" },
    { label: "Time-Lapse Recording" },
    { label: "Alert Notifications" },
    { label: "Pre-Event and Post-Event Recording" }
  ],
  Audio_output: [
    { label: "Built-in Speaker" },
    { label: "External Speaker" },
    { label: "Two-Way Audio (Microphone & Speaker)" },
    { label: "Audio Recording" },
    { label: "Audio Alerts" }
  ],
  Battery_type: [
    { label: "AA Batteries" },
    { label: "AAA Batteries" },
    { label: "9V Batteries" },
    { label: "Rechargeable Lithium-Ion Batteries" },
    { label: "CR123A Batteries" }
  ],
  Finish: [
    { label: "Stain Nickel" },
    { label: "Oil Rubbed Bronze" },
    { label: "Matte Black" },
    { label: "Polished Chrome" },
    { label: "Antique Brass" }
  ],

  Functions: [
    { label: "Keyless Entry" },
    { label: "Remote Access" },
    { label: "Auto-Lock/Unlock" },
    { label: "Activity Monitoring" },
    { label: "Smart Home Integration" },
    { label: "Voice Control" }
  ],
  Slice_capacity: [
    { label: "1 slice" },
    { label: "2 slices" },
    { label: "4 slices" },
    { label: "6 slices" },
    { label: "8 slices" },
    { label: "10 slices" }
  ],
  Engine_power: [
    { label: "500W" },
    { label: "600W" },
    { label: "700W" },
    { label: "800W" },
    { label: "900W" },
    { label: "1000W" }
  ],


  Compatible_devices: [
    { label: "Smartphones (iOS and Android)" },
    { label: "Tablets" },
    { label: "Smart Home Hubs" },
    { label: "Smartwatches" },
    { label: "Key Fobs" }
  ],

  Connective_type: [
    { label: "Wi-Fi" },
    { label: "Bluetooth" },
    { label: "Z-Wave" },
    { label: "Zigbee" },
    { label: "RFID (Radio Frequency Identification)" }
  ],

  Frame_color: [
    { label: "Black" },
    { label: "White" },
    { label: "Natural Wood" },
    { label: "Gold" },
    { label: "Silver" }
  ],

  Wall_mount: [
    { label: "Hooks" },
    { label: "Picture Hangers" },
    { label: "Nails or Screws" },
    { label: "Wire Hanging Systems" },
    { label: "Adhesive Strips" }
  ],
  Cable: [
    { label: "USB" },
    { label: "HDMI" },
    { label: "Auxiliary" },
    { label: "Ethernet" },
    { label: "VGA" },
    { label: "DisplayPort" }
  ],

  Fan_Length: [
    { label: "30 cm" },
    { label: "40 cm" },
    { label: "50 cm" },
    { label: "60 cm" },
    { label: "70 cm" },
    { label: "80 cm" }
  ],

  Projection_ratio: [
    { label: "1.2:1" },
    { label: "1.5:1" },
    { label: "1.8:1" },
    { label: "2.0:1" },
    { label: "2.5:1" },
    { label: "3.0:1" }
  ],

  Headphone_jack: [
    { label: "3.5 mm" },
    { label: "1/4 inch" },
    { label: "USB-C" },
    { label: "Lightning" },
    { label: "Bluetooth" }
  ],

  Height_width_x_depth: [
    { label: "10 x 20 x 5 cm" },
    { label: "15 x 25 x 10 cm" },
    { label: "20 x 30 x 15 cm" },
    { label: "25 x 35 x 20 cm" },
    { label: "30 x 40 x 25 cm" }
  ],

  Sensitivity: [
    { label: "80 dB" },
    { label: "85 dB" },
    { label: "90 dB" },
    { label: "95 dB" },
    { label: "100 dB" },
    { label: "105 dB" }
  ],

  Maximum_Projection_Distances: [
    { label: "1 meter" },
    { label: "2 meters" },
    { label: "3 meters" },
    { label: "4 meters" },
    { label: "5 meters" },
    { label: "6 meters" }
  ],
  Number_of_batteries: [
    { label: "1 Battery" },
    { label: "2 Batteries" },
    { label: "3 Batteries" },
    { label: "4 Batteries" },
    { label: "5 Batteries" },
    { label: "6 Batteries" },
    { label: "7 Batteries" },
    { label: "8 Batteries" },
    { label: "9 Batteries" },
    { label: "10 Batteries" }
  ],

  Mount_mechanism: [
    { label: "Bracket Mount" },
    { label: "Floating Shelf Brackets" },
    { label: "Hidden Supports" },
    { label: "Wall Anchors" },
    { label: "Cleat Systems" }
  ],

  Scratch_resistant: [
    { label: "Laminated Finish" },
    { label: "UV Coating" },
    { label: "Glossy Coating" },
    { label: "Durable Vinyl Material" },
    { label: "Protective Overlaminate" }
  ],

  Decal_color: [
    { label: "Transparent" },
    { label: "Neon Colors" },
    { label: "Metallic Colors (Gold, Silver)" },
    { label: "Black and White" },
    { label: "Full Color (Custom Print)" }
  ],

  Sticker_type: [
    { label: "Die-Cut Stickers" },
    { label: "Clear Stickers" },
    { label: "Matte Finish Stickers" },
    { label: "Glossy Finish Stickers" },
    { label: "Static Cling Stickers" }
  ],
  Art_form_type: [
    { label: "Sculpture" },
    { label: "Ceramic Art" },
    { label: "Wood Carving" },
    { label: "Metalwork" },
    { label: "Glass Art" }
  ],
  Sub_category: [
    { label: "Statues" },
    { label: "Decorative Figurines" },
    { label: "Ornaments" },
    { label: "Miniature Models" },
    { label: "Sculptural Art" }
  ],
  Rated_life: [
    { label: "1 Year" },
    { label: "2 Years" },
    { label: "3 Years" },
    { label: "4 Years" },
    { label: "5 Years" },
    { label: "6 Years" },
    { label: "7 Years" },
    { label: "8 Years" },
    { label: "9 Years" },
    { label: "10 Years" },
    { label: "11 Years" },
    { label: "12 Years" },
    { label: "13 Years" },
    { label: "14 Years" },
    { label: "15 Years" }
  ],
  Blub_shape: [
    { label: "A-Line" },
    { label: "Globe" },
    { label: "Candle" },
    { label: "Spotlight (PAR)" },
    { label: "Tubular" }
  ],
  Shatter_proof: [
    { label: "Plastic-coated LED Bulbs" },
    { label: "Shatterproof CFL Bulbs" },
    { label: "Shatter-resistant Incandescent Bulbs" },
    { label: "Toughened Glass Fluorescent Tubes" },
    { label: "Rubber-coated Bulbs" }
  ],
  Pack_of_lamps: [
    { label: "Single Wall Lamp" },
    { label: "Pair of Wall Lamps" },
    { label: "Set of 3 Wall Lamps" },
    { label: "Set of 4 Wall Lamps" },
    { label: "Multi-piece Sets" }
  ],
  Blub_used: [
    { label: "Incandescent" },
    { label: "LED" },
    { label: "CFL" },
    { label: "Halogen" }
  ],
  Card_length: [
    { label: "100mm" },
    { label: "150mm" },
    { label: "200mm" },
    { label: "250mm" },
    { label: "300mm" }
  ],
  length1: [
    { label: "188cm" },
    { label: "203cm" },
    { label: "213cm" },
  ]
};

const MessurentsSlice = createSlice({
  name: "Messurents",
  initialState,
});
export const { } = MessurentsSlice.actions;
export default MessurentsSlice.reducer;

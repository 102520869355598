import React, { useState, useEffect } from "react";
import {
  Button, Flex, Form, Select,
  Col, Divider, Row, Input
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { getOneFieldStatus } from "../../../../../redux/fieldStatus/FieldStatusSlice";
import { values } from "pdf-lib";
import { MdOutlineErrorOutline } from "react-icons/md";
const { Option } = Select;

const ElectronicsSupercat = ({
  subcategoryid,
  categoryid,
  supercategoryid,
  categoryTagid,
  buttonPress,
  getPropDetails,
  resetield,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    yes_r_no,
    Accessory_shoe,
    Lens_type,
    Focus_points,
    Manual_focus,
    Video_recording_frame_rate,
    Adjustable_aspect_ratio,
    Hdd_available,
    Ir_distance,
    Closure_mechanism,
    Head_type,
    Manufacturing_year,
    Leg_lock_type,
    Apparent_field_of_view,
    Variable_browning,
    In_built_oil_collector,
    Elements_on_cooktop,
    Generator_compatibility,
    Minimum_operating,
    Display_frame_rate,
    Overload_protection,
    Planting,
    Self_diagnosis,
    Electricity_consumption,
    Operating_modes,
    Rated_pressure,
    Material_lid,
    Phase,
    Warming,
    Toasting,
    Card_storage,
    Capacity_in_cups,
    Reheat,
    Model_let,
    Auto_pop_up,
    Working_plate,
    Removable_crumb_tray,
    High_lift_for_small_breads,
    Wide_slots_for_thick_breads,
    Liquidizing_jar_capacity,
    Noise_level,
    Chutney_jar_capacity,
    Present_cooking_menu,
    PanType,
    Diameter,
    Number_of_scrubs,
    Motion_triggered_recording,
    Folded_length,
    No_memory_effect,
    Magnification,
    Independent_leg_spread,
    Maximum_shelf_life,
    Number_of_charging_cells,
    Minimum_illumination,
    Alarm_action,
    Network_interface_time,
    Inner_height,
    Inner_width,
    Recording_format,
    Application_support,
    Record_modes,
    Image_sensor,
    Number_of_ethernet_ports,
    Image_resolution,
    Remote_connectivity,
    Vertical_scanning_frequency,
    Signal_type,
    Color_support,
    Output_per_speaker,
    Audio_type,
    Component_hd_video_in,
    Maximum_attitude,
    Number_of_channels,
    Shutter_type,
    Standby_noise_level,
    Camera_type,
    Maximum_diagonal_projection_size,
    Horizontal_scanning_frequency,
    Functions_modes,
    Processor_generation,
    Minimum_focal_length,
    Aperture_with_max_focal_length,
    Aperture_with_min_focal_length,
    Number_of_osd_languages,
    Normal_noise_level,
    Upgradeable_memory,
    Prime_zoom,
    Maximum_focal_length,
    Security,
    Minimum_aperture,
    Maximum_aperture,
    Power_adapter,
    Moisture_control,
    Chassis_material,
    Design,
    Door_finish,
    Door_lock,
    Pointer_device,
    Display_resolution_time,
    Ram_frequency,
    Display_support_network,
    Sound_enhancements,
    Optical_zoom,
    Graphics_ppl,
    Important_apps,
    Video_formats,
    Audio_formats,
    Color_code,
    Material_water_proof,
    Number_of_packets,
    Certifications,
    Pre_installed_browser,
    Content_providers,
    Dvr_control,
    Teletext,
    Remote_type,
    Mobile_high_definition_link,
    Stand_features,
    Outdoor_usage,
    Maximum_spl,
    // _3g_dongle_plug_and_play,  
    Color_screen,
    Ir_capable,
    Without_stand,
    With_stand,
    Charging_time,
    Compatible_with,
    Cable_interface,
    Output_interface,
    App_store_type,
    Rpm,
    Hdd_capacity,
    Platform,
    Write_speed,
    Size,
    Water_proof,
    Password_protection,
    Rotational_speed,
    Data_transfer_rate,
    Other_sound_features,
    White_balance,
    Svr_variant,
    Exposure_mode,
    Scene_modes,
    Wide_angle,
    Ae_lock_or_exposure_lock,
    Live_view_shooting,
    Red_eye_reduction,
    Exposure_bracketing,
    Manual_exposure,
    Flash_compensation,
    Image_size,
    Flash_modes,
    Audio_codec,
    View_finder,
    Auto_focus,
    Effective_pixels,
    Image_processor,
    Lens_mount,
    Ports,
    Metering_model,
    Focal_length,
    Dust_reduction,
    Focus_mode,
    Focus_range,
    Self_timer,
    Video_quality,
    Shooting_modes,
    Image_sensor_type,
    Iso_rating,
    Compatible_cord,
    External_flash,
    Shutter_speed,
    Image_format,
    Sensor_type,
    Brand_color,
    Image_sensor_size,
    Tripod_socket,
    Analog_tv_reception,
    Supported_audio_features,
    Bluetooth_profile,
    Bluetooth_range,
    Headphone_power_source,
    Battery_life,
    In_residue_free_removal,
    Stand_by_time,
    Tinted,
    One_touch_button,
    Hardware_interface,
    Picture_engine,
    Connector_type,
    Cache,
    Os_architecture,
    Multi_card_slot,
    Number_of_cores,
    Number_of_contents_in_sales_pack,
    Touch_control,
    Anti_drip_value,
    Brew_strength_control,
    Removable_drip,
    Automatic_thread_cutter,
    Water_ice_dispenser,
    Removable_basket,
    Foldable_or_collapsible,
    Manual,
    Magnet_type,
    Driver_type,
    Signal_to_noise_ratio,
    Filter_life,
    Modulation,
    Minimum_frequency_response,
    Cold_water_capacity,
    Hot_water_capacity,
    Number_of_castor_wheels,
    Lcd_screen_display,
    Bobbin_winder,
    Stop_start_button,
    Stitch_out_time_indicator,
    Led_needle_light,
    Frother,
    Filter_material,
    Brewing_time,
    Electric_type,
    Hot_plate,
    Removable_filter,
    Temperature_glass_lid,
    Color,
    Number_of_attached_jar,
    Processing_bowl,
    Processing_jar,
    mobiles_Usage,
    Browse_Type,
    SIM_Type,
    mobile_Display_Size,
    mobile_Resolution,
    mobile_Gpu,
    mobile_Display_Type,
    mobile_operating_system,
    mobile_Processor_brand,
    mobile_primary_clock_speed,
    mobile_secondary_clock_speed,
    mobile_tertiary_clock_speed,
    Internal_storage,
    mobile_ram,
    Total_memory,
    Secodary_camera,
    Primary_camera,
    Flash,
    Frame_rate,
    Dual_lens,
    Network_type,
    Supported_networks,
    Bluetooth_version,
    condenser_coil_types,
    Map_support,
    Touchscreen_type,
    Sim_size,
    User_interface,
    Sensors,
    Browser,
    GPS_type,
    Battery_capacity,
    Mobile_Battery_type,
    mobile_width,
    mobile_height,
    mobile_depth,
    mobile_weight,
    Ice_cube_tray_type,
    Suitable_for,
    laptop_battery_backup,
    laptop_Ram_type,
    laptop_Graphic_processor,
    laptop_Expandable_ssd_capacity,
    laptop_Screen_type,
    laptop_Sound_properties,
    Dimensions,
    Web_camera,
    Emmc_storage_capacity,
    Dedicated_graphic_memory_type,
    Dedicated_graphic_memory_capacity,
    Lock_port,
    Ideal_usage,
    Connectivity,
    laptop_Processor_name,
    tablet_Processor_speed,
    Ideal_for,
    Compatible_laptop_size,
    Material,
    Number_of_compartments,
    Capacity,
    Configuration,
    Power_source,
    Power_output,
    Frequency_response,
    Wired_wireless,
    Compatitle_devices,
    Read_speed,
    Net_quantity,
    Defrosting_Type,
    Maximum_current_rating,
    System_requirements,
    Form_factor,
    Operating_range,
    Frequency,
    Frequency_band,
    Wireless_speed,
    Dsl_modem_speed,
    Lan_wan,
    Antennae,
    Number_of_usb_ports,
    Screen_Size,
    Impedence,
    Control_types,
    Video_resolution,
    Number_of_devices_supported,
    Mount_types,
    Rotisserie_types,
    Cavity_Material_type,
    Temparature_control_range,
    Power_consumption,
    Heating_element,
    Body_material,
    Control,
    Blade_material,
    Finish,
    Cord_length,
    Shape,
    Air_suction_capacity,
    Speed_levels,
    Engine_power,
    Slice_capacity,
    Functions,
    No_of_slices,
    Pack_of,
    Total_pan_capacity,
    Motor_speed,
    Number_of_stones,
    Attachment_present,
    Filter_type,
    Membrane_type,
    Filtration_capacity,
    Purification_capacity,
    Number_of_blades,
    Blade_sweep,
    Air_flow,
    Number_of_speeds,
    Blower,
    Star_rating,
    Cooling_coverage_area,
    Speed_control,
    operating_modess,
    Air_delivery,
    Air_deflection,
    Blower_material,
    Efficiency,
    Output_voltage,
    Output_frequency,
    Depth,
    Motor_suction_power,
    Number_of_stitches,
    Number_of_button_hole_styles,
    sweing_speed,
    Weight,
    Used_for,
    Minimum_input_power,
    Temperature,
    Heater_material,
    Heater_element_type,
    Power_output_rms,
    Maximum_brightness,
    Remote_features,

    Display_resolution,
    Aspect_ratio,
    Hd_technology_resolution,
    Wifi_type,
    View_angle,
    Led_display_type,
    Refresh_rate,
    Number_of_speakers,
    Speaker_type,
    Sound_mode,
    Supported_Mobile_operating_system,
    Supported_devices_for_casting,
    Power_requirement,
    Number_of_charger_pins,
    Output_current,
    Cable_type,
    Output_wattage,
    Headphone_type,
    Headphone_design,
    Power_supply,
    Video_player_type,
    Operating_system_supported,
    Type_of_tracking,
    Number_of_layers,
    Connector_1,
    Connector_2,
    Cable,
    Fan_Length,
    Projection_ratio,
    Headphone_jack,
    Sensitivity,
    Maximum_Projection_Distances,
    Number_of_doors,
    Keyboard_Type,
    Spin_cycle_duration,
    Wash_cycle_duration,
    Wash_program_types,
    Tub_materials,
    Washing_modes,
    Outer_body_materials,
    Number_of_auto_menu,
    panel_display_types,
    Usb_version,

    Number_of_batteries,
  } = useSelector((store) => store.Messurents);

  const [getfields, setGetFields] = useState([]);
  const [getfieldsShow, setGetFieldsShow] = useState([]);

  const [distinctfield, setdistinctfield] = useState([]);
  const [distinctfieldValidate, setdistinctfieldValidate] = useState([]);
  // Nishitha.........................🤞
  const [in_the_box, setIn_the_box] = useState("");
  const [in_the_boxerror, setIn_the_boxerror] = useState("");

  const [model_number, setModel_number] = useState("");
  const [model_numbererror, setModel_numbererror] = useState("");

  const [model_name, setModel_name] = useState("");
  const [model_nameerror, setModel_nameerror] = useState("");

  const [color, setColor] = useState("");
  const [colorerror, setColorerror] = useState("");

  const [browse_type, setBrowse_type] = useState("");
  const [browse_typeerror, setBrowse_typeerror] = useState("");

  const [sim_type, setSim_type] = useState("");
  const [sim_typeerror, setSim_typeerror] = useState("");

  const [hybrid_sim_slot, setHybrid_sim_slot] = useState("");
  const [hybrid_sim_sloterror, setHybrid_sim_sloterror] = useState("");

  const [touchscreen, setTouchscreen] = useState("");
  const [touchscreenerror, setTouchscreenerror] = useState("");

  const [otg_compatible, setOtg_compatible] = useState("");
  const [otg_compatibleerror, setOtg_compatibleerror] = useState("");

  const [quick_charging, setQuick_charging] = useState("");
  const [quick_chargingerror, setQuick_chargingerror] = useState("");

  const [sar_value, setSar_value] = useState("");
  const [sar_valueerror, setSar_valueerror] = useState("");

  const [display_size, setDisplay_size] = useState("");
  const [display_sizeerror, setDisplay_sizeerror] = useState("");

  const [resolution, setResolution] = useState("");
  const [resolutionerror, setResolutionerror] = useState("");

  const [gpu, setGpu] = useState("");
  const [gpuerror, setGpuerror] = useState("");

  const [display_type, setDisplay_type] = useState("");
  const [display_typeerror, setDisplay_typeerror] = useState("");

  const [other_display_features, setOther_display_features] = useState("");
  const [other_display_featureserror, setOther_display_featureserror] =
    useState("");

  const [operating_system, setOperating_system] = useState("");
  const [operating_systemerror, setOperating_systemerror] = useState("");

  const [processor_brand, setProcessor_brand] = useState("");
  const [processor_branderror, setProcessor_branderror] = useState("");

  const [processor_type, setProcessor_type] = useState("");
  const [processor_typeerror, setProcessor_typeerror] = useState("");

  const [primary_clock_speed, setPrimary_clock_speed] = useState("");
  const [primary_clock_speederror, setPrimary_clock_speederror] = useState("");

  const [secondary_clock_speed, setSecondary_clock_speed] = useState("");
  const [secondary_clock_speederror, setSecondary_clock_speederror] =
    useState("");

  const [tertiary_clock_speed, setTertiary_clock_speed] = useState("");
  const [tertiary_clock_speederror, setTertiary_clock_speederror] =
    useState("");

  const [operating_frequency, setOperating_frequency] = useState("");
  const [operating_frequencyerror, setOperating_frequencyerror] = useState("");

  const [internal_storage, setInternal_storage] = useState("");
  const [internal_storageerror, setInternal_storageerror] = useState("");




  const [ram, setRam] = useState("");
  const [ramerror, setRamerror] = useState("");

  const [total_memory, setTotal_memory] = useState("");
  const [total_memoryerror, setTotal_memoryerror] = useState("");

  const [call_log_memory, setCall_log_memory] = useState("");
  const [call_log_memoryerror, setCall_log_memoryerror] = useState("");

  const [primary_camera_available, setPrimary_camera_available] = useState("");
  const [primary_camera_availableerror, setPrimary_camera_availableerror] =
    useState("");

  const [primary_camera, setPrimary_camera] = useState("");
  const [primary_cameraerror, setPrimary_cameraerror] = useState("");

  const [primary_camera_features, setPrimary_camera_features] = useState("");
  const [primary_camera_featureserror, setPrimary_camera_featureserror] =
    useState("");

  const [secondary_camera_available, setSecondary_camera_available] =
    useState("");
  const [secondary_camera_availableerror, setSecondary_camera_availableerror] =
    useState("");

  const [secondary_camera, setSecondary_camera] = useState("");
  const [secondary_cameraerror, setSecondary_cameraerror] = useState("");

  const [secondary_camera_features, setSecondary_camera_features] =
    useState("");
  const [secondary_camera_featureserror, setSecondary_camera_featureserror] =
    useState("");

  const [flash, setFlash] = useState("");
  const [flasherror, setFlasherror] = useState("");

  const [hd_recording, setHd_recording] = useState("");
  const [hd_recordingerror, setHd_recordingerror] = useState("");

  const [full_hd_recording, setFull_hd_recording] = useState("");
  const [full_hd_recordingerror, setFull_hd_recordingerror] = useState("");

  const [video_recording, setVideo_recording] = useState("");
  const [video_recordingerror, setVideo_recordingerror] = useState("");

  const [video_recording_resolution, setVideo_recording_resolution] =
    useState("");
  const [video_recording_resolutionerror, setVideo_recording_resolutionerror] =
    useState("");
  const [digital_zoom, setDigital_zoom] = useState("");
  const [digital_zoomerror, setDigital_zoomerror] = useState("");

  const [frame_rate, setFrame_rate] = useState("");
  const [frame_rateerror, setFrame_rateerror] = useState("");

  const [dual_camera_lens, setDual_camera_lens] = useState("");
  const [dual_camera_lenserror, setDual_camera_lenserror] = useState("");

  const [call_wait_or_hold, setCall_wait_or_hold] = useState("");
  const [call_wait_or_holderror, setCall_wait_or_holderror] = useState("");

  const [conference_call, setConference_call] = useState("");
  const [conference_callerror, setConference_callerror] = useState("");

  const [hands_free, setHands_free] = useState("");
  const [hands_freeerror, setHands_freeerror] = useState("");

  const [call_divert, setCall_divert] = useState("");
  const [call_diverterror, setCall_diverterror] = useState("");

  const [phone_book, setPhone_book] = useState("");
  const [phone_bookerror, setPhone_bookerror] = useState("");

  const [call_timer, setCall_timer] = useState("");
  const [call_timererror, setCall_timererror] = useState("");

  const [speaker_phone, setSpeaker_phone] = useState("");
  const [speaker_phoneerror, setSpeaker_phoneerror] = useState("");

  const [call_records, setCall_records] = useState("");
  const [call_recordserror, setCall_recordserror] = useState("");

  const [network_type, setNetwork_type] = useState("");
  const [network_typeerror, setNetwork_typeerror] = useState("");

  const [supported_networks, setSupported_networks] = useState("");
  const [supported_networkserror, setSupported_networkserror] = useState("");


  const [_3g, set_3g] = useState("");
  const [_3gerror, set_3gerror] = useState("");

  const [gprs, setGprs] = useState("");
  const [gprserror, setGprserror] = useState("");

  const [micro_usb_port, setMicro_usb_port] = useState("");
  const [micro_usb_porterror, setMicro_usb_porterror] = useState("");

  const [micro_usb_version, setMicro_usb_version] = useState("");
  const [micro_usb_versionerror, setMicro_usb_versionerror] = useState("");

  const [blutooth_support, setBlutooth_support] = useState("");
  const [blutooth_supporterror, setBlutooth_supporterror] = useState("");

  const [blutooth_version, setBlutooth_version] = useState("");
  const [blutooth_versionerror, setBlutooth_versionerror] = useState("");

  const [wifi, setWifi] = useState("");
  const [wifierror, setWifierror] = useState("");

  const [wifi_version, setWifi_version] = useState("");
  const [wifi_versionerror, setWifi_versionerror] = useState("");

  const [wifi_hotspot, setWifi_hotspot] = useState("");
  const [wifi_hotspoterror, setWifi_hotspoterror] = useState("");

  const [mini_hdmi_port, setMini_hdmi_port] = useState("");
  const [mini_hdmi_porterror, setMini_hdmi_porterror] = useState("");

  const [nfc, setNfc] = useState("");
  const [nfcerror, setNfcerror] = useState("");

  const [usb_tethering, setUsb_tethering] = useState("");
  const [usb_tetheringerror, setUsb_tetheringerror] = useState("");

  const [usb_connectivity, setUsb_connectivity] = useState("");
  const [usb_connectivityerror, setUsb_connectivityerror] = useState("");

  const [map_support, setMap_support] = useState("");
  const [map_supporterror, setMap_supporterror] = useState("");

  const [gps_support, setGps_support] = useState("");
  const [gps_supporterror, setGps_supporterror] = useState("");

  const [smartphone, setSmartphone] = useState("");
  const [smartphoneerror, setSmartphoneerror] = useState("");

  const [touchscreen_type, setTouchscreen_type] = useState("");
  const [touchscreen_typeerror, setTouchscreen_typeerror] = useState("");

  const [sim_size, setSim_size] = useState("");
  const [sim_sizeerror, setSim_sizeerror] = useState("");

  const [user_interface, setUser_interface] = useState("");
  const [user_interfaceerror, setUser_interfaceerror] = useState("");

  const [instant_message, setInstant_message] = useState("");
  const [instant_messageerror, setInstant_messageerror] = useState("");

  const [removable_battery, setRemovable_battery] = useState("");
  const [removable_batteryerror, setRemovable_batteryerror] = useState("");

  const [mms, setMms] = useState("");
  const [mmserror, setMmserror] = useState("");

  const [sms, setSms] = useState("");
  const [smserror, setSmserror] = useState("");


  const [keypad, setKeypad] = useState("");
  const [keypaderror, setKeypaderror] = useState("");

  const [voice_input, setVoice_input] = useState("");
  const [voice_inputerror, setVoice_inputerror] = useState("");

  const [predictive_text_input, setPredictive_text_input] = useState("");
  const [predictive_text_inputerror, setPredictive_text_inputerror] =
    useState("");

  const [user_memory, setUser_memory] = useState("");
  const [user_memoryerror, setUser_memoryerror] = useState("");

  const [sensors, setSensors] = useState("");
  const [sensorserror, setSensorserror] = useState("");

  const [browser, setBrowser] = useState("");
  const [browsererror, setBrowsererror] = useState("");

  const [other_features, setOther_features] = useState("");
  const [other_featureserror, setOther_featureserror] = useState("");

  const [gps_type, setGps_type] = useState("");
  const [gps_typeerror, setGps_typeerror] = useState("");

  const [fm_radio, setFm_radio] = useState("");
  const [fm_radioerror, setFm_radioerror] = useState("");

  const [fm_radio_recording, setFm_radio_recording] = useState("");
  const [fm_radio_recordingerror, setFm_radio_recordingerror] = useState("");

  const [battery_capacity, setBattery_capacity] = useState("");
  const [battery_capacityerror, setBattery_capacityerror] = useState("");

  const [battery_type, setBattery_type] = useState("");
  const [battery_typeerror, setBattery_typeerror] = useState("");

  const [width, setWidth] = useState("");
  const [widtherror, setWidtherror] = useState("");

  const [height, setHeight] = useState("");
  const [heighterror, setHeighterror] = useState("");

  const [depth, setDepth] = useState("");
  const [deptherror, setDeptherror] = useState("");

  const [weight, setWeight] = useState("");
  const [weighterror, setWeighterror] = useState("");

  const [warranty_summary, setWarranty_summary] = useState("");
  const [warranty_summaryerror, setWarranty_summaryerror] = useState("");

  const [domestic_warranty, setDomestic_warranty] = useState("");
  const [domestic_warrantyerror, setDomestic_warrantyerror] = useState("");


  const [function_cooking, setFunction_cooking] = useState("");
  const [function_cookingerror, setFunction_cookingerror] = useState("");

  const [steaming, setSteaming] = useState("");
  const [steamingerror, setSteamingerror] = useState("");

  const [non_stick_cooking_bowl, setNon_stick_cooking_bowl] = useState("");
  const [non_stick_cooking_bowlerror, setNon_stick_cooking_bowlerror] =
    useState("");

  const [detachable_power_cord, setDetachable_power_cord] = useState("");
  const [detachable_power_corderror, setDetachable_power_corderror] =
    useState("");

  const [see_through_glass_top, setSee_through_glass_top] = useState("");
  const [see_through_glass_toperror, setSee_through_glass_toperror] =
    useState("");

  const [measuring_cup, setMeasuring_cup] = useState("");
  const [measuring_cuperror, setMeasuring_cuperror] = useState("");

  const [trivet, setTrivet] = useState("");
  const [triveterror, setTriveterror] = useState("");

  const [temperature_glass_lid, setTemperature_glass_lid] = useState("");
  const [temperature_glass_liderror, setTemperature_glass_liderror] =
    useState("");

  const [steaming_basket, setSteaming_basket] = useState("");
  const [steaming_basketerror, setSteaming_basketerror] = useState("");

  const [spatula, setSpatula] = useState("");
  const [spatulaerror, setSpatulaerror] = useState("");

  const [cool_touch_handles, setCool_touch_handles] = useState("");
  const [cool_touch_handleserror, setCool_touch_handleserror] = useState("");

  const [thermal_fuse, setThermal_fuse] = useState("");
  const [thermal_fuseerror, setThermal_fuseerror] = useState("");

  const [motor_speed, setMotor_speed] = useState("");
  const [motor_speederror, setMotor_speederror] = useState("");

  const [number_of_stones, setNumber_of_stones] = useState("");
  const [number_of_stoneserror, setNumber_of_stoneserror] = useState("");

  const [attachment_present, setAttachment_present] = useState("");
  const [attachment_presenterror, setAttachment_presenterror] = useState("");

  const [motor_overload_protection, setMotor_overload_protection] =
    useState("");
  const [motor_overload_protectionerror, setMotor_overload_protectionerror] =
    useState("");

  const [auto_off, setAuto_off] = useState("");
  const [auto_offerror, setAuto_offerror] = useState("");

  const [safety_lock, setSafety_lock] = useState("");
  const [safety_lockerror, setSafety_lockerror] = useState("");

  const [processing_bocol, setProcessing_bocol] = useState("");
  const [processing_bocolerror, setProcessing_bocolerror] = useState("");

  const [processing_jar, setProcessing_jar] = useState("");
  const [processing_jarerror, setProcessing_jarerror] = useState("");

  const [number_of_attached_jar, setNumber_of_attached_jar] = useState("");
  const [number_of_attached_jarerror, setNumber_of_attached_jarerror] =
    useState("");

  const [anti_drip_value, setAnti_drip_value] = useState("");
  const [anti_drip_valueerror, setAnti_drip_valueerror] = useState("");

  const [brew_strength_control, setBrew_strength_control] = useState("");
  const [brew_strength_controlerror, setBrew_strength_controlerror] =
    useState("");

  const [removable_drip, setRemovable_drip] = useState("");
  const [removable_driperror, setRemovable_driperror] = useState("");

  const [frother, setFrother] = useState("");
  const [frothererror, setFrothererror] = useState("");

  const [illuminated_on_off_switch, setIlluminated_on_off_switch] =
    useState("");
  const [illuminated_on_off_switcherror, setIlluminated_on_off_switcherror] =
    useState("");

  const [hot_plate, setHot_plate] = useState("");
  const [hot_plateerror, setHot_plateerror] = useState("");

  const [net_quantity, setNet_quantity] = useState("");
  const [net_quantityerror, setNet_quantityerror] = useState("");

  const [brewing_time, setBrewing_time] = useState("");
  const [brewing_timeerror, setBrewing_timeerror] = useState("");

  const [removable_filter, setRemovable_filter] = useState("");
  const [removable_filtererror, setRemovable_filtererror] = useState("");

  const [other_dimensions, setOther_dimensions] = useState("");
  const [other_dimensionserror, setOther_dimensionserror] = useState("");

  const [filter_material, setFilter_material] = useState("");
  const [filter_materialerror, setFilter_materialerror] = useState("");

  const [temperature_control, setTemperature_control] = useState("");
  const [temperature_controlerror, setTemperature_controlerror] = useState("");

  const [spray, setSpray] = useState("");
  const [sprayerror, setSprayerror] = useState("");

  const [steam_burst, setSteam_burst] = useState("");
  const [steam_bursterror, setSteam_bursterror] = useState("");

  const [self_cleaning, setSelf_cleaning] = useState("");
  const [self_cleaningerror, setSelf_cleaningerror] = useState("");

  const [swivel_cord, setSwivel_cord] = useState("");
  const [swivel_corderror, setSwivel_corderror] = useState("");

  const [electric_type, setElectric_type] = useState("");
  const [electric_typeerror, setElectric_typeerror] = useState("");

  const [purification_features, setPurification_features] = useState("");
  const [purification_featureserror, setPurification_featureserror] =
    useState("");

  const [detachable_storage_tank, setDetachable_storage_tank] = useState("");
  const [detachable_storage_tankerror, setDetachable_storage_tankerror] =
    useState("");

  const [membrane_type, setMembrane_type] = useState("");
  const [membrane_typeerror, setMembrane_typeerror] = useState("");

  const [overflow_protection, setOverflow_protection] = useState("");
  const [overflow_protectionerror, setOverflow_protectionerror] = useState("");

  const [filter_life, setFilter_life] = useState("");
  const [filter_lifeerror, setFilter_lifeerror] = useState("");

  const [alarms, setAlarms] = useState("");
  const [alarmserror, setAlarmserror] = useState("");

  const [filtration_capacity, setFiltration_capacity] = useState("");
  const [filtration_capacityerror, setFiltration_capacityerror] = useState("");

  const [purification_capacity, setPurification_capacity] = useState("");
  const [purification_capacityerror, setPurification_capacityerror] =
    useState("");

  const [cold_water_capacity, setCold_water_capacity] = useState("");
  const [cold_water_capacityerror, setCold_water_capacityerror] = useState("");

  const [cold_water_dispensor, setCold_water_dispensor] = useState("");
  const [cold_water_dispensorerror, setCold_water_dispensorerror] =
    useState("");

  const [hot_water_capacity, setHot_water_capacity] = useState("");
  const [hot_water_capacityerror, setHot_water_capacityerror] = useState("");

  const [hot_water_dispensor, setHot_water_dispensor] = useState("");
  const [hot_water_dispensorerror, setHot_water_dispensorerror] = useState("");

  const [purification_stages, setPurification_stages] = useState("");
  const [purification_stageserror, setPurification_stageserror] = useState("");

  const [airflow, setAirflow] = useState("");
  const [airflowerror, setAirflowerror] = useState("");


  const [blade_sweep, setBlade_sweep] = useState("");
  const [blade_sweeperror, setBlade_sweeperror] = useState("");

  const [number_of_speeds, setNumber_of_speeds] = useState("");
  const [number_of_speedserror, setNumber_of_speedserror] = useState("");

  const [cooling_coverage_area, setCooling_coverage_area] = useState("");
  const [cooling_coverage_areaerror, setCooling_coverage_areaerror] =
    useState("");

  const [speed_control, setSpeed_control] = useState("");
  const [speed_controlerror, setSpeed_controlerror] = useState("");

  const [operating_mode, setOperating_mode] = useState("");
  const [operating_modeerror, setOperating_modeerror] = useState("");

  const [air_delivery, setAir_delivery] = useState("");
  const [air_deliveryerror, setAir_deliveryerror] = useState("");

  const [air_deflection, setAir_deflection] = useState("");
  const [air_deflectionerror, setAir_deflectionerror] = useState("");

  const [blower_material, setBlower_material] = useState("");
  const [blower_materialerror, setBlower_materialerror] = useState("");

  const [ice_chamber, setIce_chamber] = useState("");
  const [ice_chambererror, setIce_chambererror] = useState("");

  const [caston_wheels, setCaston_wheels] = useState("");
  const [caston_wheelserror, setCaston_wheelserror] = useState("");

  const [number_of_caston_wheels, setNumber_of_caston_wheels] = useState("");
  const [number_of_caston_wheelserror, setNumber_of_caston_wheelserror] =
    useState("");

  const [number_of_fan_blades, setNumber_of_fan_blades] = useState("");
  const [number_of_fan_bladeserror, setNumber_of_fan_bladeserror] =
    useState("");

  const [auto_louver_movement, setAuto_louver_movement] = useState("");
  const [auto_louver_movementerror, setAuto_louver_movementerror] =
    useState("");

  const [oscillating_function, setOscillating_function] = useState("");
  const [oscillating_functionerror, setOscillating_functionerror] =
    useState("");

  const [technology_used, setTechnology_used] = useState("");
  const [technology_usederror, setTechnology_usederror] = useState("");

  const [blower, setBlower] = useState("");
  const [blowererror, setBlowererror] = useState("");

  const [motor_suction_power, setMotor_suction_power] = useState("");
  const [motor_suction_powererror, setMotor_suction_powererror] = useState("");

  const [solar_power, setSolar_power] = useState("");
  const [solar_powererror, setSolar_powererror] = useState("");

  const [efficiency, setEfficiency] = useState("");
  const [efficiencyerror, setEfficiencyerror] = useState("");

  const [output_voltage, setOutput_voltage] = useState("");
  const [output_voltageerror, setOutput_voltageerror] = useState("");

  const [launch_year, setLaunch_year] = useState("");
  const [launch_yearerror, setLaunch_yearerror] = useState("");

  const [deodarizer, setDeodarizer] = useState("");
  const [deodarizererror, setDeodarizererror] = useState("");

  const [ice_cub_tray_type, setIce_cub_tray_type] = useState("");
  const [ice_cub_tray_typeerror, setIce_cub_tray_typeerror] = useState("");

  const [wheel_support, setWheel_support] = useState("");
  const [wheel_supporterror, setWheel_supporterror] = useState("");

  const [removable_basket, setRemovable_basket] = useState("");
  const [removable_basketerror, setRemovable_basketerror] = useState("");

  const [flexible_rack, setFlexible_rack] = useState("");
  const [flexible_rackerror, setFlexible_rackerror] = useState("");

  const [removable_rack, setRemovable_rack] = useState("");
  const [removable_rackerror, setRemovable_rackerror] = useState("");

  const [brand, setBrand] = useState("");
  const [branderror, setBranderror] = useState("");
  const [capacity, setCapacity] = useState("");
  const [capacityerror, setCapacityerror] = useState("");
  const [manufacturing_year, setManufacturing_year] = useState("");
  const [manufacturing_yearerror, setManufacturing_yearerror] = useState("");

  const [cover_in_warranty, setCover_in_warranty] = useState("");
  const [cover_in_warrantyerror, setCover_in_warrantyerror] = useState("");

  const [not_cover_in_warranty, setNot_cover_in_warranty] = useState("");
  const [not_cover_in_warrantyerror, setNot_cover_in_warrantyerror] =
    useState("");

  const [warranty_service_type, setWarranty_service_type] = useState("");
  const [warranty_service_typeerror, setWarranty_service_typeerror] =
    useState("");

  const [preheat, setPreheat] = useState("");
  const [preheaterror, setPreheaterror] = useState("");

  const [timer, setTimer] = useState("");
  const [timererror, setTimererror] = useState("");

  const [auto_cook_menu_available, setAuto_cook_menu_available] = useState("");
  const [auto_cook_menu_availableerror, setAuto_cook_menu_availableerror] =
    useState("");

  const [power_output, setPower_output] = useState("");
  const [power_outputerror, setPower_outputerror] = useState("");

  const [sales_package, setSales_package] = useState("");
  const [sales_packageerror, setSales_packageerror] = useState("");

  const [rotisserie, setRotisserie] = useState("");
  const [rotisserieerror, setRotisserieerror] = useState("");

  const [rotisserie_type, setRotisserie_type] = useState("");
  const [rotisserie_typeerror, setRotisserie_typeerror] = useState("");

  const [cavity_material, setCavity_material] = useState("");
  const [cavity_materialerror, setCavity_materialerror] = useState("");

  const [temperature_control_range, setTemperature_control_range] =
    useState("");
  const [temperature_control_rangeerror, setTemperature_control_rangeerror] =
    useState("");

  const [auto_menu, setAuto_menu] = useState("");
  const [auto_menuerror, setAuto_menuerror] = useState("");

  const [number_of_auto_menu, setNumber_of_auto_menu] = useState("");
  const [number_of_auto_menuerror, setNumber_of_auto_menuerror] = useState("");

  const [power_requirement, setPower_requirement] = useState("");
  const [power_requirementerror, setPower_requirementerror] = useState("");

  const [material, setMaterial] = useState("");
  const [materialerror, setMaterialerror] = useState("");

  const [dry_grinding, setDry_grinding] = useState("");
  const [dry_grindingerror, setDry_grindingerror] = useState("");

  const [mincing, setMincing] = useState("");
  const [mincingerror, setMincingerror] = useState("");

  const [chutey_grinding, setChutey_grinding] = useState("");
  const [chutey_grindingerror, setChutey_grindingerror] = useState("");

  const [liguidizing_jar_capacity, setLiguidizing_jar_capacity] = useState("");
  const [liguidizing_jar_capacityerror, setLiguidizing_jar_capacityerror] =
    useState("");

  const [chatey_jar_capacity, setChatey_jar_capacity] = useState("");
  const [chatey_jar_capacityerror, setChatey_jar_capacityerror] = useState("");

  const [series, setSeries] = useState("");
  const [serieserror, setSerieserror] = useState("");

  const [locbable_lid, setLocbable_lid] = useState("");
  const [locbable_liderror, setLocbable_liderror] = useState("");

  const [dry_boil_protection, setDry_boil_protection] = useState("");
  const [dry_boil_protectionerror, setDry_boil_protectionerror] = useState("");

  const [auto_switch_off, setAuto_switch_off] = useState("");
  const [auto_switch_offerror, setAuto_switch_offerror] = useState("");

  const [heating_element, setHeating_element] = useState("");
  const [heating_elementerror, setHeating_elementerror] = useState("");

  const [water_level_indicator, setWater_level_indicator] = useState("");
  const [water_level_indicatorerror, setWater_level_indicatorerror] =
    useState("");

  const [card_length, setCard_length] = useState("");
  const [card_lengtherror, setCard_lengtherror] = useState("");

  const [body_material, setBody_material] = useState("");
  const [body_materialerror, setBody_materialerror] = useState("");

  const [control, setControl] = useState("");
  const [controlerror, setControlerror] = useState("");

  const [present_cooking_menu, setPresent_cooking_menu] = useState("");
  const [present_cooking_menuerror, setPresent_cooking_menuerror] =
    useState("");

  const [elements_on_cooktop, setElements_on_cooktop] = useState("");
  const [elements_on_cooktoperror, setElements_on_cooktoperror] = useState("");

  const [other_body_and_design_features, setOther_body_and_design_features] =
    useState("");
  const [
    other_body_and_design_featureserror,
    setOther_body_and_design_featureserror,
  ] = useState("");

  const [service_type, setService_type] = useState("");
  const [service_typeerror, setService_typeerror] = useState("");

  const [installation_and_demo, setInstallation_and_demo] = useState("");
  const [installation_and_demoerror, setInstallation_and_demoerror] =
    useState("");

  const [finish, setFinish] = useState("");
  const [finisherror, setFinisherror] = useState("");

  const [air_section_capacity, setAir_section_capacity] = useState("");
  const [air_section_capacityerror, setAir_section_capacityerror] =
    useState("");

  const [ductless, setDuctless] = useState("");
  const [ductlesserror, setDuctlesserror] = useState("");

  const [filter_type, setFilter_type] = useState("");
  const [filter_typeerror, setFilter_typeerror] = useState("");

  const [speed_levels, setSpeed_levels] = useState("");
  const [speed_levelserror, setSpeed_levelserror] = useState("");

  const [filter_block_indicator, setFilter_block_indicator] = useState("");
  const [filter_block_indicatorerror, setFilter_block_indicatorerror] =
    useState("");

  const [noise_level, setNoise_level] = useState("");
  const [noise_levelerror, setNoise_levelerror] = useState("");

  const [phase, setPhase] = useState("");
  const [phaseerror, setPhaseerror] = useState("");

  const [engine_power, setEngine_power] = useState("");
  const [engine_powererror, setEngine_powererror] = useState("");

  const [in_built_oil_collector, setIn_built_oil_collector] = useState("");
  const [in_built_oil_collectorerror, setIn_built_oil_collectorerror] =
    useState("");

  const [timer_present, setTimer_present] = useState("");
  const [timer_presenterror, setTimer_presenterror] = useState("");

  const [dblade_material, setDblade_material] = useState("");
  const [dblade_materialerror, setDblade_materialerror] = useState("");

  const [blending, setBlending] = useState("");
  const [blendingerror, setBlendingerror] = useState("");

  const [pureing, setPureing] = useState("");
  const [pureingerror, setPureingerror] = useState("");

  const [stirring, setStirring] = useState("");
  const [stirringerror, setStirringerror] = useState("");

  const [safety_features, setSafety_features] = useState("");
  const [safety_featureserror, setSafety_featureserror] = useState("");

  const [model_let, setModel_let] = useState("");
  const [model_leterror, setModel_leterror] = useState("");

  const [slice_capacity, setSlice_capacity] = useState("");
  const [slice_capacityerror, setSlice_capacityerror] = useState("");

  const [working_plate, setWorking_plate] = useState("");
  const [working_plateerror, setWorking_plateerror] = useState("");

  const [lid_lock, setLid_lock] = useState("");
  const [lid_lockerror, setLid_lockerror] = useState("");

  const [no_of_slices, setNo_of_slices] = useState("");
  const [no_of_sliceserror, setNo_of_sliceserror] = useState("");

  const [wide_slots_for_thick_breads, setWide_slots_for_thick_breads] =
    useState("");
  const [
    wide_slots_for_thick_breadserror,
    setWide_slots_for_thick_breadserror,
  ] = useState("");

  const [high_lift_for_small_breads, setHigh_lift_for_small_breads] =
    useState("");
  const [high_lift_for_small_breadserror, setHigh_lift_for_small_breadserror] =
    useState("");

  const [auto_pop_up, setAuto_pop_up] = useState("");
  const [auto_pop_uperror, setAuto_pop_uperror] = useState("");

  const [removable_crumbtroy, setRemovable_crumbtroy] = useState("");
  const [removable_crumbtroyerror, setRemovable_crumbtroyerror] = useState("");

  const [toasting, setToasting] = useState("");
  const [toastingerror, setToastingerror] = useState("");

  const [warming, setWarming] = useState("");
  const [warmingerror, setWarmingerror] = useState("");

  const [reheat, setReheat] = useState("");
  const [reheaterror, setReheaterror] = useState("");

  const [variable_browing, setVariable_browing] = useState("");
  const [variable_browingerror, setVariable_browingerror] = useState("");

  const [defrost, setDefrost] = useState("");
  const [defrosterror, setDefrosterror] = useState("");

  const [card_storage, setCard_storage] = useState("");
  const [card_storageerror, setCard_storageerror] = useState("");

  const [cool_touch, setCool_touch] = useState("");
  const [cool_toucherror, setCool_toucherror] = useState("");

  const [length, setLength] = useState("");
  const [lengtherror, setLengtherror] = useState("");

  const [capacity_incups, setCapacity_incups] = useState("");
  const [capacity_incupserror, setCapacity_incupserror] = useState("");

  const [total_pan_capacity, setTotal_pan_capacity] = useState("");
  const [total_pan_capacityerror, setTotal_pan_capacityerror] = useState("");

  const [functions, setFunctions] = useState("");
  const [functionserror, setFunctionserror] = useState("");

  const [material_lid, setMaterial_lid] = useState("");
  const [material_liderror, setMaterial_liderror] = useState("");

  const [net_quantity_product_details, setNet_quantity_product_details] =
    useState("");
  const [
    net_quantity_product_detailserror,
    setNet_quantity_product_detailserror,
  ] = useState("");

  const [autokeep_warm_function_cooking, setAutokeep_warm_function_cooking] =
    useState("");
  const [
    autokeep_warm_function_cookingerror,
    setAutokeep_warm_function_cookingerror,
  ] = useState("");
  const [output_frequency, setOutput_frequency] = useState("");
  const [output_frequencyerror, setOutput_frequencyerror] = useState("");

  const [number_of_stitches, setNumber_of_stitches] = useState("");
  const [number_of_stitcheserror, setNumber_of_stitcheserror] = useState("");

  const [number_of_button_holes_styles, setNumber_of_button_holes_styles] =
    useState("");
  const [
    number_of_button_holes_styleserror,
    setNumber_of_button_holes_styleserror,
  ] = useState("");

  const [sewing_speed, setSewing_speed] = useState("");
  const [sewing_speederror, setSewing_speederror] = useState("");

  const [lcd_screen_display, setLcd_screen_display] = useState("");
  const [lcd_screen_displayerror, setLcd_screen_displayerror] = useState("");

  const [shape, setShape] = useState("");
  const [shapeerror, setShapeerror] = useState("");

  const [bobbin_winder, setBobbin_winder] = useState("");
  const [bobbin_windererror, setBobbin_windererror] = useState("");

  const [stop_start_button, setStop_start_button] = useState("");
  const [stop_start_buttonerror, setStop_start_buttonerror] = useState("");

  const [stitch_out_time_indicator, setStitch_out_time_indicator] =
    useState("");
  const [stitch_out_time_indicatorerror, setStitch_out_time_indicatorerror] =
    useState("");

  const [led_needle_light, setLed_needle_light] = useState("");
  const [led_needle_lighterror, setLed_needle_lighterror] = useState("");

  const [automatic_thread_cutter, setAutomatic_thread_cutter] = useState("");
  const [automatic_thread_cuttererror, setAutomatic_thread_cuttererror] =
    useState("");

  const [used_for, setUsed_for] = useState("");
  const [used_forerror, setUsed_forerror] = useState("");

  const [generator_compatibility, setGenerator_compatibility] = useState("");
  const [generator_compatibilityerror, setGenerator_compatibilityerror] =
    useState("");


  const [cooling, setCooling] = useState("");
  const [coolingerror, setCoolingerror] = useState("");

  const [mount_type, setMount_type] = useState("");
  const [mount_typeerror, setMount_typeerror] = useState("");

  const [minimum_operating, setMinimum_operating] = useState("");
  const [minimum_operatingerror, setMinimum_operatingerror] = useState("");

  const [under_vlot_protection, setUnder_vlot_protection] = useState("");
  const [under_vlot_protectionerror, setUnder_vlot_protectionerror] =
    useState("");

  const [over_vlot_protection, setOver_vlot_protection] = useState("");
  const [over_vlot_protectionerror, setOver_vlot_protectionerror] =
    useState("");

  const [overload_protection, setOverload_protection] = useState("");
  const [overload_protectionerror, setOverload_protectionerror] = useState("");

  const [minimum_input_power, setMinimum_input_power] = useState("");
  const [minimum_input_powererror, setMinimum_input_powererror] = useState("");

  const [suitable_for, setSuitable_for] = useState("");
  const [suitable_forerror, setSuitable_forerror] = useState("");

  const [rated_pressure, setRated_pressure] = useState("");
  const [rated_pressureerror, setRated_pressureerror] = useState("");

  const [star_rating, setStar_rating] = useState("");
  const [star_ratingerror, setStar_ratingerror] = useState("");

  const [thermostot, setThermostot] = useState("");
  const [thermostoterror, setThermostoterror] = useState("");

  const [planting, setPlanting] = useState("");
  const [plantingerror, setPlantingerror] = useState("");

  const [heater_material, setHeater_material] = useState("");
  const [heater_materialerror, setHeater_materialerror] = useState("");

  const [heating_element_type, setHeating_element_type] = useState("");
  const [heating_element_typeerror, setHeating_element_typeerror] =
    useState("");

  const [washing_method, setWashing_method] = useState("");
  const [washing_methoderror, setWashing_methoderror] = useState("");

  const [in_built_heater, setIn_built_heater] = useState("");
  const [in_built_heatererror, setIn_built_heatererror] = useState("");

  const [tub_material, setTub_material] = useState("");
  const [tub_materialerror, setTub_materialerror] = useState("");

  const [digital_display, setDigital_display] = useState("");
  const [digital_displayerror, setDigital_displayerror] = useState("");

  const [water_level_selector, setWater_level_selector] = useState("");
  const [water_level_selectorerror, setWater_level_selectorerror] =
    useState("");

  const [wash_cycle_duration, setWash_cycle_duration] = useState("");
  const [wash_cycle_durationerror, setWash_cycle_durationerror] = useState("");

  const [spin_cycle_duration, setSpin_cycle_duration] = useState("");
  const [spin_cycle_durationerror, setSpin_cycle_durationerror] = useState("");

  const [inverter, setInverter] = useState("");
  const [invertererror, setInvertererror] = useState("");

  const [steam, setSteam] = useState("");
  const [steamerror, setSteamerror] = useState("");

  const [quick_wash, setQuick_wash] = useState("");
  const [quick_washerror, setQuick_washerror] = useState("");

  const [lint_filter, setLint_filter] = useState("");
  const [lint_filtererror, setLint_filtererror] = useState("");

  const [child_lock, setChild_lock] = useState("");
  const [child_lockerror, setChild_lockerror] = useState("");

  const [wash_program_types, setWash_program_types] = useState("");
  const [wash_program_typeserror, setWash_program_typeserror] = useState("");

  const [compressor, setCompressor] = useState("");
  const [compressorerror, setCompressorerror] = useState("");

  const [dehumidification, setDehumidification] = useState("");
  const [dehumidificationerror, setDehumidificationerror] = useState("");

  const [remote_control, setRemote_control] = useState("");
  const [remote_controlerror, setRemote_controlerror] = useState("");

  const [operating_modes, setOperating_modes] = useState("");
  const [operating_modeserror, setOperating_modeserror] = useState("");

  const [condenser_coil, setCondenser_coil] = useState("");
  const [condenser_coilerror, setCondenser_coilerror] = useState("");

  const [indoor_w_h_d, setIndoor_w_h_d] = useState("");
  const [indoor_w_h_derror, setIndoor_w_h_derror] = useState("");

  const [indoor_unit_weight, setIndoor_unit_weight] = useState("");
  const [indoor_unit_weighterror, setIndoor_unit_weighterror] = useState("");

  const [auto_air_swing, setAuto_air_swing] = useState("");
  const [auto_air_swingerror, setAuto_air_swingerror] = useState("");


  const [air_flow_direction, setAir_flow_direction] = useState("");
  const [air_flow_directionerror, setAir_flow_directionerror] = useState("");

  const [anti_bacteria_filter, setAnti_bacteria_filter] = useState("");
  const [anti_bacteria_filtererror, setAnti_bacteria_filtererror] =
    useState("");

  const [dust_filter, setDust_filter] = useState("");
  const [dust_filtererror, setDust_filtererror] = useState("");

  const [auto_restart, setAuto_restart] = useState("");
  const [auto_restarterror, setAuto_restarterror] = useState("");

  const [quit_mode, setQuit_mode] = useState("");
  const [quit_modeerror, setQuit_modeerror] = useState("");

  const [sleep_mode, setSleep_mode] = useState("");
  const [sleep_modeerror, setSleep_modeerror] = useState("");

  const [memory_feature, setMemory_feature] = useState("");
  const [memory_featureerror, setMemory_featureerror] = useState("");

  const [self_diagosis, setSelf_diagosis] = useState("");
  const [self_diagosiserror, setSelf_diagosiserror] = useState("");

  const [electricity_consumption, setElectricity_consumption] = useState("");
  const [electricity_consumptionerror, setElectricity_consumptionerror] =
    useState("");
  const [defrosting_type, setDefrosting_type] = useState("");
  const [defrosting_typeerror, setDefrosting_typeerror] = useState("");

  const [number_of_doors, setNumber_of_doors] = useState("");
  const [number_of_doorserror, setNumber_of_doorserror] = useState("");

  const [coolpad, setCoolpad] = useState("");
  const [coolpaderror, setCoolpaderror] = useState("");

  const [build_in_stabilizer, setBuild_in_stabilizer] = useState("");
  const [build_in_stabilizererror, setBuild_in_stabilizererror] = useState("");

  const [toughened_glass, setToughened_glass] = useState("");
  const [toughened_glasserror, setToughened_glasserror] = useState("");

  const [moisture_control, setMoisture_control] = useState("");
  const [moisture_controlerror, setMoisture_controlerror] = useState("");

  const [design, setDesign] = useState("");
  const [designerror, setDesignerror] = useState("");

  const [door_finish, setDoor_finish] = useState("");
  const [door_finisherror, setDoor_finisherror] = useState("");

  const [door_lock, setDoor_lock] = useState("");
  const [door_lockerror, setDoor_lockerror] = useState("");

  const [water_ice_dispenser, setWater_ice_dispenser] = useState("");
  const [water_ice_dispensererror, setWater_ice_dispensererror] = useState("");

  const [stabilizer_required, setStabilizer_required] = useState("");
  const [stabilizer_requirederror, setStabilizer_requirederror] = useState("");

  const [egg_tray, setEgg_tray] = useState("");
  const [egg_trayerror, setEgg_trayerror] = useState("");



  const [headphone_type, setHeadphone_type] = useState("");
  const [headphone_typeerror, setHeadphone_typeerror] = useState("");

  const [inline_remote, setInline_remote] = useState("");
  const [inline_remoteerror, setInline_remoteerror] = useState("");

  const [connectivity, setConnectivity] = useState("");
  const [connectivityerror, setConnectivityerror] = useState("");

  const [headphone_design, setHeadphone_design] = useState("");
  const [headphone_designerror, setHeadphone_designerror] = useState("");

  const [foldable_or_collapsible, setFoldable_or_collapsible] = useState("");
  const [foldable_or_collapsibleerror, setFoldable_or_collapsibleerror] =
    useState("");


  const [deep_bass, setDeep_bass] = useState("");
  const [deep_basserror, setDeep_basserror] = useState("");

  const [manual, setManual] = useState("");
  const [manualerror, setManualerror] = useState("");

  const [magnet_type, setMagnet_type] = useState("");
  const [magnet_typeerror, setMagnet_typeerror] = useState("");

  const [driver_type, setdriver_type] = useState("");
  const [driver_typeerror, setdriver_typeerror] = useState("");

  const [indicators, setIndicators] = useState("");
  const [indicatorserror, setIndicatorserror] = useState("");

  const [sensitivity, setsensitivity] = useState("");
  const [sensitivityerror, setsensitivityerror] = useState("");

  const [impedance, setImpedance] = useState("");
  const [impedanceerror, setImpedanceerror] = useState("");

  const [signal_to_noise_ratio, setSignal_to_noise_ratio] = useState("");
  const [signal_to_noise_ratioerror, setSignal_to_noise_ratioerror] =
    useState("");

  const [modulation, setModulation] = useState("");
  const [modulationerror, setModulationerror] = useState("");

  const [minimum_frequency_response, setMinimum_frequency_response] =
    useState("");
  const [minimum_frequency_responseerror, setMinimum_frequency_responseerror] =
    useState("");

  const [other_sound_features, setOther_sound_features] = useState("");
  const [other_sound_featureserror, setOther_sound_featureserror] =
    useState("");

  const [audio_codec, setAudio_codec] = useState("");
  const [audio_codecerror, setAudio_codecerror] = useState("");

  const [blutooth_profile, setBlutooth_profile] = useState("");
  const [blutooth_profileerror, setBlutooth_profileerror] = useState("");

  const [blutooth_range, setBlutooth_range] = useState("");
  const [blutooth_rangeerror, setBlutooth_rangeerror] = useState("");

  const [headphone_power_source, setHeadphone_power_source] = useState("");
  const [headphone_power_sourceerror, setHeadphone_power_sourceerror] =
    useState("");

  const [power_supply, setPower_supply] = useState("");
  const [power_supplyerror, setPower_supplyerror] = useState("");

  const [battery_life, setBattery_life] = useState("");
  const [battery_lifeerror, setBattery_lifeerror] = useState("");

  const [stand_by_time, setStand_by_time] = useState("");
  const [stand_by_timeerror, setStand_by_timeerror] = useState("");

  const [applied, setApplied] = useState("");
  const [appliederror, setAppliederror] = useState("");

  const [number_of_layers, setNumber_of_layers] = useState("");
  const [number_of_layerserror, setNumber_of_layerserror] = useState("");

  const [in_rescidue_free_removal, setIn_rescidue_free_removal] = useState("");
  const [in_rescidue_free_removalerror, setIn_rescidue_free_removalerror] =
    useState("");

  const [tinted, setTinted] = useState("");
  const [tintederror, setTintederror] = useState("");

  const [camera_hole_present, setcamera_hole_present] = useState("");
  const [camera_hole_presenterror, setcamera_hole_presenterror] = useState("");

  const [usage, setUsage] = useState("");
  const [usageerror, setUsageerror] = useState("");

  const [operating_system_supported, setOperating_system_supported] =
    useState("");
  const [operating_system_supportederror, setOperating_system_supportederror] =
    useState("");

  const [type_of_tracking, setType_of_tracking] = useState("");
  const [type_of_trackingerror, setType_of_trackingerror] = useState("");

  const [auto_trasperancy, setAuto_trasperancy] = useState("");
  const [auto_trasperancyerror, setAuto_trasperancyerror] = useState("");

  const [display, setDisplay] = useState("");
  const [displayerror, setDisplayerror] = useState("");

  const [wireless_data_processing, setWireless_data_processing] = useState("");
  const [wireless_data_processingerror, setWireless_data_processingerror] =
    useState("");

  const [one_touch_button, setOne_touch_button] = useState("");
  const [one_touch_buttonerror, setOne_touch_buttonerror] = useState("");

  const [touch_control, setTouch_control] = useState("");
  const [touch_controlerror, setTouch_controlerror] = useState("");

  const [battery_included, setBattery_included] = useState("");
  const [battery_includederror, setBattery_includederror] = useState("");

  const [battery_chargable, setBattery_chargable] = useState("");
  const [battery_chargableerror, setBattery_chargableerror] = useState("");

  const [water_resistance, setWater_resistance] = useState("");
  const [water_resistanceerror, setWater_resistanceerror] = useState("");

  const [noise_concellation, setNoise_concellation] = useState("");
  const [noise_concellationerror, setNoise_concellationerror] = useState("");

  const [
    number_of_contents_in_sales_pack,
    setNumber_of_contents_in_sales_pack,
  ] = useState("");
  const [
    number_of_contents_in_sales_packerror,
    setNumber_of_contents_in_sales_packerror,
  ] = useState("");

  const [cable_type, setCable_type] = useState("");
  const [cable_typeerror, setCable_typeerror] = useState("");

  const [connector1, setConnector1] = useState("");
  const [connector1error, setConnector1error] = useState("");

  const [connector2, setConnector2] = useState("");
  const [connector2error, setConnector2error] = useState("");

  const [cable, setCable] = useState("");
  const [cableerror, setCableerror] = useState("");

  const [compatible_devices, setCompatible_devices] = useState("");
  const [compatible_deviceserror, setCompatible_deviceserror] = useState("");

  const [cable_length, setCable_length] = useState("");
  const [cable_lengtherror, setCable_lengtherror] = useState("");

  const [keyboard, setKeyboard] = useState("");
  const [keyboarderror, setKeyboarderror] = useState("");

  const [security_chip, setSecurity_chip] = useState("");
  const [security_chiperror, setSecurity_chiperror] = useState("");

  const [web_camera, setWeb_camera] = useState("");
  const [web_cameraerror, setWeb_cameraerror] = useState("");

  const [finger_print_sensor, setFinger_print_sensor] = useState("");
  const [finger_print_sensorerror, setFinger_print_sensorerror] = useState("");

  const [processor_variant, setProcessor_variant] = useState("");
  const [processor_varianterror, setProcessor_varianterror] = useState("");

  const [cache, setCache] = useState("");
  const [cacheerror, setCacheerror] = useState("");

  const [number_of_cores, setNumber_of_cores] = useState("");
  const [number_of_coreserror, setNumber_of_coreserror] = useState("");

  const [os_architecture, setOs_architecture] = useState("");
  const [os_architectureerror, setOs_architectureerror] = useState("");

  const [mic_in, setMic_in] = useState("");
  const [mic_inerror, setMic_inerror] = useState("");

  const [rj45, setRj45] = useState("");
  const [rj45error, setRj45error] = useState("");

  const [multi_card_slot, setMulti_card_slot] = useState("");
  const [multi_card_sloterror, setMulti_card_sloterror] = useState("");

  const [hardware_interface, setHardware_interface] = useState("");
  const [hardware_interfaceerror, setHardware_interfaceerror] = useState("");

  const [disk_drive, setDisk_drive] = useState("");
  const [disk_driveerror, setDisk_driveerror] = useState("");

  const [connector_type, setConnector_type] = useState("");
  const [connector_typeerror, setConnector_typeerror] = useState("");

  const [number_of_devices, setNumber_of_devices] = useState("");
  const [number_of_deviceserror, setNumber_of_deviceserror] = useState("");

  const [number_of_charger_pins, setNumber_of_charger_pins] = useState("");
  const [number_of_charger_pinserror, setNumber_of_charger_pinserror] =
    useState("");
  const [output_current, setOutput_current] = useState("");
  const [output_currenterror, setOutput_currenterror] = useState("");

  const [output_wattage, setOutput_wattage] = useState("");
  const [output_wattageerror, setOutput_wattageerror] = useState("");

  const [compatible_with, setCompatible_with] = useState("");
  const [compatible_witherror, setCompatible_witherror] = useState("");

  const [flodable, setFlodable] = useState("");
  const [flodableerror, setFlodableerror] = useState("");

  const [rpm, setRpm] = useState("");
  const [rpmerror, setRpmerror] = useState("");

  const [graphic_processor, setGraphic_processor] = useState("");
  const [graphic_processorerror, setGraphic_processorerror] = useState("");

  const [battery_backup, setBattery_backup] = useState("");
  const [battery_backuperror, setBattery_backuperror] = useState("");

  const [battery_cell, setBattery_cell] = useState("");
  const [battery_cellerror, setBattery_cellerror] = useState("");

  const [ms_office_provided, setMs_office_provided] = useState("");
  const [ms_office_providederror, setMs_office_providederror] = useState("");

  const [processor_name, setProcessor_name] = useState("");
  const [processor_nameerror, setProcessor_nameerror] = useState("");

  const [processor_generation, setProcessor_generation] = useState("");
  const [processor_generationerror, setProcessor_generationerror] =
    useState("");

  const [ram_type, setRam_type] = useState("");
  const [ram_typeerror, setRam_typeerror] = useState("");

  const [hdd_capacity, setHdd_capacity] = useState("");
  const [hdd_capacityerror, setHdd_capacityerror] = useState("");

  const [output_interface, setOutput_interface] = useState("");
  const [output_interfaceerror, setOutput_interfaceerror] = useState("");

  const [maximum_current_rating, setMaximum_current_rating] = useState("");
  const [maximum_current_ratingerror, setMaximum_current_ratingerror] =
    useState("");
  const [other_material, setOther_material] = useState("");
  const [other_materialerror, setOther_materialerror] = useState("");

  const [pointer_device, setPointer_device] = useState("");
  const [pointer_deviceerror, setPointer_deviceerror] = useState("");

  const [emmc_storage_capacity, setEmmc_storage_capacity] = useState("");
  const [emmc_storage_capacityerror, setEmmc_storage_capacityerror] =
    useState("");

  const [ram_frequency, setRam_frequency] = useState("");
  const [ram_frequencyerror, setRam_frequencyerror] = useState("");

  const [screen_size, setScreen_size] = useState("");
  const [screen_sizeerror, setScreen_sizeerror] = useState("");

  const [screen_type, setScreen_type] = useState("");
  const [screen_typeerror, setScreen_typeerror] = useState("");

  const [speakers, setSpeakers] = useState("");
  const [speakerserror, setSpeakerserror] = useState("");

  const [internal_mic, setInternal_mic] = useState("");
  const [internal_micerror, setInternal_micerror] = useState("");

  const [wireless_lan, setWireless_lan] = useState("");
  const [wireless_lanerror, setWireless_lanerror] = useState("");

  const [dedicated_graphic_memory_type, setDedicated_graphic_memory_type] =
    useState("");
  const [
    dedicated_graphic_memory_typeerror,
    setDedicated_graphic_memory_typeerror,
  ] = useState("");

  const [
    dedicated_graphic_memory_capacity,
    setDedicated_graphic_memory_capacity,
  ] = useState("");
  const [
    dedicated_graphic_memory_capacityerror,
    setDedicated_graphic_memory_capacityerror,
  ] = useState("");

  const [ethernet, setEthernet] = useState("");
  const [etherneterror, setEtherneterror] = useState("");

  const [backlit_keyboard, setBacklit_keyboard] = useState("");
  const [backlit_keyboarderror, setBacklit_keyboarderror] = useState("");

  const [memory_slot, setMemory_slot] = useState("");
  const [memory_sloterror, setMemory_sloterror] = useState("");

  const [expandle_memory, setExpandle_memory] = useState("");
  const [expandle_memoryerror, setExpandle_memoryerror] = useState("");

  const [sound_properties, setSound_properties] = useState("");
  const [sound_propertieserror, setSound_propertieserror] = useState("");

  const [lock_port, setLock_port] = useState("");
  const [lock_porterror, setLock_porterror] = useState("");

  const [operating_system_version, setOperating_system_version] = useState("");
  const [operating_system_versionerror, setOperating_system_versionerror] =
    useState("");

  const [voice_call, setVoice_call] = useState("");
  const [voice_callerror, setVoice_callerror] = useState("");

  const [display_resolution_time, setDisplay_resolution_time] = useState("");
  const [display_resolution_timeerror, setDisplay_resolution_timeerror] =
    useState("");

  const [display_support_network, setDisplay_support_network] = useState("");
  const [display_support_networkerror, setDisplay_support_networkerror] =
    useState("");

  const [display_resolution, setDisplay_resolution] = useState("");
  const [display_resolutionerror, setDisplay_resolutionerror] = useState("");

  const [processor_speed, setProcessor_speed] = useState("");
  const [processor_speederror, setProcessor_speederror] = useState("");

  const [headphone_jack, setHeadphone_jack] = useState("");
  const [headphone_jackerror, setHeadphone_jackerror] = useState("");

  const [expandable_storage_capacity, setExpandable_storage_capacity] = useState("");
  const [
    expandable_storage_capacityerror,
    setExpandable_storage_capacityerror,
  ] = useState("");

  const [memory_card_slot_type, setMemory_card_slot_type] = useState("");
  const [memory_card_slot_typeerror, setMemory_card_slot_typeerror] =
    useState("");

  const [memory_card_support, setMemory_card_support] = useState("");
  const [memory_card_supporterror, setMemory_card_supporterror] = useState("");


  const [warranty_period, setWarranty_period] = useState("");
  const [warranty_perioderror, setWarranty_perioderror] = useState("");

  const [sound_enhancements, setSound_enhancements] = useState("");
  const [sound_enhancementserror, setSound_enhancementserror] = useState("");

  const [optical_zoom, setOptical_zoom] = useState("");
  const [optical_zoomerror, setOptical_zoomerror] = useState("");

  const [video_call_support, setVideo_call_support] = useState("");
  const [video_call_supporterror, setVideo_call_supporterror] = useState("");

  const [graphicsppl, setGraphicsppl] = useState("");
  const [graphicspplerror, setGraphicspplerror] = useState("");

  const [supported_languages, setSupported_languages] = useState("");
  const [supported_languageserror, setSupported_languageserror] = useState("");

  const [important_apps, setImportant_apps] = useState("");
  const [important_appserror, setImportant_appserror] = useState("");

  const [audio_formats, setAudio_formats] = useState("");
  const [audio_formatserror, setAudio_formatserror] = useState("");

  const [video_formats, setVideo_formats] = useState("");
  const [video_formatserror, setVideo_formatserror] = useState("");

  const [ideal_for, setIdeal_for] = useState("");
  const [ideal_forerror, setIdeal_forerror] = useState("");

  const [ideal_usage, setIdeal_usage] = useState("");
  const [ideal_usageerror, setIdeal_usageerror] = useState("");

  const [loptop_sleeve, setLoptop_sleeve] = useState("");
  const [loptop_sleeveerror, setLoptop_sleeveerror] = useState("");

  const [with_rain_cover, setWith_rain_cover] = useState("");
  const [with_rain_covererror, setWith_rain_covererror] = useState("");

  const [compatibility_loptop_size, setCompatibility_loptop_size] =
    useState("");
  const [compatibility_loptop_sizeerror, setCompatibility_loptop_sizeerror] =
    useState("");

  const [style_code, setStyle_code] = useState("");
  const [style_codeerror, setStyle_codeerror] = useState("");

  const [color_code, setColor_code] = useState("");
  const [color_codeerror, setColor_codeerror] = useState("");

  const [material_water_proof, setMaterial_water_proof] = useState("");
  const [material_water_prooferror, setMaterial_water_prooferror] =
    useState("");
  const [number_of_compartments, setNumber_of_compartments] = useState("");
  const [number_of_compartmentserror, setNumber_of_compartmentserror] =
    useState("");
  const [number_of_packets, setNumber_of_packets] = useState("");
  const [number_of_packetserror, setNumber_of_packetserror] = useState("");

  const [configuration, setConfiguration] = useState("");
  const [configurationerror, setConfigurationerror] = useState("");

  const [frequency_response, setFrequency_response] = useState("");
  const [frequency_responseerror, setFrequency_responseerror] = useState("");

  const [built_in_fm_radio, setBuilt_in_fm_radio] = useState("");
  const [built_in_fm_radioerror, setBuilt_in_fm_radioerror] = useState("");

  const [certifications, setCertifications] = useState("");
  const [certificationserror, setCertificationserror] = useState("");

  const [charging_time, setCharging_time] = useState("");
  const [charging_timeerror, setCharging_timeerror] = useState("");

  const [cable_interface, setCable_interface] = useState("");
  const [cable_interfaceerror, setCable_interfaceerror] = useState("");

  const [platform, setPlatform] = useState("");
  const [platformerror, setPlatformerror] = useState("");

  const [read_speed, setRead_speed] = useState("");
  const [read_speederror, setRead_speederror] = useState("");

  const [write_speed, setWrite_speed] = useState("");
  const [write_speederror, setWrite_speederror] = useState("");

  const [system_requirements, setSystem_requirements] = useState("");
  const [system_requirementserror, setSystem_requirementserror] = useState("");

  const [form_factor, setForm_factor] = useState("");
  const [form_factorerror, setForm_factorerror] = useState("");

  const [cloud_backup, setCloud_backup] = useState("");
  const [cloud_backuperror, setCloud_backuperror] = useState("");

  const [data_tranfer_rate, setData_tranfer_rate] = useState("");
  const [data_tranfer_rateerror, setData_tranfer_rateerror] = useState("");

  const [rotational_speed, setRotational_speed] = useState("");
  const [rotational_speederror, setRotational_speederror] = useState("");

  const [password_protection, setPassword_protection] = useState("");
  const [password_protectionerror, setPassword_protectionerror] = useState("");

  const [power_source, setPower_source] = useState("");
  const [power_sourceerror, setPower_sourceerror] = useState("");

  const [size, setSize] = useState("");
  const [sizeerror, setSizeerror] = useState("");

  const [shock_resistant, setShock_resistant] = useState("");
  const [shock_resistanterror, setShock_resistanterror] = useState("");

  const [water_proof, setWater_proof] = useState("");
  const [water_prooferror, setWater_prooferror] = useState("");

  const [led_indicator, setLed_indicator] = useState("");
  const [led_indicatorerror, setLed_indicatorerror] = useState("");

  const [operating_range, setOperating_range] = useState("");
  const [operating_rangeerror, setOperating_rangeerror] = useState("");

  const [frequency, setFrequency] = useState("");
  const [frequencyerror, setFrequencyerror] = useState("");

  const [frequency_band, setFrequency_band] = useState("");
  const [frequency_banderror, setFrequency_banderror] = useState("");

  const [wireless_speed, setWireless_speed] = useState("");
  const [wireless_speederror, setWireless_speederror] = useState("");

  const [dsl_modern_speed, setDsl_modern_speed] = useState("");
  const [dsl_modern_speederror, setDsl_modern_speederror] = useState("");
  const [lan_wan, setLan_wan] = useState("");
  const [lan_wanerror, setLan_wanerror] = useState("");
  const [antennae, setAntennae] = useState("");
  const [antennaeerror, setAntennaeerror] = useState("");
  const [number_of_usb_ports, setNumber_of_usb_ports] = useState("");
  const [number_of_usb_portserror, setNumber_of_usb_portserror] = useState("");
  const [lan, setLan] = useState("");
  const [lanerror, setLanerror] = useState("");
  const [no_memory_effect, setNo_memory_effect] = useState("");
  const [no_memory_effecterror, setNo_memory_effecterror] = useState("");
  const [maximum_shelf_life, setMaximum_shelf_life] = useState("");
  const [maximum_shelf_lifeerror, setMaximum_shelf_lifeerror] = useState("");
  const [magnification, setMagnification] = useState("");
  const [magnificationerror, setMagnificationerror] = useState("");
  const [apparent_field_of_view, setApparent_field_of_view] = useState("");
  const [apparent_field_of_viewerror, setApparent_field_of_viewerror] =
    useState("");

  const [hd_resolution, setHd_resolution] = useState("");
  const [hd_resolutionerror, setHd_resolutionerror] = useState("");

  const [smart_tv, setSmart_tv] = useState("");
  const [smart_tverror, setSmart_tverror] = useState("");

  const [hdmi, setHdmi] = useState("");
  const [hdmierror, setHdmierror] = useState("");

  const [usb, setUsb] = useState("");
  const [usberror, setUsberror] = useState("");

  const [wifi_type, setWifi_type] = useState("");
  const [wifi_typeerror, setWifi_typeerror] = useState("");

  const [built_in_wifi, setBuilt_in_wifi] = useState("");
  const [built_in_wifierror, setBuilt_in_wifierror] = useState("");

  const [wall_mount_included, setWall_mount_included] = useState("");
  const [wall_mount_includederror, setWall_mount_includederror] = useState("");

  const [picture_in_picture, setPicture_in_picture] = useState("");
  const [picture_in_pictureerror, setPicture_in_pictureerror] = useState("");

  const [picture_engine, setPicture_engine] = useState("");
  const [picture_engineerror, setPicture_engineerror] = useState("");

  const [analog_tv_reception, setAnalog_tv_reception] = useState("");
  const [analog_tv_receptionerror, setAnalog_tv_receptionerror] = useState("");

  const [view_angle, setView_angle] = useState("");
  const [view_angleerror, setView_angleerror] = useState("");

  const [panel_type, setPanel_type] = useState("");
  const [panel_typeerror, setPanel_typeerror] = useState("");

  const [digital_noise_filter, setDigital_noise_filter] = useState("");
  const [digital_noise_filtererror, setDigital_noise_filtererror] =
    useState("");

  const [led_display_type, setLed_display_type] = useState("");
  const [led_display_typeerror, setLed_display_typeerror] = useState("");

  const [aspect_ratio, setAspect_ratio] = useState("");
  const [aspect_ratioerror, setAspect_ratioerror] = useState("");

  const [refresh_rate, setRefresh_rate] = useState("");
  const [refresh_rateerror, setRefresh_rateerror] = useState("");

  const [number_of_speakers, setNumber_of_speakers] = useState("");
  const [number_of_speakerserror, setNumber_of_speakerserror] = useState("");

  const [speaker_type, setSpeaker_type] = useState("");
  const [speaker_typeerror, setSpeaker_typeerror] = useState("");

  const [sound_technology, setSound_technology] = useState("");
  const [sound_technologyerror, setSound_technologyerror] = useState("");

  const [surround_sound, setSurround_sound] = useState("");
  const [surround_sounderror, setSurround_sounderror] = useState("");

  const [speaker_output_rms, setSpeaker_output_rms] = useState("");
  const [speaker_output_rmserror, setSpeaker_output_rmserror] = useState("");

  const [sound_mode, setSound_mode] = useState("");
  const [sound_modeerror, setSound_modeerror] = useState("");

  const [supported_audio_features, setSupported_audio_features] = useState("");
  const [supported_audio_featureserror, setSupported_audio_featureserror] =
    useState("");

  const [supported_app_netflix, setSupported_app_netflix] = useState("");
  const [supported_app_netflixerror, setSupported_app_netflixerror] =
    useState("");

  const [supported_app_youtube, setSupported_app_youtube] = useState("");
  const [supported_app_youtubeerror, setSupported_app_youtubeerror] =
    useState("");

  const [supported_app_disney_hotstar, setSupported_app_disney_hotstar] =
    useState("");
  const [
    supported_app_disney_hotstarerror,
    setSupported_app_disney_hotstarerror,
  ] = useState("");

  const [supported_app_prime_video, setSupported_app_prime_video] =
    useState("");
  const [supported_app_prime_videoerror, setSupported_app_prime_videoerror] =
    useState("");

  const [
    supported_mobile_operating_system,
    setSupported_mobile_operating_system,
  ] = useState("");
  const [
    supported_mobile_operating_systemerror,
    setSupported_mobile_operating_systemerror,
  ] = useState("");

  const [operating_system_present, setOperating_system_present] = useState("");
  const [operating_system_presenterror, setOperating_system_presenterror] =
    useState("");


  const [screen_mirroring, setScreen_mirroring] = useState("");
  const [screen_mirroringerror, setScreen_mirroringerror] = useState("");

  const [app_store_type, setApp_store_type] = useState("");
  const [app_store_typeerror, setApp_store_typeerror] = useState("");

  const [pre_installed_browser, setPre_installed_browser] = useState("");
  const [pre_installed_browsererror, setPre_installed_browsererror] =
    useState("");

  const [content_providers, setContent_providers] = useState("");
  const [content_providerserror, setContent_providerserror] = useState("");

  const [supported_devices_for_casting, setSupported_devices_for_casting] =
    useState("");
  const [
    supported_devices_for_castingerror,
    setSupported_devices_for_castingerror,
  ] = useState("");



  const [teletext, setTeletext] = useState("");
  const [teletexterror, setTeletexterror] = useState("");
  const [mobile_high_defination_link, setMobile_high_defination_link] =
    useState("");

  const [
    mobile_high_defination_linkerror,
    setMobile_high_defination_linkerror,
  ] = useState("");

  const [_3g_dongle_plug_and_play, set_3G_dongle_plug_and_play] = useState("");
  const [_3g_dongle_plug_and_playerror, set_3G_dongle_plug_and_playerror] =
    useState("");

  const [touch_remote, setTouch_remote] = useState("");
  const [touch_remoteerror, setTouch_remoteerror] = useState("");

  const [smart_remote, setSmart_remote] = useState("");
  const [smart_remoteerror, setSmart_remoteerror] = useState("");

  const [color_screen, setColor_screen] = useState("");
  const [color_screenerror, setColor_screenerror] = useState("");
  const [ir_capable, setIr_capable] = useState("");
  const [ir_capableerror, setIr_capableerror] = useState("");
  const [dvr_control, setDvr_control] = useState("");
  const [dvr_controlerror, setDvr_controlerror] = useState("");

  const [remote_type, setRemote_type] = useState("");
  const [remote_typeerror, setRemote_typeerror] = useState("");
  const [clock, setClock] = useState("");
  const [clockerror, setClockerror] = useState("");
  const [on_off_timer, setOn_off_timer] = useState("");
  const [on_off_timererror, setOn_off_timererror] = useState("");
  const [without_stand, setWithout_stand] = useState("");
  const [without_standerror, setWithout_standerror] = useState("");
  const [with_stand, setWith_stand] = useState("");
  const [with_standerror, setWith_standerror] = useState("");
  const [stand_features, setStand_features] = useState("");
  const [stand_featureserror, setStand_featureserror] = useState("");
  const [sleep_timer, setSleep_timer] = useState("");
  const [sleep_timererror, setSleep_timererror] = useState("");
  const [wired_wireless, setWired_wireless] = useState("");
  const [wired_wirelesserror, setWired_wirelesserror] = useState("");
  const [outdoor_usage, setOutdoor_usage] = useState("");
  const [outdoor_usageerror, setOutdoor_usageerror] = useState("");
  const [maximum_spl, setMaximum_spl] = useState("");
  const [maximum_splerror, setMaximum_splerror] = useState("");
  const [chassis_material, setChassis_material] = useState("");
  const [chassis_materialerror, setChassis_materialerror] = useState("");
  const [international_warranty, setInternational_warranty] = useState("");
  const [international_warrantyerror, setInternational_warrantyerror] =
    useState("");
  const [part_number, setPart_number] = useState("");
  const [part_numbererror, setPart_numbererror] = useState("");

  const [portable, setPortable] = useState("");
  const [portableerror, setPortableerror] = useState("");

  const [maximum_brightness, setMaximum_brightness] = useState("");
  const [maximum_brightnesserror, setMaximum_brightnesserror] = useState("");

  const [maximum_projection_distances, setMaximum_projection_distances] =
    useState("");
  const [
    maximum_projection_distanceserror,
    setMaximum_projection_distanceserror,
  ] = useState("");

  const [projection_ratio, setProjection_ratio] = useState("");
  const [projection_ratioerror, setProjection_ratioerror] = useState("");


  const [adjustable_aspect_ratio, setAdjustable_aspect_ratio] = useState("");
  const [adjustable_aspect_ratioerror, setAdjustable_aspect_ratioerror] =
    useState("");
  const [vertical_scanning_frequency, setVertical_scanning_frequency] =
    useState("");
  const [
    vertical_scanning_frequencyerror,
    setVertical_scanning_frequencyerror,
  ] = useState("");
  const [horizontal_scanning_frequency, setHorizontal_scanning_frequency] =
    useState("");
  const [
    horizontal_scanning_frequencyerror,
    setHorizontal_scanning_frequencyerror,
  ] = useState("");
  const [signal_type, setSignal_type] = useState("");
  const [signal_typeerror, setSignal_typeerror] = useState("");
  const [standard_brightness, setStandard_brightness] = useState("");
  const [standard_brightnesserror, setStandard_brightnesserror] = useState("");
  const [zoom_ratio, setZoom_ratio] = useState("");
  const [zoom_ratioerror, setZoom_ratioerror] = useState("");
  const [
    maximum_diagonal_projection_size,
    setMaximum_diagonal_projection_size,
  ] = useState("");
  const [
    maximum_diagonal_projection_sizeerror,
    setMaximum_diagonal_projection_sizeerror,
  ] = useState("");
  const [color_support, setColor_support] = useState("");
  const [color_supporterror, setColor_supporterror] = useState("");
  const [output_per_speaker, setOutput_per_speaker] = useState("");
  const [output_per_speakererror, setOutput_per_speakererror] = useState("");
  const [audio_type, setAudio_type] = useState("");
  const [audio_typeerror, setAudio_typeerror] = useState("");
  const [componenr_hd_video_in, setComponenr_hd_video_in] = useState("");
  const [componenr_hd_video_inerror, setComponenr_hd_video_inerror] =
    useState("");
  const [maximum_attitude, setMaximum_attitude] = useState("");
  const [maximum_attitudeerror, setMaximum_attitudeerror] = useState("");
  const [standby_noise_level, setStandby_noise_level] = useState("");
  const [standby_noise_levelerror, setStandby_noise_levelerror] = useState("");
  const [normal_noise_level, setNormal_noise_level] = useState("");
  const [normal_noise_levelerror, setNormal_noise_levelerror] = useState("");
  const [minimum_aperture, setMinimum_aperture] = useState("");
  const [minimum_apertureerror, setMinimum_apertureerror] = useState("");
  const [maximum_aperture, setMaximum_aperture] = useState("");
  const [maximum_apertureerror, setMaximum_apertureerror] = useState("");
  const [functions_modes, setFunctions_modes] = useState("");
  const [functions_modeserror, setFunctions_modeserror] = useState("");
  const [number_of_usd_languages, setNumber_of_usd_languages] = useState("");
  const [number_of_usd_languageserror, setNumber_of_usd_languageserror] =
    useState("");
  const [security, setSecurity] = useState("");
  const [securityerror, setSecurityerror] = useState("");
  const [offer, setOffer] = useState("");
  const [offererror, setOffererror] = useState("");
  const [remote_features, setRemote_features] = useState("");
  const [remote_featureserror, setRemote_featureserror] = useState("");
  const [control_type, setControl_type] = useState("");
  const [control_typeerror, setControl_typeerror] = useState("");
  const [power_adapter, setPower_adapter] = useState("");
  const [power_adaptererror, setPower_adaptererror] = useState("");
  const [video_player_type, setVideo_player_type] = useState("");
  const [video_player_typeerror, setVideo_player_typeerror] = useState("");

  const [svr_varient, setSvr_varient] = useState("");
  const [svr_varienterror, setSvr_varienterror] = useState("");
  const [brand_color, setBrand_color] = useState("");
  const [brand_colorerror, setBrand_colorerror] = useState("");
  const [effective_pixels, setEffective_pixels] = useState("");
  const [effective_pixelserror, setEffective_pixelserror] = useState("");
  const [tripod_socket, setTripod_socket] = useState("");
  const [tripod_socketerror, setTripod_socketerror] = useState("");
  const [sensor_type, setSensor_type] = useState("");
  const [sensor_typeerror, setSensor_typeerror] = useState("");
  const [image_sensor_size, setImage_sensor_size] = useState("");
  const [image_sensor_sizeerror, setImage_sensor_sizeerror] = useState("");
  const [lens_mount, setLens_mount] = useState("");
  const [lens_mounterror, setLens_mounterror] = useState("");
  const [dust_reduction, setDust_reduction] = useState("");
  const [dust_reductionerror, setDust_reductionerror] = useState("");
  const [shutter_speed, setShutter_speed] = useState("");
  const [shutter_speederror, setShutter_speederror] = useState("");
  const [self_timer, setSelf_timer] = useState("");
  const [self_timererror, setSelf_timererror] = useState("");
  const [built_in_flash, setBuilt_in_flash] = useState("");
  const [built_in_flasherror, setBuilt_in_flasherror] = useState("");



  const [external_flash, setExternal_flash] = useState("");
  const [external_flasherror, setExternal_flasherror] = useState("");


  const [image_format, setImage_format] = useState("");
  const [image_formaterror, setImage_formaterror] = useState("");
  const [video_resolution, setVideo_resolution] = useState("");
  const [video_resolutionerror, setVideo_resolutionerror] = useState("");
  const [video_quality, setVideo_quality] = useState("");
  const [video_qualityerror, setVideo_qualityerror] = useState("");
  const [comapatible_cord, setComapatible_cord] = useState("");
  const [comapatible_corderror, setComapatible_corderror] = useState("");
  const [number_of_batteries, setNumber_of_batteries] = useState("");
  const [number_of_batterieserror, setNumber_of_batterieserror] = useState("");
  const [shooting_modes, setShooting_modes] = useState("");
  const [shooting_modeserror, setShooting_modeserror] = useState("");
  const [image_sensor_type, setImage_sensor_type] = useState("");
  const [image_sensor_typeerror, setImage_sensor_typeerror] = useState("");
  const [iso_rating, setIso_rating] = useState("");
  const [iso_ratingerror, setIso_ratingerror] = useState("");
  const [focal_length, setFocal_length] = useState("");
  const [focal_lengtherror, setFocal_lengtherror] = useState("");
  const [focus_mode, setFocus_mode] = useState("");
  const [focus_modeerror, setFocus_modeerror] = useState("");
  const [focus_range, setFocus_range] = useState("");
  const [focus_rangeerror, setFocus_rangeerror] = useState("");

  const [metering_model, setMetering_model] = useState("");
  const [metering_modelerror, setMetering_modelerror] = useState("");
  const [face_detection, setFace_detection] = useState("");
  const [face_detectionerror, setFace_detectionerror] = useState("");
  const [temperature, setTemperature] = useState("");
  const [temperatureerror, setTemperatureerror] = useState("");
  const [image_processsor, setImage_processsor] = useState("");
  const [image_processsorerror, setImage_processsorerror] = useState("");
  const [ports, setPorts] = useState("");
  const [portserror, setPortserror] = useState("");
  const [accessory_shoe, setAccessory_shoe] = useState("");
  const [accessory_shoeerror, setAccessory_shoeerror] = useState("");
  const [lens_type, setLens_type] = useState("");
  const [lens_typeerror, setLens_typeerror] = useState("");
  const [manual_focus, setManual_focus] = useState("");
  const [manual_focuserror, setManual_focuserror] = useState("");
  const [view_finder, setView_finder] = useState("");
  const [view_findererror, setView_findererror] = useState("");
  const [auto_focus, setAuto_focus] = useState("");
  const [auto_focuserror, setAuto_focuserror] = useState("");
  const [focus_points, setFocus_points] = useState("");
  const [focus_pointserror, setFocus_pointserror] = useState("");
  const [white_balance, setWhite_balance] = useState("");
  const [white_balanceerror, setWhite_balanceerror] = useState("");
  const [scene_modes, setScene_modes] = useState("");
  const [scene_modeserror, setScene_modeserror] = useState("");
  const [exposure_mode, setExposure_mode] = useState("");
  const [exposure_modeerror, setExposure_modeerror] = useState("");
  const [ae_lock_or_exposure_lock, setAe_lock_or_exposure_lock] = useState("");
  const [ae_lock_or_exposure_lockerror, setAe_lock_or_exposure_lockerror] =
    useState("");
  const [exposure_bracketing, setExposure_bracketing] = useState("");
  const [exposure_bracketingerror, setExposure_bracketingerror] = useState("");
  const [manual_exposure, setManual_exposure] = useState("");
  const [manual_exposureerror, setManual_exposureerror] = useState("");
  const [flash_modes, setFlash_modes] = useState("");
  const [flash_modeserror, setFlash_modeserror] = useState("");
  const [flash_compension, setFlash_compension] = useState("");
  const [flash_compensionerror, setFlash_compensionerror] = useState("");
  const [image_size, setImage_size] = useState("");
  const [image_sizeerror, setImage_sizeerror] = useState("");
  const [wide_angle, setWide_angle] = useState("");
  const [wide_angleerror, setWide_angleerror] = useState("");
  const [red_eye_reduction, setRed_eye_reduction] = useState("");
  const [red_eye_reductionerror, setRed_eye_reductionerror] = useState("");
  const [live_view_shooting, setLive_view_shooting] = useState("");
  const [live_view_shootingerror, setLive_view_shootingerror] = useState("");
  const [hdr_support, setHdr_support] = useState("");
  const [hdr_supporterror, setHdr_supporterror] = useState("");

  const [microphone, setMicrophone] = useState("");
  const [microphoneerror, setMicrophoneerror] = useState("");
  const [upgradeable_memory, setUpgradeable_memory] = useState("");
  const [upgradeable_memoryerror, setUpgradeable_memoryerror] = useState("");

  const [model, setModel] = useState("");
  const [modelerror, setModelerror] = useState("");
  const [prime_zoom, setPrime_zoom] = useState("");
  const [prime_zoomerror, setPrime_zoomerror] = useState("");
  const [designed_for, setDesigned_for] = useState("");
  const [designed_forerror, setDesigned_forerror] = useState("");
  const [maximum_focal_length, setMaximum_focal_length] = useState("");
  const [maximum_focal_lengtherror, setMaximum_focal_lengtherror] =
    useState("");
  const [minimum_focal_length, setMinimum_focal_length] = useState("");
  const [minimum_focal_lengtherror, setMinimum_focal_lengtherror] =
    useState("");
  const [aperture_with_max_focal_length, setAperture_with_max_focal_length] =
    useState("");
  const [
    aperture_with_max_focal_lengtherror,
    setAperture_with_max_focal_lengtherror,
  ] = useState("");
  const [aperture_with_min_focal_length, setAperture_with_min_focal_length] =
    useState("");
  const [
    aperture_with_min_focal_lengtherror,
    setAperture_with_min_focal_lengtherror,
  ] = useState("");
  const [maximum_magnification, setMaximum_magnification] = useState("");
  const [maximum_magnificationerror, setMaximum_magnificationerror] =
    useState("");
  const [shutter_type, setShutter_type] = useState("");
  const [shutter_typeerror, setShutter_typeerror] = useState("");
  const [number_of_channels, setNumber_of_channels] = useState("");
  const [number_of_channelserror, setNumber_of_channelserror] = useState("");
  const [camera_type, setCamera_type] = useState("");
  const [camera_typeerror, setCamera_typeerror] = useState("");
  const [video_recording_frame_rate, setVideo_recording_frame_rate] =
    useState("");
  const [video_recording_frame_rateerror, setVideo_recording_frame_rateerror] =
    useState("");
  const [hdd_available, setHdd_available] = useState("");
  const [hdd_availableerror, setHdd_availableerror] = useState("");
  const [recording_format, setRecording_format] = useState("");
  const [recording_formaterror, setRecording_formaterror] = useState("");
  const [display_frame_rate, setDisplay_frame_rate] = useState("");
  const [display_frame_rateerror, setDisplay_frame_rateerror] = useState("");
  const [record_modes, setRecord_modes] = useState("");
  const [record_modeserror, setRecord_modeserror] = useState("");
  const [image_sensor, setImage_sensor] = useState("");
  const [image_sensorerror, setImage_sensorerror] = useState("");
  const [image_resolution, setImage_resolution] = useState("");
  const [image_resolutionerror, setImage_resolutionerror] = useState("");
  const [minimum_illumination, setMinimum_illumination] = useState("");
  const [minimum_illuminationerror, setMinimum_illuminationerror] =
    useState("");
  const [ir_distance, setIr_distance] = useState("");
  const [ir_distanceerror, setIr_distanceerror] = useState("");


  const [motin_triggered_recording, setMotin_triggered_recording] =
    useState("");
  const [motin_triggered_recordingerror, setMotin_triggered_recordingerror] =
    useState("");
  const [audio_output, setAudio_output] = useState("");
  const [audio_outputerror, setAudio_outputerror] = useState("");
  const [network_interface_time, setNetwork_interface_time] = useState("");
  const [network_interface_timeerror, setNetwork_interface_timeerror] =
    useState("");
  const [number_of_ethiernet_ports, setNumber_of_ethiernet_ports] =
    useState("");
  const [number_of_ethiernet_portserror, setNumber_of_ethiernet_portserror] =
    useState("");
  const [power_consumption, setPower_consumption] = useState("");
  const [power_consumptionerror, setPower_consumptionerror] = useState("");
  const [alarm_action, setAlarm_action] = useState("");
  const [alarm_actionerror, setAlarm_actionerror] = useState("");
  const [application_support, setApplication_support] = useState("");
  const [application_supporterror, setApplication_supporterror] = useState("");
  const [remote_connectivity, setRemote_connectivity] = useState("");
  const [remote_connectivityerror, setRemote_connectivityerror] = useState("");
  const [closure_mechanism, setClosure_mechanism] = useState("");
  const [closure_mechanismerror, setClosure_mechanismerror] = useState("");
  const [outer_material, setOuter_material] = useState("");
  const [outer_materialerror, setOuter_materialerror] = useState("");
  const [inner_height, setInner_height] = useState("");
  const [inner_heighterror, setInner_heighterror] = useState("");
  const [inner_width, setInner_width] = useState("");
  const [inner_widtherror, setInner_widtherror] = useState("");
  const [inner_depth, setInner_depth] = useState("");
  const [inner_deptherror, setInner_deptherror] = useState("");
  const [head_type, setHead_type] = useState("");
  const [head_typeerror, setHead_typeerror] = useState("");
  const [leg_lock_type, setLeg_lock_type] = useState("");
  const [leg_lock_typeerror, setLeg_lock_typeerror] = useState("");
  const [independent_leg_spread, setIndependent_leg_spread] = useState("");
  const [independent_leg_spreaderror, setIndependent_leg_spreaderror] =
    useState("");
  const [pack_of, setPack_of] = useState("");
  const [pack_oferror, setPack_oferror] = useState("");
  const [floded_length, setFloded_length] = useState("");
  const [floded_lengtherror, setFloded_lengtherror] = useState("");
  const [number_of_charging_cells, setNumber_of_charging_cells] = useState("");
  const [number_of_charging_cellserror, setNumber_of_charging_cellserror] =
    useState("");

  // Nishitha.........................🤞

  useEffect(() => {
    if (categoryTagid) {
      const getdata = async () => {
        const formData = {
          superCategoryId: supercategoryid,
          categoryId: categoryid,
          SubCategoryId: subcategoryid,
          CategoryTagId: categoryTagid,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          let distValfielda = [];
          for (let i = 0; i < filterShow.length; i++) {
            let element = filterShow[i];
            let obje2 = { field: element.field_Name, value: "" };
            distValfielda = [...distValfielda, obje2];
          }
          distValfielda = distValfielda;
          setdistinctfield(distinctNames);
          setdistinctfieldValidate(distValfielda);
        }
      };
      getdata();
    }
  }, [subcategoryid, categoryid, supercategoryid, categoryTagid]);






  useEffect(() => {
    if (resetield === true) {
    }
  }, [resetield]);

  const updateValues = (fieldname, value) => {
    const index = distinctfieldValidate.findIndex(
      (item) => item.field === fieldname
    );
    let updateValue = distinctfieldValidate;
    updateValue[index].value = value;

    setdistinctfieldValidate([...updateValue]);
  };
  useEffect(() => {
    if (buttonPress === true) {

      if (in_the_box === "") {
        setIn_the_boxerror("Required");
      } else {
        setIn_the_boxerror("");
      }

      if (model_number === "") {
        setModel_numbererror("Required");
      } else {
        setModel_numbererror("");
      }

      if (model_name === "") {
        setModel_nameerror("Required");
      } else {
        setModel_nameerror("");
      }

      if (color === "") {
        setColorerror("Required");
      } else {
        setColorerror("");
      }

      if (browse_type === "") {
        setBrowse_typeerror("Required");
      } else {
        setBrowse_typeerror("");
      }

      if (sim_type === "") {
        setSim_typeerror("Required");
      } else {
        setSim_typeerror("");
      }

      if (hybrid_sim_slot === "") {
        setHybrid_sim_sloterror("Required");
      } else {
        setHybrid_sim_sloterror("");
      }

      if (touchscreen === "") {
        setTouchscreenerror("Required");
      } else {
        setTouchscreenerror("");
      }

      if (otg_compatible === "") {
        setOtg_compatibleerror("Required");
      } else {
        setOtg_compatibleerror("");
      }

      if (quick_charging === "") {
        setQuick_chargingerror("Required");
      } else {
        setQuick_chargingerror("");
      }

      if (sar_value === "") {
        setSar_valueerror("Required");
      } else {
        setSar_valueerror("");
      }
      if (display_size === "") {
        setDisplay_sizeerror("Required");
      } else {
        setDisplay_sizeerror("");
      }

      if (resolution === "") {
        setResolutionerror("Required");
      } else {
        setResolutionerror("");
      }

      if (gpu === "") {
        setGpuerror("Required");
      } else {
        setGpuerror("");
      }

      if (display_type === "") {
        setDisplay_typeerror("Required");
      } else {
        setDisplay_typeerror("");
      }

      if (other_display_features === "") {
        setOther_display_featureserror("Required");
      } else {
        setOther_display_featureserror("");
      }

      if (operating_system === "") {
        setOperating_systemerror("Required");
      } else {
        setOperating_systemerror("");
      }

      if (processor_brand === "") {
        setProcessor_branderror("Required");
      } else {
        setProcessor_branderror("");
      }

      if (processor_type === "") {
        setProcessor_typeerror("Required");
      } else {
        setProcessor_typeerror("");
      }

      if (primary_clock_speed === "") {
        setPrimary_clock_speederror("Required");
      } else {
        setPrimary_clock_speederror("");
      }

      if (secondary_clock_speed === "") {
        setSecondary_clock_speederror("Required");
      } else {
        setSecondary_clock_speederror("");
      }

      if (tertiary_clock_speed === "") {
        setTertiary_clock_speederror("Required");
      } else {
        setTertiary_clock_speederror("");
      }

      if (operating_frequency === "") {
        setOperating_frequencyerror("Required");
      } else {
        setOperating_frequencyerror("");
      }

      if (internal_storage === "") {
        setInternal_storageerror("Required");
      } else {
        setInternal_storageerror("");
      }

      if (ram === "") {
        setRamerror("Required");
      } else {
        setRamerror("");
      }
      if (total_memory === "") {
        setTotal_memoryerror("Required");
      } else {
        setTotal_memoryerror("");
      }

      if (call_log_memory === "") {
        setCall_log_memoryerror("Required");
      } else {
        setCall_log_memoryerror("");
      }

      if (primary_camera_available === "") {
        setPrimary_camera_availableerror("Required");
      } else {
        setPrimary_camera_availableerror("");
      }

      if (primary_camera === "") {
        setPrimary_cameraerror("Required");
      } else {
        setPrimary_cameraerror("");
      }

      if (primary_camera_features === "") {
        setPrimary_camera_featureserror("Required");
      } else {
        setPrimary_camera_featureserror("");
      }

      if (secondary_camera_available === "") {
        setSecondary_camera_availableerror("Required");
      } else {
        setSecondary_camera_availableerror("");
      }

      if (secondary_camera === "") {
        setSecondary_cameraerror("Required");
      } else {
        setSecondary_cameraerror("");
      }

      if (secondary_camera_features === "") {
        setSecondary_camera_featureserror("Required");
      } else {
        setSecondary_camera_featureserror("");
      }

      if (flash === "") {
        setFlasherror("Required");
      } else {
        setFlasherror("");
      }

      if (hd_recording === "") {
        setHd_recordingerror("Required");
      } else {
        setHd_recordingerror("");
      }

      if (full_hd_recording === "") {
        setFull_hd_recordingerror("Required");
      } else {
        setFull_hd_recordingerror("");
      }

      if (video_recording === "") {
        setVideo_recordingerror("Required");
      } else {
        setVideo_recordingerror("");
      }

      if (video_recording_resolution === "") {
        setVideo_recording_resolutionerror("Required");
      } else {
        setVideo_recording_resolutionerror("");
      }

      if (digital_zoom === "") {
        setDigital_zoomerror("Required");
      } else {
        setDigital_zoomerror("");
      }

      if (frame_rate === "") {
        setFrame_rateerror("Required");
      } else {
        setFrame_rateerror("");
      }

      if (dual_camera_lens === "") {
        setDual_camera_lenserror("Required");
      } else {
        setDual_camera_lenserror("");
      }

      if (call_wait_or_hold === "") {
        setCall_wait_or_holderror("Required");
      } else {
        setCall_wait_or_holderror("");
      }

      if (conference_call === "") {
        setConference_callerror("Required");
      } else {
        setConference_callerror("");
      }

      if (hands_free === "") {
        setHands_freeerror("Required");
      } else {
        setHands_freeerror("");
      }

      if (call_divert === "") {
        setCall_diverterror("Required");
      } else {
        setCall_diverterror("");
      }

      if (phone_book === "") {
        setPhone_bookerror("Required");
      } else {
        setPhone_bookerror("");
      }

      if (call_timer === "") {
        setCall_timererror("Required");
      } else {
        setCall_timererror("");
      }

      if (speaker_phone === "") {
        setSpeaker_phoneerror("Required");
      } else {
        setSpeaker_phoneerror("");
      }

      if (call_records === "") {
        setCall_recordserror("Required");
      } else {
        setCall_recordserror("");
      }

      if (network_type === "") {
        setNetwork_typeerror("Required");
      } else {
        setNetwork_typeerror("");
      }

      if (supported_networks === "") {
        setSupported_networkserror("Required");
      } else {
        setSupported_networkserror("");
      }

      if (_3g === "") {
        set_3gerror("Required");
      } else {
        set_3gerror("");
      }

      if (gprs === "") {
        setGprserror("Required");
      } else {
        setGprserror("");
      }

      if (micro_usb_port === "") {
        setMicro_usb_porterror("Required");
      } else {
        setMicro_usb_porterror("");
      }

      if (micro_usb_version === "") {
        setMicro_usb_versionerror("Required");
      } else {
        setMicro_usb_versionerror("");
      }

      if (blutooth_support === "") {
        setBlutooth_supporterror("Required");
      } else {
        setBlutooth_supporterror("");
      }

      if (blutooth_version === "") {
        setBlutooth_versionerror("Required");
      } else {
        setBlutooth_versionerror("");
      }

      if (wifi === "") {
        setWifierror("Required");
      } else {
        setWifierror("");
      }

      if (wifi_version === "") {
        setWifi_versionerror("Required");
      } else {
        setWifi_versionerror("");
      }

      if (wifi_hotspot === "") {
        setWifi_hotspoterror("Required");
      } else {
        setWifi_hotspoterror("");
      }

      if (mini_hdmi_port === "") {
        setMini_hdmi_porterror("Required");
      } else {
        setMini_hdmi_porterror("");
      }

      if (nfc === "") {
        setNfcerror("Required");
      } else {
        setNfcerror("");
      }

      if (usb_tethering === "") {
        setUsb_tetheringerror("Required");
      } else {
        setUsb_tetheringerror("");
      }

      if (usb_connectivity === "") {
        setUsb_connectivityerror("Required");
      } else {
        setUsb_connectivityerror("");
      }

      if (map_support === "") {
        setMap_supporterror("Required");
      } else {
        setMap_supporterror("");
      }

      if (gps_support === "") {
        setGps_supporterror("Required");
      } else {
        setGps_supporterror("");
      }

      if (smartphone === "") {
        setSmartphoneerror("Required");
      } else {
        setSmartphoneerror("");
      }

      if (touchscreen_type === "") {
        setTouchscreen_typeerror("Required");
      } else {
        setTouchscreen_typeerror("");
      }

      if (sim_size === "") {
        setSim_sizeerror("Required");
      } else {
        setSim_sizeerror("");
      }

      if (user_interface === "") {
        setUser_interfaceerror("Required");
      } else {
        setUser_interfaceerror("");
      }

      if (instant_message === "") {
        setInstant_messageerror("Required");
      } else {
        setInstant_messageerror("");
      }

      if (removable_battery === "") {
        setRemovable_batteryerror("Required");
      } else {
        setRemovable_batteryerror("");
      }

      if (mms === "") {
        setMmserror("Required");
      } else {
        setMmserror("");
      }

      if (sms === "") {
        setSmserror("Required");
      } else {
        setSmserror("");
      }

      if (keypad === "") {
        setKeypaderror("Required");
      } else {
        setKeypaderror("");
      }

      if (voice_input === "") {
        setVoice_inputerror("Required");
      } else {
        setVoice_inputerror("");
      }

      if (predictive_text_input === "") {
        setPredictive_text_inputerror("Required");
      } else {
        setPredictive_text_inputerror("");
      }
      if (user_memory === "") {
        setUser_memoryerror("Required");
      } else {
        setUser_memoryerror("");
      }

      if (sensors === "") {
        setSensorserror("Required");
      } else {
        setSensorserror("");
      }

      if (browser === "") {
        setBrowsererror("Required");
      } else {
        setBrowsererror("");
      }

      if (other_features === "") {
        setOther_featureserror("Required");
      } else {
        setOther_featureserror("");
      }

      if (gps_type === "") {
        setGps_typeerror("Required");
      } else {
        setGps_typeerror("");
      }

      if (fm_radio === "") {
        setFm_radioerror("Required");
      } else {
        setFm_radioerror("");
      }

      if (fm_radio_recording === "") {
        setFm_radio_recordingerror("Required");
      } else {
        setFm_radio_recordingerror("");
      }

      if (battery_capacity === "") {
        setBattery_capacityerror("Required");
      } else {
        setBattery_capacityerror("");
      }

      if (battery_type === "") {
        setBattery_typeerror("Required");
      } else {
        setBattery_typeerror("");
      }

      if (width === "") {
        setWidtherror("Required");
      } else {
        setWidtherror("");
      }
      if (height === "") {
        setHeighterror("Required");
      } else {
        setHeighterror("");
      }

      if (depth === "") {
        setDeptherror("Required");
      } else {
        setDeptherror("");
      }

      if (weight === "") {
        setWeighterror("Required");
      } else {
        setWeighterror("");
      }

      if (warranty_summary === "") {
        setWarranty_summaryerror("Required");
      } else {
        setWarranty_summaryerror("");
      }

      if (domestic_warranty === "") {
        setDomestic_warrantyerror("Required");
      } else {
        setDomestic_warrantyerror("");
      }

      if (function_cooking === "") {
        setFunction_cookingerror("Required");
      } else {
        setFunction_cookingerror("");
      }

      if (steaming === "") {
        setSteamingerror("Required");
      } else {
        setSteamingerror("");
      }

      if (non_stick_cooking_bowl === "") {
        setNon_stick_cooking_bowlerror("Required");
      } else {
        setNon_stick_cooking_bowlerror("");
      }

      if (detachable_power_cord === "") {
        setDetachable_power_corderror("Required");
      } else {
        setDetachable_power_corderror("");
      }

      if (see_through_glass_top === "") {
        setSee_through_glass_toperror("Required");
      } else {
        setSee_through_glass_toperror("");
      }

      if (measuring_cup === "") {
        setMeasuring_cuperror("Required");
      } else {
        setMeasuring_cuperror("");
      }

      if (trivet === "") {
        setTriveterror("Required");
      } else {
        setTriveterror("");
      }

      if (temperature_glass_lid === "") {
        setTemperature_glass_liderror("Required");
      } else {
        setTemperature_glass_liderror("");
      }

      if (steaming_basket === "") {
        setSteaming_basketerror("Required");
      } else {
        setSteaming_basketerror("");
      }

      if (spatula === "") {
        setSpatulaerror("Required");
      } else {
        setSpatulaerror("");
      }

      if (cool_touch_handles === "") {
        setCool_touch_handleserror("Required");
      } else {
        setCool_touch_handleserror("");
      }

      if (thermal_fuse === "") {
        setThermal_fuseerror("Required");
      } else {
        setThermal_fuseerror("");
      }

      if (motor_speed === "") {
        setMotor_speederror("Required");
      } else {
        setMotor_speederror("");
      }

      if (number_of_stones === "") {
        setNumber_of_stoneserror("Required");
      } else {
        setNumber_of_stoneserror("");
      }

      if (attachment_present === "") {
        setAttachment_presenterror("Required");
      } else {
        setAttachment_presenterror("");
      }

      if (motor_overload_protection === "") {
        setMotor_overload_protectionerror("Required");
      } else {
        setMotor_overload_protectionerror("");
      }
      if (auto_off === "") {
        setAuto_offerror("Required");
      } else {
        setAuto_offerror("");
      }

      if (safety_lock === "") {
        setSafety_lockerror("Required");
      } else {
        setSafety_lockerror("");
      }

      if (processing_bocol === "") {
        setProcessing_bocolerror("Required");
      } else {
        setProcessing_bocolerror("");
      }

      if (processing_jar === "") {
        setProcessing_jarerror("Required");
      } else {
        setProcessing_jarerror("");
      }

      if (number_of_attached_jar === "") {
        setNumber_of_attached_jarerror("Required");
      } else {
        setNumber_of_attached_jarerror("");
      }

      if (anti_drip_value === "") {
        setAnti_drip_valueerror("Required");
      } else {
        setAnti_drip_valueerror("");
      }

      if (brew_strength_control === "") {
        setBrew_strength_controlerror("Required");
      } else {
        setBrew_strength_controlerror("");
      }

      if (removable_drip === "") {
        setRemovable_driperror("Required");
      } else {
        setRemovable_driperror("");
      }

      if (frother === "") {
        setFrothererror("Required");
      } else {
        setFrothererror("");
      }

      if (illuminated_on_off_switch === "") {
        setIlluminated_on_off_switcherror("Required");
      } else {
        setIlluminated_on_off_switcherror("");
      }

      if (hot_plate === "") {
        setHot_plateerror("Required");
      } else {
        setHot_plateerror("");
      }

      if (net_quantity === "") {
        setNet_quantityerror("Required");
      } else {
        setNet_quantityerror("");
      }

      if (brewing_time === "") {
        setBrewing_timeerror("Required");
      } else {
        setBrewing_timeerror("");
      }

      if (removable_filter === "") {
        setRemovable_filtererror("Required");
      } else {
        setRemovable_filtererror("");
      }

      if (other_dimensions === "") {
        setOther_dimensionserror("Required");
      } else {
        setOther_dimensionserror("");
      }

      if (filter_material === "") {
        setFilter_materialerror("Required");
      } else {
        setFilter_materialerror("");
      }

      if (temperature_control === "") {
        setTemperature_controlerror("Required");
      } else {
        setTemperature_controlerror("");
      }

      if (spray === "") {
        setSprayerror("Required");
      } else {
        setSprayerror("");
      }

      if (steam_burst === "") {
        setSteam_bursterror("Required");
      } else {
        setSteam_bursterror("");
      }

      if (self_cleaning === "") {
        setSelf_cleaningerror("Required");
      } else {
        setSelf_cleaningerror("");
      }
      if (swivel_cord === "") {
        setSwivel_corderror("Required");
      } else {
        setSwivel_corderror("");
      }

      if (electric_type === "") {
        setElectric_typeerror("Required");
      } else {
        setElectric_typeerror("");
      }

      if (purification_features === "") {
        setPurification_featureserror("Required");
      } else {
        setPurification_featureserror("");
      }

      if (detachable_storage_tank === "") {
        setDetachable_storage_tankerror("Required");
      } else {
        setDetachable_storage_tankerror("");
      }

      if (membrane_type === "") {
        setMembrane_typeerror("Required");
      } else {
        setMembrane_typeerror("");
      }

      if (overflow_protection === "") {
        setOverflow_protectionerror("Required");
      } else {
        setOverflow_protectionerror("");
      }

      if (filter_life === "") {
        setFilter_lifeerror("Required");
      } else {
        setFilter_lifeerror("");
      }

      if (alarms === "") {
        setAlarmserror("Required");
      } else {
        setAlarmserror("");
      }

      if (filtration_capacity === "") {
        setFiltration_capacityerror("Required");
      } else {
        setFiltration_capacityerror("");
      }

      if (purification_capacity === "") {
        setPurification_capacityerror("Required");
      } else {
        setPurification_capacityerror("");
      }

      if (cold_water_capacity === "") {
        setCold_water_capacityerror("Required");
      } else {
        setCold_water_capacityerror("");
      }

      if (cold_water_dispensor === "") {
        setCold_water_dispensorerror("Required");
      } else {
        setCold_water_dispensorerror("");
      }

      if (hot_water_capacity === "") {
        setHot_water_capacityerror("Required");
      } else {
        setHot_water_capacityerror("");
      }

      if (hot_water_dispensor === "") {
        setHot_water_dispensorerror("Required");
      } else {
        setHot_water_dispensorerror("");
      }

      if (purification_stages === "") {
        setPurification_stageserror("Required");
      } else {
        setPurification_stageserror("");
      }

      if (airflow === "") {
        setAirflowerror("Required");
      } else {
        setAirflowerror("");
      }

      if (blade_sweep === "") {
        setBlade_sweeperror("Required");
      } else {
        setBlade_sweeperror("");
      }

      if (number_of_speeds === "") {
        setNumber_of_speedserror("Required");
      } else {
        setNumber_of_speedserror("");
      }

      if (cooling_coverage_area === "") {
        setCooling_coverage_areaerror("Required");
      } else {
        setCooling_coverage_areaerror("");
      }

      if (speed_control === "") {
        setSpeed_controlerror("Required");
      } else {
        setSpeed_controlerror("");
      }

      if (operating_mode === "") {
        setOperating_modeerror("Required");
      } else {
        setOperating_modeerror("");
      }

      if (air_delivery === "") {
        setAir_deliveryerror("Required");
      } else {
        setAir_deliveryerror("");
      }

      if (air_deflection === "") {
        setAir_deflectionerror("Required");
      } else {
        setAir_deflectionerror("");
      }

      if (blower_material === "") {
        setBlower_materialerror("Required");
      } else {
        setBlower_materialerror("");
      }

      if (ice_chamber === "") {
        setIce_chambererror("Required");
      } else {
        setIce_chambererror("");
      }

      if (caston_wheels === "") {
        setCaston_wheelserror("Required");
      } else {
        setCaston_wheelserror("");
      }

      if (number_of_caston_wheels === "") {
        setNumber_of_caston_wheelserror("Required");
      } else {
        setNumber_of_caston_wheelserror("");
      }

      if (number_of_fan_blades === "") {
        setNumber_of_fan_bladeserror("Required");
      } else {
        setNumber_of_fan_bladeserror("");
      }

      if (auto_louver_movement === "") {
        setAuto_louver_movementerror("Required");
      } else {
        setAuto_louver_movementerror("");
      }

      if (oscillating_function === "") {
        setOscillating_functionerror("Required");
      } else {
        setOscillating_functionerror("");
      }
      if (technology_used === "") {
        setTechnology_usederror("Required");
      } else {
        setTechnology_usederror("");
      }

      if (blower === "") {
        setBlowererror("Required");
      } else {
        setBlowererror("");
      }

      if (motor_suction_power === "") {
        setMotor_suction_powererror("Required");
      } else {
        setMotor_suction_powererror("");
      }

      if (solar_power === "") {
        setSolar_powererror("Required");
      } else {
        setSolar_powererror("");
      }

      if (efficiency === "") {
        setEfficiencyerror("Required");
      } else {
        setEfficiencyerror("");
      }

      if (output_voltage === "") {
        setOutput_voltageerror("Required");
      } else {
        setOutput_voltageerror("");
      }

      if (launch_year === "") {
        setLaunch_yearerror("Required");
      } else {
        setLaunch_yearerror("");
      }

      if (deodarizer === "") {
        setDeodarizererror("Required");
      } else {
        setDeodarizererror("");
      }

      if (ice_cub_tray_type === "") {
        setIce_cub_tray_typeerror("Required");
      } else {
        setIce_cub_tray_typeerror("");
      }

      if (wheel_support === "") {
        setWheel_supporterror("Required");
      } else {
        setWheel_supporterror("");
      }

      if (removable_basket === "") {
        setRemovable_basketerror("Required");
      } else {
        setRemovable_basketerror("");
      }

      if (flexible_rack === "") {
        setFlexible_rackerror("Required");
      } else {
        setFlexible_rackerror("");
      }

      if (removable_rack === "") {
        setRemovable_rackerror("Required");
      } else {
        setRemovable_rackerror("");
      }

      if (brand === "") {
        setBranderror("Required");
      } else {
        setBranderror("");
      }

      if (capacity === "") {
        setCapacityerror("Required");
      } else {
        setCapacityerror("");
      }

      if (manufacturing_year === "") {
        setManufacturing_yearerror("Required");
      } else {
        setManufacturing_yearerror("");
      }

      if (cover_in_warranty === "") {
        setCover_in_warrantyerror("Required");
      } else {
        setCover_in_warrantyerror("");
      }

      if (not_cover_in_warranty === "") {
        setNot_cover_in_warrantyerror("Required");
      } else {
        setNot_cover_in_warrantyerror("");
      }

      if (warranty_service_type === "") {
        setWarranty_service_typeerror("Required");
      } else {
        setWarranty_service_typeerror("");
      }

      if (preheat === "") {
        setPreheaterror("Required");
      } else {
        setPreheaterror("");
      }

      if (timer === "") {
        setTimererror("Required");
      } else {
        setTimererror("");
      }

      if (auto_cook_menu_available === "") {
        setAuto_cook_menu_availableerror("Required");
      } else {
        setAuto_cook_menu_availableerror("");
      }

      if (power_output === "") {
        setPower_outputerror("Required");
      } else {
        setPower_outputerror("");
      }

      if (sales_package === "") {
        setSales_packageerror("Required");
      } else {
        setSales_packageerror("");
      }

      if (rotisserie === "") {
        setRotisserieerror("Required");
      } else {
        setRotisserieerror("");
      }

      if (rotisserie_type === "") {
        setRotisserie_typeerror("Required");
      } else {
        setRotisserie_typeerror("");
      }

      if (cavity_material === "") {
        setCavity_materialerror("Required");
      } else {
        setCavity_materialerror("");
      }

      if (temperature_control_range === "") {
        setTemperature_control_rangeerror("Required");
      } else {
        setTemperature_control_rangeerror("");
      }

      if (auto_menu === "") {
        setAuto_menuerror("Required");
      } else {
        setAuto_menuerror("");
      }

      if (number_of_auto_menu === "") {
        setNumber_of_auto_menuerror("Required");
      } else {
        setNumber_of_auto_menuerror("");
      }

      if (power_requirement === "") {
        setPower_requirementerror("Required");
      } else {
        setPower_requirementerror("");
      }

      if (material === "") {
        setMaterialerror("Required");
      } else {
        setMaterialerror("");
      }

      if (dry_grinding === "") {
        setDry_grindingerror("Required");
      } else {
        setDry_grindingerror("");
      }

      if (mincing === "") {
        setMincingerror("Required");
      } else {
        setMincingerror("");
      }

      if (chutey_grinding === "") {
        setChutey_grindingerror("Required");
      } else {
        setChutey_grindingerror("");
      }

      if (liguidizing_jar_capacity === "") {
        setLiguidizing_jar_capacityerror("Required");
      } else {
        setLiguidizing_jar_capacityerror("");
      }

      if (chatey_jar_capacity === "") {
        setChatey_jar_capacityerror("Required");
      } else {
        setChatey_jar_capacityerror("");
      }

      if (series === "") {
        setSerieserror("Required");
      } else {
        setSerieserror("");
      }

      if (locbable_lid === "") {
        setLocbable_liderror("Required");
      } else {
        setLocbable_liderror("");
      }

      if (dry_boil_protection === "") {
        setDry_boil_protectionerror("Required");
      } else {
        setDry_boil_protectionerror("");
      }

      if (auto_switch_off === "") {
        setAuto_switch_offerror("Required");
      } else {
        setAuto_switch_offerror("");
      }

      if (heating_element === "") {
        setHeating_elementerror("Required");
      } else {
        setHeating_elementerror("");
      }

      if (water_level_indicator === "") {
        setWater_level_indicatorerror("Required");
      } else {
        setWater_level_indicatorerror("");
      }

      if (card_length === "") {
        setCard_lengtherror("Required");
      } else {
        setCard_lengtherror("");
      }

      if (body_material === "") {
        setBody_materialerror("Required");
      } else {
        setBody_materialerror("");
      }

      if (control === "") {
        setControlerror("Required");
      } else {
        setControlerror("");
      }

      if (present_cooking_menu === "") {
        setPresent_cooking_menuerror("Required");
      } else {
        setPresent_cooking_menuerror("");
      }

      if (elements_on_cooktop === "") {
        setElements_on_cooktoperror("Required");
      } else {
        setElements_on_cooktoperror("");
      }

      if (other_body_and_design_features === "") {
        setOther_body_and_design_featureserror("Required");
      } else {
        setOther_body_and_design_featureserror("");
      }

      if (service_type === "") {
        setService_typeerror("Required");
      } else {
        setService_typeerror("");
      }

      if (installation_and_demo === "") {
        setInstallation_and_demoerror("Required");
      } else {
        setInstallation_and_demoerror("");
      }
      if (finish === "") {
        setFinisherror("Required");
      } else {
        setFinisherror("");
      }

      if (air_section_capacity === "") {
        setAir_section_capacityerror("Required");
      } else {
        setAir_section_capacityerror("");
      }

      if (ductless === "") {
        setDuctlesserror("Required");
      } else {
        setDuctlesserror("");
      }

      if (filter_type === "") {
        setFilter_typeerror("Required");
      } else {
        setFilter_typeerror("");
      }

      if (speed_levels === "") {
        setSpeed_levelserror("Required");
      } else {
        setSpeed_levelserror("");
      }

      if (filter_block_indicator === "") {
        setFilter_block_indicatorerror("Required");
      } else {
        setFilter_block_indicatorerror("");
      }

      if (noise_level === "") {
        setNoise_levelerror("Required");
      } else {
        setNoise_levelerror("");
      }

      if (phase === "") {
        setPhaseerror("Required");
      } else {
        setPhaseerror("");
      }

      if (engine_power === "") {
        setEngine_powererror("Required");
      } else {
        setEngine_powererror("");
      }

      if (in_built_oil_collector === "") {
        setIn_built_oil_collectorerror("Required");
      } else {
        setIn_built_oil_collectorerror("");
      }

      if (timer_present === "") {
        setTimer_presenterror("Required");
      } else {
        setTimer_presenterror("");
      }

      if (dblade_material === "") {
        setDblade_materialerror("Required");
      } else {
        setDblade_materialerror("");
      }

      if (blending === "") {
        setBlendingerror("Required");
      } else {
        setBlendingerror("");
      }

      if (pureing === "") {
        setPureingerror("Required");
      } else {
        setPureingerror("");
      }

      if (stirring === "") {
        setStirringerror("Required");
      } else {
        setStirringerror("");
      }

      if (safety_features === "") {
        setSafety_featureserror("Required");
      } else {
        setSafety_featureserror("");
      }

      if (model_let === "") {
        setModel_leterror("Required");
      } else {
        setModel_leterror("");
      }

      if (slice_capacity === "") {
        setSlice_capacityerror("Required");
      } else {
        setSlice_capacityerror("");
      }

      if (working_plate === "") {
        setWorking_plateerror("Required");
      } else {
        setWorking_plateerror("");
      }

      if (lid_lock === "") {
        setLid_lockerror("Required");
      } else {
        setLid_lockerror("");
      }

      if (no_of_slices === "") {
        setNo_of_sliceserror("Required");
      } else {
        setNo_of_sliceserror("");
      }

      if (wide_slots_for_thick_breads === "") {
        setWide_slots_for_thick_breadserror("Required");
      } else {
        setWide_slots_for_thick_breadserror("");
      }

      if (high_lift_for_small_breads === "") {
        setHigh_lift_for_small_breadserror("Required");
      } else {
        setHigh_lift_for_small_breadserror("");
      }

      if (auto_pop_up === "") {
        setAuto_pop_uperror("Required");
      } else {
        setAuto_pop_uperror("");
      }

      if (removable_crumbtroy === "") {
        setRemovable_crumbtroyerror("Required");
      } else {
        setRemovable_crumbtroyerror("");
      }

      if (toasting === "") {
        setToastingerror("Required");
      } else {
        setToastingerror("");
      }

      if (warming === "") {
        setWarmingerror("Required");
      } else {
        setWarmingerror("");
      }

      if (reheat === "") {
        setReheaterror("Required");
      } else {
        setReheaterror("");
      }

      if (variable_browing === "") {
        setVariable_browingerror("Required");
      } else {
        setVariable_browingerror("");
      }

      if (defrost === "") {
        setDefrosterror("Required");
      } else {
        setDefrosterror("");
      }

      if (card_storage === "") {
        setCard_storageerror("Required");
      } else {
        setCard_storageerror("");
      }

      if (cool_touch === "") {
        setCool_toucherror("Required");
      } else {
        setCool_toucherror("");
      }

      if (length === "") {
        setLengtherror("Required");
      } else {
        setLengtherror("");
      }

      if (capacity_incups === "") {
        setCapacity_incupserror("Required");
      } else {
        setCapacity_incupserror("");
      }

      if (total_pan_capacity === "") {
        setTotal_pan_capacityerror("Required");
      } else {
        setTotal_pan_capacityerror("");
      }

      if (functions === "") {
        setFunctionserror("Required");
      } else {
        setFunctionserror("");
      }

      if (material_lid === "") {
        setMaterial_liderror("Required");
      } else {
        setMaterial_liderror("");
      }

      if (net_quantity_product_details === "") {
        setNet_quantity_product_detailserror("Required");
      } else {
        setNet_quantity_product_detailserror("");
      }

      if (autokeep_warm_function_cooking === "") {
        setAutokeep_warm_function_cookingerror("Required");
      } else {
        setAutokeep_warm_function_cookingerror("");
      }

      if (output_frequency === "") {
        setOutput_frequencyerror("Required");
      } else {
        setOutput_frequencyerror("");
      }

      if (number_of_stitches === "") {
        setNumber_of_stitcheserror("Required");
      } else {
        setNumber_of_stitcheserror("");
      }

      if (number_of_button_holes_styles === "") {
        setNumber_of_button_holes_styleserror("Required");
      } else {
        setNumber_of_button_holes_styleserror("");
      }

      if (sewing_speed === "") {
        setSewing_speederror("Required");
      } else {
        setSewing_speederror("");
      }

      if (lcd_screen_display === "") {
        setLcd_screen_displayerror("Required");
      } else {
        setLcd_screen_displayerror("");
      }

      if (shape === "") {
        setShapeerror("Required");
      } else {
        setShapeerror("");
      }

      if (bobbin_winder === "") {
        setBobbin_windererror("Required");
      } else {
        setBobbin_windererror("");
      }

      if (stop_start_button === "") {
        setStop_start_buttonerror("Required");
      } else {
        setStop_start_buttonerror("");
      }

      if (stitch_out_time_indicator === "") {
        setStitch_out_time_indicatorerror("Required");
      } else {
        setStitch_out_time_indicatorerror("");
      }

      if (led_needle_light === "") {
        setLed_needle_lighterror("Required");
      } else {
        setLed_needle_lighterror("");
      }

      if (automatic_thread_cutter === "") {
        setAutomatic_thread_cuttererror("Required");
      } else {
        setAutomatic_thread_cuttererror("");
      }

      if (used_for === "") {
        setUsed_forerror("Required");
      } else {
        setUsed_forerror("");
      }

      if (generator_compatibility === "") {
        setGenerator_compatibilityerror("Required");
      } else {
        setGenerator_compatibilityerror("");
      }

      if (cooling === "") {
        setCoolingerror("Required");
      } else {
        setCoolingerror("");
      }

      if (mount_type === "") {
        setMount_typeerror("Required");
      } else {
        setMount_typeerror("");
      }

      if (minimum_operating === "") {
        setMinimum_operatingerror("Required");
      } else {
        setMinimum_operatingerror("");
      }

      if (under_vlot_protection === "") {
        setUnder_vlot_protectionerror("Required");
      } else {
        setUnder_vlot_protectionerror("");
      }

      if (over_vlot_protection === "") {
        setOver_vlot_protectionerror("Required");
      } else {
        setOver_vlot_protectionerror("");
      }

      if (overload_protection === "") {
        setOverload_protectionerror("Required");
      } else {
        setOverload_protectionerror("");
      }

      if (minimum_input_power === "") {
        setMinimum_input_powererror("Required");
      } else {
        setMinimum_input_powererror("");
      }

      if (suitable_for === "") {
        setSuitable_forerror("Required");
      } else {
        setSuitable_forerror("");
      }

      if (rated_pressure === "") {
        setRated_pressureerror("Required");
      } else {
        setRated_pressureerror("");
      }
      if (star_rating === "") {
        setStar_ratingerror("Required");
      } else {
        setStar_ratingerror("");
      }

      if (thermostot === "") {
        setThermostoterror("Required");
      } else {
        setThermostoterror("");
      }

      if (planting === "") {
        setPlantingerror("Required");
      } else {
        setPlantingerror("");
      }

      if (heater_material === "") {
        setHeater_materialerror("Required");
      } else {
        setHeater_materialerror("");
      }

      if (heating_element_type === "") {
        setHeating_element_typeerror("Required");
      } else {
        setHeating_element_typeerror("");
      }

      if (washing_method === "") {
        setWashing_methoderror("Required");
      } else {
        setWashing_methoderror("");
      }

      if (in_built_heater === "") {
        setIn_built_heatererror("Required");
      } else {
        setIn_built_heatererror("");
      }

      if (tub_material === "") {
        setTub_materialerror("Required");
      } else {
        setTub_materialerror("");
      }

      if (digital_display === "") {
        setDigital_displayerror("Required");
      } else {
        setDigital_displayerror("");
      }

      if (water_level_selector === "") {
        setWater_level_selectorerror("Required");
      } else {
        setWater_level_selectorerror("");
      }

      if (wash_cycle_duration === "") {
        setWash_cycle_durationerror("Required");
      } else {
        setWash_cycle_durationerror("");
      }

      if (spin_cycle_duration === "") {
        setSpin_cycle_durationerror("Required");
      } else {
        setSpin_cycle_durationerror("");
      }

      if (inverter === "") {
        setInvertererror("Required");
      } else {
        setInvertererror("");
      }

      if (steam === "") {
        setSteamerror("Required");
      } else {
        setSteamerror("");
      }

      if (quick_wash === "") {
        setQuick_washerror("Required");
      } else {
        setQuick_washerror("");
      }

      if (lint_filter === "") {
        setLint_filtererror("Required");
      } else {
        setLint_filtererror("");
      }

      if (child_lock === "") {
        setChild_lockerror("Required");
      } else {
        setChild_lockerror("");
      }

      if (wash_program_types === "") {
        setWash_program_typeserror("Required");
      } else {
        setWash_program_typeserror("");
      }

      if (compressor === "") {
        setCompressorerror("Required");
      } else {
        setCompressorerror("");
      }

      if (dehumidification === "") {
        setDehumidificationerror("Required");
      } else {
        setDehumidificationerror("");
      }

      if (remote_control === "") {
        setRemote_controlerror("Required");
      } else {
        setRemote_controlerror("");
      }

      if (operating_modes === "") {
        setOperating_modeserror("Required");
      } else {
        setOperating_modeserror("");
      }

      if (condenser_coil === "") {
        setCondenser_coilerror("Required");
      } else {
        setCondenser_coilerror("");
      }

      if (indoor_w_h_d === "") {
        setIndoor_w_h_derror("Required");
      } else {
        setIndoor_w_h_derror("");
      }

      if (indoor_unit_weight === "") {
        setIndoor_unit_weighterror("Required");
      } else {
        setIndoor_unit_weighterror("");
      }

      if (auto_air_swing === "") {
        setAuto_air_swingerror("Required");
      } else {
        setAuto_air_swingerror("");
      }

      if (air_flow_direction === "") {
        setAir_flow_directionerror("Required");
      } else {
        setAir_flow_directionerror("");
      }

      if (anti_bacteria_filter === "") {
        setAnti_bacteria_filtererror("Required");
      } else {
        setAnti_bacteria_filtererror("");
      }

      if (dust_filter === "") {
        setDust_filtererror("Required");
      } else {
        setDust_filtererror("");
      }

      if (auto_restart === "") {
        setAuto_restarterror("Required");
      } else {
        setAuto_restarterror("");
      }

      if (quit_mode === "") {
        setQuit_modeerror("Required");
      } else {
        setQuit_modeerror("");
      }

      if (sleep_mode === "") {
        setSleep_modeerror("Required");
      } else {
        setSleep_modeerror("");
      }

      if (memory_feature === "") {
        setMemory_featureerror("Required");
      } else {
        setMemory_featureerror("");
      }

      if (self_diagosis === "") {
        setSelf_diagosiserror("Required");
      } else {
        setSelf_diagosiserror("");
      }
      if (electricity_consumption === "") {
        setElectricity_consumptionerror("Required");
      } else {
        setElectricity_consumptionerror("");
      }

      if (defrosting_type === "") {
        setDefrosting_typeerror("Required");
      } else {
        setDefrosting_typeerror("");
      }

      if (number_of_doors === "") {
        setNumber_of_doorserror("Required");
      } else {
        setNumber_of_doorserror("");
      }

      if (coolpad === "") {
        setCoolpaderror("Required");
      } else {
        setCoolpaderror("");
      }

      if (build_in_stabilizer === "") {
        setBuild_in_stabilizererror("Required");
      } else {
        setBuild_in_stabilizererror("");
      }

      if (toughened_glass === "") {
        setToughened_glasserror("Required");
      } else {
        setToughened_glasserror("");
      }

      if (moisture_control === "") {
        setMoisture_controlerror("Required");
      } else {
        setMoisture_controlerror("");
      }

      if (design === "") {
        setDesignerror("Required");
      } else {
        setDesignerror("");
      }

      if (door_finish === "") {
        setDoor_finisherror("Required");
      } else {
        setDoor_finisherror("");
      }

      if (door_lock === "") {
        setDoor_lockerror("Required");
      } else {
        setDoor_lockerror("");
      }

      if (water_ice_dispenser === "") {
        setWater_ice_dispensererror("Required");
      } else {
        setWater_ice_dispensererror("");
      }

      if (stabilizer_required === "") {
        setStabilizer_requirederror("Required");
      } else {
        setStabilizer_requirederror("");
      }

      if (egg_tray === "") {
        setEgg_trayerror("Required");
      } else {
        setEgg_trayerror("");
      }

      if (headphone_type === "") {
        setHeadphone_typeerror("Required");
      } else {
        setHeadphone_typeerror("");
      }

      if (inline_remote === "") {
        setInline_remoteerror("Required");
      } else {
        setInline_remoteerror("");
      }

      if (connectivity === "") {
        setConnectivityerror("Required");
      } else {
        setConnectivityerror("");
      }

      if (headphone_design === "") {
        setHeadphone_designerror("Required");
      } else {
        setHeadphone_designerror("");
      }

      if (foldable_or_collapsible === "") {
        setFoldable_or_collapsibleerror("Required");
      } else {
        setFoldable_or_collapsibleerror("");
      }

      if (deep_bass === "") {
        setDeep_basserror("Required");
      } else {
        setDeep_basserror("");
      }

      if (manual === "") {
        setManualerror("Required");
      } else {
        setManualerror("");
      }

      if (magnet_type === "") {
        setMagnet_typeerror("Required");
      } else {
        setMagnet_typeerror("");
      }

      if (driver_type === "") {
        setdriver_typeerror("Required");
      } else {
        setdriver_typeerror("");
      }

      if (indicators === "") {
        setIndicatorserror("Required");
      } else {
        setIndicatorserror("");
      }

      if (sensitivity === "") {
        setsensitivityerror("Required");
      } else {
        setsensitivityerror("");
      }
      if (impedance === "") {
        setImpedanceerror("Required");
      } else {
        setImpedanceerror("");
      }

      if (signal_to_noise_ratio === "") {
        setSignal_to_noise_ratioerror("Required");
      } else {
        setSignal_to_noise_ratioerror("");
      }

      if (modulation === "") {
        setModulationerror("Required");
      } else {
        setModulationerror("");
      }

      if (minimum_frequency_response === "") {
        setMinimum_frequency_responseerror("Required");
      } else {
        setMinimum_frequency_responseerror("");
      }

      if (other_sound_features === "") {
        setOther_sound_featureserror("Required");
      } else {
        setOther_sound_featureserror("");
      }

      if (audio_codec === "") {
        setAudio_codecerror("Required");
      } else {
        setAudio_codecerror("");
      }

      if (blutooth_profile === "") {
        setBlutooth_profileerror("Required");
      } else {
        setBlutooth_profileerror("");
      }

      if (blutooth_range === "") {
        setBlutooth_rangeerror("Required");
      } else {
        setBlutooth_rangeerror("");
      }

      if (headphone_power_source === "") {
        setHeadphone_power_sourceerror("Required");
      } else {
        setHeadphone_power_sourceerror("");
      }

      if (power_supply === "") {
        setPower_supplyerror("Required");
      } else {
        setPower_supplyerror("");
      }

      if (battery_life === "") {
        setBattery_lifeerror("Required");
      } else {
        setBattery_lifeerror("");
      }

      if (stand_by_time === "") {
        setStand_by_timeerror("Required");
      } else {
        setStand_by_timeerror("");
      }

      if (applied === "") {
        setAppliederror("Required");
      } else {
        setAppliederror("");
      }

      if (number_of_layers === "") {
        setNumber_of_layerserror("Required");
      } else {
        setNumber_of_layerserror("");
      }

      if (in_rescidue_free_removal === "") {
        setIn_rescidue_free_removalerror("Required");
      } else {
        setIn_rescidue_free_removalerror("");
      }

      if (tinted === "") {
        setTintederror("Required");
      } else {
        setTintederror("");
      }

      if (camera_hole_present === "") {
        setcamera_hole_presenterror("Required");
      } else {
        setcamera_hole_presenterror("");
      }

      if (usage === "") {
        setUsageerror("Required");
      } else {
        setUsageerror("");
      }

      if (operating_system_supported === "") {
        setOperating_system_supportederror("Required");
      } else {
        setOperating_system_supportederror("");
      }

      if (type_of_tracking === "") {
        setType_of_trackingerror("Required");
      } else {
        setType_of_trackingerror("");
      }

      if (auto_trasperancy === "") {
        setAuto_trasperancyerror("Required");
      } else {
        setAuto_trasperancyerror("");
      }

      if (display === "") {
        setDisplayerror("Required");
      } else {
        setDisplayerror("");
      }

      if (wireless_data_processing === "") {
        setWireless_data_processingerror("Required");
      } else {
        setWireless_data_processingerror("");
      }

      if (one_touch_button === "") {
        setOne_touch_buttonerror("Required");
      } else {
        setOne_touch_buttonerror("");
      }

      if (touch_control === "") {
        setTouch_controlerror("Required");
      } else {
        setTouch_controlerror("");
      }

      if (battery_included === "") {
        setBattery_includederror("Required");
      } else {
        setBattery_includederror("");
      }

      if (battery_chargable === "") {
        setBattery_chargableerror("Required");
      } else {
        setBattery_chargableerror("");
      }

      if (water_resistance === "") {
        setWater_resistanceerror("Required");
      } else {
        setWater_resistanceerror("");
      }

      if (noise_concellation === "") {
        setNoise_concellationerror("Required");
      } else {
        setNoise_concellationerror("");
      }

      if (number_of_contents_in_sales_pack === "") {
        setNumber_of_contents_in_sales_packerror("Required");
      } else {
        setNumber_of_contents_in_sales_packerror("");
      }

      if (cable_type === "") {
        setCable_typeerror("Required");
      } else {
        setCable_typeerror("");
      }

      if (connector1 === "") {
        setConnector1error("Required");
      } else {
        setConnector1error("");
      }

      if (connector2 === "") {
        setConnector2error("Required");
      } else {
        setConnector2error("");
      }

      if (cable === "") {
        setCableerror("Required");
      } else {
        setCableerror("");
      }

      if (compatible_devices === "") {
        setCompatible_deviceserror("Required");
      } else {
        setCompatible_deviceserror("");
      }

      if (cable_length === "") {
        setCable_lengtherror("Required");
      } else {
        setCable_lengtherror("");
      }

      if (keyboard === "") {
        setKeyboarderror("Required");
      } else {
        setKeyboarderror("");
      }

      if (security_chip === "") {
        setSecurity_chiperror("Required");
      } else {
        setSecurity_chiperror("");
      }

      if (web_camera === "") {
        setWeb_cameraerror("Required");
      } else {
        setWeb_cameraerror("");
      }

      if (finger_print_sensor === "") {
        setFinger_print_sensorerror("Required");
      } else {
        setFinger_print_sensorerror("");
      }

      if (processor_variant === "") {
        setProcessor_varianterror("Required");
      } else {
        setProcessor_varianterror("");
      }

      if (cache === "") {
        setCacheerror("Required");
      } else {
        setCacheerror("");
      }

      if (number_of_cores === "") {
        setNumber_of_coreserror("Required");
      } else {
        setNumber_of_coreserror("");
      }

      if (os_architecture === "") {
        setOs_architectureerror("Required");
      } else {
        setOs_architectureerror("");
      }

      if (mic_in === "") {
        setMic_inerror("Required");
      } else {
        setMic_inerror("");
      }
      if (rj45 === "") {
        setRj45error("Required");
      } else {
        setRj45error("");
      }

      if (multi_card_slot === "") {
        setMulti_card_sloterror("Required");
      } else {
        setMulti_card_sloterror("");
      }

      if (hardware_interface === "") {
        setHardware_interfaceerror("Required");
      } else {
        setHardware_interfaceerror("");
      }

      if (disk_drive === "") {
        setDisk_driveerror("Required");
      } else {
        setDisk_driveerror("");
      }

      if (connector_type === "") {
        setConnector_typeerror("Required");
      } else {
        setConnector_typeerror("");
      }

      if (number_of_devices === "") {
        setNumber_of_deviceserror("Required");
      } else {
        setNumber_of_deviceserror("");
      }

      if (number_of_charger_pins === "") {
        setNumber_of_charger_pinserror("Required");
      } else {
        setNumber_of_charger_pinserror("");
      }

      if (output_current === "") {
        setOutput_currenterror("Required");
      } else {
        setOutput_currenterror("");
      }

      if (output_wattage === "") {
        setOutput_wattageerror("Required");
      } else {
        setOutput_wattageerror("");
      }

      if (compatible_with === "") {
        setCompatible_witherror("Required");
      } else {
        setCompatible_witherror("");
      }

      if (flodable === "") {
        setFlodableerror("Required");
      } else {
        setFlodableerror("");
      }

      if (rpm === "") {
        setRpmerror("Required");
      } else {
        setRpmerror("");
      }

      if (graphic_processor === "") {
        setGraphic_processorerror("Required");
      } else {
        setGraphic_processorerror("");
      }

      if (battery_backup === "") {
        setBattery_backuperror("Required");
      } else {
        setBattery_backuperror("");
      }

      if (battery_cell === "") {
        setBattery_cellerror("Required");
      } else {
        setBattery_cellerror("");
      }

      if (ms_office_provided === "") {
        setMs_office_providederror("Required");
      } else {
        setMs_office_providederror("");
      }

      if (processor_name === "") {
        setProcessor_nameerror("Required");
      } else {
        setProcessor_nameerror("");
      }

      if (processor_generation === "") {
        setProcessor_generationerror("Required");
      } else {
        setProcessor_generationerror("");
      }

      if (ram_type === "") {
        setRam_typeerror("Required");
      } else {
        setRam_typeerror("");
      }

      if (hdd_capacity === "") {
        setHdd_capacityerror("Required");
      } else {
        setHdd_capacityerror("");
      }

      if (output_interface === "") {
        setOutput_interfaceerror("Required");
      } else {
        setOutput_interfaceerror("");
      }

      if (maximum_current_rating === "") {
        setMaximum_current_ratingerror("Required");
      } else {
        setMaximum_current_ratingerror("");
      }

      if (other_material === "") {
        setOther_materialerror("Required");
      } else {
        setOther_materialerror("");
      }

      if (pointer_device === "") {
        setPointer_deviceerror("Required");
      } else {
        setPointer_deviceerror("");
      }

      if (emmc_storage_capacity === "") {
        setEmmc_storage_capacityerror("Required");
      } else {
        setEmmc_storage_capacityerror("");
      }

      if (ram_frequency === "") {
        setRam_frequencyerror("Required");
      } else {
        setRam_frequencyerror("");
      }

      if (screen_size === "") {
        setScreen_sizeerror("Required");
      } else {
        setScreen_sizeerror("");
      }

      if (screen_type === "") {
        setScreen_typeerror("Required");
      } else {
        setScreen_typeerror("");
      }

      if (speakers === "") {
        setSpeakerserror("Required");
      } else {
        setSpeakerserror("");
      }

      if (internal_mic === "") {
        setInternal_micerror("Required");
      } else {
        setInternal_micerror("");
      }

      if (wireless_lan === "") {
        setWireless_lanerror("Required");
      } else {
        setWireless_lanerror("");
      }

      if (dedicated_graphic_memory_type === "") {
        setDedicated_graphic_memory_typeerror("Required");
      } else {
        setDedicated_graphic_memory_typeerror("");
      }

      if (dedicated_graphic_memory_capacity === "") {
        setDedicated_graphic_memory_capacityerror("Required");
      } else {
        setDedicated_graphic_memory_capacityerror("");
      }

      if (ethernet === "") {
        setEtherneterror("Required");
      } else {
        setEtherneterror("");
      }

      if (backlit_keyboard === "") {
        setBacklit_keyboarderror("Required");
      } else {
        setBacklit_keyboarderror("");
      }

      if (memory_slot === "") {
        setMemory_sloterror("Required");
      } else {
        setMemory_sloterror("");
      }

      if (expandle_memory === "") {
        setExpandle_memoryerror("Required");
      } else {
        setExpandle_memoryerror("");
      }

      if (sound_properties === "") {
        setSound_propertieserror("Required");
      } else {
        setSound_propertieserror("");
      }

      if (lock_port === "") {
        setLock_porterror("Required");
      } else {
        setLock_porterror("");
      }

      if (operating_system_version === "") {
        setOperating_system_versionerror("Required");
      } else {
        setOperating_system_versionerror("");
      }
      if (voice_call === "") {
        setVoice_callerror("Required");
      } else {
        setVoice_callerror("");
      }

      if (display_resolution_time === "") {
        setDisplay_resolution_timeerror("Required");
      } else {
        setDisplay_resolution_timeerror("");
      }

      if (display_support_network === "") {
        setDisplay_support_networkerror("Required");
      } else {
        setDisplay_support_networkerror("");
      }

      if (display_resolution === "") {
        setDisplay_resolutionerror("Required");
      } else {
        setDisplay_resolutionerror("");
      }

      if (processor_speed === "") {
        setProcessor_speederror("Required");
      } else {
        setProcessor_speederror("");
      }

      if (headphone_jack === "") {
        setHeadphone_jackerror("Required");
      } else {
        setHeadphone_jackerror("");
      }

      if (expandable_storage_capacity === "") {
        setExpandable_storage_capacityerror("Required");
      } else {
        setExpandable_storage_capacityerror("");
      }

      if (memory_card_slot_type === "") {
        setMemory_card_slot_typeerror("Required");
      } else {
        setMemory_card_slot_typeerror("");
      }

      if (memory_card_support === "") {
        setMemory_card_supporterror("Required");
      } else {
        setMemory_card_supporterror("");
      }

      if (warranty_period === "") {
        setWarranty_perioderror("Required");
      } else {
        setWarranty_perioderror("");
      }

      if (sound_enhancements === "") {
        setSound_enhancementserror("Required");
      } else {
        setSound_enhancementserror("");
      }

      if (optical_zoom === "") {
        setOptical_zoomerror("Required");
      } else {
        setOptical_zoomerror("");
      }

      if (video_call_support === "") {
        setVideo_call_supporterror("Required");
      } else {
        setVideo_call_supporterror("");
      }

      if (graphicsppl === "") {
        setGraphicspplerror("Required");
      } else {
        setGraphicspplerror("");
      }

      if (supported_languages === "") {
        setSupported_languageserror("Required");
      } else {
        setSupported_languageserror("");
      }

      if (important_apps === "") {
        setImportant_appserror("Required");
      } else {
        setImportant_appserror("");
      }

      if (audio_formats === "") {
        setAudio_formatserror("Required");
      } else {
        setAudio_formatserror("");
      }

      if (video_formats === "") {
        setVideo_formatserror("Required");
      } else {
        setVideo_formatserror("");
      }

      if (ideal_for === "") {
        setIdeal_forerror("Required");
      } else {
        setIdeal_forerror("");
      }

      if (ideal_usage === "") {
        setIdeal_usageerror("Required");
      } else {
        setIdeal_usageerror("");
      }

      if (loptop_sleeve === "") {
        setLoptop_sleeveerror("Required");
      } else {
        setLoptop_sleeveerror("");
      }

      if (with_rain_cover === "") {
        setWith_rain_covererror("Required");
      } else {
        setWith_rain_covererror("");
      }

      if (compatibility_loptop_size === "") {
        setCompatibility_loptop_sizeerror("Required");
      } else {
        setCompatibility_loptop_sizeerror("");
      }

      if (style_code === "") {
        setStyle_codeerror("Required");
      } else {
        setStyle_codeerror("");
      }

      if (color_code === "") {
        setColor_codeerror("Required");
      } else {
        setColor_codeerror("");
      }

      if (material_water_proof === "") {
        setMaterial_water_prooferror("Required");
      } else {
        setMaterial_water_prooferror("");
      }

      if (number_of_compartments === "") {
        setNumber_of_compartmentserror("Required");
      } else {
        setNumber_of_compartmentserror("");
      }

      if (number_of_packets === "") {
        setNumber_of_packetserror("Required");
      } else {
        setNumber_of_packetserror("");
      }

      if (configuration === "") {
        setConfigurationerror("Required");
      } else {
        setConfigurationerror("");
      }

      if (frequency_response === "") {
        setFrequency_responseerror("Required");
      } else {
        setFrequency_responseerror("");
      }

      if (built_in_fm_radio === "") {
        setBuilt_in_fm_radioerror("Required");
      } else {
        setBuilt_in_fm_radioerror("");
      }

      if (certifications === "") {
        setCertificationserror("Required");
      } else {
        setCertificationserror("");
      }

      if (charging_time === "") {
        setCharging_timeerror("Required");
      } else {
        setCharging_timeerror("");
      }

      if (cable_interface === "") {
        setCable_interfaceerror("Required");
      } else {
        setCable_interfaceerror("");
      }

      if (platform === "") {
        setPlatformerror("Required");
      } else {
        setPlatformerror("");
      }

      if (read_speed === "") {
        setRead_speederror("Required");
      } else {
        setRead_speederror("");
      }

      if (write_speed === "") {
        setWrite_speederror("Required");
      } else {
        setWrite_speederror("");
      }

      if (system_requirements === "") {
        setSystem_requirementserror("Required");
      } else {
        setSystem_requirementserror("");
      }

      if (form_factor === "") {
        setForm_factorerror("Required");
      } else {
        setForm_factorerror("");
      }

      if (cloud_backup === "") {
        setCloud_backuperror("Required");
      } else {
        setCloud_backuperror("");
      }

      if (data_tranfer_rate === "") {
        setData_tranfer_rateerror("Required");
      } else {
        setData_tranfer_rateerror("");
      }

      if (rotational_speed === "") {
        setRotational_speederror("Required");
      } else {
        setRotational_speederror("");
      }

      if (password_protection === "") {
        setPassword_protectionerror("Required");
      } else {
        setPassword_protectionerror("");
      }

      if (power_source === "") {
        setPower_sourceerror("Required");
      } else {
        setPower_sourceerror("");
      }

      if (size === "") {
        setSizeerror("Required");
      } else {
        setSizeerror("");
      }

      if (shock_resistant === "") {
        setShock_resistanterror("Required");
      } else {
        setShock_resistanterror("");
      }
      if (water_proof === "") {
        setWater_prooferror("Required");
      } else {
        setWater_prooferror("");
      }

      if (led_indicator === "") {
        setLed_indicatorerror("Required");
      } else {
        setLed_indicatorerror("");
      }

      if (operating_range === "") {
        setOperating_rangeerror("Required");
      } else {
        setOperating_rangeerror("");
      }

      if (frequency === "") {
        setFrequencyerror("Required");
      } else {
        setFrequencyerror("");
      }

      if (frequency_band === "") {
        setFrequency_banderror("Required");
      } else {
        setFrequency_banderror("");
      }

      if (wireless_speed === "") {
        setWireless_speederror("Required");
      } else {
        setWireless_speederror("");
      }

      if (dsl_modern_speed === "") {
        setDsl_modern_speederror("Required");
      } else {
        setDsl_modern_speederror("");
      }

      if (lan_wan === "") {
        setLan_wanerror("Required");
      } else {
        setLan_wanerror("");
      }

      if (antennae === "") {
        setAntennaeerror("Required");
      } else {
        setAntennaeerror("");
      }

      if (number_of_usb_ports === "") {
        setNumber_of_usb_portserror("Required");
      } else {
        setNumber_of_usb_portserror("");
      }

      if (lan === "") {
        setLanerror("Required");
      } else {
        setLanerror("");
      }

      if (no_memory_effect === "") {
        setNo_memory_effecterror("Required");
      } else {
        setNo_memory_effecterror("");
      }

      if (maximum_shelf_life === "") {
        setMaximum_shelf_lifeerror("Required");
      } else {
        setMaximum_shelf_lifeerror("");
      }

      if (magnification === "") {
        setMagnificationerror("Required");
      } else {
        setMagnificationerror("");
      }

      if (apparent_field_of_view === "") {
        setApparent_field_of_viewerror("Required");
      } else {
        setApparent_field_of_viewerror("");
      }

      // For the new set of states

      if (hd_resolution === "") {
        setHd_resolutionerror("Required");
      } else {
        setHd_resolutionerror("");
      }

      if (smart_tv === "") {
        setSmart_tverror("Required");
      } else {
        setSmart_tverror("");
      }

      if (hdmi === "") {
        setHdmierror("Required");
      } else {
        setHdmierror("");
      }

      if (usb === "") {
        setUsberror("Required");
      } else {
        setUsberror("");
      }

      if (wifi_type === "") {
        setWifi_typeerror("Required");
      } else {
        setWifi_typeerror("");
      }

      if (built_in_wifi === "") {
        setBuilt_in_wifierror("Required");
      } else {
        setBuilt_in_wifierror("");
      }

      if (wall_mount_included === "") {
        setWall_mount_includederror("Required");
      } else {
        setWall_mount_includederror("");
      }

      if (picture_in_picture === "") {
        setPicture_in_pictureerror("Required");
      } else {
        setPicture_in_pictureerror("");
      }

      if (picture_engine === "") {
        setPicture_engineerror("Required");
      } else {
        setPicture_engineerror("");
      }

      if (analog_tv_reception === "") {
        setAnalog_tv_receptionerror("Required");
      } else {
        setAnalog_tv_receptionerror("");
      }

      if (view_angle === "") {
        setView_angleerror("Required");
      } else {
        setView_angleerror("");
      }

      if (panel_type === "") {
        setPanel_typeerror("Required");
      } else {
        setPanel_typeerror("");
      }

      if (digital_noise_filter === "") {
        setDigital_noise_filtererror("Required");
      } else {
        setDigital_noise_filtererror("");
      }

      if (led_display_type === "") {
        setLed_display_typeerror("Required");
      } else {
        setLed_display_typeerror("");
      }

      if (aspect_ratio === "") {
        setAspect_ratioerror("Required");
      } else {
        setAspect_ratioerror("");
      }

      if (refresh_rate === "") {
        setRefresh_rateerror("Required");
      } else {
        setRefresh_rateerror("");
      }

      if (number_of_speakers === "") {
        setNumber_of_speakerserror("Required");
      } else {
        setNumber_of_speakerserror("");
      }
      if (speaker_type === "") {
        setSpeaker_typeerror("Required");
      } else {
        setSpeaker_typeerror("");
      }

      if (sound_technology === "") {
        setSound_technologyerror("Required");
      } else {
        setSound_technologyerror("");
      }

      if (surround_sound === "") {
        setSurround_sounderror("Required");
      } else {
        setSurround_sounderror("");
      }

      if (speaker_output_rms === "") {
        setSpeaker_output_rmserror("Required");
      } else {
        setSpeaker_output_rmserror("");
      }

      if (sound_mode === "") {
        setSound_modeerror("Required");
      } else {
        setSound_modeerror("");
      }

      if (supported_audio_features === "") {
        setSupported_audio_featureserror("Required");
      } else {
        setSupported_audio_featureserror("");
      }

      if (supported_app_netflix === "") {
        setSupported_app_netflixerror("Required");
      } else {
        setSupported_app_netflixerror("");
      }

      if (supported_app_youtube === "") {
        setSupported_app_youtubeerror("Required");
      } else {
        setSupported_app_youtubeerror("");
      }

      if (supported_app_disney_hotstar === "") {
        setSupported_app_disney_hotstarerror("Required");
      } else {
        setSupported_app_disney_hotstarerror("");
      }

      if (supported_app_prime_video === "") {
        setSupported_app_prime_videoerror("Required");
      } else {
        setSupported_app_prime_videoerror("");
      }

      if (supported_mobile_operating_system === "") {
        setSupported_mobile_operating_systemerror("Required");
      } else {
        setSupported_mobile_operating_systemerror("");
      }

      if (operating_system_present === "") {
        setOperating_system_presenterror("Required");
      } else {
        setOperating_system_presenterror("");
      }

      if (screen_mirroring === "") {
        setScreen_mirroringerror("Required");
      } else {
        setScreen_mirroringerror("");
      }

      if (app_store_type === "") {
        setApp_store_typeerror("Required");
      } else {
        setApp_store_typeerror("");
      }

      if (pre_installed_browser === "") {
        setPre_installed_browsererror("Required");
      } else {
        setPre_installed_browsererror("");
      }

      if (content_providers === "") {
        setContent_providerserror("Required");
      } else {
        setContent_providerserror("");
      }

      if (supported_devices_for_casting === "") {
        setSupported_devices_for_castingerror("Required");
      } else {
        setSupported_devices_for_castingerror("");
      }

      if (teletext === "") {
        setTeletexterror("Required");
      } else {
        setTeletexterror("");
      }

      if (mobile_high_defination_link === "") {
        setMobile_high_defination_linkerror("Required");
      } else {
        setMobile_high_defination_linkerror("");
      }

      if (_3g_dongle_plug_and_play === "") {
        set_3G_dongle_plug_and_playerror("Required");
      } else {
        set_3G_dongle_plug_and_playerror("");
      }

      if (touch_remote === "") {
        setTouch_remoteerror("Required");
      } else {
        setTouch_remoteerror("");
      }

      if (smart_remote === "") {
        setSmart_remoteerror("Required");
      } else {
        setSmart_remoteerror("");
      }

      if (color_screen === "") {
        setColor_screenerror("Required");
      } else {
        setColor_screenerror("");
      }

      if (ir_capable === "") {
        setIr_capableerror("Required");
      } else {
        setIr_capableerror("");
      }

      if (dvr_control === "") {
        setDvr_controlerror("Required");
      } else {
        setDvr_controlerror("");
      }

      if (remote_type === "") {
        setRemote_typeerror("Required");
      } else {
        setRemote_typeerror("");
      }

      if (clock === "") {
        setClockerror("Required");
      } else {
        setClockerror("");
      }

      if (on_off_timer === "") {
        setOn_off_timererror("Required");
      } else {
        setOn_off_timererror("");
      }

      if (without_stand === "") {
        setWithout_standerror("Required");
      } else {
        setWithout_standerror("");
      }

      if (with_stand === "") {
        setWith_standerror("Required");
      } else {
        setWith_standerror("");
      }

      if (stand_features === "") {
        setStand_featureserror("Required");
      } else {
        setStand_featureserror("");
      }

      if (sleep_timer === "") {
        setSleep_timererror("Required");
      } else {
        setSleep_timererror("");
      }

      if (wired_wireless === "") {
        setWired_wirelesserror("Required");
      } else {
        setWired_wirelesserror("");
      }

      if (outdoor_usage === "") {
        setOutdoor_usageerror("Required");
      } else {
        setOutdoor_usageerror("");
      }

      if (maximum_spl === "") {
        setMaximum_splerror("Required");
      } else {
        setMaximum_splerror("");
      }

      if (chassis_material === "") {
        setChassis_materialerror("Required");
      } else {
        setChassis_materialerror("");
      }

      if (international_warranty === "") {
        setInternational_warrantyerror("Required");
      } else {
        setInternational_warrantyerror("");
      }

      if (part_number === "") {
        setPart_numbererror("Required");
      } else {
        setPart_numbererror("");
      }

      if (portable === "") {
        setPortableerror("Required");
      } else {
        setPortableerror("");
      }

      if (maximum_brightness === "") {
        setMaximum_brightnesserror("Required");
      } else {
        setMaximum_brightnesserror("");
      }

      if (maximum_projection_distances === "") {
        setMaximum_projection_distanceserror("Required");
      } else {
        setMaximum_projection_distanceserror("");
      }
      if (projection_ratio === "") {
        setProjection_ratioerror("Required");
      } else {
        setProjection_ratioerror("");
      }

      if (adjustable_aspect_ratio === "") {
        setAdjustable_aspect_ratioerror("Required");
      } else {
        setAdjustable_aspect_ratioerror("");
      }

      if (vertical_scanning_frequency === "") {
        setVertical_scanning_frequencyerror("Required");
      } else {
        setVertical_scanning_frequencyerror("");
      }

      if (horizontal_scanning_frequency === "") {
        setHorizontal_scanning_frequencyerror("Required");
      } else {
        setHorizontal_scanning_frequencyerror("");
      }

      if (signal_type === "") {
        setSignal_typeerror("Required");
      } else {
        setSignal_typeerror("");
      }

      if (standard_brightness === "") {
        setStandard_brightnesserror("Required");
      } else {
        setStandard_brightnesserror("");
      }

      if (zoom_ratio === "") {
        setZoom_ratioerror("Required");
      } else {
        setZoom_ratioerror("");
      }

      if (maximum_diagonal_projection_size === "") {
        setMaximum_diagonal_projection_sizeerror("Required");
      } else {
        setMaximum_diagonal_projection_sizeerror("");
      }

      if (color_support === "") {
        setColor_supporterror("Required");
      } else {
        setColor_supporterror("");
      }

      if (output_per_speaker === "") {
        setOutput_per_speakererror("Required");
      } else {
        setOutput_per_speakererror("");
      }

      if (audio_type === "") {
        setAudio_typeerror("Required");
      } else {
        setAudio_typeerror("");
      }

      if (componenr_hd_video_in === "") {
        setComponenr_hd_video_inerror("Required");
      } else {
        setComponenr_hd_video_inerror("");
      }

      if (maximum_attitude === "") {
        setMaximum_attitudeerror("Required");
      } else {
        setMaximum_attitudeerror("");
      }

      if (standby_noise_level === "") {
        setStandby_noise_levelerror("Required");
      } else {
        setStandby_noise_levelerror("");
      }

      if (normal_noise_level === "") {
        setNormal_noise_levelerror("Required");
      } else {
        setNormal_noise_levelerror("");
      }

      if (minimum_aperture === "") {
        setMinimum_apertureerror("Required");
      } else {
        setMinimum_apertureerror("");
      }

      if (maximum_aperture === "") {
        setMaximum_apertureerror("Required");
      } else {
        setMaximum_apertureerror("");
      }

      if (functions_modes === "") {
        setFunctions_modeserror("Required");
      } else {
        setFunctions_modeserror("");
      }

      if (number_of_usd_languages === "") {
        setNumber_of_usd_languageserror("Required");
      } else {
        setNumber_of_usd_languageserror("");
      }

      if (security === "") {
        setSecurityerror("Required");
      } else {
        setSecurityerror("");
      }
      if (offer === "") {
        setOffererror("Required");
      } else {
        setOffererror("");
      }

      if (remote_features === "") {
        setRemote_featureserror("Required");
      } else {
        setRemote_featureserror("");
      }

      if (control_type === "") {
        setControl_typeerror("Required");
      } else {
        setControl_typeerror("");
      }

      if (power_adapter === "") {
        setPower_adaptererror("Required");
      } else {
        setPower_adaptererror("");
      }

      if (video_player_type === "") {
        setVideo_player_typeerror("Required");
      } else {
        setVideo_player_typeerror("");
      }

      if (svr_varient === "") {
        setSvr_varienterror("Required");
      } else {
        setSvr_varienterror("");
      }

      if (brand_color === "") {
        setBrand_colorerror("Required");
      } else {
        setBrand_colorerror("");
      }

      if (effective_pixels === "") {
        setEffective_pixelserror("Required");
      } else {
        setEffective_pixelserror("");
      }

      if (tripod_socket === "") {
        setTripod_socketerror("Required");
      } else {
        setTripod_socketerror("");
      }

      if (sensor_type === "") {
        setSensor_typeerror("Required");
      } else {
        setSensor_typeerror("");
      }

      if (image_sensor_size === "") {
        setImage_sensor_sizeerror("Required");
      } else {
        setImage_sensor_sizeerror("");
      }

      if (lens_mount === "") {
        setLens_mounterror("Required");
      } else {
        setLens_mounterror("");
      }

      if (dust_reduction === "") {
        setDust_reductionerror("Required");
      } else {
        setDust_reductionerror("");
      }

      if (shutter_speed === "") {
        setShutter_speederror("Required");
      } else {
        setShutter_speederror("");
      }

      if (self_timer === "") {
        setSelf_timererror("Required");
      } else {
        setSelf_timererror("");
      }

      if (built_in_flash === "") {
        setBuilt_in_flasherror("Required");
      } else {
        setBuilt_in_flasherror("");
      }

      if (external_flash === "") {
        setExternal_flasherror("Required");
      } else {
        setExternal_flasherror("");
      }

      if (image_format === "") {
        setImage_formaterror("Required");
      } else {
        setImage_formaterror("");
      }

      if (video_resolution === "") {
        setVideo_resolutionerror("Required");
      } else {
        setVideo_resolutionerror("");
      }

      if (video_quality === "") {
        setVideo_qualityerror("Required");
      } else {
        setVideo_qualityerror("");
      }

      if (comapatible_cord === "") {
        setComapatible_corderror("Required");
      } else {
        setComapatible_corderror("");
      }

      if (number_of_batteries === "") {
        setNumber_of_batterieserror("Required");
      } else {
        setNumber_of_batterieserror("");
      }

      if (shooting_modes === "") {
        setShooting_modeserror("Required");
      } else {
        setShooting_modeserror("");
      }

      if (image_sensor_type === "") {
        setImage_sensor_typeerror("Required");
      } else {
        setImage_sensor_typeerror("");
      }

      if (iso_rating === "") {
        setIso_ratingerror("Required");
      } else {
        setIso_ratingerror("");
      }

      if (focal_length === "") {
        setFocal_lengtherror("Required");
      } else {
        setFocal_lengtherror("");
      }

      if (focus_mode === "") {
        setFocus_modeerror("Required");
      } else {
        setFocus_modeerror("");
      }

      if (focus_range === "") {
        setFocus_rangeerror("Required");
      } else {
        setFocus_rangeerror("");
      }

      if (metering_model === "") {
        setMetering_modelerror("Required");
      } else {
        setMetering_modelerror("");
      }

      if (face_detection === "") {
        setFace_detectionerror("Required");
      } else {
        setFace_detectionerror("");
      }

      if (temperature === "") {
        setTemperatureerror("Required");
      } else {
        setTemperatureerror("");
      }

      if (image_processsor === "") {
        setImage_processsorerror("Required");
      } else {
        setImage_processsorerror("");
      }

      if (ports === "") {
        setPortserror("Required");
      } else {
        setPortserror("");
      }

      if (accessory_shoe === "") {
        setAccessory_shoeerror("Required");
      } else {
        setAccessory_shoeerror("");
      }

      if (lens_type === "") {
        setLens_typeerror("Required");
      } else {
        setLens_typeerror("");
      }

      if (manual_focus === "") {
        setManual_focuserror("Required");
      } else {
        setManual_focuserror("");
      }

      if (view_finder === "") {
        setView_findererror("Required");
      } else {
        setView_findererror("");
      }

      if (auto_focus === "") {
        setAuto_focuserror("Required");
      } else {
        setAuto_focuserror("");
      }

      if (focus_points === "") {
        setFocus_pointserror("Required");
      } else {
        setFocus_pointserror("");
      }

      if (white_balance === "") {
        setWhite_balanceerror("Required");
      } else {
        setWhite_balanceerror("");
      }

      if (scene_modes === "") {
        setScene_modeserror("Required");
      } else {
        setScene_modeserror("");
      }

      if (exposure_mode === "") {
        setExposure_modeerror("Required");
      } else {
        setExposure_modeerror("");
      }

      if (ae_lock_or_exposure_lock === "") {
        setAe_lock_or_exposure_lockerror("Required");
      } else {
        setAe_lock_or_exposure_lockerror("");
      }

      if (exposure_bracketing === "") {
        setExposure_bracketingerror("Required");
      } else {
        setExposure_bracketingerror("");
      }

      if (manual_exposure === "") {
        setManual_exposureerror("Required");
      } else {
        setManual_exposureerror("");
      }

      if (flash_modes === "") {
        setFlash_modeserror("Required");
      } else {
        setFlash_modeserror("");
      }

      if (flash_compension === "") {
        setFlash_compensionerror("Required");
      } else {
        setFlash_compensionerror("");
      }

      if (image_size === "") {
        setImage_sizeerror("Required");
      } else {
        setImage_sizeerror("");
      }

      if (wide_angle === "") {
        setWide_angleerror("Required");
      } else {
        setWide_angleerror("");
      }

      if (red_eye_reduction === "") {
        setRed_eye_reductionerror("Required");
      } else {
        setRed_eye_reductionerror("");
      }

      if (live_view_shooting === "") {
        setLive_view_shootingerror("Required");
      } else {
        setLive_view_shootingerror("");
      }

      if (hdr_support === "") {
        setHdr_supporterror("Required");
      } else {
        setHdr_supporterror("");
      }

      if (microphone === "") {
        setMicrophoneerror("Required");
      } else {
        setMicrophoneerror("");
      }

      if (upgradeable_memory === "") {
        setUpgradeable_memoryerror("Required");
      } else {
        setUpgradeable_memoryerror("");
      }

      if (model === "") {
        setModelerror("Required");
      } else {
        setModelerror("");
      }

      if (prime_zoom === "") {
        setPrime_zoomerror("Required");
      } else {
        setPrime_zoomerror("");
      }

      if (designed_for === "") {
        setDesigned_forerror("Required");
      } else {
        setDesigned_forerror("");
      }

      if (maximum_focal_length === "") {
        setMaximum_focal_lengtherror("Required");
      } else {
        setMaximum_focal_lengtherror("");
      }

      if (minimum_focal_length === "") {
        setMinimum_focal_lengtherror("Required");
      } else {
        setMinimum_focal_lengtherror("");
      }

      if (aperture_with_max_focal_length === "") {
        setAperture_with_max_focal_lengtherror("Required");
      } else {
        setAperture_with_max_focal_lengtherror("");
      }

      if (aperture_with_min_focal_length === "") {
        setAperture_with_min_focal_lengtherror("Required");
      } else {
        setAperture_with_min_focal_lengtherror("");
      }

      if (maximum_magnification === "") {
        setMaximum_magnificationerror("Required");
      } else {
        setMaximum_magnificationerror("");
      }

      if (shutter_type === "") {
        setShutter_typeerror("Required");
      } else {
        setShutter_typeerror("");
      }

      if (number_of_channels === "") {
        setNumber_of_channelserror("Required");
      } else {
        setNumber_of_channelserror("");
      }

      if (camera_type === "") {
        setCamera_typeerror("Required");
      } else {
        setCamera_typeerror("");
      }

      if (video_recording_frame_rate === "") {
        setVideo_recording_frame_rateerror("Required");
      } else {
        setVideo_recording_frame_rateerror("");
      }

      if (hdd_available === "") {
        setHdd_availableerror("Required");
      } else {
        setHdd_availableerror("");
      }

      if (recording_format === "") {
        setRecording_formaterror("Required");
      } else {
        setRecording_formaterror("");
      }

      if (display_frame_rate === "") {
        setDisplay_frame_rateerror("Required");
      } else {
        setDisplay_frame_rateerror("");
      }

      if (record_modes === "") {
        setRecord_modeserror("Required");
      } else {
        setRecord_modeserror("");
      }

      if (image_sensor === "") {
        setImage_sensorerror("Required");
      } else {
        setImage_sensorerror("");
      }

      if (image_resolution === "") {
        setImage_resolutionerror("Required");
      } else {
        setImage_resolutionerror("");
      }

      if (minimum_illumination === "") {
        setMinimum_illuminationerror("Required");
      } else {
        setMinimum_illuminationerror("");
      }

      if (ir_distance === "") {
        setIr_distanceerror("Required");
      } else {
        setIr_distanceerror("");
      }
      if (motin_triggered_recording === "") {
        setMotin_triggered_recordingerror("Required");
      } else {
        setMotin_triggered_recordingerror("");
      }

      if (audio_output === "") {
        setAudio_outputerror("Required");
      } else {
        setAudio_outputerror("");
      }

      if (network_interface_time === "") {
        setNetwork_interface_timeerror("Required");
      } else {
        setNetwork_interface_timeerror("");
      }

      if (number_of_ethiernet_ports === "") {
        setNumber_of_ethiernet_portserror("Required");
      } else {
        setNumber_of_ethiernet_portserror("");
      }
      if (power_consumption === "") {
        setPower_consumptionerror("Required");
      } else {
        setPower_consumptionerror("");
      }

      if (alarm_action === "") {
        setAlarm_actionerror("Required");
      } else {
        setAlarm_actionerror("");
      }

      if (application_support === "") {
        setApplication_supporterror("Required");
      } else {
        setApplication_supporterror("");
      }

      if (remote_connectivity === "") {
        setRemote_connectivityerror("Required");
      } else {
        setRemote_connectivityerror("");
      }

      if (closure_mechanism === "") {
        setClosure_mechanismerror("Required");
      } else {
        setClosure_mechanismerror("");
      }

      if (outer_material === "") {
        setOuter_materialerror("Required");
      } else {
        setOuter_materialerror("");
      }

      if (inner_height === "") {
        setInner_heighterror("Required");
      } else {
        setInner_heighterror("");
      }

      if (inner_width === "") {
        setInner_widtherror("Required");
      } else {
        setInner_widtherror("");
      }

      if (inner_depth === "") {
        setInner_deptherror("Required");
      } else {
        setInner_deptherror("");
      }

      if (head_type === "") {
        setHead_typeerror("Required");
      } else {
        setHead_typeerror("");
      }

      if (leg_lock_type === "") {
        setLeg_lock_typeerror("Required");
      } else {
        setLeg_lock_typeerror("");
      }

      if (independent_leg_spread === "") {
        setIndependent_leg_spreaderror("Required");
      } else {
        setIndependent_leg_spreaderror("");
      }

      if (pack_of === "") {
        setPack_oferror("Required");
      } else {
        setPack_oferror("");
      }

      if (floded_length === "") {
        setFloded_lengtherror("Required");
      } else {
        setFloded_lengtherror("");
      }

      if (number_of_charging_cells === "") {
        setNumber_of_charging_cellserror("Required");
      } else {
        setNumber_of_charging_cellserror("");
      }









    }
  }, [
    buttonPress,
    distinctfieldValidate,
    in_the_box,
    model_number,
    model_name,
    color,
    browse_type,
    sim_type,
    hybrid_sim_slot,
    touchscreen,
    otg_compatible,
    quick_charging,
    sar_value,
    display_size,
    resolution,
    gpu,
    display_type,
    other_display_features,
    operating_system,
    processor_brand,
    processor_type,
    primary_clock_speed,
    secondary_clock_speed,
    tertiary_clock_speed,
    operating_frequency,
    internal_storage,
    ram,
    total_memory,
    call_log_memory,
    primary_camera_available,
    primary_camera,
    primary_camera_features,
    secondary_camera_available,
    secondary_camera,
    secondary_camera_features,
    flash,
    hd_recording,
    full_hd_recording,
    video_recording,
    video_recording_resolution,
    digital_zoom,
    frame_rate,
    dual_camera_lens,
    call_wait_or_hold,
    conference_call,
    hands_free,
    call_divert,
    phone_book,
    call_timer,
    speaker_phone,
    call_records,
    network_type,
    supported_networks,
    _3g,
    gprs,
    micro_usb_port,
    micro_usb_version,
    blutooth_support,
    blutooth_version,
    wifi,
    wifi_version,
    wifi_hotspot,
    mini_hdmi_port,
    nfc,
    usb_tethering,
    usb_connectivity,
    map_support,
    gps_support,
    smartphone,
    touchscreen_type,
    sim_size,
    user_interface,
    instant_message,
    removable_battery,
    mms,
    sms,
    keypad,
    voice_input,
    predictive_text_input,
    user_memory,
    sensors,
    browser,
    other_features,
    gps_type,
    fm_radio,
    fm_radio_recording,
    battery_capacity,
    battery_type,
    width,
    height,
    depth,
    weight,
    warranty_summary,
    domestic_warranty,
    function_cooking,
    steaming,
    non_stick_cooking_bowl,
    detachable_power_cord,
    see_through_glass_top,
    measuring_cup,
    trivet,
    temperature_glass_lid,
    steaming_basket,
    spatula,
    cool_touch_handles,
    thermal_fuse,
    motor_speed,
    number_of_stones,
    attachment_present,
    motor_overload_protection,
    auto_off,
    safety_lock,
    processing_bocol,
    processing_jar,
    number_of_attached_jar,
    anti_drip_value,
    brew_strength_control,
    removable_drip,
    frother,
    illuminated_on_off_switch,
    hot_plate,
    net_quantity,
    brewing_time,
    removable_filter,
    other_dimensions,
    filter_material,
    temperature_control,
    spray,
    steam_burst,
    self_cleaning,
    swivel_cord,

    electric_type,
    purification_features,
    detachable_storage_tank,
    membrane_type,
    overflow_protection,
    filter_life,
    alarms,
    filtration_capacity,
    purification_capacity,
    cold_water_capacity,
    cold_water_dispensor,
    hot_water_capacity,
    hot_water_dispensor,
    purification_stages,
    airflow,
    blade_sweep,
    number_of_speeds,
    cooling_coverage_area,
    speed_control,
    operating_mode,
    air_delivery,
    air_deflection,
    blower_material,
    ice_chamber,
    caston_wheels,
    number_of_caston_wheels,
    number_of_fan_blades,
    auto_louver_movement,
    oscillating_function,
    technology_used,
    blower,
    motor_suction_power,
    solar_power,
    efficiency,
    output_voltage,
    launch_year,
    deodarizer,
    ice_cub_tray_type,
    wheel_support,
    removable_basket,
    flexible_rack,
    removable_rack,
    brand,
    capacity,
    manufacturing_year,
    cover_in_warranty,
    not_cover_in_warranty,
    warranty_service_type,
    preheat,
    timer,
    auto_cook_menu_available,
    power_output,
    sales_package,
    rotisserie,
    rotisserie_type,
    cavity_material,
    temperature_control_range,
    auto_menu,
    number_of_auto_menu,
    power_requirement,
    material,
    dry_grinding,
    mincing,
    chutey_grinding,
    liguidizing_jar_capacity,
    chatey_jar_capacity,
    series,
    locbable_lid,
    dry_boil_protection,
    auto_switch_off,
    heating_element,
    water_level_indicator,
    card_length,
    body_material,
    control,
    present_cooking_menu,
    elements_on_cooktop,
    other_body_and_design_features,
    service_type,
    installation_and_demo,
    finish, air_section_capacity,
    ductless, filter_type,
    speed_levels, filter_block_indicator,
    noise_level, phase, engine_power,
    in_built_oil_collector, timer_present,
    dblade_material, blending, pureing,
    stirring, safety_features, model_let,
    slice_capacity, working_plate,
    lid_lock, no_of_slices,
    wide_slots_for_thick_breads, high_lift_for_small_breads, auto_pop_up, removable_crumbtroy, toasting, warming, reheat, variable_browing, defrost, card_storage, cool_touch, length, capacity_incups, total_pan_capacity, functions, material_lid, net_quantity_product_details, autokeep_warm_function_cooking, output_frequency, number_of_stitches, number_of_button_holes_styles,
    sewing_speed, lcd_screen_display, shape, bobbin_winder, stop_start_button, stitch_out_time_indicator, led_needle_light, automatic_thread_cutter, used_for, generator_compatibility,
    cooling, mount_type, minimum_operating, under_vlot_protection, over_vlot_protection, overload_protection, minimum_input_power, suitable_for, rated_pressure, star_rating, thermostot, planting, heater_material, heating_element_type, washing_method, in_built_heater, tub_material, digital_display, water_level_selector, wash_cycle_duration, spin_cycle_duration, inverter, steam, quick_wash, lint_filter, child_lock, wash_program_types, compressor, dehumidification, remote_control, operating_modes, condenser_coil,

    indoor_w_h_d, indoor_unit_weight, auto_air_swing, air_flow_direction, anti_bacteria_filter, dust_filter, auto_restart, quit_mode, sleep_mode, memory_feature, self_diagosis, electricity_consumption, defrosting_type, number_of_doors, coolpad, build_in_stabilizer, toughened_glass, moisture_control, design, door_finish, door_lock, water_ice_dispenser, stabilizer_required, egg_tray, headphone_type, inline_remote, connectivity, headphone_design, foldable_or_collapsible, deep_bass, manual, magnet_type, driver_type, indicators, sensitivity, impedance, signal_to_noise_ratio, modulation, minimum_frequency_response, other_sound_features, audio_codec, blutooth_profile, blutooth_range, headphone_power_source, power_supply, battery_life, stand_by_time, applied, number_of_layers, in_rescidue_free_removal, tinted, camera_hole_present, usage, operating_system_supported, type_of_tracking, auto_trasperancy, display, wireless_data_processing, one_touch_button, touch_control, battery_included, battery_chargable, water_resistance, noise_concellation, number_of_contents_in_sales_pack,
    cable_type, connector1, connector2, cable, compatible_devices, cable_length, keyboard, security_chip, web_camera, finger_print_sensor, processor_variant, cache, number_of_cores, os_architecture, mic_in, rj45, multi_card_slot, hardware_interface, disk_drive, connector_type, number_of_devices, number_of_charger_pins, output_current, output_wattage, compatible_with, flodable, rpm, graphic_processor, battery_backup, battery_cell, ms_office_provided, processor_name, processor_generation, ram_type, hdd_capacity, output_interface, maximum_current_rating, other_material, pointer_device, emmc_storage_capacity, ram_frequency, screen_size, screen_type, speakers, internal_mic, wireless_lan, dedicated_graphic_memory_type, dedicated_graphic_memory_capacity, ethernet, backlit_keyboard, memory_slot, expandle_memory, sound_properties,
    lock_port, operating_system_version, voice_call, display_resolution_time, display_support_network, display_resolution, processor_speed, headphone_jack, expandable_storage_capacity, memory_card_slot_type,
    memory_card_support, warranty_period, sound_enhancements, optical_zoom, video_call_support, graphicsppl, supported_languages, important_apps, audio_formats, video_formats, ideal_for, ideal_usage, loptop_sleeve, with_rain_cover, compatibility_loptop_size, style_code, color_code, material_water_proof, number_of_compartments, number_of_packets, configuration, frequency_response, built_in_fm_radio, certifications, charging_time, cable_interface, platform, read_speed, write_speed, system_requirements, form_factor, cloud_backup, data_tranfer_rate, rotational_speed, password_protection, power_source, size, shock_resistant, water_proof, led_indicator, operating_range, frequency, frequency_band, wireless_speed, dsl_modern_speed, lan_wan, antennae, number_of_usb_ports, lan, no_memory_effect, maximum_shelf_life, magnification, apparent_field_of_view,
    hd_resolution,
    smart_tv, hdmi, usb, wifi_type,
    built_in_wifi, wall_mount_included,
    picture_in_picture, picture_engine,
    analog_tv_reception, view_angle,
    panel_type, digital_noise_filter,

    led_display_type, aspect_ratio,
    refresh_rate, number_of_speakers,
    speaker_type, sound_technology,
    surround_sound,
    speaker_output_rms,
    sound_mode,
    supported_audio_features,
    supported_app_netflix,
    supported_app_youtube,
    supported_app_disney_hotstar,
    supported_app_prime_video,
    supported_mobile_operating_system, operating_system_present, screen_mirroring, app_store_type, pre_installed_browser,

    content_providers,
    supported_devices_for_casting,
    teletext,
    mobile_high_defination_link,
    _3g_dongle_plug_and_play,
    touch_remote,
    smart_remote,
    color_screen,
    ir_capable,
    dvr_control,
    remote_type,
    clock,
    on_off_timer,
    without_stand,
    with_stand,
    stand_features,
    sleep_timer,
    wired_wireless,
    outdoor_usage,
    maximum_spl,
    chassis_material,
    international_warranty,
    part_number,
    portable,
    maximum_brightness,
    maximum_projection_distances,
    projection_ratio,
    adjustable_aspect_ratio,
    vertical_scanning_frequency,
    horizontal_scanning_frequency,
    signal_type,
    standard_brightness,
    zoom_ratio,
    maximum_diagonal_projection_size,
    color_support,
    output_per_speaker,
    audio_type,
    componenr_hd_video_in,
    maximum_attitude,
    standby_noise_level,
    normal_noise_level,
    minimum_aperture,
    maximum_aperture,
    functions_modes,
    number_of_usd_languages,
    security,
    offer,
    remote_features,
    control_type,
    power_adapter,
    video_player_type,
    svr_varient,
    brand_color,
    effective_pixels,
    tripod_socket,
    sensor_type,
    image_sensor_size,
    lens_mount,
    dust_reduction,
    shutter_speed,
    self_timer,
    built_in_flash,
    external_flash,
    image_format,
    video_resolution,
    video_quality,
    comapatible_cord,
    number_of_batteries,
    shooting_modes,
    image_sensor_type,
    iso_rating,
    focal_length,
    focus_mode,
    focus_range,
    metering_model,
    face_detection,
    temperature,
    image_processsor,
    ports,
    accessory_shoe,
    lens_type,
    manual_focus,
    view_finder,
    auto_focus,
    focus_points,
    white_balance,
    scene_modes,
    exposure_mode,
    ae_lock_or_exposure_lock,
    exposure_bracketing,
    manual_exposure,
    flash_modes,
    flash_compension,
    image_size,
    wide_angle,
    red_eye_reduction,
    live_view_shooting,
    hdr_support,
    microphone,
    upgradeable_memory,
    model,
    prime_zoom,
    designed_for,
    maximum_focal_length,
    minimum_focal_length,
    aperture_with_max_focal_length,
    aperture_with_min_focal_length,
    maximum_magnification,
    shutter_type,
    number_of_channels,
    camera_type,
    video_recording_frame_rate,

    hdd_available,
    recording_format,
    display_frame_rate,
    record_modes,
    image_sensor,
    image_resolution,
    minimum_illumination,
    ir_distance,
    motin_triggered_recording,
    audio_output,
    network_interface_time,
    number_of_ethiernet_ports,
    power_consumption,
    alarm_action,
    application_support,
    remote_connectivity,
    closure_mechanism,
    outer_material,
    inner_height,
    inner_width,
    inner_depth,
    head_type,
    leg_lock_type,
    independent_leg_spread,
    pack_of,
    floded_length,
    number_of_charging_cells,

































  ])
  // useEffect(() => {
  //   if (buttonPress === true) {
  //     console.log(distinctfieldValidate, "distinctfieldValidate");
  //     if (distinctfieldValidate.every((field) => field.value !== "")) {
  //       let finalarray = [];
  //       for (let index = 0; index < distinctfieldValidate.length; index++) {
  //         let element = distinctfieldValidate[index];
  //         let obj = { [element.field]: element.value };
  //         finalarray = [...finalarray, obj];
  //       }
  //       finalarray = finalarray;

  //       const singleObject = finalarray.reduce((acc, obj) => {
  //         return { ...acc, ...obj };
  //       }, {});
  //       getPropDetails(singleObject, true);
  //     } else {
  //     }
  //   }
  // }, [buttonPress, distinctfieldValidate]);


  useEffect(() => {
    if (buttonPress === true) {
      console.log(distinctfieldValidate, "distinctfieldValidate");

      const finalarray = distinctfieldValidate.filter((data) =>
        data.field !== " heating_element")
      if (finalarray.every((field) => field.value !== "")) {
        let finalarray = [];
        for (let index = 0; index < distinctfieldValidate.length; index++) {
          let element = distinctfieldValidate[index];
          let obj = { [element.field]: element.value };
          finalarray = [...finalarray, obj];
        }
        finalarray = finalarray;

        const singleObject = finalarray.reduce((acc, obj) => {
          return { ...acc, ...obj };
        }, {});

        const newForm = {
          ...singleObject,
          occasion: "abc"
        }
        getPropDetails(newForm, true);
      } else {
      }
    }
  }, [buttonPress, distinctfieldValidate]);



  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Flex wrap="wrap">
          {distinctfield.includes("in_the_box") && (
            <Col className="gutter-row" span={8}>
              <span>In the Box:</span>
              <Input
                status={in_the_boxerror !== "" ? "error" : ""}
                suffix={in_the_boxerror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter In the Box"
                label="In the Box"
                value={in_the_box}
                onChange={(e) => {
                  setIn_the_box(e.target.value);
                  updateValues("in_the_box", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("model_number") && (
            <Col className="gutter-row" span={12}>
              <span>Model Number:</span>
              <Input
                status={model_numbererror !== "" ? "error" : ""}
                suffix={
                  model_numbererror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Model Number"
                value={model_number}
                onChange={(e) => {
                  setModel_number(e.target.value);
                  updateValues("model_number", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("model_name") && (
            <Col className="gutter-row" span={12}>
              <span>Model Name:</span>
              <Input
                status={model_nameerror !== "" ? "error" : ""}
                suffix={model_nameerror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter Model Name"
                value={model_name}
                onChange={(e) => {
                  setModel_name(e.target.value);
                  updateValues("model_name", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color"
                hasFeedback
                validateStatus={colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Color"
                  value={color}
                  onChange={(value) => {
                    setColor(value);
                    updateValues("color", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Color.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("browse_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Browse Type"
                hasFeedback
                validateStatus={browse_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Browse Type"
                  value={browse_type}
                  onChange={(value) => {
                    setBrowse_type(value);
                    updateValues("browse_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Browse_Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={browse_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sim_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sim Type"
                hasFeedback
                validateStatus={sim_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sim Type"
                  value={sim_type}
                  onChange={(value) => {
                    setSim_type(value);
                    updateValues("sim_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {SIM_Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sim_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hybrid_sim_slot") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hybrid Sim Slot"
                hasFeedback
                validateStatus={hybrid_sim_sloterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Hybrid Sim Slot"
                  value={hybrid_sim_slot}
                  onChange={(value) => {
                    setHybrid_sim_slot(value);
                    updateValues("hybrid_sim_slot", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hybrid_sim_slot === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("touchscreen") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Touchscreen"
                hasFeedback
                validateStatus={touchscreenerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Touchscreen"
                  value={touchscreen}
                  onChange={(value) => {
                    setTouchscreen(value);
                    updateValues("touchscreen", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={touchscreen === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("otg_compatible") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="OTG Compatible"
                hasFeedback
                validateStatus={otg_compatibleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select OTG Compatible"
                  value={otg_compatible}
                  onChange={(value) => {
                    setOtg_compatible(value);
                    updateValues("otg_compatible", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={otg_compatible === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("quick_charging") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Quick Charging"
                hasFeedback
                validateStatus={quick_chargingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Quick Charging"
                  value={quick_charging}
                  onChange={(value) => {
                    setQuick_charging(value);
                    updateValues("quick_charging", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={quick_charging === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sar_value") && (
            <Col className="gutter-row" span={8}>
              <span>SAR value:</span>
              <Input
                status={sar_valueerror !== "" ? "error" : ""}
                suffix={sar_valueerror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter SAR value"
                label="SAR value"
                value={sar_value}
                onChange={(e) => {
                  setSar_value(e.target.value);
                  updateValues("sar_value", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display Size"
                hasFeedback
                validateStatus={display_sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Display Size"
                  value={display_size}
                  onChange={(value) => {
                    setDisplay_size(value);
                    updateValues("display_size", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_Display_Size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("resolution") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Resolution"
                hasFeedback
                validateStatus={resolutionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Resolution"
                  value={resolution}
                  onChange={(value) => {
                    setResolution(value);
                    updateValues("resolution", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_Resolution.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={resolution === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("gpu") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="GPU"
                hasFeedback
                validateStatus={gpuerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select GPU"
                  value={gpu}
                  onChange={(value) => {
                    setGpu(value);
                    updateValues("gpu", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_Gpu.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={gpu === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display Type"
                hasFeedback
                validateStatus={display_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Display Type"
                  value={display_type}
                  onChange={(value) => {
                    setDisplay_type(value);
                    updateValues("display_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_Display_Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("other_display_features") && (
            <Col className="gutter-row" span={12}>
              <span>Other Display Features:</span>
              <Input
                status={other_display_featureserror !== "" ? "error" : ""}
                suffix={
                  other_display_featureserror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Other Display Features"
                label="Other Display Features"
                value={other_display_features}
                onChange={(e) => {
                  setOther_display_features(e.target.value);
                  updateValues("other_display_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        {/* ----------------------------------------- */}
        <Flex wrap="wrap">
          {distinctfield.includes("operating_system") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Operating System"
                hasFeedback
                validateStatus={operating_systemerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select OPerating System"
                  value={operating_system}
                  onChange={(value) => {
                    setOperating_system(value);
                    updateValues("operating_system", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_operating_system.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={operating_system === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processor_brand") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Processor Brand"
                hasFeedback
                validateStatus={processor_branderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Processor Brand"
                  value={processor_brand}
                  onChange={(value) => {
                    setProcessor_brand(value);
                    updateValues("processor_brand", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_Processor_brand.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={processor_brand === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processor_type") && (
            <Col className="gutter-row" span={12}>
              <span>Processor Type:</span>
              <Input
                status={processor_typeerror !== "" ? "error" : ""}
                suffix={
                  processor_typeerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Processor Type"
                value={processor_type}
                onChange={(e) => {
                  setProcessor_type(e.target.value);
                  updateValues("processor_type", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("primary_clock_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Primary Clock Speed"
                hasFeedback
                validateStatus={primary_clock_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Primary Clock Speed"
                  value={primary_clock_speed}
                  onChange={(value) => {
                    setPrimary_clock_speed(value);
                    updateValues("primary_clock_speed", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_primary_clock_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={primary_clock_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("secondary_clock_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Secondary Clock Speed"
                hasFeedback
                validateStatus={
                  secondary_clock_speederror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Secondary Clock Speed"
                  value={secondary_clock_speed}
                  onChange={(value) => {
                    setSecondary_clock_speed(value);
                    updateValues("secondary_clock_speed", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_secondary_clock_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={secondary_clock_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("tertiary_clock_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Tertiary Clock Speed"
                hasFeedback
                validateStatus={tertiary_clock_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Tertiary Clock Speed"
                  value={tertiary_clock_speed}
                  onChange={(value) => {
                    setTertiary_clock_speed(value);
                    updateValues("tertiary_clock_speed", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_tertiary_clock_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={tertiary_clock_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("operating_frequency") && (
            <Col className="gutter-row" span={12}>
              <span>Operating Frequency:</span>
              <Input
                status={operating_frequencyerror !== "" ? "error" : ""}
                suffix={
                  operating_frequencyerror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Operating Frequency"
                value={operating_frequency}
                onChange={(e) => {
                  setOperating_frequency(e.target.value);
                  updateValues("operating_frequency", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("internal_storage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Internal Storage"
                hasFeedback
                validateStatus={internal_storageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Internal Storage"
                  value={internal_storage}
                  onChange={(value) => {
                    setInternal_storage(value);
                    updateValues("internal_storage", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Internal_storage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={internal_storage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ram") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="RAM"
                hasFeedback
                validateStatus={ramerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select RAM"
                  value={ram}
                  onChange={(value) => {
                    setRam(value);
                    updateValues("ram", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_ram.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ram === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("total_memory") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Total Memory"
                hasFeedback
                validateStatus={total_memoryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Total Memory"
                  value={total_memory}
                  onChange={(value) => {
                    setTotal_memory(value);
                    updateValues("total_memory", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Total_memory.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={total_memory === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("call_log_memory") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Call Log Memory"
                hasFeedback
                validateStatus={call_log_memoryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Call Log Memory"
                  value={call_log_memory}
                  onChange={(value) => {
                    setCall_log_memory(value);
                    updateValues("call_log_memory", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={call_log_memory === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("primary_camera_available") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Primary Camera Available"
                hasFeedback
                validateStatus={
                  primary_camera_availableerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Primary Camera Available"
                  value={primary_camera_available}
                  onChange={(value) => {
                    setPrimary_camera_available(value);
                    updateValues("primary_camera_available", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={primary_camera_available === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("primary_camera") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Primary Camera"
                hasFeedback
                validateStatus={primary_cameraerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Primary Camera"
                  value={primary_camera}
                  onChange={(value) => {
                    setPrimary_camera(value);
                    updateValues("primary_camera", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Primary_camera.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={primary_camera === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("primary_camera_features") && (
            <Col className="gutter-row" span={12}>
              <span>Primary Camera Features:</span>
              <Input
                status={primary_camera_featureserror !== "" ? "error" : ""}
                suffix={
                  primary_camera_featureserror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Primary Camera Features"
                value={primary_camera_features}
                onChange={(e) => {
                  setPrimary_camera_features(e.target.value);
                  updateValues("primary_camera_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("secondary_camera_available") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Secondary Camera Available"
                hasFeedback
                validateStatus={
                  secondary_camera_availableerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Secondary Camera Available"
                  value={secondary_camera_available}
                  onChange={(value) => {
                    setSecondary_camera_available(value);
                    updateValues("secondary_camera_available", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={secondary_camera_available === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("secondary_camera") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Secondary Camera"
                hasFeedback
                validateStatus={secondary_cameraerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Secondary Camera"
                  value={secondary_camera}
                  onChange={(value) => {
                    setSecondary_camera(value);
                    updateValues("secondary_camera", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Secodary_camera.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={secondary_camera === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("secondary_camera_features") && (
            <Col className="gutter-row" span={12}>
              <span>Secondary Camera Features:</span>
              <Input
                status={secondary_camera_featureserror !== "" ? "error" : ""}
                suffix={
                  secondary_camera_featureserror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Secondary Camera Features"
                value={secondary_camera_features}
                onChange={(e) => {
                  setSecondary_camera_features(e.target.value);
                  updateValues("secondary_camera_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("flash") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Flash"
                hasFeedback
                validateStatus={flasherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Flash"
                  value={flash}
                  onChange={(value) => {
                    setFlash(value);
                    updateValues("flash", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Flash.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={flash === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hd_recording") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="HD Recording"
                hasFeedback
                validateStatus={hd_recordingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select HD Recording"
                  value={hd_recording}
                  onChange={(value) => {
                    setHd_recording(value);
                    updateValues("hd_recording", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hd_recording === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("full_hd_recording") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="FullHD Recording"
                hasFeedback
                validateStatus={full_hd_recordingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select FullHD Recording"
                  value={full_hd_recording}
                  onChange={(value) => {
                    setFull_hd_recording(value);
                    updateValues("full_hd_recording", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={full_hd_recording === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_recording") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Recording"
                hasFeedback
                validateStatus={video_recordingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Video Recording"
                  value={video_recording}
                  onChange={(value) => {
                    setVideo_recording(value);
                    updateValues("video_recording", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_recording === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_recording_resolution") && (
            <Col className="gutter-row" span={12}>
              <span>Video Recording Resolution:</span>
              <Input
                status={video_recording_resolutionerror !== "" ? "error" : ""}
                suffix={
                  video_recording_resolutionerror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Video Recording Resolution"
                value={video_recording_resolution}
                onChange={(e) => {
                  setVideo_recording_resolution(e.target.value);
                  updateValues("video_recording_resolution", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("frame_rate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Frame Rate"
                hasFeedback
                validateStatus={frame_rateerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Frame Rate"
                  value={frame_rate}
                  onChange={(value) => {
                    setFrame_rate(value);
                    updateValues("frame_rate", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Frame_rate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={frame_rate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dual_camera_lens") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dual Camera Lens"
                hasFeedback
                validateStatus={dual_camera_lenserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Dual Camera Lens"
                  value={dual_camera_lens}
                  onChange={(value) => {
                    setDual_camera_lens(value);
                    updateValues("dual_camera_lens", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Dual_lens.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dual_camera_lens === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("call_wait_or_hold") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Call Wait/Hold"
                hasFeedback
                validateStatus={call_wait_or_holderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Call Wait/Hold"
                  value={call_wait_or_hold}
                  onChange={(value) => {
                    setCall_wait_or_hold(value);
                    updateValues("call_wait_or_hold", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={call_wait_or_hold === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("conference_call") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Conference Call"
                hasFeedback
                validateStatus={conference_callerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Conference Call"
                  value={conference_call}
                  onChange={(value) => {
                    setConference_call(value);
                    updateValues("conference_call", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={conference_call === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hands_free") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hands Free"
                hasFeedback
                validateStatus={hands_freeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Hands Free"
                  value={hands_free}
                  onChange={(value) => {
                    setHands_free(value);
                    updateValues("hands_free", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hands_free === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("call_divert") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Call Divert"
                hasFeedback
                validateStatus={call_diverterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Call Divert"
                  value={call_divert}
                  onChange={(value) => {
                    setCall_divert(value);
                    updateValues("call_divert", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={call_divert === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("phone_book") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Phone Book"
                hasFeedback
                validateStatus={phone_bookerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Phone Book"
                  value={phone_book}
                  onChange={(value) => {
                    setPhone_book(value);
                    updateValues("phone_book", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={phone_book === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("call_timer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Call Time"
                hasFeedback
                validateStatus={call_timererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Call Time"
                  value={call_timer}
                  onChange={(value) => {
                    setCall_timer(value);
                    updateValues("call_timer", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={call_timer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("speaker_phone") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Speaker Phone"
                hasFeedback
                validateStatus={speaker_phoneerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Speaker Phone"
                  value={speaker_phone}
                  onChange={(value) => {
                    setSpeaker_phone(value);
                    updateValues("speaker_phone", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={speaker_phone === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("call_records") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Call Recording"
                hasFeedback
                validateStatus={call_recordserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Call Recording"
                  value={call_records}
                  onChange={(value) => {
                    setCall_records(value);
                    updateValues("call_records", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={call_records === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("network_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Network Type"
                hasFeedback
                validateStatus={network_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Network Type"
                  value={network_type}
                  onChange={(value) => {
                    setNetwork_type(value);
                    updateValues("network_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Network_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={network_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_networks") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported Networks"
                hasFeedback
                validateStatus={supported_networkserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported Networks"
                  value={supported_networks}
                  onChange={(value) => {
                    setSupported_networks(value);
                    updateValues("supported_networks", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Supported_networks.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_networks === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("_3g") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="3G"
                hasFeedback
                validateStatus={_3gerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select 3G"
                  value={_3g}
                  onChange={(value) => {
                    set_3g(value);
                    updateValues("_3g", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={_3g === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("gprs") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="GPRS"
                hasFeedback
                validateStatus={gprserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select GPRS"
                  value={gprs}
                  onChange={(value) => {
                    setGprs(value);
                    updateValues("gprs", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={gprs === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("micro_usb_port") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Micro USB Port"
                hasFeedback
                validateStatus={micro_usb_porterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Micro USB Port"
                  value={micro_usb_port}
                  onChange={(value) => {
                    setMicro_usb_port(value);
                    updateValues("micro_usb_port", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={micro_usb_port === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("micro_usb_version") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Micro USB Version"
                hasFeedback
                validateStatus={micro_usb_versionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Micro USB Version"
                  value={micro_usb_version}
                  onChange={(value) => {
                    setMicro_usb_version(value);
                    updateValues("micro_usb_version", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Usb_version.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={micro_usb_version === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blutooth_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blutooth Support"
                hasFeedback
                validateStatus={blutooth_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Blutooth Support"
                  value={blutooth_support}
                  onChange={(value) => {
                    setBlutooth_support(value);
                    updateValues("blutooth_support", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blutooth_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blutooth_version") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Bluetooth Version"
                hasFeedback
                validateStatus={blutooth_versionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Bluetooth Version"
                  value={blutooth_version}
                  onChange={(value) => {
                    setBlutooth_version(value);
                    updateValues("blutooth_version", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Bluetooth_version.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blutooth_version === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wifi") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wifi"
                hasFeedback
                validateStatus={wifierror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wifi"
                  value={wifi}
                  onChange={(value) => {
                    setWifi(value);
                    updateValues("wifi", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wifi === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wifi_version") && (
            <Col className="gutter-row" span={12}>
              <span>Wifi Version:</span>
              <Input
                status={wifi_versionerror !== "" ? "error" : ""}
                suffix={
                  wifi_versionerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Wifi Version"
                value={wifi_version}
                onChange={(e) => {
                  setWifi_version(e.target.value);
                  updateValues("wifi_version", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("wifi_hotspot") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wifi Hotspot"
                hasFeedback
                validateStatus={wifi_hotspoterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wifi Hotspot"
                  value={wifi_hotspot}
                  onChange={(value) => {
                    setWifi_hotspot(value);
                    updateValues("wifi_hotspot", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wifi_hotspot === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("mini_hdmi_port") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Mini HDMI Port"
                hasFeedback
                validateStatus={mini_hdmi_porterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Mini HDMI Port"
                  value={mini_hdmi_port}
                  onChange={(value) => {
                    setMini_hdmi_port(value);
                    updateValues("mini_hdmi_port", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={mini_hdmi_port === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("nfc") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="NFC"
                hasFeedback
                validateStatus={nfcerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select NFC"
                  value={nfc}
                  onChange={(value) => {
                    setNfc(value);
                    updateValues("nfc", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={nfc === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("usb_tethering") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="USB Tethering"
                hasFeedback
                validateStatus={usb_tetheringerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select USB Tethering"
                  value={usb_tethering}
                  onChange={(value) => {
                    setUsb_tethering(value);
                    updateValues("usb_tethering", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={usb_tethering === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("usb_connectivity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="USB Connectivivty"
                hasFeedback
                validateStatus={usb_connectivityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select USB Connectivivty"
                  value={usb_connectivity}
                  onChange={(value) => {
                    setUsb_connectivity(value);
                    updateValues("usb_connectivity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={usb_connectivity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("map_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Map Support"
                hasFeedback
                validateStatus={map_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Map Support"
                  value={map_support}
                  onChange={(value) => {
                    setMap_support(value);
                    updateValues("map_support", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Map_support.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={map_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("gps_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="GPS Support"
                hasFeedback
                validateStatus={gps_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select GPS Support"
                  value={gps_support}
                  onChange={(value) => {
                    setGps_support(value);
                    updateValues("gps_support", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={gps_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("smartphone") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="SmartPhone"
                hasFeedback
                validateStatus={smartphoneerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select SmartPhone"
                  value={smartphone}
                  onChange={(value) => {
                    setSmartphone(value);
                    updateValues("smartphone", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={smartphone === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("touchscreen_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Touchscreen Type"
                hasFeedback
                validateStatus={touchscreen_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Touchscreen Type"
                  value={touchscreen_type}
                  onChange={(value) => {
                    setTouchscreen_type(value);
                    updateValues("touchscreen_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Touchscreen_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={touchscreen_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sim_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sim Size"
                hasFeedback
                validateStatus={sim_sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sim Size"
                  value={sim_size}
                  onChange={(value) => {
                    setSim_size(value);
                    updateValues("sim_size", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sim_size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sim_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("user_interface") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="User Interface"
                hasFeedback
                validateStatus={user_interfaceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select User Interface"
                  value={user_interface}
                  onChange={(value) => {
                    setUser_interface(value);
                    updateValues("user_interface", value);
                  }}
                  style={{ width: 240 }}
                >
                  {User_interface.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={user_interface === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("instant_message") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Instant Message"
                hasFeedback
                validateStatus={instant_messageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Instant Message"
                  value={instant_message}
                  onChange={(value) => {
                    setInstant_message(value);
                    updateValues("instant_message", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={instant_message === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_battery") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Removable Battery"
                hasFeedback
                validateStatus={removable_batteryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Removable Battery"
                  value={removable_battery}
                  onChange={(value) => {
                    setRemovable_battery(value);
                    updateValues("removable_battery", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_battery === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("mms") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="MMS"
                hasFeedback
                validateStatus={mmserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select MMS"
                  value={mms}
                  onChange={(value) => {
                    setMms(value);
                    updateValues("mms", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={mms === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sms") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="SMS"
                hasFeedback
                validateStatus={smserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select SMS"
                  value={sms}
                  onChange={(value) => {
                    setSms(value);
                    updateValues("sms", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sms === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("keypad") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Keypad"
                hasFeedback
                validateStatus={keypaderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Keypad"
                  value={keypad}
                  onChange={(value) => {
                    setKeypad(value);
                    updateValues("keypad", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={keypad === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("voice_input") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Voice Input"
                hasFeedback
                validateStatus={voice_inputerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Voice Input"
                  value={voice_input}
                  onChange={(value) => {
                    setVoice_input(value);
                    updateValues("voice_input", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={voice_input === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("predictive_text_input") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Predictive Text Input"
                hasFeedback
                validateStatus={
                  predictive_text_inputerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Predictive Text Input"
                  value={predictive_text_input}
                  onChange={(value) => {
                    setPredictive_text_input(value);
                    updateValues("predictive_text_input", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={predictive_text_input === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("user_memory") && (
            <Col className="gutter-row" span={12}>
              <span>User Memory:</span>
              <Input
                status={user_memoryerror !== "" ? "error" : ""}
                suffix={
                  user_memoryerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter User Memory"
                value={user_memory}
                onChange={(e) => {
                  setUser_memory(e.target.value);
                  updateValues("user_memory", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("sensors") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sensors"
                hasFeedback
                validateStatus={sensorserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sensors"
                  value={sensors}
                  onChange={(value) => {
                    setSensors(value);
                    updateValues("sensors", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sensors.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sensors === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("browser") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Browser"
                hasFeedback
                validateStatus={browsererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Browser"
                  value={browser}
                  onChange={(value) => {
                    setBrowser(value);
                    updateValues("browser", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Browser.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={browser === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("other_features") && (
            <Col className="gutter-row" span={12}>
              <span>Other Features:</span>
              <Input
                status={other_featureserror !== "" ? "error" : ""}
                suffix={
                  other_featureserror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Other Features"
                value={other_features}
                onChange={(e) => {
                  setOther_features(e.target.value);
                  updateValues("other_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("gps_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="GPS Type"
                hasFeedback
                validateStatus={gps_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select GPS Type"
                  value={gps_type}
                  onChange={(value) => {
                    setGps_type(value);
                    updateValues("gps_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {GPS_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={gps_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("fm_radio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="FM Radio"
                hasFeedback
                validateStatus={fm_radioerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select FM Radio"
                  value={fm_radio}
                  onChange={(value) => {
                    setFm_radio(value);
                    updateValues("fm_radio", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={fm_radio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("fm_radio_recording") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="FM Radio Recording"
                hasFeedback
                validateStatus={fm_radio_recordingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select FM Radio Recording"
                  value={fm_radio_recording}
                  onChange={(value) => {
                    setFm_radio_recording(value);
                    updateValues("fm_radio_recording", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={fm_radio_recording === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery Capacity"
                hasFeedback
                validateStatus={battery_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Battery Capacity"
                  value={battery_capacity}
                  onChange={(value) => {
                    setBattery_capacity(value);
                    updateValues("battery_capacity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Battery_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery Type"
                hasFeedback
                validateStatus={battery_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Battery Type"
                  value={battery_type}
                  onChange={(value) => {
                    setBattery_type(value);
                    updateValues("battery_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Mobile_Battery_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("width") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Width"
                hasFeedback
                validateStatus={widtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Width"
                  value={width}
                  onChange={(value) => {
                    setWidth(value);
                    updateValues("width", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_width.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={width === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("height") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Height"
                hasFeedback
                validateStatus={heighterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Height"
                  value={height}
                  onChange={(value) => {
                    setHeight(value);
                    updateValues("height", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_height.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={height === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("depth") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Depth"
                hasFeedback
                validateStatus={deptherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Depth"
                  value={depth}
                  onChange={(value) => {
                    setDepth(value);
                    updateValues("depth", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_depth.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={depth === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("weight") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Weight"
                hasFeedback
                validateStatus={weighterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Weight"
                  value={weight}
                  onChange={(value) => {
                    setWeight(value);
                    updateValues("weight", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobile_weight.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={weight === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("warranty_summary") && (
            <Col className="gutter-row" span={12}>
              <span>Warranty Summary:</span>
              <Input
                status={warranty_summaryerror !== "" ? "error" : ""}
                suffix={
                  warranty_summaryerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Warranty Summary"
                value={warranty_summary}
                onChange={(e) => {
                  setWarranty_summary(e.target.value);
                  updateValues("warranty_summary", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("domestic_warranty") && (
            <Col className="gutter-row" span={12}>
              <span>Domestic Warranty:</span>
              <Input
                status={domestic_warrantyerror !== "" ? "error" : ""}
                suffix={
                  domestic_warrantyerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Domestic Warranty"
                value={domestic_warranty}
                onChange={(e) => {
                  setDomestic_warranty(e.target.value);
                  updateValues("domestic_warranty", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("warranty_period") && (
            <Col className="gutter-row" span={12}>
              <span>Warranty Period:</span>
              <Input
                status={warranty_perioderror !== "" ? "error" : ""}
                suffix={
                  warranty_perioderror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Warranty Period"
                value={warranty_period}
                onChange={(e) => {
                  setWarranty_period(e.target.value);
                  updateValues("warranty_period", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        {/* ***************************************************** */}
        <Flex wrap="wrap">
          {distinctfield.includes("function_cooking") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Function Cooking"
                hasFeedback
                validateStatus={function_cookingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Function Cooking"
                  value={function_cooking}
                  onChange={(value) => {
                    setFunction_cooking(value);
                    updateValues("function_cooking", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={function_cooking === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("steaming") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Steaming"
                hasFeedback
                validateStatus={steamingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Steaming"
                  value={steaming}
                  onChange={(value) => {
                    setSteaming(value);
                    updateValues("steaming", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={steaming === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("non_stick_cooking_bowl") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="non Stick Cooking Bowl"
                hasFeedback
                validateStatus={
                  non_stick_cooking_bowlerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Non Stick Cooking Bowl"
                  value={non_stick_cooking_bowl}
                  onChange={(value) => {
                    setNon_stick_cooking_bowl(value);
                    updateValues("non_stick_cooking_bowl", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={non_stick_cooking_bowl === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("detachable_power_cord") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Detachable Power Cord"
                hasFeedback
                validateStatus={
                  detachable_power_corderror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Detachable Power Cord"
                  value={detachable_power_cord}
                  onChange={(value) => {
                    setDetachable_power_cord(value);
                    updateValues("detachable_power_cord", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={detachable_power_cord === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("see_through_glass_top") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="See Through Glass Top"
                hasFeedback
                validateStatus={
                  see_through_glass_toperror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select See Through Glass Top"
                  value={see_through_glass_top}
                  onChange={(value) => {
                    setSee_through_glass_top(value);
                    updateValues("see_through_glass_top", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={see_through_glass_top === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("measuring_cup") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Measuring Cup"
                hasFeedback
                validateStatus={measuring_cuperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Measuring Cup"
                  value={measuring_cup}
                  onChange={(value) => {
                    setMeasuring_cup(value);
                    updateValues("measuring_cup", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={measuring_cup === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("trivet") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Trivet"
                hasFeedback
                validateStatus={triveterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Trivet"
                  value={trivet}
                  onChange={(value) => {
                    setTrivet(value);
                    updateValues("trivet", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={trivet === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("temperature_glass_lid") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Temperature Glass Lid"
                hasFeedback
                validateStatus={
                  temperature_glass_liderror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Temperature Glass Lid"
                  value={temperature_glass_lid}
                  onChange={(value) => {
                    setTemperature_glass_lid(value);
                    updateValues("temperature_glass_lid", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Temperature_glass_lid.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={temperature_glass_lid === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("steaming_basket") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Steaming Basket"
                hasFeedback
                validateStatus={steaming_basketerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Steaming Basket"
                  value={steaming_basket}
                  onChange={(value) => {
                    setSteaming_basket(value);
                    updateValues("steaming_basket", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={steaming_basket === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("spatula") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Spatula"
                hasFeedback
                validateStatus={spatulaerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Spatula"
                  value={spatula}
                  onChange={(value) => {
                    setSpatula(value);
                    updateValues("spatula", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={spatula === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cool_touch_handles") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cool Touch Handles"
                hasFeedback
                validateStatus={cool_touch_handleserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cool Touch Handles"
                  value={cool_touch_handles}
                  onChange={(value) => {
                    setCool_touch_handles(value);
                    updateValues("cool_touch_handles", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cool_touch_handles === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("thermal_fuse") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Thermal Fuse"
                hasFeedback
                validateStatus={thermal_fuseerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Thermal Fuse"
                  value={thermal_fuse}
                  onChange={(value) => {
                    setThermal_fuse(value);
                    updateValues("thermal_fuse", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={thermal_fuse === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("motor_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Motor Speed"
                hasFeedback
                validateStatus={motor_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Motor Speed"
                  value={motor_speed}
                  onChange={(value) => {
                    setMotor_speed(value);
                    updateValues("motor_speed", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Motor_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={motor_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_stones") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Stones"
                hasFeedback
                validateStatus={number_of_stoneserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Stones"
                  value={number_of_stones}
                  onChange={(value) => {
                    setNumber_of_stones(value);
                    updateValues("number_of_stones", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_stones.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_stones === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("attachment_present") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Attachment Present"
                hasFeedback
                validateStatus={attachment_presenterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Attachment Present"
                  value={attachment_present}
                  onChange={(value) => {
                    setAttachment_present(value);
                    updateValues("attachment_present", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Attachment_present.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={attachment_present === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("motor_overload_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Motor Overload Protection"
                hasFeedback
                validateStatus={
                  motor_overload_protectionerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Motor Overload Protection"
                  value={motor_overload_protection}
                  onChange={(value) => {
                    setMotor_overload_protection(value);
                    updateValues("motor_overload_protection", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={motor_overload_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_off") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto OFF"
                hasFeedback
                validateStatus={auto_offerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Auto OFF"
                  value={auto_off}
                  onChange={(value) => {
                    setAuto_off(value);
                    updateValues("auto_off", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_off === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("safety_lock") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Safety Lock"
                hasFeedback
                validateStatus={safety_lockerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Safety Lock"
                  value={safety_lock}
                  onChange={(value) => {
                    setSafety_lock(value);
                    updateValues("safety_lock", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={safety_lock === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processing_bocol") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Processing Bocol"
                hasFeedback
                validateStatus={processing_bocolerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Processing Bocol"
                  value={processing_bocol}
                  onChange={(value) => {
                    setProcessing_bocol(value);
                    updateValues("processing_bocol", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Processing_bowl.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={processing_bocol === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processing_jar") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Processing Jar"
                hasFeedback
                validateStatus={processing_jarerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Processing Jar"
                  value={processing_jar}
                  onChange={(value) => {
                    setProcessing_jar(value);
                    updateValues("processing_jar", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Processing_jar.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={processing_jar === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_attached_jar") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number Of atteched Jar"
                hasFeedback
                validateStatus={
                  number_of_attached_jarerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number Of atteched Jar"
                  value={number_of_attached_jar}
                  onChange={(value) => {
                    setNumber_of_attached_jar(value);
                    updateValues("number_of_attached_jar", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_attached_jar.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_attached_jar === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("anti_drip_value") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Anti Drip Value"
                hasFeedback
                validateStatus={anti_drip_valueerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Anti Drip Value"
                  value={anti_drip_value}
                  onChange={(value) => {
                    setAnti_drip_value(value);
                    updateValues("anti_drip_value", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Anti_drip_value.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={anti_drip_value === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("brew_strength_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Brew Strength Control"
                hasFeedback
                validateStatus={
                  brew_strength_controlerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Brew Strength Control"
                  value={brew_strength_control}
                  onChange={(value) => {
                    setBrew_strength_control(value);
                    updateValues("brew_strength_control", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Brew_strength_control.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={brew_strength_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_drip") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="removable drip"
                hasFeedback
                validateStatus={removable_driperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select removable drip"
                  value={removable_drip}
                  onChange={(value) => {
                    setRemovable_drip(value);
                    updateValues("removable_drip", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Removable_drip.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_drip === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("frother") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Frother"
                hasFeedback
                validateStatus={frothererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Frother"
                  value={frother}
                  onChange={(value) => {
                    setFrother(value);
                    updateValues("frother", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Frother.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={frother === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("illuminated_on_off_switch") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Illuminated ON/OFF Switch"
                hasFeedback
                validateStatus={
                  illuminated_on_off_switcherror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Illuminated ON/OFF Switch"
                  value={illuminated_on_off_switch}
                  onChange={(value) => {
                    setIlluminated_on_off_switch(value);
                    updateValues("illuminated_on_off_switch", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={illuminated_on_off_switch === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hot_plate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hot Plate"
                hasFeedback
                validateStatus={hot_plateerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Hot Plate"
                  value={hot_plate}
                  onChange={(value) => {
                    setHot_plate(value);
                    updateValues("hot_plate", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Hot_plate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hot_plate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("net_quantity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Net Quantity"
                hasFeedback
                validateStatus={net_quantityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Net Quantity"
                  value={net_quantity}
                  onChange={(value) => {
                    setNet_quantity(value);
                    updateValues("net_quantity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Net_quantity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={net_quantity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("brewing_time") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Brewing Time"
                hasFeedback
                validateStatus={brewing_timeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Brewing Time"
                  value={brewing_time}
                  onChange={(value) => {
                    setBrewing_time(value);
                    updateValues("brewing_time", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Brewing_time.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={brewing_time === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_filter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Removable Filter"
                hasFeedback
                validateStatus={removable_filtererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Removable Filter"
                  value={removable_filter}
                  onChange={(value) => {
                    setRemovable_filter(value);
                    updateValues("removable_filter", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Removable_filter.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_filter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("other_dimensions") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Other Dimensions"
                hasFeedback
                validateStatus={other_dimensionserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Other Dimensions"
                  value={other_dimensions}
                  onChange={(value) => {
                    setOther_dimensions(value);
                    updateValues("other_dimensions", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Dimensions.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={other_dimensions === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("filter_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Filter Material"
                hasFeedback
                validateStatus={filter_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Filter Material"
                  value={filter_material}
                  onChange={(value) => {
                    setFilter_material(value);
                    updateValues("filter_material", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Filter_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={filter_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("temperature_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Temperature Control"
                hasFeedback
                validateStatus={temperature_controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Temperature Control"
                  value={temperature_control}
                  onChange={(value) => {
                    setTemperature_control(value);
                    updateValues("temperature_control", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={temperature_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("spray") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Spray"
                hasFeedback
                validateStatus={sprayerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Spray"
                  value={spray}
                  onChange={(value) => {
                    setSpray(value);
                    updateValues("spray", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={spray === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("steam_burst") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Steam Burst"
                hasFeedback
                validateStatus={steam_bursterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Steam Burst"
                  value={steam_burst}
                  onChange={(value) => {
                    setSteam_burst(value);
                    updateValues("steam_burst", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={steam_burst === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("self_cleaning") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Self Cleaning"
                hasFeedback
                validateStatus={self_cleaningerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Self Cleaning"
                  value={self_cleaning}
                  onChange={(value) => {
                    setSelf_cleaning(value);
                    updateValues("self_cleaning", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={self_cleaning === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("swivel_cord") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Swivel Cord"
                hasFeedback
                validateStatus={swivel_corderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Swivel Cord"
                  value={swivel_cord}
                  onChange={(value) => {
                    setSwivel_cord(value);
                    updateValues("swivel_cord", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={swivel_cord === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("electric_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Electric Type"
                hasFeedback
                validateStatus={electric_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Electric Type"
                  value={electric_type}
                  onChange={(value) => {
                    setElectric_type(value);
                    updateValues("electric_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Electric_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={electric_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("purification_features") && (
            <Col className="gutter-row" span={12}>
              <span>Purification Features:</span>
              <Input
                status={purification_featureserror !== "" ? "error" : ""}
                suffix={
                  purification_featureserror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Purification Features"
                label="Purification Features"
                value={purification_features}
                onChange={(e) => {
                  setPurification_features(e.target.value);
                  updateValues("purification_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("detachable_storage_tank") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Detachable Storage Tank"
                hasFeedback
                validateStatus={
                  detachable_storage_tankerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Detachable Storage Tank"
                  value={detachable_storage_tank}
                  onChange={(value) => {
                    setDetachable_storage_tank(value);
                    updateValues("detachable_storage_tank", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={detachable_storage_tank === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("membrane_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Membrane Type"
                hasFeedback
                validateStatus={membrane_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Membrane Type"
                  value={membrane_type}
                  onChange={(value) => {
                    setMembrane_type(value);
                    updateValues("membrane_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Membrane_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={membrane_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("overflow_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Overflow Protection"
                hasFeedback
                validateStatus={overflow_protectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Overflow Protection"
                  value={overflow_protection}
                  onChange={(value) => {
                    setOverflow_protection(value);
                    updateValues("overflow_protection", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={overflow_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("filter_life") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Filter Life"
                hasFeedback
                validateStatus={filter_lifeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Filter Life"
                  value={filter_life}
                  onChange={(value) => {
                    setFilter_life(value);
                    updateValues("filter_life", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Filter_life.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={filter_life === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("alarms") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Alarms"
                hasFeedback
                validateStatus={alarmserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Alarms"
                  value={alarms}
                  onChange={(value) => {
                    setAlarms(value);
                    updateValues("alarms", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={alarms === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("filtration_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Filtration Capacity"
                hasFeedback
                validateStatus={filtration_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Filtration Capacity"
                  value={filtration_capacity}
                  onChange={(value) => {
                    setFiltration_capacity(value);
                    updateValues("filtration_capacity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Filtration_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={filtration_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("purification_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Purification Capacity"
                hasFeedback
                validateStatus={
                  purification_capacityerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Purification Capacity"
                  value={purification_capacity}
                  onChange={(value) => {
                    setPurification_capacity(value);
                    updateValues("purification_capacity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Purification_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={purification_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cold_water_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cold Water Capacity"
                hasFeedback
                validateStatus={cold_water_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cold Water Capacity"
                  value={cold_water_capacity}
                  onChange={(value) => {
                    setCold_water_capacity(value);
                    updateValues("cold_water_capacity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Cold_water_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cold_water_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cold_water_dispensor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cold Water Dispensor"
                hasFeedback
                validateStatus={cold_water_dispensorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cold Water Dispensor"
                  value={cold_water_dispensor}
                  onChange={(value) => {
                    setCold_water_dispensor(value);
                    updateValues("cold_water_dispensor", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cold_water_dispensor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hot_water_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hot Water Capacity"
                hasFeedback
                validateStatus={hot_water_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Hot Water Capacity"
                  value={hot_water_capacity}
                  onChange={(value) => {
                    setHot_water_capacity(value);
                    updateValues("hot_water_capacity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Hot_water_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hot_water_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hot_water_dispensor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hot Water Dispensor"
                hasFeedback
                validateStatus={hot_water_dispensorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Hot Water Dispensor"
                  value={hot_water_dispensor}
                  onChange={(value) => {
                    setHot_water_dispensor(value);
                    updateValues("hot_water_dispensor", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hot_water_dispensor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("purification_stages") && (
            <Col className="gutter-row" span={12}>
              <span>Purification Stages:</span>
              <Input
                status={purification_stageserror !== "" ? "error" : ""}
                suffix={
                  purification_stageserror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Purification Stages"
                label="Purification Stages"
                value={purification_stages}
                onChange={(e) => {
                  setPurification_stages(e.target.value);
                  updateValues("purification_stages", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("airflow") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Airflow"
                hasFeedback
                validateStatus={airflowerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Airflow"
                  value={airflow}
                  onChange={(value) => {
                    setAirflow(value);
                    updateValues("airflow", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Air_flow.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={airflow === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blade_sweep") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blade Sweep"
                hasFeedback
                validateStatus={blade_sweeperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Blade Sweep"
                  value={blade_sweep}
                  onChange={(value) => {
                    setBlade_sweep(value);
                    updateValues("blade_sweep", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Blade_sweep.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blade_sweep === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_speeds") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Speeds"
                hasFeedback
                validateStatus={number_of_speedserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Speeds"
                  value={number_of_speeds}
                  onChange={(value) => {
                    setNumber_of_speeds(value);
                    updateValues("number_of_speeds", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_speeds.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_speeds === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cooling_coverage_area") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cooling Coverage Area"
                hasFeedback
                validateStatus={
                  cooling_coverage_areaerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cooling Coverage Area"
                  value={cooling_coverage_area}
                  onChange={(value) => {
                    setCooling_coverage_area(value);
                    updateValues("cooling_coverage_area", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Cooling_coverage_area.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cooling_coverage_area === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("speed_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Speed Control"
                hasFeedback
                validateStatus={speed_controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Speed Control"
                  value={speed_control}
                  onChange={(value) => {
                    setSpeed_control(value);
                    updateValues("speed_control", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Speed_control.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={speed_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("operating_mode") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Operating Mode"
                hasFeedback
                validateStatus={operating_modeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Operating Mode"
                  value={operating_mode}
                  onChange={(value) => {
                    setOperating_mode(value);
                    updateValues("operating_mode", value);
                  }}
                  style={{ width: 240 }}
                >
                  {operating_modess.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={operating_mode === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("air_delivery") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Air Delivery"
                hasFeedback
                validateStatus={air_deliveryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Air Delivery"
                  value={air_delivery}
                  onChange={(value) => {
                    setAir_delivery(value);
                    updateValues("air_delivery", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Air_delivery.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={air_delivery === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("air_deflection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Air Deflection"
                hasFeedback
                validateStatus={air_deflectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Air Deflection"
                  value={air_deflection}
                  onChange={(value) => {
                    setAir_deflection(value);
                    updateValues("air_deflection", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Air_deflection.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={air_deflection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blower_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blower Material"
                hasFeedback
                validateStatus={blower_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Blower Material"
                  value={blower_material}
                  onChange={(value) => {
                    setBlower_material(value);
                    updateValues("blower_material", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Blower_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blower_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ice_chamber") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ice Chamber"
                hasFeedback
                validateStatus={ice_chambererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Ice Chamber"
                  value={ice_chamber}
                  onChange={(value) => {
                    setIce_chamber(value);
                    updateValues("ice_chamber", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ice_chamber === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("caston_wheels") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Caston Wheels"
                hasFeedback
                validateStatus={caston_wheelserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Caston Wheels"
                  value={caston_wheels}
                  onChange={(value) => {
                    setCaston_wheels(value);
                    updateValues("caston_wheels", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={caston_wheels === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_caston_wheels") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Waston Wheels"
                hasFeedback
                validateStatus={
                  number_of_caston_wheelserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Waston Wheels"
                  value={number_of_caston_wheels}
                  onChange={(value) => {
                    setNumber_of_caston_wheels(value);
                    updateValues("number_of_caston_wheels", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_castor_wheels.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_caston_wheels === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_fan_blades") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Fan Blades"
                hasFeedback
                validateStatus={number_of_fan_bladeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Fan Blades"
                  value={number_of_fan_blades}
                  onChange={(value) => {
                    setNumber_of_fan_blades(value);
                    updateValues("number_of_fan_blades", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_blades.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_fan_blades === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_louver_movement") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Louver Movement"
                hasFeedback
                validateStatus={auto_louver_movementerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Auto Louver Movement"
                  value={auto_louver_movement}
                  onChange={(value) => {
                    setAuto_louver_movement(value);
                    updateValues("auto_louver_movement", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_louver_movement === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("oscillating_function") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Oscillating Function"
                hasFeedback
                validateStatus={oscillating_functionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Oscillating Function"
                  value={oscillating_function}
                  onChange={(value) => {
                    setOscillating_function(value);
                    updateValues("oscillating_function", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={oscillating_function === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("technology_used") && (
            <Col className="gutter-row" span={12}>
              <span>Technology Used:</span>
              <Input
                status={technology_usederror !== "" ? "error" : ""}
                suffix={
                  technology_usederror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Technology Used"
                label="Technology Used"
                value={technology_used}
                onChange={(e) => {
                  setTechnology_used(e.target.value);
                  updateValues("technology_used", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blower") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blower"
                hasFeedback
                validateStatus={blowererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Blower"
                  value={blower}
                  onChange={(value) => {
                    setBlower(value);
                    updateValues("blower", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Blower.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blower === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("motor_suction_power") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Motor Suction Power"
                hasFeedback
                validateStatus={motor_suction_powererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Motor Suction Power"
                  value={motor_suction_power}
                  onChange={(value) => {
                    setMotor_suction_power(value);
                    updateValues("motor_suction_power", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Motor_suction_power.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={motor_suction_power === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("solar_power") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Solar Power"
                hasFeedback
                validateStatus={solar_powererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Solar Power"
                  value={solar_power}
                  onChange={(value) => {
                    setSolar_power(value);
                    updateValues("solar_power", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={solar_power === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("efficiency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Efficiency"
                hasFeedback
                validateStatus={efficiencyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Efficiency"
                  value={efficiency}
                  onChange={(value) => {
                    setEfficiency(value);
                    updateValues("efficiency", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Efficiency.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={efficiency === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("output_voltage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Output Voltage"
                hasFeedback
                validateStatus={output_voltageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Output Voltage"
                  value={output_voltage}
                  onChange={(value) => {
                    setOutput_voltage(value);
                    updateValues("output_voltage", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Output_voltage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={output_voltage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        {/* ************************************************************************************************** */}
        <Flex wrap="wrap">
          {distinctfield.includes("defrosting_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Defrosting Type"
                hasFeedback
                validateStatus={defrosting_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Defrosting Type"
                  onChange={(value) => {
                    setDefrosting_type(value);
                    updateValues("defrosting_type", value);
                  }}
                  value={defrosting_type}
                  style={{ width: 240 }}
                >
                  {Defrosting_Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={defrosting_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("coolpad") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Coolpad"
                hasFeedback
                validateStatus={coolpaderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Coolpad"
                  onChange={(value) => {
                    setCoolpad(value);
                    updateValues("coolpad", value);
                  }}
                  value={coolpad}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={coolpad === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("build_in_stabilizer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Build in Stabilizer"
                hasFeedback
                validateStatus={build_in_stabilizererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Build in Stabilizer"
                  onChange={(value) => {
                    setBuild_in_stabilizer(value);
                    updateValues("build_in_stabilizer", value);
                  }}
                  value={build_in_stabilizer}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={build_in_stabilizer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("toughened_glass") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Toughened Glass"
                hasFeedback
                validateStatus={toughened_glasserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Toughened Glass"
                  onChange={(value) => {
                    setToughened_glass(value);
                    updateValues("toughened_glass", value);
                  }}
                  value={toughened_glass}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={toughened_glass === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("moisture_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Moisture Control"
                hasFeedback
                validateStatus={moisture_controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Moisture Control"
                  onChange={(value) => {
                    setMoisture_control(value);
                    updateValues("moisture_control", value);
                  }}
                  value={moisture_control}
                  style={{ width: 240 }}
                >
                  {Moisture_control.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={moisture_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("design") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Design"
                hasFeedback
                validateStatus={designerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Design"
                  onChange={(value) => {
                    setDesign(value);
                    updateValues("design", value);
                  }}
                  value={design}
                  style={{ width: 240 }}
                >
                  {Design.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={design === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("door_finish") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Door Finish"
                hasFeedback
                validateStatus={door_finisherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Door Finish"
                  onChange={(value) => {
                    setDoor_finish(value);
                    updateValues("door_finish", value);
                  }}
                  value={door_finish}
                  style={{ width: 240 }}
                >
                  {Door_finish.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={door_finish === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("door_lock") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Door Lock"
                hasFeedback
                validateStatus={door_lockerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Door Lock"
                  onChange={(value) => {
                    setDoor_lock(value);
                    updateValues("door_lock", value);
                  }}
                  value={door_lock}
                  style={{ width: 240 }}
                >
                  {Door_lock.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={door_lock === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_doors") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number oF Doors"
                hasFeedback
                validateStatus={number_of_doorserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number oF Doors"
                  onChange={(value) => {
                    setNumber_of_doors(value);
                    updateValues("number_of_doors", value);
                  }}
                  value={number_of_doors}
                  style={{ width: 240 }}
                >
                  {Number_of_doors.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_doors === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("output_frequency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Output Frequency"
                hasFeedback
                validateStatus={output_frequencyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Output Frequency"
                  value={output_frequency}
                  onChange={(value) => {
                    setOutput_frequency(value);
                    updateValues("output_frequency", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Output_frequency.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={output_frequency === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_stitches") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Stitches"
                hasFeedback
                validateStatus={number_of_stitcheserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Stitches"
                  value={number_of_stitches}
                  onChange={(value) => {
                    setNumber_of_stitches(value);
                    updateValues("number_of_stitches", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_stitches.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_stitches === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_button_holes_styles") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Button Holes Styles"
                hasFeedback
                validateStatus={
                  number_of_button_holes_styleserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Button Holes Styles"
                  value={number_of_button_holes_styles}
                  onChange={(value) => {
                    setNumber_of_button_holes_styles(value);
                    updateValues("number_of_button_holes_styles", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_button_hole_styles.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_button_holes_styles === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sewing_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sewing Speed"
                hasFeedback
                validateStatus={sewing_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sewing Speed"
                  value={sewing_speed}
                  onChange={(value) => {
                    setSewing_speed(value);
                    updateValues("sewing_speed", value);
                  }}
                  style={{ width: 240 }}
                >
                  {sweing_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sewing_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lcd_screen_display") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lcd Screen Display"
                hasFeedback
                validateStatus={lcd_screen_displayerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Lcd Screen Display"
                  value={lcd_screen_display}
                  onChange={(value) => {
                    setLcd_screen_display(value);
                    updateValues("lcd_screen_display", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Lcd_screen_display.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lcd_screen_display === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("shape") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Shape"
                hasFeedback
                validateStatus={shapeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Shape"
                  value={shape}
                  onChange={(value) => {
                    setShape(value);
                    updateValues("shape", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Shape.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={shape === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("bobbin_winder") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Bobbin Winder"
                hasFeedback
                validateStatus={bobbin_windererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Bobbin Winder"
                  value={bobbin_winder}
                  onChange={(value) => {
                    setBobbin_winder(value);
                    updateValues("bobbin_winder", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Bobbin_winder.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={bobbin_winder === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stop_start_button") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stop Start Button"
                hasFeedback
                validateStatus={stop_start_buttonerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Stop Start Button"
                  value={stop_start_button}
                  onChange={(value) => {
                    setStop_start_button(value);
                    updateValues("stop_start_button", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Stop_start_button.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stop_start_button === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stitch_out_time_indicator") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stitch Out Time Indicator"
                hasFeedback
                validateStatus={
                  stitch_out_time_indicatorerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Stitch Out Time Indicator"
                  value={stitch_out_time_indicator}
                  onChange={(value) => {
                    setStitch_out_time_indicator(value);
                    updateValues("stitch_out_time_indicator", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Stitch_out_time_indicator.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stitch_out_time_indicator === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("led_needle_light") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="LED Needle Light"
                hasFeedback
                validateStatus={led_needle_lighterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select LED Needle Light"
                  value={led_needle_light}
                  onChange={(value) => {
                    setLed_needle_light(value);
                    updateValues("led_needle_light", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Led_needle_light.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={led_needle_light === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("automatic_thread_cutter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Automatic Thread Cutter"
                hasFeedback
                validateStatus={
                  automatic_thread_cuttererror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Automatic Thread Cutter"
                  value={automatic_thread_cutter}
                  onChange={(value) => {
                    setAutomatic_thread_cutter(value);
                    updateValues("automatic_thread_cutter", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Automatic_thread_cutter.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={automatic_thread_cutter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("water_ice_dispenser") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Water Ice Dispenser"
                hasFeedback
                validateStatus={water_ice_dispensererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Water Ice Dispenser"
                  value={water_ice_dispenser}
                  onChange={(value) => {
                    setWater_ice_dispenser(value);
                    updateValues("water_ice_dispenser", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Water_ice_dispenser.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={water_ice_dispenser === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stabilizer_required") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stabilizer Required"
                hasFeedback
                validateStatus={stabilizer_requirederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Stabilizer Required"
                  value={stabilizer_required}
                  onChange={(value) => {
                    setStabilizer_required(value);
                    updateValues("stabilizer_required", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stabilizer_required === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("egg_tray") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Egg Tray"
                hasFeedback
                validateStatus={egg_trayerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Egg Tray"
                  value={egg_tray}
                  onChange={(value) => {
                    setEgg_tray(value);
                    updateValues("egg_tray", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={egg_tray === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("launch_year") && (
            <Col className="gutter-row" span={8}>
              <span>Launch Year:</span>
              <Input
                status={launch_yearerror !== "" ? "error" : ""}
                suffix={
                  launch_yearerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Launch Year"
                label="Launch Year"
                value={launch_year}
                onChange={(e) => {
                  setLaunch_year(e.target.value);
                  updateValues("launch_year", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("deodarizer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Deodarizer"
                hasFeedback
                validateStatus={deodarizererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Deodarizer"
                  value={deodarizer}
                  onChange={(value) => {
                    setDeodarizer(value);
                    updateValues("deodarizer", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={deodarizer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ice_cub_tray_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ice Cub Tray Type"
                hasFeedback
                validateStatus={ice_cub_tray_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Ice Cub Tray Type"
                  value={ice_cub_tray_type}
                  onChange={(value) => {
                    setIce_cub_tray_type(value);
                    updateValues("ice_cub_tray_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Ice_cube_tray_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ice_cub_tray_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wheel_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wheel Support"
                hasFeedback
                validateStatus={wheel_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wheel Support"
                  value={wheel_support}
                  onChange={(value) => {
                    setWheel_support(value);
                    updateValues("wheel_support", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wheel_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_basket") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Removable Basket"
                hasFeedback
                validateStatus={removable_basketerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Removable Basket"
                  value={removable_basket}
                  onChange={(value) => {
                    setRemovable_basket(value);
                    updateValues("removable_basket", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Removable_basket.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_basket === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("flexible_rack") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Flexible Rack"
                hasFeedback
                validateStatus={flexible_rackerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Flexible Rack"
                  value={flexible_rack}
                  onChange={(value) => {
                    setFlexible_rack(value);
                    updateValues("flexible_rack", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={flexible_rack === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_rack") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Removable Rack"
                hasFeedback
                validateStatus={removable_rackerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Removable Rack"
                  value={removable_rack}
                  onChange={(value) => {
                    setRemovable_rack(value);
                    updateValues("removable_rack", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_rack === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        {/* -------------------------------------------------------------------------- */}
        <Flex wrap="wrap">
          {distinctfield.includes("headphone_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Headphone Type"
                hasFeedback
                validateStatus={headphone_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Headphone Type"
                  value={headphone_type}
                  onChange={(value) => {
                    setHeadphone_type(value);
                    updateValues("headphone_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Headphone_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={headphone_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("inline_remote") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Inline Remote"
                hasFeedback
                validateStatus={inline_remoteerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Inline Remote"
                  value={inline_remote}
                  onChange={(value) => {
                    setInline_remote(value);
                    updateValues("inline_remote", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={inline_remote === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("connectivity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Connectivity"
                hasFeedback
                validateStatus={connectivityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Connectivity"
                  value={connectivity}
                  onChange={(value) => {
                    setConnectivity(value);
                    updateValues("connectivity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Connectivity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={connectivity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("headphone_design") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Headphone Design"
                hasFeedback
                validateStatus={headphone_designerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Headphone Design"
                  value={headphone_design}
                  onChange={(value) => {
                    setHeadphone_design(value);
                    updateValues("headphone_design", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Headphone_design.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={headphone_design === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("foldable_or_collapsible") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Foldable or Collapsible"
                hasFeedback
                validateStatus={
                  foldable_or_collapsibleerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Foldable or Collapsible"
                  value={foldable_or_collapsible}
                  onChange={(value) => {
                    setFoldable_or_collapsible(value);
                    updateValues("foldable_or_collapsible", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Foldable_or_collapsible.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={foldable_or_collapsible === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("deep_bass") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="deep_bass"
                hasFeedback
                validateStatus={deep_basserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select deep_bass"
                  value={deep_bass}
                  onChange={(value) => {
                    setDeep_bass(value);
                    updateValues("deep_bass", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={deep_bass === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("manual") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Manual"
                hasFeedback
                validateStatus={manualerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Manual"
                  value={manual}
                  onChange={(value) => {
                    setManual(value);
                    updateValues("manual", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Manual.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={manual === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("magnet_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Magnet Type"
                hasFeedback
                validateStatus={magnet_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Magnet Type"
                  value={magnet_type}
                  onChange={(value) => {
                    setMagnet_type(value);
                    updateValues("magnet_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Magnet_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={magnet_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("driver_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="driver_type"
                hasFeedback
                validateStatus={driver_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select driver_type"
                  value={driver_type}
                  onChange={(value) => {
                    setdriver_type(value);
                    updateValues("driver_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Driver_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={driver_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("indicators") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Indicators"
                hasFeedback
                validateStatus={indicatorserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Indicators"
                  value={indicators}
                  onChange={(value) => {
                    setIndicators(value);
                    updateValues("indicators", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={indicators === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sensitivity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="sensitivity"
                hasFeedback
                validateStatus={sensitivityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select sensitivity"
                  value={sensitivity}
                  onChange={(value) => {
                    setsensitivity(value);
                    updateValues("sensitivity", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sensitivity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sensitivity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("impedance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Impedance"
                hasFeedback
                validateStatus={impedanceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Impedance"
                  value={impedance}
                  onChange={(value) => {
                    setImpedance(value);
                    updateValues("impedance", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Impedence.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={impedance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("signal_to_noise_ratio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Signal To Noise Ratio"
                hasFeedback
                validateStatus={
                  signal_to_noise_ratioerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Signal To Noise Ratio"
                  value={signal_to_noise_ratio}
                  onChange={(value) => {
                    setSignal_to_noise_ratio(value);
                    updateValues("signal_to_noise_ratio", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Signal_to_noise_ratio.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={signal_to_noise_ratio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("modulation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Modulation"
                hasFeedback
                validateStatus={modulationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Modulation"
                  value={modulation}
                  onChange={(value) => {
                    setModulation(value);
                    updateValues("modulation", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Modulation.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={modulation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("minimum_frequency_response") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum Frequency Response"
                hasFeedback
                validateStatus={
                  minimum_frequency_responseerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Minimum Frequency Response"
                  value={minimum_frequency_response}
                  onChange={(value) => {
                    setMinimum_frequency_response(value);
                    updateValues("minimum_frequency_response", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Minimum_frequency_response.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={minimum_frequency_response === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("other_sound_features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Other Sound Features"
                hasFeedback
                validateStatus={other_sound_featureserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Other Sound Features"
                  value={other_sound_features}
                  onChange={(value) => {
                    setOther_sound_features(value);
                    updateValues("other_sound_features", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Other_sound_features.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={other_sound_features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("audio_codec") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Audio Codec"
                hasFeedback
                validateStatus={audio_codecerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Audio Codec"
                  value={audio_codec}
                  onChange={(value) => {
                    setAudio_codec(value);
                    updateValues("audio_codec", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Audio_codec.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={audio_codec === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blutooth_profile") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blutooth Profile"
                hasFeedback
                validateStatus={blutooth_profileerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Blutooth Profile"
                  value={blutooth_profile}
                  onChange={(value) => {
                    setBlutooth_profile(value);
                    updateValues("blutooth_profile", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Bluetooth_profile.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blutooth_profile === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blutooth_range") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blutooth Range"
                hasFeedback
                validateStatus={blutooth_rangeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Blutooth Range"
                  value={blutooth_range}
                  onChange={(value) => {
                    setBlutooth_range(value);
                    updateValues("blutooth_range", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Bluetooth_range.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blutooth_range === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("headphone_power_source") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Headphone Power Source"
                hasFeedback
                validateStatus={
                  headphone_power_sourceerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Headphone Power Source"
                  value={headphone_power_source}
                  onChange={(value) => {
                    setHeadphone_power_source(value);
                    updateValues("headphone_power_source", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Headphone_power_source.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={headphone_power_source === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("power_supply") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Power Supply"
                hasFeedback
                validateStatus={power_supplyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Power Supply"
                  value={power_supply}
                  onChange={(value) => {
                    setPower_supply(value);
                    updateValues("power_supply", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Power_output.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={power_supply === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_life") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery Life"
                hasFeedback
                validateStatus={battery_lifeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Battery Life"
                  value={battery_life}
                  onChange={(value) => {
                    setBattery_life(value);
                    updateValues("battery_life", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Battery_life.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_life === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stand_by_time") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stand by Time"
                hasFeedback
                validateStatus={stand_by_timeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Stand by Time"
                  value={stand_by_time}
                  onChange={(value) => {
                    setStand_by_time(value);
                    updateValues("stand_by_time", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Stand_by_time.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stand_by_time === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("applied") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Applied"
                hasFeedback
                validateStatus={appliederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Applied"
                  value={applied}
                  onChange={(value) => {
                    setApplied(value);
                    updateValues("applied", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_layers.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={applied === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_layers") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Layers"
                hasFeedback
                validateStatus={number_of_layerserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Layers"
                  value={number_of_layers}
                  onChange={(value) => {
                    setNumber_of_layers(value);
                    updateValues("number_of_layers", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_layers.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_layers === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("in_rescidue_free_removal") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="In Rescidue Free Removal"
                hasFeedback
                validateStatus={
                  in_rescidue_free_removalerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select In Rescidue Free Removal"
                  value={in_rescidue_free_removal}
                  onChange={(value) => {
                    setIn_rescidue_free_removal(value);
                    updateValues("in_rescidue_free_removal", value);
                  }}
                  style={{ width: 240 }}
                >
                  {In_residue_free_removal.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={in_rescidue_free_removal === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("tinted") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Tinted"
                hasFeedback
                validateStatus={tintederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Tinted"
                  value={tinted}
                  onChange={(value) => {
                    setTinted(value);
                    updateValues("tinted", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Tinted.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={tinted === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("camera_hole_present") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Camera Pole Present"
                hasFeedback
                validateStatus={camera_hole_presenterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Camera Pole Present"
                  value={camera_hole_present}
                  onChange={(value) => {
                    setcamera_hole_present(value);
                    updateValues("camera_hole_present", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={camera_hole_present === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("usage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Usage"
                hasFeedback
                validateStatus={usageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Usage"
                  value={usage}
                  onChange={(value) => {
                    setUsage(value);
                    updateValues("usage", value);
                  }}
                  style={{ width: 240 }}
                >
                  {mobiles_Usage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={usage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("operating_system_supported") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Operating System Supported"
                hasFeedback
                validateStatus={
                  operating_system_supportederror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Operating System Supported"
                  value={operating_system_supported}
                  onChange={(value) => {
                    setOperating_system_supported(value);
                    updateValues("operating_system_supported", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Operating_system_supported.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={operating_system_supported === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("type_of_tracking") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Type of Tracking"
                hasFeedback
                validateStatus={type_of_trackingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Type of Tracking"
                  value={type_of_tracking}
                  onChange={(value) => {
                    setType_of_tracking(value);
                    updateValues("type_of_tracking", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Type_of_tracking.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={type_of_tracking === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_trasperancy") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Trasperancy"
                hasFeedback
                validateStatus={auto_trasperancyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Auto Trasperancy"
                  value={auto_trasperancy}
                  onChange={(value) => {
                    setAuto_trasperancy(value);
                    updateValues("auto_trasperancy", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_trasperancy === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display"
                hasFeedback
                validateStatus={displayerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Display"
                  value={display}
                  onChange={(value) => {
                    setDisplay(value);
                    updateValues("display", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wireless_data_processing") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wireless Data Processing"
                hasFeedback
                validateStatus={
                  wireless_data_processingerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wireless Data Processing"
                  value={wireless_data_processing}
                  onChange={(value) => {
                    setWireless_data_processing(value);
                    updateValues("wireless_data_processing", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wireless_data_processing === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("one_touch_button") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="One Touch Button"
                hasFeedback
                validateStatus={one_touch_buttonerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select One Touch Button"
                  value={one_touch_button}
                  onChange={(value) => {
                    setOne_touch_button(value);
                    updateValues("one_touch_button", value);
                  }}
                  style={{ width: 240 }}
                >
                  {One_touch_button.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={one_touch_button === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("touch_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Touch Control"
                hasFeedback
                validateStatus={touch_controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Touch Control"
                  value={touch_control}
                  onChange={(value) => {
                    setTouch_control(value);
                    updateValues("touch_control", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Touch_control.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={touch_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_included") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery Included"
                hasFeedback
                validateStatus={battery_includederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Battery Included"
                  value={battery_included}
                  onChange={(value) => {
                    setBattery_included(value);
                    updateValues("battery_included", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_included === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_chargable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery Chargable"
                hasFeedback
                validateStatus={battery_chargableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Battery Chargable"
                  value={battery_chargable}
                  onChange={(value) => {
                    setBattery_chargable(value);
                    updateValues("battery_chargable", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_chargable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("water_resistance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Water Resistance"
                hasFeedback
                validateStatus={water_resistanceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Water Resistance"
                  value={water_resistance}
                  onChange={(value) => {
                    setWater_resistance(value);
                    updateValues("water_resistance", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={water_resistance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("noise_concellation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Noise Concellation"
                hasFeedback
                validateStatus={noise_concellationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Noise Concellation"
                  value={noise_concellation}
                  onChange={(value) => {
                    setNoise_concellation(value);
                    updateValues("noise_concellation", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={noise_concellation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_contents_in_sales_pack") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Contents in Sales Pack"
                hasFeedback
                validateStatus={
                  number_of_contents_in_sales_packerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Contents in Sales Pack"
                  value={number_of_contents_in_sales_pack}
                  onChange={(value) => {
                    setNumber_of_contents_in_sales_pack(value);
                    updateValues("number_of_contents_in_sales_pack", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_contents_in_sales_pack.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_contents_in_sales_pack === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cable_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cable Type"
                hasFeedback
                validateStatus={cable_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cable Type"
                  value={cable_type}
                  onChange={(value) => {
                    setCable_type(value);
                    updateValues("cable_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Cable_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cable_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("connector1") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Connector1"
                hasFeedback
                validateStatus={connector1error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Connector1"
                  value={connector1}
                  onChange={(value) => {
                    setConnector1(value);
                    updateValues("connector1", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Connector_1.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={connector1 === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("connector2") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Connector2"
                hasFeedback
                validateStatus={connector2error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Connector2"
                  value={connector2}
                  onChange={(value) => {
                    setConnector2(value);
                    updateValues("connector2", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Connector_2.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={connector2 === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cable"
                hasFeedback
                validateStatus={cableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cable"
                  value={cable}
                  onChange={(value) => {
                    setCable(value);
                    updateValues("cable", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Cable.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("model") && (
            <Col className="gutter-row" span={12}>
              <span>Model:</span>
              <Input
                status={modelerror !== "" ? "error" : ""}
                suffix={modelerror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter Model"
                label="Model"
                value={model}
                onChange={(e) => {
                  setModel(e.target.value);
                  updateValues("model", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("compatible_devices") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Compatible Devices"
                hasFeedback
                validateStatus={compatible_deviceserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Compatible Devices"
                  value={compatible_devices}
                  onChange={(value) => {
                    setCompatible_devices(value);
                    updateValues("compatible_devices", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Compatitle_devices.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={compatible_devices === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cable_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cable Length"
                hasFeedback
                validateStatus={cable_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cable Length"
                  value={cable_length}
                  onChange={(value) => {
                    setCable_length(value);
                    updateValues("cable_length", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Cord_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cable_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("keyboard") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Keyboard"
                hasFeedback
                validateStatus={keyboarderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Keyboard"
                  value={keyboard}
                  onChange={(value) => {
                    setKeyboard(value);
                    updateValues("keyboard", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Keyboard_Type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={keyboard === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("security_chip") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Security Chip"
                hasFeedback
                validateStatus={security_chiperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Security Chip"
                  value={security_chip}
                  onChange={(value) => {
                    setSecurity_chip(value);
                    updateValues("security_chip", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={security_chip === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("web_camera") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Web Camera"
                hasFeedback
                validateStatus={web_cameraerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Web Camera"
                  value={web_camera}
                  onChange={(value) => {
                    setWeb_camera(value);
                    updateValues("web_camera", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Web_camera.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={web_camera === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("finger_print_sensor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Finger Print Sensor"
                hasFeedback
                validateStatus={finger_print_sensorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Finger Print Sensor"
                  value={finger_print_sensor}
                  onChange={(value) => {
                    setFinger_print_sensor(value);
                    updateValues("finger_print_sensor", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={finger_print_sensor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processor_variant") && (
            <Col className="gutter-row" span={12}>
              <span>Processor Varient:</span>
              <Input
                status={processor_varianterror !== "" ? "error" : ""}
                suffix={
                  processor_varianterror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Processor Varient"
                label="Processor Varient"
                value={processor_variant}
                onChange={(e) => {
                  setProcessor_variant(e.target.value);
                  updateValues("processor_variant", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cache") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cache"
                hasFeedback
                validateStatus={cacheerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cache"
                  value={cache}
                  onChange={(value) => {
                    setCache(value);
                    updateValues("cache", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Cache.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cache === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_cores") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="number_of_cores"
                hasFeedback
                validateStatus={number_of_coreserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number_of Cores"
                  value={number_of_cores}
                  onChange={(value) => {
                    setNumber_of_cores(value);
                    updateValues("number_of_cores", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_cores.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_cores === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("os_architecture") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="OS Architecture"
                hasFeedback
                validateStatus={os_architectureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select OS Architecture"
                  value={os_architecture}
                  onChange={(value) => {
                    setOs_architecture(value);
                    updateValues("os_architecture", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Os_architecture.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={os_architecture === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("mic_in") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="MIC In"
                hasFeedback
                validateStatus={mic_inerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select MIC In"
                  value={mic_in}
                  onChange={(value) => {
                    setMic_in(value);
                    updateValues("mic_in", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={mic_in === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("rj45") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Rj45"
                hasFeedback
                validateStatus={rj45error !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Rj45"
                  value={rj45}
                  onChange={(value) => {
                    setRj45(value);
                    updateValues("rj45", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={rj45 === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("multi_card_slot") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Multi Card Slot"
                hasFeedback
                validateStatus={multi_card_sloterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Multi Card Slot"
                  value={multi_card_slot}
                  onChange={(value) => {
                    setMulti_card_slot(value);
                    updateValues("multi_card_slot", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Multi_card_slot.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={multi_card_slot === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hardware_interface") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hardware Interface"
                hasFeedback
                validateStatus={hardware_interfaceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Hardware Interface"
                  value={hardware_interface}
                  onChange={(value) => {
                    setHardware_interface(value);
                    updateValues("hardware_interface", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Hardware_interface.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hardware_interface === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("disk_drive") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="disk_drive"
                hasFeedback
                validateStatus={disk_driveerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select disk_drive"
                  value={disk_drive}
                  onChange={(value) => {
                    setDisk_drive(value);
                    updateValues("disk_drive", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={disk_drive === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("connector_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Connector Type"
                hasFeedback
                validateStatus={connector_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Connector Type"
                  value={connector_type}
                  onChange={(value) => {
                    setConnector_type(value);
                    updateValues("connector_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Connector_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={connector_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_devices") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Devices"
                hasFeedback
                validateStatus={number_of_deviceserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Devices"
                  value={number_of_devices}
                  onChange={(value) => {
                    setNumber_of_devices(value);
                    updateValues("number_of_devices", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_devices_supported.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_devices === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_charger_pins") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Charger Pins"
                hasFeedback
                validateStatus={
                  number_of_charger_pinserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Charger Pins"
                  value={number_of_charger_pins}
                  onChange={(value) => {
                    setNumber_of_charger_pins(value);
                    updateValues("number_of_charger_pins", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_charger_pins.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_charger_pins === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("output_current") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Output Current"
                hasFeedback
                validateStatus={output_currenterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Output Current"
                  value={output_current}
                  onChange={(value) => {
                    setOutput_current(value);
                    updateValues("output_current", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Output_current.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={output_current === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("pointer_device") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pointer device"
                hasFeedback
                validateStatus={pointer_deviceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Pointer device"
                  onChange={(value) => {
                    setPointer_device(value);
                    updateValues("pointer_device", value);
                  }}
                  value={pointer_device}
                  style={{ width: 240 }}
                >
                  {Pointer_device.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pointer_device === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("emmc_storage_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Emmc Storage Capacity"
                hasFeedback
                validateStatus={
                  emmc_storage_capacityerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Emmc Storage Capacity"
                  onChange={(value) => {
                    setEmmc_storage_capacity(value);
                    updateValues("emmc_storage_capacity", value);
                  }}
                  value={emmc_storage_capacity}
                  style={{ width: 240 }}
                >
                  {Emmc_storage_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={emmc_storage_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ram_frequency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ram Frequency"
                hasFeedback
                validateStatus={ram_frequencyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ram Frequency"
                  onChange={(value) => {
                    setRam_frequency(value);
                    updateValues("ram_frequency", value);
                  }}
                  value={ram_frequency}
                  style={{ width: 240 }}
                >
                  {Ram_frequency.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ram_frequency === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("screen_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Screen Size"
                hasFeedback
                validateStatus={screen_sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Screen Size"
                  onChange={(value) => {
                    setScreen_size(value);
                    updateValues("screen_size", value);
                  }}
                  value={screen_size}
                  style={{ width: 240 }}
                >
                  {Screen_Size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={screen_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("screen_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Screen Type"
                hasFeedback
                validateStatus={screen_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Screen Type"
                  onChange={(value) => {
                    setScreen_type(value);
                    updateValues("screen_type", value);
                  }}
                  value={screen_type}
                  style={{ width: 240 }}
                >
                  {laptop_Screen_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={screen_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("speakers") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Speakers"
                hasFeedback
                validateStatus={speakerserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Speakers"
                  onChange={(value) => {
                    setSpeakers(value);
                    updateValues("speakers", value);
                  }}
                  value={speakers}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={speakers === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("internal_mic") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Internal Mic"
                hasFeedback
                validateStatus={internal_micerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Internal Mic"
                  onChange={(value) => {
                    setInternal_mic(value);
                    updateValues("internal_mic", value);
                  }}
                  value={internal_mic}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={internal_mic === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wireless_lan") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wireless Lan"
                hasFeedback
                validateStatus={wireless_lanerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wireless Lan"
                  onChange={(value) => {
                    setWireless_lan(value);
                    updateValues("wireless_lan", value);
                  }}
                  value={wireless_lan}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wireless_lan === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dedicated_graphic_memory_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dedicated Graphicmemory Type"
                hasFeedback
                validateStatus={
                  dedicated_graphic_memory_typeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dedicated Graphicmemory Type"
                  onChange={(value) => {
                    setDedicated_graphic_memory_type(value);
                    updateValues("dedicated_graphic_memory_type", value);
                  }}
                  value={dedicated_graphic_memory_type}
                  style={{ width: 240 }}
                >
                  {Dedicated_graphic_memory_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dedicated_graphic_memory_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dedicated_graphic_memory_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dedicated Graphic Memory Capacity"
                hasFeedback
                validateStatus={
                  dedicated_graphic_memory_capacityerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dedicated Graphic Memory Capacity"
                  onChange={(value) => {
                    setDedicated_graphic_memory_capacity(value);
                    updateValues("dedicated_graphic_memory_capacity", value);
                  }}
                  value={dedicated_graphic_memory_capacity}
                  style={{ width: 240 }}
                >
                  {Dedicated_graphic_memory_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={
                        dedicated_graphic_memory_capacity === data.label
                      }
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ethernet") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ethernet"
                hasFeedback
                validateStatus={etherneterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ethernet"
                  onChange={(value) => {
                    setEthernet(value);
                    updateValues("ethernet", value);
                  }}
                  value={ethernet}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ethernet === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("backlit_keyboard") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Backlit Keyboard"
                hasFeedback
                validateStatus={backlit_keyboarderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Backlit Keyboard"
                  onChange={(value) => {
                    setBacklit_keyboard(value);
                    updateValues("backlit_keyboard", value);
                  }}
                  value={backlit_keyboard}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={backlit_keyboard === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("memory_slot") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Memory Slot"
                hasFeedback
                validateStatus={memory_sloterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Memory Slot"
                  onChange={(value) => {
                    setMemory_slot(value);
                    updateValues("memory_slot", value);
                  }}
                  value={memory_slot}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={memory_slot === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("expandle_memory") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Expandle Memory"
                hasFeedback
                validateStatus={expandle_memoryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Expandle Memory"
                  onChange={(value) => {
                    setExpandle_memory(value);
                    updateValues("expandle_memory", value);
                  }}
                  value={expandle_memory}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={expandle_memory === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sound_properties") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sound Properties"
                hasFeedback
                validateStatus={sound_propertieserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sound Properties"
                  onChange={(value) => {
                    setSound_properties(value);
                    updateValues("sound_properties", value);
                  }}
                  value={sound_properties}
                  style={{ width: 240 }}
                >
                  {laptop_Sound_properties.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sound_properties === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lock_port") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lock Port"
                hasFeedback
                validateStatus={lock_porterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Lock Port"
                  onChange={(value) => {
                    setLock_port(value);
                    updateValues("lock_port", value);
                  }}
                  value={lock_port}
                  style={{ width: 240 }}
                >
                  {Lock_port.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lock_port === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("operating_system_version") && (
            <Col className="gutter-row" span={12}>
              <span>Operating system version:</span>
              <Input
                status={operating_system_versionerror !== "" ? "error" : ""}
                suffix={
                  operating_system_versionerror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Operating system version"
                label="Operating system version"
                value={operating_system_version}
                onChange={(e) => {
                  setOperating_system_version(e.target.value);
                  updateValues("operating_system_version", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("voice_call") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Voice Call"
                hasFeedback
                validateStatus={voice_callerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Voice Call"
                  onChange={(value) => {
                    setVoice_call(value);
                    updateValues("voice_call", value);
                  }}
                  value={voice_call}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={voice_call === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display_resolution_time") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display Resolution Time"
                hasFeedback
                validateStatus={
                  display_resolution_timeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Display Resolution Time"
                  onChange={(value) => {
                    setDisplay_resolution_time(value);
                    updateValues("display_resolution_time", value);
                  }}
                  value={display_resolution_time}
                  style={{ width: 240 }}
                >
                  {Display_resolution_time.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display_resolution_time === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display_support_network") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display Support Network"
                hasFeedback
                validateStatus={
                  display_support_networkerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Display Support Network"
                  onChange={(value) => {
                    setDisplay_support_network(value);
                    updateValues("display_support_network", value);
                  }}
                  value={display_support_network}
                  style={{ width: 240 }}
                >
                  {Display_support_network.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display_support_network === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display_resolution") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display Resolution"
                hasFeedback
                validateStatus={display_resolutionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Display Resolution"
                  onChange={(value) => {
                    setDisplay_resolution(value);
                    updateValues("display_resolution", value);
                  }}
                  value={display_resolution}
                  style={{ width: 240 }}
                >
                  {Display_resolution.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display_resolution === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processor_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Processor Speed"
                hasFeedback
                validateStatus={processor_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Processor Speed"
                  onChange={(value) => {
                    setProcessor_speed(value);
                    updateValues("processor_speed", value);
                  }}
                  value={processor_speed}
                  style={{ width: 240 }}
                >
                  {tablet_Processor_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={processor_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("headphone_jack") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Headphone Jack"
                hasFeedback
                validateStatus={headphone_jackerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Headphone Jack"
                  onChange={(value) => {
                    setHeadphone_jack(value);
                    updateValues("headphone_jack", value);
                  }}
                  value={headphone_jack}
                  style={{ width: 240 }}
                >
                  {Headphone_jack.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={headphone_jack === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("expandable_storage_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Expandable Storage Capacity"
                hasFeedback
                validateStatus={
                  expandable_storage_capacityerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Expandable Storage Capacity"
                  onChange={(value) => {
                    setExpandable_storage_capacity(value);
                    updateValues("expandable_storage_capacity", value);
                  }}
                  value={expandable_storage_capacity}
                  style={{ width: 240 }}
                >
                  {laptop_Expandable_ssd_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={expandable_storage_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("memory_card_slot_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Memory Card Slot Type"
                hasFeedback
                validateStatus={
                  memory_card_slot_typeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Memory Card Slot Type"
                  onChange={(value) => {
                    setMemory_card_slot_type(value);
                    updateValues("memory_card_slot_type", value);
                  }}
                  value={memory_card_slot_type}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={memory_card_slot_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("memory_card_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Memory Card Support"
                hasFeedback
                validateStatus={memory_card_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Memory Card Support"
                  onChange={(value) => {
                    setMemory_card_support(value);
                    updateValues("memory_card_support", value);
                  }}
                  value={memory_card_support}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={memory_card_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("sound_enhancements") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sound Enhancements"
                hasFeedback
                validateStatus={sound_enhancementserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sound Enhancements"
                  onChange={(value) => {
                    setSound_enhancements(value);
                    updateValues("sound_enhancements", value);
                  }}
                  value={sound_enhancements}
                  style={{ width: 240 }}
                >
                  {Sound_enhancements.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sound_enhancements === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("optical_zoom") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Optical Zoom"
                hasFeedback
                validateStatus={optical_zoomerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Optical Zoom"
                  onChange={(value) => {
                    setOptical_zoom(value);
                    updateValues("optical_zoom", value);
                  }}
                  value={optical_zoom}
                  style={{ width: 240 }}
                >
                  {Optical_zoom.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={optical_zoom === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_call_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Call Support"
                hasFeedback
                validateStatus={video_call_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Video Call Support"
                  onChange={(value) => {
                    setVideo_call_support(value);
                    updateValues("video_call_support", value);
                  }}
                  value={video_call_support}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_call_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("graphicsppl") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Graphicsppl"
                hasFeedback
                validateStatus={graphicspplerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Graphicsppl"
                  onChange={(value) => {
                    setGraphicsppl(value);
                    updateValues("graphicsppl", value);
                  }}
                  value={graphicsppl}
                  style={{ width: 240 }}
                >
                  {Graphics_ppl.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={graphicsppl === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("supported_languages") && (
            <Col className="gutter-row" span={12}>
              <span>Supported Languages:</span>
              <Input
                status={supported_languageserror !== "" ? "error" : ""}
                suffix={
                  supported_languageserror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Supported Languages"
                label="Supported Languages"
                value={supported_languages}
                onChange={(e) => {
                  setSupported_languages(e.target.value);
                  updateValues("supported_languages", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("important_apps") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Important Apps"
                hasFeedback
                validateStatus={important_appserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Important Apps"
                  onChange={(value) => {
                    setImportant_apps(value);
                    updateValues("important_apps", value);
                  }}
                  value={important_apps}
                  style={{ width: 240 }}
                >
                  {Important_apps.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={important_apps === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("audio_formats") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Audio Formats"
                hasFeedback
                validateStatus={audio_formatserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Audio Formats"
                  onChange={(value) => {
                    setAudio_formats(value);
                    updateValues("audio_formats", value);
                  }}
                  value={audio_formats}
                  style={{ width: 240 }}
                >
                  {Audio_formats.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={audio_formats === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_formats") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Formats"
                hasFeedback
                validateStatus={video_formatserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Video Formats"
                  onChange={(value) => {
                    setVideo_formats(value);
                    updateValues("video_formats", value);
                  }}
                  value={video_formats}
                  style={{ width: 240 }}
                >
                  {Video_formats.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_formats === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ideal_for") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ideal For"
                hasFeedback
                validateStatus={ideal_forerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ideal For"
                  onChange={(value) => {
                    setIdeal_for(value);
                    updateValues("ideal_for", value);
                  }}
                  value={ideal_for}
                  style={{ width: 240 }}
                >
                  {Ideal_for.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ideal_for === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ideal_usage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ideal Usage"
                hasFeedback
                validateStatus={ideal_usageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ideal Usage"
                  onChange={(value) => {
                    setIdeal_usage(value);
                    updateValues("ideal_usage", value);
                  }}
                  value={ideal_usage}
                  style={{ width: 240 }}
                >
                  {Ideal_usage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ideal_usage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("loptop_sleeve") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Loptop Sleeve"
                hasFeedback
                validateStatus={loptop_sleeveerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Loptop Sleeve"
                  onChange={(value) => {
                    setLoptop_sleeve(value);
                    updateValues("loptop_sleeve", value);
                  }}
                  value={loptop_sleeve}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={loptop_sleeve === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("with_rain_cover") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="With Rain Cover"
                hasFeedback
                validateStatus={with_rain_covererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select With Rain Cover"
                  onChange={(value) => {
                    setWith_rain_cover(value);
                    updateValues("with_rain_cover", value);
                  }}
                  value={with_rain_cover}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={with_rain_cover === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("compatibility_loptop_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Compatibility Loptop Size"
                hasFeedback
                validateStatus={
                  compatibility_loptop_sizeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Compatibility Loptop Size"
                  onChange={(value) => {
                    setCompatibility_loptop_size(value);
                    updateValues("compatibility_loptop_size", value);
                  }}
                  value={compatibility_loptop_size}
                  style={{ width: 240 }}
                >
                  {Compatible_laptop_size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={compatibility_loptop_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("style_code") && (
            <Col className="gutter-row" span={8}>
              <span>Style Code:</span>
              <Input
                status={style_codeerror !== "" ? "error" : ""}
                suffix={style_codeerror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter Style Code"
                label="Style Code"
                value={style_code}
                onChange={(e) => {
                  setStyle_code(e.target.value);
                  updateValues("style_code", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_code") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color Code"
                hasFeedback
                validateStatus={color_codeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Color Code"
                  onChange={(value) => {
                    setColor_code(value);
                    updateValues("color_code", value);
                  }}
                  value={color_code}
                  style={{ width: 240 }}
                >
                  {Color_code.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_code === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("material_water_proof") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Material Water Proof"
                hasFeedback
                validateStatus={material_water_prooferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Material Water Proof"
                  onChange={(value) => {
                    setMaterial_water_proof(value);
                    updateValues("material_water_proof", value);
                  }}
                  value={material_water_proof}
                  style={{ width: 240 }}
                >
                  {Material_water_proof.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={material_water_proof === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_compartments") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number Of Compartments"
                hasFeedback
                validateStatus={
                  number_of_compartmentserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number Of Compartments"
                  onChange={(value) => {
                    setNumber_of_compartments(value);
                    updateValues("number_of_compartments", value);
                  }}
                  value={number_of_compartments}
                  style={{ width: 240 }}
                >
                  {Number_of_compartments.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_compartments === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_packets") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number Of Packets"
                hasFeedback
                validateStatus={number_of_packetserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number Of Packets"
                  onChange={(value) => {
                    setNumber_of_packets(value);
                    updateValues("number_of_packets", value);
                  }}
                  value={number_of_packets}
                  style={{ width: 240 }}
                >
                  {Number_of_packets.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_packets === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("configuration") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Configuration"
                hasFeedback
                validateStatus={configurationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Configuration"
                  onChange={(value) => {
                    setConfiguration(value);
                    updateValues("configuration", value);
                  }}
                  value={configuration}
                  style={{ width: 240 }}
                >
                  {Configuration.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={configuration === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("frequency_response") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Frequency response"
                hasFeedback
                validateStatus={frequency_responseerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Frequency response"
                  onChange={(value) => {
                    setFrequency_response(value);
                    updateValues("frequency_response", value);
                  }}
                  value={frequency_response}
                  style={{ width: 240 }}
                >
                  {Frequency_response.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={frequency_response === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("built_in_fm_radio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Built in fm Radio"
                hasFeedback
                validateStatus={built_in_fm_radioerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Built in fm Radio"
                  onChange={(value) => {
                    setBuilt_in_fm_radio(value);
                    updateValues("built_in_fm_radio", value);
                  }}
                  value={built_in_fm_radio}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={built_in_fm_radio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("certifications") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Certifications"
                hasFeedback
                validateStatus={certificationserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Certifications"
                  onChange={(value) => {
                    setCertifications(value);
                    updateValues("certifications", value);
                  }}
                  value={certifications}
                  style={{ width: 240 }}
                >
                  {Certifications.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={certifications === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("charging_time") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Charging time"
                hasFeedback
                validateStatus={charging_timeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Charging time"
                  onChange={(value) => {
                    setCharging_time(value);
                    updateValues("charging_time", value);
                  }}
                  value={charging_time}
                  style={{ width: 240 }}
                >
                  {Charging_time.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={charging_time === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cable_interface") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cable interface"
                hasFeedback
                validateStatus={cable_interfaceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Cable interface"
                  onChange={(value) => {
                    setCable_interface(value);
                    updateValues("cable_interface", value);
                  }}
                  value={cable_interface}
                  style={{ width: 240 }}
                >
                  {Cable_interface.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cable_interface === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("platform") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Platform"
                hasFeedback
                validateStatus={platformerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Platform"
                  onChange={(value) => {
                    setPlatform(value);
                    updateValues("platform", value);
                  }}
                  value={platform}
                  style={{ width: 240 }}
                >
                  {Platform.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={platform === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("read_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Read Speed"
                hasFeedback
                validateStatus={read_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Read Speed"
                  onChange={(value) => {
                    setRead_speed(value);
                    updateValues("read_speed", value);
                  }}
                  value={read_speed}
                  style={{ width: 240 }}
                >
                  {Read_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={read_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("write_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Write speed"
                hasFeedback
                validateStatus={write_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Write speed"
                  onChange={(value) => {
                    setWrite_speed(value);
                    updateValues("write_speed", value);
                  }}
                  value={write_speed}
                  style={{ width: 240 }}
                >
                  {Write_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={write_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("system_requirements") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="System requirements"
                hasFeedback
                validateStatus={system_requirementserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select System requirements"
                  onChange={(value) => {
                    setSystem_requirements(value);
                    updateValues("system_requirements", value);
                  }}
                  value={system_requirements}
                  style={{ width: 240 }}
                >
                  {System_requirements.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={system_requirements === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("form_factor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Form factor"
                hasFeedback
                validateStatus={form_factorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Form factor"
                  onChange={(value) => {
                    setForm_factor(value);
                    updateValues("form_factor", value);
                  }}
                  value={form_factor}
                  style={{ width: 240 }}
                >
                  {Form_factor.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={form_factor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cloud_backup") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cloud Backup"
                hasFeedback
                validateStatus={cloud_backuperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Cloud Backup"
                  onChange={(value) => {
                    setCloud_backup(value);
                    updateValues("cloud_backup", value);
                  }}
                  value={cloud_backup}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cloud_backup === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("data_tranfer_rate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Data Tranfer rate"
                hasFeedback
                validateStatus={data_tranfer_rateerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Data Tranfer rate"
                  onChange={(value) => {
                    setData_tranfer_rate(value);
                    updateValues("data_tranfer_rate", value);
                  }}
                  value={data_tranfer_rate}
                  style={{ width: 240 }}
                >
                  {Data_transfer_rate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={data_tranfer_rate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("rotational_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Rotational Speed"
                hasFeedback
                validateStatus={rotational_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Rotational Speed"
                  onChange={(value) => {
                    setRotational_speed(value);
                    updateValues("rotational_speed", value);
                  }}
                  value={rotational_speed}
                  style={{ width: 240 }}
                >
                  {Rotational_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={rotational_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("password_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Password Protection"
                hasFeedback
                validateStatus={password_protectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Password Protection"
                  onChange={(value) => {
                    setPassword_protection(value);
                    updateValues("password_protection", value);
                  }}
                  value={password_protection}
                  style={{ width: 240 }}
                >
                  {Password_protection.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={password_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("power_source") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Power Source"
                hasFeedback
                validateStatus={power_sourceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Power Source"
                  onChange={(value) => {
                    setPower_source(value);
                    updateValues("power_source", value);
                  }}
                  value={power_source}
                  style={{ width: 240 }}
                >
                  {Power_source.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={power_source === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Size"
                hasFeedback
                validateStatus={sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Size"
                  onChange={(value) => {
                    setSize(value);
                    updateValues("size", value);
                  }}
                  value={size}
                  style={{ width: 240 }}
                >
                  {Size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("shock_resistant") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Shock resistant"
                hasFeedback
                validateStatus={shock_resistanterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Shock resistant"
                  onChange={(value) => {
                    setShock_resistant(value);
                    updateValues("shock_resistant", value);
                  }}
                  value={shock_resistant}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={shock_resistant === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("water_proof") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Water Proof"
                hasFeedback
                validateStatus={water_prooferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Water Proof"
                  onChange={(value) => {
                    setWater_proof(value);
                    updateValues("water_proof", value);
                  }}
                  value={water_proof}
                  style={{ width: 240 }}
                >
                  {Water_proof.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={water_proof === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("led_indicator") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Led Indicator"
                hasFeedback
                validateStatus={led_indicatorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Led Indicator"
                  onChange={(value) => {
                    setLed_indicator(value);
                    updateValues("led_indicator", value);
                  }}
                  value={led_indicator}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={led_indicator === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("operating_range") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Operating Range"
                hasFeedback
                validateStatus={operating_rangeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Operating Range"
                  onChange={(value) => {
                    setOperating_range(value);
                    updateValues("operating_range", value);
                  }}
                  value={operating_range}
                  style={{ width: 240 }}
                >
                  {Operating_range.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={operating_range === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("frequency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Frequency"
                hasFeedback
                validateStatus={frequencyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Frequency"
                  onChange={(value) => {
                    setFrequency(value);
                    updateValues("frequency", value);
                  }}
                  value={frequency}
                  style={{ width: 240 }}
                >
                  {Frequency.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={frequency === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("frequency_band") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Frequency Band"
                hasFeedback
                validateStatus={frequency_banderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Frequency Band"
                  onChange={(value) => {
                    setFrequency_band(value);
                    updateValues("frequency_band", value);
                  }}
                  value={frequency_band}
                  style={{ width: 240 }}
                >
                  {Frequency_band.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={frequency_band === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wireless_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wireless Speed"
                hasFeedback
                validateStatus={wireless_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wireless Speed"
                  onChange={(value) => {
                    setWireless_speed(value);
                    updateValues("wireless_speed", value);
                  }}
                  value={wireless_speed}
                  style={{ width: 240 }}
                >
                  {Wireless_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wireless_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dsl_modern_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dsl Modern Speed"
                hasFeedback
                validateStatus={dsl_modern_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dsl Modern Speed"
                  onChange={(value) => {
                    setDsl_modern_speed(value);
                    updateValues("dsl_modern_speed", value);
                  }}
                  value={dsl_modern_speed}
                  style={{ width: 240 }}
                >
                  {Dsl_modem_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dsl_modern_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lan_wan") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lan Wan"
                hasFeedback
                validateStatus={lan_wanerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Lan Wan"
                  onChange={(value) => {
                    setLan_wan(value);
                    updateValues("lan_wan", value);
                  }}
                  value={lan_wan}
                  style={{ width: 240 }}
                >
                  {Lan_wan.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lan_wan === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("antennae") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Antennae"
                hasFeedback
                validateStatus={antennaeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Antennae"
                  onChange={(value) => {
                    setAntennae(value);
                    updateValues("antennae", value);
                  }}
                  value={antennae}
                  style={{ width: 240 }}
                >
                  {Antennae.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={antennae === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_usb_ports") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Usb Ports"
                hasFeedback
                validateStatus={number_of_usb_portserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number of Usb Ports"
                  onChange={(value) => {
                    setNumber_of_usb_ports(value);
                    updateValues("number_of_usb_ports", value);
                  }}
                  value={number_of_usb_ports}
                  style={{ width: 240 }}
                >
                  {Number_of_usb_ports.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_usb_ports === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lan") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lan"
                hasFeedback
                validateStatus={lanerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Lan"
                  onChange={(value) => {
                    setLan(value);
                    updateValues("lan", value);
                  }}
                  value={lan}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lan === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        {/* /--------------------------------- */}
        <Flex wrap="wrap">
          {distinctfield.includes("output_wattage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Output wattage"
                hasFeedback
                validateStatus={output_wattageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Output wattage"
                  onChange={(value) => {
                    setOutput_wattage(value);
                    updateValues("output_wattage", value);
                  }}
                  value={output_wattage}
                  style={{ width: 240 }}
                >
                  {Output_wattage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={output_wattage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("compatible_with") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Compatible With"
                hasFeedback
                validateStatus={compatible_witherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Compatible With"
                  onChange={(value) => {
                    setCompatible_with(value);
                    updateValues("compatible_with", value);
                  }}
                  value={compatible_with}
                  style={{ width: 240 }}
                >
                  {Compatible_with.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={compatible_with === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("flodable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Flodable"
                hasFeedback
                validateStatus={flodableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Flodable"
                  onChange={(value) => {
                    setFlodable(value);
                    updateValues("flodable", value);
                  }}
                  value={flodable}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={flodable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("rpm") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Rpm"
                hasFeedback
                validateStatus={rpmerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Rpm"
                  onChange={(value) => {
                    setRpm(value);
                    updateValues("rpm", value);
                  }}
                  value={rpm}
                  style={{ width: 240 }}
                >
                  {Rpm.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={rpm === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("graphic_processor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Graphic processor"
                hasFeedback
                validateStatus={graphic_processorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Graphic processor"
                  onChange={(value) => {
                    setGraphic_processor(value);
                    updateValues("graphic_processor", value);
                  }}
                  value={graphic_processor}
                  style={{ width: 240 }}
                >
                  {laptop_Graphic_processor.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={graphic_processor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_backup") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery backup"
                hasFeedback
                validateStatus={battery_backuperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Battery backup"
                  onChange={(value) => {
                    setBattery_backup(value);
                    updateValues("battery_backup", value);
                  }}
                  value={battery_backup}
                  style={{ width: 240 }}
                >
                  {laptop_battery_backup.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_backup === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("battery_cell") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Battery cell"
                hasFeedback
                validateStatus={battery_cellerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Battery cell"
                  onChange={(value) => {
                    setBattery_cell(value);
                    updateValues("battery_cell", value);
                  }}
                  value={battery_cell}
                  style={{ width: 240 }}
                >
                  {Hdd_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={battery_cell === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ms_office_provided") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ms office Provided"
                hasFeedback
                validateStatus={ms_office_providederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ms office Provided"
                  onChange={(value) => {
                    setMs_office_provided(value);
                    updateValues("ms_office_provided", value);
                  }}
                  value={ms_office_provided}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ms_office_provided === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processor_name") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Processor Name"
                hasFeedback
                validateStatus={processor_nameerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Processor Name"
                  onChange={(value) => {
                    setProcessor_name(value);
                    updateValues("processor_name", value);
                  }}
                  value={processor_name}
                  style={{ width: 240 }}
                >
                  {laptop_Processor_name.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={processor_name === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("processor_generation") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Processor Generation"
                hasFeedback
                validateStatus={processor_generationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Processor Generation"
                  onChange={(value) => {
                    setProcessor_generation(value);
                    updateValues("processor_generation", value);
                  }}
                  value={processor_generation}
                  style={{ width: 240 }}
                >
                  {Processor_generation.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={processor_generation === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ram_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="RAM Type"
                hasFeedback
                validateStatus={ram_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ram Type"
                  onChange={(value) => {
                    setRam_type(value);
                    updateValues("ram_type", value);
                  }}
                  value={ram_type}
                  style={{ width: 240 }}
                >
                  {laptop_Ram_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ram_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hdd_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Hdd capacity"
                hasFeedback
                validateStatus={hdd_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Hdd capacity"
                  onChange={(value) => {
                    setHdd_capacity(value);
                    updateValues("hdd_capacity", value);
                  }}
                  value={hdd_capacity}
                  style={{ width: 240 }}
                >
                  {Hdd_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hdd_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("output_interface") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Output Interface"
                hasFeedback
                validateStatus={output_interfaceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Output Interface"
                  onChange={(value) => {
                    setOutput_interface(value);
                    updateValues("output_interface", value);
                  }}
                  value={output_interface}
                  style={{ width: 240 }}
                >
                  {Output_interface.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={output_interface === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_current_rating") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Current Rating"
                hasFeedback
                validateStatus={
                  maximum_current_ratingerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Current Rating"
                  onChange={(value) => {
                    setMaximum_current_rating(value);
                    updateValues("maximum_current_rating", value);
                  }}
                  value={maximum_current_rating}
                  style={{ width: 240 }}
                >
                  {Maximum_current_rating.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_current_rating === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("other_material") && (
            <Col className="gutter-row" span={12}>
              <span>Other Material:</span>
              <Input
                status={other_materialerror !== "" ? "error" : ""}
                suffix={
                  other_materialerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Other Material"
                label="Other Material"
                value={other_material}
                onChange={(e) => {
                  setOther_material(e.target.value);
                  updateValues("other_material", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hd_resolution") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="HD Resolution"
                hasFeedback
                validateStatus={hd_resolutionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select HD Resolution"
                  value={hd_resolution}
                  onChange={(value) => {
                    setHd_resolution(value);
                    updateValues("hd_resolution", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Hd_technology_resolution.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hd_resolution === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("smart_tv") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Smart Tv"
                hasFeedback
                validateStatus={smart_tverror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Smart Tv"
                  value={smart_tv}
                  onChange={(value) => {
                    setSmart_tv(value);
                    updateValues("smart_tv", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={smart_tv === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hdmi") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="HDMI"
                hasFeedback
                validateStatus={hdmierror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select HDMI"
                  value={hdmi}
                  onChange={(value) => {
                    setHdmi(value);
                    updateValues("hdmi", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hdmi === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("usb") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="USB"
                hasFeedback
                validateStatus={usberror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select USB"
                  value={usb}
                  onChange={(value) => {
                    setUsb(value);
                    updateValues("usb", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={usb === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wifi_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wi-Fi Type"
                hasFeedback
                validateStatus={wifi_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wi-Fi Type"
                  value={wifi_type}
                  onChange={(value) => {
                    setWifi_type(value);
                    updateValues("wifi_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Wifi_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wifi_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("built_in_wifi") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Built in Wi-Fi"
                hasFeedback
                validateStatus={built_in_wifierror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Built in Wi-Fi"
                  value={built_in_wifi}
                  onChange={(value) => {
                    setBuilt_in_wifi(value);
                    updateValues("built_in_wifi", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={built_in_wifi === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wall_mount_included") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wall Mount Included"
                hasFeedback
                validateStatus={wall_mount_includederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wall Mount Included"
                  value={wall_mount_included}
                  onChange={(value) => {
                    setWall_mount_included(value);
                    updateValues("wall_mount_included", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wall_mount_included === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("picture_in_picture") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Picture in Picture"
                hasFeedback
                validateStatus={picture_in_pictureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Picture in Picture"
                  value={picture_in_picture}
                  onChange={(value) => {
                    setPicture_in_picture(value);
                    updateValues("picture_in_picture", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={picture_in_picture === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("picture_engine") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Picture Engine"
                hasFeedback
                validateStatus={picture_engineerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Picture Engine"
                  value={picture_engine}
                  onChange={(value) => {
                    setPicture_engine(value);
                    updateValues("picture_engine", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Picture_engine.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={picture_engine === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("analog_tv_reception") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Analog TVReception"
                hasFeedback
                validateStatus={analog_tv_receptionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Analog TVReception"
                  value={analog_tv_reception}
                  onChange={(value) => {
                    setAnalog_tv_reception(value);
                    updateValues("analog_tv_reception", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Analog_tv_reception.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={analog_tv_reception === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("view_angle") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="View Angle"
                hasFeedback
                validateStatus={view_angleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select View Angle"
                  value={view_angle}
                  onChange={(value) => {
                    setView_angle(value);
                    updateValues("view_angle", value);
                  }}
                  style={{ width: 240 }}
                >
                  {View_angle.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={view_angle === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("panel_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Panel Type"
                hasFeedback
                validateStatus={panel_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Panel Type"
                  value={panel_type}
                  onChange={(value) => {
                    setPanel_type(value);
                    updateValues("panel_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {panel_display_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={panel_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("digital_noise_filter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Digital Noise Filter"
                hasFeedback
                validateStatus={digital_noise_filtererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Digital Noise Filter"
                  value={digital_noise_filter}
                  onChange={(value) => {
                    setDigital_noise_filter(value);
                    updateValues("digital_noise_filter", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={digital_noise_filter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("led_display_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="LED Display Type"
                hasFeedback
                validateStatus={led_display_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select LED Display Type"
                  value={led_display_type}
                  onChange={(value) => {
                    setLed_display_type(value);
                    updateValues("led_display_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Led_display_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={led_display_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("aspect_ratio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Aspect Ratio"
                hasFeedback
                validateStatus={aspect_ratioerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Aspect Ratio"
                  value={aspect_ratio}
                  onChange={(value) => {
                    setAspect_ratio(value);
                    updateValues("aspect_ratio", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Aspect_ratio.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={aspect_ratio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("refresh_rate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Refresh Rate"
                hasFeedback
                validateStatus={refresh_rateerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Refresh Rate"
                  value={refresh_rate}
                  onChange={(value) => {
                    setRefresh_rate(value);
                    updateValues("refresh_rate", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Refresh_rate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={refresh_rate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_speakers") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Speakers"
                hasFeedback
                validateStatus={number_of_speakerserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Speakers"
                  value={number_of_speakers}
                  onChange={(value) => {
                    setNumber_of_speakers(value);
                    updateValues("number_of_speakers", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_speakers.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_speakers === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("speaker_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Speaker Type"
                hasFeedback
                validateStatus={speaker_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Speaker Type"
                  value={speaker_type}
                  onChange={(value) => {
                    setSpeaker_type(value);
                    updateValues("speaker_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Speaker_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={speaker_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sound_technology") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sound Technology"
                hasFeedback
                validateStatus={sound_technologyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sound Technology"
                  value={sound_technology}
                  onChange={(value) => {
                    setSound_technology(value);
                    updateValues("sound_technology", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sound_mode.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sound_technology === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("surround_sound") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Surround Sound"
                hasFeedback
                validateStatus={surround_sounderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Surround Sound"
                  value={surround_sound}
                  onChange={(value) => {
                    setSurround_sound(value);
                    updateValues("surround_sound", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={surround_sound === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("speaker_output_rms") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Speaker Output RMS"
                hasFeedback
                validateStatus={speaker_output_rmserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Speaker Output RMS"
                  value={speaker_output_rms}
                  onChange={(value) => {
                    setSpeaker_output_rms(value);
                    updateValues("speaker_output_rms", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Power_output_rms.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={speaker_output_rms === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sound_mode") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="sound_mode"
                hasFeedback
                validateStatus={sound_modeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select sound_mode"
                  value={sound_mode}
                  onChange={(value) => {
                    setSound_mode(value);
                    updateValues("sound_mode", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sound_mode.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sound_mode === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_audio_features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported Audio Features"
                hasFeedback
                validateStatus={
                  supported_audio_featureserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported Audio Features"
                  value={supported_audio_features}
                  onChange={(value) => {
                    setSupported_audio_features(value);
                    updateValues("supported_audio_features", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Supported_audio_features.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_audio_features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_app_netflix") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported app Netflix"
                hasFeedback
                validateStatus={
                  supported_app_netflixerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported app Netflix"
                  value={supported_app_netflix}
                  onChange={(value) => {
                    setSupported_app_netflix(value);
                    updateValues("supported_app_netflix", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_app_netflix === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_app_youtube") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported App Youtube"
                hasFeedback
                validateStatus={
                  supported_app_youtubeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported App Youtube"
                  value={supported_app_youtube}
                  onChange={(value) => {
                    setSupported_app_youtube(value);
                    updateValues("supported_app_youtube", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_app_youtube === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_app_disney_hotstar") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported App Disney+Hotstar"
                hasFeedback
                validateStatus={
                  supported_app_disney_hotstarerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported App Disney+Hotstar"
                  value={supported_app_disney_hotstar}
                  onChange={(value) => {
                    setSupported_app_disney_hotstar(value);
                    updateValues("supported_app_disney_hotstar", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_app_disney_hotstar === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_app_prime_video") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported App Prime Video"
                hasFeedback
                validateStatus={
                  supported_app_prime_videoerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported App Prime Video"
                  value={supported_app_prime_video}
                  onChange={(value) => {
                    setSupported_app_prime_video(value);
                    updateValues("supported_app_prime_video", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_app_prime_video === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_mobile_operating_system") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported Mobile Operating System"
                hasFeedback
                validateStatus={
                  supported_mobile_operating_systemerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Supported Mobile Operating System"
                  value={supported_mobile_operating_system}
                  onChange={(value) => {
                    setSupported_mobile_operating_system(value);
                    updateValues("supported_mobile_operating_system", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Supported_Mobile_operating_system.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={
                        supported_mobile_operating_system === data.label
                      }
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        {/* -----------------------------09/05 */}
        <Flex wrap="wrap">
          {distinctfield.includes("operating_system_present") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Operating System Present"
                hasFeedback
                validateStatus={
                  operating_system_presenterror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Operating System Present"
                  onChange={(value) => {
                    setOperating_system_present(value);
                    updateValues("operating_system_present", value);
                  }}
                  value={operating_system_present}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={operating_system_present === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("screen_mirroring") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Screen Mirroring"
                hasFeedback
                validateStatus={screen_mirroringerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Screen Mirroring"
                  onChange={(value) => {
                    setScreen_mirroring(value);
                    updateValues("screen_mirroring", value);
                  }}
                  value={screen_mirroring}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={screen_mirroring === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("app_store_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="App Store Type"
                hasFeedback
                validateStatus={app_store_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select App Store Type"
                  onChange={(value) => {
                    setApp_store_type(value);
                    updateValues("app_store_type", value);
                  }}
                  value={app_store_type}
                  style={{ width: 240 }}
                >
                  {App_store_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={app_store_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pre_installed_browser") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pre Installed Browser"
                hasFeedback
                validateStatus={
                  pre_installed_browsererror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Pre Installed Browser"
                  onChange={(value) => {
                    setPre_installed_browser(value);
                    updateValues("pre_installed_browser", value);
                  }}
                  value={pre_installed_browser}
                  style={{ width: 240 }}
                >
                  {Pre_installed_browser.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pre_installed_browser === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("content_providers") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Content Providers"
                hasFeedback
                validateStatus={content_providerserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Content Providers"
                  onChange={(value) => {
                    setContent_providers(value);
                    updateValues("content_providers", value);
                  }}
                  value={content_providers}
                  style={{ width: 240 }}
                >
                  {Content_providers.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={content_providers === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("supported_devices_for_casting") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Supported Devices for Casting"
                hasFeedback
                validateStatus={
                  supported_devices_for_castingerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Supported Devices for Casting"
                  onChange={(value) => {
                    setSupported_devices_for_casting(value);
                    updateValues("supported_devices_for_casting", value);
                  }}
                  value={supported_devices_for_casting}
                  style={{ width: 240 }}
                >
                  {Supported_devices_for_casting.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={supported_devices_for_casting === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("teletext") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Teletext"
                hasFeedback
                validateStatus={teletexterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Teletext"
                  onChange={(value) => {
                    setTeletext(value);
                    updateValues("teletext", value);
                  }}
                  value={teletext}
                  style={{ width: 240 }}
                >
                  {Teletext.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={teletext === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("mobile_high_defination_link") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Mobile High Defination link"
                hasFeedback
                validateStatus={
                  mobile_high_defination_linkerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Mobile High Defination link"
                  onChange={(value) => {
                    setMobile_high_defination_link(value);
                    updateValues("mobile_high_defination_link", value);
                  }}
                  value={mobile_high_defination_link}
                  style={{ width: 240 }}
                >
                  {Mobile_high_definition_link.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={mobile_high_defination_link === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("_3g_dongle_plug_and_play") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" 3G Dongle Plug and Play"
                hasFeedback
                validateStatus={
                  _3g_dongle_plug_and_playerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select  3G Dongle Plug and Play"
                  onChange={(value) => {
                    set_3G_dongle_plug_and_play(value);
                    updateValues("_3g_dongle_plug_and_play", value);
                  }}
                  value={_3g_dongle_plug_and_play}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={_3g_dongle_plug_and_play === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("touch_remote") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Touch Remote"
                hasFeedback
                validateStatus={touch_remoteerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Touch Remote"
                  onChange={(value) => {
                    setTouch_remote(value);
                    updateValues("touch_remote", value);
                  }}
                  value={touch_remote}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={touch_remote === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("smart_remote") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Smart Remote"
                hasFeedback
                validateStatus={smart_remoteerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Smart Remote"
                  onChange={(value) => {
                    setSmart_remote(value);
                    updateValues("smart_remote", value);
                  }}
                  value={smart_remote}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={smart_remote === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_screen") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="color_screen"
                hasFeedback
                validateStatus={color_screenerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select color_screen"
                  onChange={(value) => {
                    setColor_screen(value);
                    updateValues("color_screen", value);
                  }}
                  value={color_screen}
                  style={{ width: 240 }}
                >
                  {Color_screen.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_screen === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ir_capable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ir Capable"
                hasFeedback
                validateStatus={ir_capableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ir Capable"
                  onChange={(value) => {
                    setIr_capable(value);
                    updateValues("ir_capable", value);
                  }}
                  value={ir_capable}
                  style={{ width: 240 }}
                >
                  {Ir_capable.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ir_capable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dvr_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dvr Control"
                hasFeedback
                validateStatus={dvr_controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dvr Control"
                  onChange={(value) => {
                    setDvr_control(value);
                    updateValues("dvr_control", value);
                  }}
                  value={dvr_control}
                  style={{ width: 240 }}
                >
                  {Dvr_control.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dvr_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("remote_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Remote Type"
                hasFeedback
                validateStatus={remote_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Remote Type"
                  onChange={(value) => {
                    setRemote_type(value);
                    updateValues("remote_type", value);
                  }}
                  value={remote_type}
                  style={{ width: 240 }}
                >
                  {Remote_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={remote_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("clock") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Clock"
                hasFeedback
                validateStatus={clockerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Clock"
                  onChange={(value) => {
                    setClock(value);
                    updateValues("clock", value);
                  }}
                  value={clock}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={clock === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("on_off_timer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="On Off Timer"
                hasFeedback
                validateStatus={on_off_timererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select On Off Timer"
                  onChange={(value) => {
                    setOn_off_timer(value);
                    updateValues("on_off_timer", value);
                  }}
                  value={on_off_timer}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={on_off_timer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("without_stand") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Without stand"
                hasFeedback
                validateStatus={without_standerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Without stand"
                  onChange={(value) => {
                    setWithout_stand(value);
                    updateValues("without_stand", value);
                  }}
                  value={without_stand}
                  style={{ width: 240 }}
                >
                  {Without_stand.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={without_stand === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("with_stand") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="With Stand"
                hasFeedback
                validateStatus={with_standerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select With Stand"
                  onChange={(value) => {
                    setWith_stand(value);
                    updateValues("with_stand", value);
                  }}
                  value={with_stand}
                  style={{ width: 240 }}
                >
                  {With_stand.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={with_stand === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stand_features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stand Features"
                hasFeedback
                validateStatus={stand_featureserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Stand Features"
                  onChange={(value) => {
                    setStand_features(value);
                    updateValues("stand_features", value);
                  }}
                  value={stand_features}
                  style={{ width: 240 }}
                >
                  {Stand_features.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stand_features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sleep_timer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sleep timer"
                hasFeedback
                validateStatus={sleep_timererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sleep timer"
                  onChange={(value) => {
                    setSleep_timer(value);
                    updateValues("sleep_timer", value);
                  }}
                  value={sleep_timer}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sleep_timer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wired_wireless") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wired Wireless"
                hasFeedback
                validateStatus={wired_wirelesserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wired Wireless"
                  onChange={(value) => {
                    setWired_wireless(value);
                    updateValues("wired_wireless", value);
                  }}
                  value={wired_wireless}
                  style={{ width: 240 }}
                >
                  {Wired_wireless.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wired_wireless === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("outdoor_usage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Outdoor Usage"
                hasFeedback
                validateStatus={outdoor_usageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Outdoor Usage"
                  onChange={(value) => {
                    setOutdoor_usage(value);
                    updateValues("outdoor_usage", value);
                  }}
                  value={outdoor_usage}
                  style={{ width: 240 }}
                >
                  {Outdoor_usage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={outdoor_usage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_spl") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Spl"
                hasFeedback
                validateStatus={maximum_splerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Spl"
                  onChange={(value) => {
                    setMaximum_spl(value);
                    updateValues("maximum_spl", value);
                  }}
                  value={maximum_spl}
                  style={{ width: 240 }}
                >
                  {Maximum_spl.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_spl === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("chassis_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Chassis Material"
                hasFeedback
                validateStatus={chassis_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Chassis Material"
                  onChange={(value) => {
                    setChassis_material(value);
                    updateValues("chassis_material", value);
                  }}
                  value={chassis_material}
                  style={{ width: 240 }}
                >
                  {Chassis_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={chassis_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("international_warranty") && (
            <Col className="gutter-row" span={12}>
              <span>International Warranty:</span>
              <Input
                status={international_warrantyerror !== "" ? "error" : ""}
                suffix={
                  international_warrantyerror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter International Warranty"
                label="International Warranty"
                value={international_warranty}
                onChange={(e) => {
                  setInternational_warranty(e.target.value);
                  updateValues("international_warranty", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("part_number") && (
            <Col className="gutter-row" span={12}>
              <span>Part Number:</span>
              <Input
                status={part_numbererror !== "" ? "error" : ""}
                suffix={
                  part_numbererror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Part Number"
                label="Part Number"
                value={part_number}
                onChange={(e) => {
                  setPart_number(e.target.value);
                  updateValues("part_number", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("portable") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Portable"
                hasFeedback
                validateStatus={portableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Portable"
                  onChange={(value) => {
                    setPortable(value);
                    updateValues("portable", value);
                  }}
                  value={portable}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={portable === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_brightness") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Brightness"
                hasFeedback
                validateStatus={maximum_brightnesserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Brightness"
                  onChange={(value) => {
                    setMaximum_brightness(value);
                    updateValues("maximum_brightness", value);
                  }}
                  value={maximum_brightness}
                  style={{ width: 240 }}
                >
                  {Maximum_brightness.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_brightness === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_projection_distances") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Projection Distances"
                hasFeedback
                validateStatus={
                  maximum_projection_distanceserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Projection Distances"
                  onChange={(value) => {
                    setMaximum_projection_distances(value);
                    updateValues("maximum_projection_distances", value);
                  }}
                  value={maximum_projection_distances}
                  style={{ width: 240 }}
                >
                  {Maximum_Projection_Distances.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_projection_distances === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("projection_ratio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Projection Ratio"
                hasFeedback
                validateStatus={projection_ratioerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Projection Ratio"
                  onChange={(value) => {
                    setProjection_ratio(value);
                    updateValues("projection_ratio", value);
                  }}
                  value={projection_ratio}
                  style={{ width: 240 }}
                >
                  {Projection_ratio.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={projection_ratio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("adjustable_aspect_ratio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Adjustable Aspect Ratio"
                hasFeedback
                validateStatus={
                  adjustable_aspect_ratioerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Adjustable Aspect Ratio"
                  onChange={(value) => {
                    setAdjustable_aspect_ratio(value);
                    updateValues("adjustable_aspect_ratio", value);
                  }}
                  value={adjustable_aspect_ratio}
                  style={{ width: 240 }}
                >
                  {Adjustable_aspect_ratio.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={adjustable_aspect_ratio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("vertical_scanning_frequency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Vertical Scanning Frequency"
                hasFeedback
                validateStatus={
                  vertical_scanning_frequencyerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Vertical Scanning Frequency"
                  onChange={(value) => {
                    setVertical_scanning_frequency(value);
                    updateValues("vertical_scanning_frequency", value);
                  }}
                  value={vertical_scanning_frequency}
                  style={{ width: 240 }}
                >
                  {Vertical_scanning_frequency.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={vertical_scanning_frequency === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("horizontal_scanning_frequency") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Horizontal Scanning Frequency"
                hasFeedback
                validateStatus={
                  horizontal_scanning_frequencyerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Horizontal Scanning Frequency"
                  onChange={(value) => {
                    setHorizontal_scanning_frequency(value);
                    updateValues("horizontal_scanning_frequency", value);
                  }}
                  value={horizontal_scanning_frequency}
                  style={{ width: 240 }}
                >
                  {Horizontal_scanning_frequency.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={horizontal_scanning_frequency === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("signal_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Signal Type"
                hasFeedback
                validateStatus={signal_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Signal Type"
                  onChange={(value) => {
                    setSignal_type(value);
                    updateValues("signal_type", value);
                  }}
                  value={signal_type}
                  style={{ width: 240 }}
                >
                  {Signal_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={signal_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("standard_brightness") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Standard Brightness"
                hasFeedback
                validateStatus={standard_brightnesserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Standard Brightness"
                  onChange={(value) => {
                    setStandard_brightness(value);
                    updateValues("standard_brightness", value);
                  }}
                  value={standard_brightness}
                  style={{ width: 240 }}
                >
                  {Maximum_brightness.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={standard_brightness === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("zoom_ratio") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Zoom Ratio"
                hasFeedback
                validateStatus={zoom_ratioerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Zoom Ratio"
                  onChange={(value) => {
                    setZoom_ratio(value);
                    updateValues("zoom_ratio", value);
                  }}
                  value={zoom_ratio}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={zoom_ratio === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_diagonal_projection_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Diagonal Projection Size"
                hasFeedback
                validateStatus={
                  maximum_diagonal_projection_sizeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Diagonal Projection Size"
                  onChange={(value) => {
                    setMaximum_diagonal_projection_size(value);
                    updateValues("maximum_diagonal_projection_size", value);
                  }}
                  value={maximum_diagonal_projection_size}
                  style={{ width: 240 }}
                >
                  {Maximum_diagonal_projection_size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_diagonal_projection_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("color_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Color Support"
                hasFeedback
                validateStatus={color_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Color Support"
                  onChange={(value) => {
                    setColor_support(value);
                    updateValues("color_support", value);
                  }}
                  value={color_support}
                  style={{ width: 240 }}
                >
                  {Color_support.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={color_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("output_per_speaker") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Output Per Speaker"
                hasFeedback
                validateStatus={output_per_speakererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Output Per Speaker"
                  onChange={(value) => {
                    setOutput_per_speaker(value);
                    updateValues("output_per_speaker", value);
                  }}
                  value={output_per_speaker}
                  style={{ width: 240 }}
                >
                  {Output_per_speaker.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={output_per_speaker === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("audio_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Audio Type"
                hasFeedback
                validateStatus={audio_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Audio Type"
                  onChange={(value) => {
                    setAudio_type(value);
                    updateValues("audio_type", value);
                  }}
                  value={audio_type}
                  style={{ width: 240 }}
                >
                  {Audio_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={audio_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("componenr_hd_video_in") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Componenr hd video in"
                hasFeedback
                validateStatus={
                  componenr_hd_video_inerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Componenr hd video in"
                  onChange={(value) => {
                    setComponenr_hd_video_in(value);
                    updateValues("componenr_hd_video_in", value);
                  }}
                  value={componenr_hd_video_in}
                  style={{ width: 240 }}
                >
                  {Component_hd_video_in.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={componenr_hd_video_in === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_attitude") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Attitude"
                hasFeedback
                validateStatus={maximum_attitudeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Attitude"
                  onChange={(value) => {
                    setMaximum_attitude(value);
                    updateValues("maximum_attitude", value);
                  }}
                  value={maximum_attitude}
                  style={{ width: 240 }}
                >
                  {Maximum_attitude.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_attitude === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("standby_noise_level") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Standby Noise level"
                hasFeedback
                validateStatus={standby_noise_levelerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Standby Noise level"
                  onChange={(value) => {
                    setStandby_noise_level(value);
                    updateValues("standby_noise_level", value);
                  }}
                  value={standby_noise_level}
                  style={{ width: 240 }}
                >
                  {Standby_noise_level.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={standby_noise_level === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("normal_noise_level") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Normal Noise Level"
                hasFeedback
                validateStatus={normal_noise_levelerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Normal Noise Level"
                  onChange={(value) => {
                    setNormal_noise_level(value);
                    updateValues("normal_noise_level", value);
                  }}
                  value={normal_noise_level}
                  style={{ width: 240 }}
                >
                  {Normal_noise_level.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={normal_noise_level === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("minimum_aperture") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum Aperture"
                hasFeedback
                validateStatus={minimum_apertureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Minimum Aperture"
                  onChange={(value) => {
                    setMinimum_aperture(value);
                    updateValues("minimum_aperture", value);
                  }}
                  value={minimum_aperture}
                  style={{ width: 240 }}
                >
                  {Minimum_aperture.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={minimum_aperture === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_aperture") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Aperture"
                hasFeedback
                validateStatus={maximum_apertureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Aperture"
                  onChange={(value) => {
                    setMaximum_aperture(value);
                    updateValues("maximum_aperture", value);
                  }}
                  value={maximum_aperture}
                  style={{ width: 240 }}
                >
                  {Maximum_aperture.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_aperture === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("functions_modes") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Functions Modes"
                hasFeedback
                validateStatus={functions_modeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Functions Modes"
                  onChange={(value) => {
                    setFunctions_modes(value);
                    updateValues("functions_modes", value);
                  }}
                  value={functions_modes}
                  style={{ width: 240 }}
                >
                  {Functions_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={functions_modes === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_usd_languages") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of USD Languages"
                hasFeedback
                validateStatus={
                  number_of_usd_languageserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number of USD Languages"
                  onChange={(value) => {
                    setNumber_of_usd_languages(value);
                    updateValues("number_of_usd_languages", value);
                  }}
                  value={number_of_usd_languages}
                  style={{ width: 240 }}
                >
                  {Number_of_osd_languages.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_usd_languages === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("security") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Security"
                hasFeedback
                validateStatus={securityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Security"
                  onChange={(value) => {
                    setSecurity(value);
                    updateValues("security", value);
                  }}
                  value={security}
                  style={{ width: 240 }}
                >
                  {Security.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={security === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("offer") && (
            <Col className="gutter-row" span={12}>
              <span>Offer:</span>
              <Input
                status={offererror !== "" ? "error" : ""}
                suffix={offererror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter Offer"
                label="Offer"
                value={offer}
                onChange={(e) => {
                  setOffer(e.target.value);
                  updateValues("offer", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("remote_features") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Remote Features"
                hasFeedback
                validateStatus={remote_featureserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Remote Features"
                  onChange={(value) => {
                    setRemote_features(value);
                    updateValues("remote_features", value);
                  }}
                  value={remote_features}
                  style={{ width: 240 }}
                >
                  {Remote_features.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={remote_features === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("control_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Control Type"
                hasFeedback
                validateStatus={control_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Control Type"
                  onChange={(value) => {
                    setControl_type(value);
                    updateValues("control_type", value);
                  }}
                  value={control_type}
                  style={{ width: 240 }}
                >
                  {Control_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={control_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("power_adapter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Power Adapter"
                hasFeedback
                validateStatus={power_adaptererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Power Adapter"
                  onChange={(value) => {
                    setPower_adapter(value);
                    updateValues("power_adapter", value);
                  }}
                  value={power_adapter}
                  style={{ width: 240 }}
                >
                  {Power_adapter.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={power_adapter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_player_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Player Type"
                hasFeedback
                validateStatus={video_player_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Video Player Type"
                  onChange={(value) => {
                    setVideo_player_type(value);
                    updateValues("video_player_type", value);
                  }}
                  value={video_player_type}
                  style={{ width: 240 }}
                >
                  {Video_player_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_player_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}

        </Flex>
        {/* ********========================* */}
        <Flex wrap="wrap">
          {distinctfield.includes("svr_varient") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="SVR Varient"
                hasFeedback
                validateStatus={svr_varienterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select SVR Varient"
                  value={svr_varient}
                  onChange={(value) => {
                    setSvr_varient(value);
                    updateValues("svr_varient", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Svr_variant.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={svr_varient === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("brand_color") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Brand Color"
                hasFeedback
                validateStatus={brand_colorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Brand Color"
                  value={brand_color}
                  onChange={(value) => {
                    setBrand_color(value);
                    updateValues("brand_color", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Brand_color.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={brand_color === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("effective_pixels") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Effective Pixels"
                hasFeedback
                validateStatus={effective_pixelserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Effective Pixels"
                  value={effective_pixels}
                  onChange={(value) => {
                    setEffective_pixels(value);
                    updateValues("effective_pixels", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Effective_pixels.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={effective_pixels === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("tripod_socket") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Tripod Socket"
                hasFeedback
                validateStatus={tripod_socketerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Tripod Socket"
                  value={tripod_socket}
                  onChange={(value) => {
                    setTripod_socket(value);
                    updateValues("tripod_socket", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Tripod_socket.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={tripod_socket === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sensor_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sensor Type"
                hasFeedback
                validateStatus={sensor_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Sensor Type"
                  value={sensor_type}
                  onChange={(value) => {
                    setSensor_type(value);
                    updateValues("sensor_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Sensor_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sensor_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_sensor_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Image Sensor Size"
                hasFeedback
                validateStatus={image_sensor_sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Image Sensor Size"
                  value={image_sensor_size}
                  onChange={(value) => {
                    setImage_sensor_size(value);
                    updateValues("image_sensor_size", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Image_sensor_size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_sensor_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lens_mount") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lens Mount"
                hasFeedback
                validateStatus={lens_mounterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Lens Mount"
                  value={lens_mount}
                  onChange={(value) => {
                    setLens_mount(value);
                    updateValues("lens_mount", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Lens_mount.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lens_mount === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dust_reduction") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dust Reduction"
                hasFeedback
                validateStatus={dust_reductionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Dust Reduction"
                  value={dust_reduction}
                  onChange={(value) => {
                    setDust_reduction(value);
                    updateValues("dust_reduction", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Dust_reduction.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dust_reduction === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("shutter_speed") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Shutter Speed"
                hasFeedback
                validateStatus={shutter_speederror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Shutter Speed"
                  value={shutter_speed}
                  onChange={(value) => {
                    setShutter_speed(value);
                    updateValues("shutter_speed", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Shutter_speed.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={shutter_speed === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("self_timer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Self Timer"
                hasFeedback
                validateStatus={self_timererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Self Timer"
                  value={self_timer}
                  onChange={(value) => {
                    setSelf_timer(value);
                    updateValues("self_timer", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Self_timer.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={self_timer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("built_in_flash") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Built in Flash"
                hasFeedback
                validateStatus={built_in_flasherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Built in Flash"
                  value={built_in_flash}
                  onChange={(value) => {
                    setBuilt_in_flash(value);
                    updateValues("built_in_flash", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={built_in_flash === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("external_flash") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="External Flash"
                hasFeedback
                validateStatus={external_flasherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select External Flash"
                  value={external_flash}
                  onChange={(value) => {
                    setExternal_flash(value);
                    updateValues("external_flash", value);
                  }}
                  style={{ width: 240 }}
                >
                  {External_flash.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={external_flash === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_format") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Image Format"
                hasFeedback
                validateStatus={image_formaterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Image Format"
                  value={image_format}
                  onChange={(value) => {
                    setImage_format(value);
                    updateValues("image_format", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Image_format.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_format === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_resolution") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Resolution"
                hasFeedback
                validateStatus={video_resolutionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Video Resolution"
                  value={video_resolution}
                  onChange={(value) => {
                    setVideo_resolution(value);
                    updateValues("video_resolution", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Video_resolution.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_resolution === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_quality") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Quality"
                hasFeedback
                validateStatus={video_qualityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Video Quality"
                  value={video_quality}
                  onChange={(value) => {
                    setVideo_quality(value);
                    updateValues("video_quality", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Video_quality.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_quality === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("comapatible_cord") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Comapatible Cord"
                hasFeedback
                validateStatus={comapatible_corderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Comapatible Cord"
                  value={comapatible_cord}
                  onChange={(value) => {
                    setComapatible_cord(value);
                    updateValues("comapatible_cord", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Compatible_cord.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={comapatible_cord === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_batteries") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Batteries"
                hasFeedback
                validateStatus={number_of_batterieserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Number of Batteries"
                  value={number_of_batteries}
                  onChange={(value) => {
                    setNumber_of_batteries(value);
                    updateValues("number_of_batteries", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Number_of_batteries.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_batteries === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("shooting_modes") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Shooting Modes"
                hasFeedback
                validateStatus={shooting_modeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Shooting Modes"
                  value={shooting_modes}
                  onChange={(value) => {
                    setShooting_modes(value);
                    updateValues("shooting_modes", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Shooting_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={shooting_modes === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_sensor_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Image Sensor Type"
                hasFeedback
                validateStatus={image_sensor_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Image Sensor Type"
                  value={image_sensor_type}
                  onChange={(value) => {
                    setImage_sensor_type(value);
                    updateValues("image_sensor_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Image_sensor_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_sensor_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("iso_rating") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="ISO Rating"
                hasFeedback
                validateStatus={iso_ratingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select ISO Rating"
                  value={iso_rating}
                  onChange={(value) => {
                    setIso_rating(value);
                    updateValues("iso_rating", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Iso_rating.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={iso_rating === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("focal_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Focal Length"
                hasFeedback
                validateStatus={focal_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Focal Length"
                  value={focal_length}
                  onChange={(value) => {
                    setFocal_length(value);
                    updateValues("focal_length", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Focal_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={focal_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("focus_mode") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Focus Mode"
                hasFeedback
                validateStatus={focus_modeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Focus Mode"
                  value={focus_mode}
                  onChange={(value) => {
                    setFocus_mode(value);
                    updateValues("focus_mode", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Focus_mode.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={focus_mode === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("focus_range") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Focus Range"
                hasFeedback
                validateStatus={focus_rangeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Focus Range"
                  value={focus_range}
                  onChange={(value) => {
                    setFocus_range(value);
                    updateValues("focus_range", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Focus_range.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={focus_range === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("digital_zoom") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Digital Zoom"
                hasFeedback
                validateStatus={digital_zoomerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Digital Zoom"
                  value={digital_zoom}
                  onChange={(value) => {
                    setDigital_zoom(value);
                    updateValues("digital_zoom", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={digital_zoom === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("metering_model") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Metering Model"
                hasFeedback
                validateStatus={metering_modelerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Metering Model"
                  value={metering_model}
                  onChange={(value) => {
                    setMetering_model(value);
                    updateValues("metering_model", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Metering_model.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={metering_model === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("face_detection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Face Detection"
                hasFeedback
                validateStatus={face_detectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Face Detection"
                  value={face_detection}
                  onChange={(value) => {
                    setFace_detection(value);
                    updateValues("face_detection", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={face_detection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("temperature") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Temperature"
                hasFeedback
                validateStatus={temperatureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Temperature"
                  value={temperature}
                  onChange={(value) => {
                    setTemperature(value);
                    updateValues("temperature", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Temperature.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={temperature === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_processsor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Image Processsor"
                hasFeedback
                validateStatus={image_processsorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Image Processsor"
                  value={image_processsor}
                  onChange={(value) => {
                    setImage_processsor(value);
                    updateValues("image_processsor", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Image_processor.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_processsor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ports") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ports"
                hasFeedback
                validateStatus={portserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Ports"
                  value={ports}
                  onChange={(value) => {
                    setPorts(value);
                    updateValues("ports", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Ports.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ports === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("accessory_shoe") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Accessory Shoe"
                hasFeedback
                validateStatus={accessory_shoeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Accessory Shoe"
                  value={accessory_shoe}
                  onChange={(value) => {
                    setAccessory_shoe(value);
                    updateValues("accessory_shoe", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Accessory_shoe.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={accessory_shoe === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lens_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lens Type"
                hasFeedback
                validateStatus={lens_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Lens Type"
                  value={lens_type}
                  onChange={(value) => {
                    setLens_type(value);
                    updateValues("lens_type", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Lens_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lens_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("manual_focus") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Manual Focus"
                hasFeedback
                validateStatus={manual_focuserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Manual Focus"
                  value={manual_focus}
                  onChange={(value) => {
                    setManual_focus(value);
                    updateValues("manual_focus", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Manual_focus.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={manual_focus === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("view_finder") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="View Finder"
                hasFeedback
                validateStatus={view_findererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select View Finder"
                  value={view_finder}
                  onChange={(value) => {
                    setView_finder(value);
                    updateValues("view_finder", value);
                  }}
                  style={{ width: 240 }}
                >
                  {View_finder.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={view_finder === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_focus") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Focus"
                hasFeedback
                validateStatus={auto_focuserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Auto Focus"
                  value={auto_focus}
                  onChange={(value) => {
                    setAuto_focus(value);
                    updateValues("auto_focus", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Auto_focus.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_focus === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("focus_points") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Focus Points"
                hasFeedback
                validateStatus={focus_pointserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Focus Points"
                  value={focus_points}
                  onChange={(value) => {
                    setFocus_points(value);
                    updateValues("focus_points", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Focus_points.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={focus_points === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("white_balance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="White Balance"
                hasFeedback
                validateStatus={white_balanceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select White Balance"
                  value={white_balance}
                  onChange={(value) => {
                    setWhite_balance(value);
                    updateValues("white_balance", value);
                  }}
                  style={{ width: 240 }}
                >
                  {White_balance.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={white_balance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("scene_modes") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Scene Modes"
                hasFeedback
                validateStatus={scene_modeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Scene Modes"
                  value={scene_modes}
                  onChange={(value) => {
                    setScene_modes(value);
                    updateValues("scene_modes", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Scene_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={scene_modes === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("exposure_mode") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Exposure Mode"
                hasFeedback
                validateStatus={exposure_modeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Exposure Mode"
                  value={exposure_mode}
                  onChange={(value) => {
                    setExposure_mode(value);
                    updateValues("exposure_mode", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Exposure_mode.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={exposure_mode === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ae_lock_or_exposure_lock") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="AE Lock or Exposure Lock"
                hasFeedback
                validateStatus={
                  ae_lock_or_exposure_lockerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select AE Lock or Exposure Lock"
                  value={ae_lock_or_exposure_lock}
                  onChange={(value) => {
                    setAe_lock_or_exposure_lock(value);
                    updateValues("ae_lock_or_exposure_lock", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Ae_lock_or_exposure_lock.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ae_lock_or_exposure_lock === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("exposure_bracketing") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Exposure Bracketing"
                hasFeedback
                validateStatus={exposure_bracketingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Exposure Bracketing"
                  value={exposure_bracketing}
                  onChange={(value) => {
                    setExposure_bracketing(value);
                    updateValues("exposure_bracketing", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Exposure_bracketing.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={exposure_bracketing === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("manual_exposure") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Manual Exposure"
                hasFeedback
                validateStatus={manual_exposureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Manual Exposure"
                  value={manual_exposure}
                  onChange={(value) => {
                    setManual_exposure(value);
                    updateValues("manual_exposure", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Manual_exposure.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={manual_exposure === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("flash_modes") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Flash Modes"
                hasFeedback
                validateStatus={flash_modeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Flash Modes"
                  value={flash_modes}
                  onChange={(value) => {
                    setFlash_modes(value);
                    updateValues("flash_modes", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Flash_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={flash_modes === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("flash_compension") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Flash Compension"
                hasFeedback
                validateStatus={flash_compensionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select flash_compension"
                  value={flash_compension}
                  onChange={(value) => {
                    setFlash_compension(value);
                    updateValues("flash_compension", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Flash_compensation.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={flash_compension === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_size") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="image_size"
                hasFeedback
                validateStatus={image_sizeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Image Size"
                  value={image_size}
                  onChange={(value) => {
                    setImage_size(value);
                    updateValues("image_size", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Image_size.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_size === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wide_angle") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wide Angle"
                hasFeedback
                validateStatus={wide_angleerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Wide Angle"
                  value={wide_angle}
                  onChange={(value) => {
                    setWide_angle(value);
                    updateValues("wide_angle", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Wide_angle.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wide_angle === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("red_eye_reduction") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Red Eye Reduction"
                hasFeedback
                validateStatus={red_eye_reductionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Red Eye Reduction"
                  value={red_eye_reduction}
                  onChange={(value) => {
                    setRed_eye_reduction(value);
                    updateValues("red_eye_reduction", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Red_eye_reduction.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={red_eye_reduction === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("live_view_shooting") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Live View Shooting"
                hasFeedback
                validateStatus={live_view_shootingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Live View Shooting"
                  value={live_view_shooting}
                  onChange={(value) => {
                    setLive_view_shooting(value);
                    updateValues("live_view_shooting", value);
                  }}
                  style={{ width: 240 }}
                >
                  {Live_view_shooting.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={live_view_shooting === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hdr_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="HDR Support"
                hasFeedback
                validateStatus={hdr_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select HDR Support"
                  value={hdr_support}
                  onChange={(value) => {
                    setHdr_support(value);
                    updateValues("hdr_support", value);
                  }}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hdr_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("microphone") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Microphone"
                hasFeedback
                validateStatus={microphoneerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Microphone"
                  onChange={(value) => {
                    setMicrophone(value);
                    updateValues("microphone", value);
                  }}
                  value={microphone}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={microphone === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("upgradeable_memory") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Upgradeable Memory"
                hasFeedback
                validateStatus={upgradeable_memoryerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Upgradeable Memory"
                  onChange={(value) => {
                    setUpgradeable_memory(value);
                    updateValues("upgradeable_memory", value);
                  }}
                  value={upgradeable_memory}
                  style={{ width: 240 }}
                >
                  {Upgradeable_memory.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={upgradeable_memory === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("prime_zoom") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Prime/Zoom"
                hasFeedback
                validateStatus={prime_zoomerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Prime/Zoom"
                  onChange={(value) => {
                    setPrime_zoom(value);
                    updateValues("prime_zoom", value);
                  }}
                  value={prime_zoom}
                  style={{ width: 240 }}
                >
                  {Prime_zoom.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={prime_zoom === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("designed_for") && (
            <Col className="gutter-row" span={8}>
              <span>Designed For:</span>
              <Input
                status={designed_forerror !== "" ? "error" : ""}
                suffix={
                  designed_forerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Designed For"
                label="Designed For"
                value={designed_for}
                onChange={(e) => {
                  setDesigned_for(e.target.value);
                  updateValues("designed_for", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_focal_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Focal Length"
                hasFeedback
                validateStatus={maximum_focal_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Focal Length"
                  onChange={(value) => {
                    setMaximum_focal_length(value);
                    updateValues("maximum_focal_length", value);
                  }}
                  value={maximum_focal_length}
                  style={{ width: 240 }}
                >
                  {Maximum_focal_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_focal_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("minimum_focal_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum Focal Length"
                hasFeedback
                validateStatus={minimum_focal_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Minimum Focal Length"
                  onChange={(value) => {
                    setMinimum_focal_length(value);
                    updateValues("minimum_focal_length", value);
                  }}
                  value={minimum_focal_length}
                  style={{ width: 240 }}
                >
                  {Minimum_focal_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={minimum_focal_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("aperture_with_max_focal_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Aperture with Max Focal Length"
                hasFeedback
                validateStatus={
                  aperture_with_max_focal_lengtherror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Aperture with Max Focal Length"
                  onChange={(value) => {
                    setAperture_with_max_focal_length(value);
                    updateValues("aperture_with_max_focal_length", value);
                  }}
                  value={aperture_with_max_focal_length}
                  style={{ width: 240 }}
                >
                  {Aperture_with_max_focal_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={aperture_with_max_focal_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("aperture_with_min_focal_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Aperture with Min Focal length"
                hasFeedback
                validateStatus={
                  aperture_with_min_focal_lengtherror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Aperture with Min Focal length"
                  onChange={(value) => {
                    setAperture_with_min_focal_length(value);
                    updateValues("aperture_with_min_focal_length", value);
                  }}
                  value={aperture_with_min_focal_length}
                  style={{ width: 240 }}
                >
                  {Aperture_with_min_focal_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={aperture_with_min_focal_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("shutter_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Shutter type"
                hasFeedback
                validateStatus={shutter_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Shutter type"
                  onChange={(value) => {
                    setShutter_type(value);
                    updateValues("shutter_type", value);
                  }}
                  value={shutter_type}
                  style={{ width: 240 }}
                >
                  {Shutter_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={shutter_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_channels") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Channels"
                hasFeedback
                validateStatus={number_of_channelserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number of Channels"
                  onChange={(value) => {
                    setNumber_of_channels(value);
                    updateValues("number_of_channels", value);
                  }}
                  value={number_of_channels}
                  style={{ width: 240 }}
                >
                  {Number_of_channels.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_channels === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("camera_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Camera type"
                hasFeedback
                validateStatus={camera_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Camera type"
                  onChange={(value) => {
                    setCamera_type(value);
                    updateValues("camera_type", value);
                  }}
                  value={camera_type}
                  style={{ width: 240 }}
                >
                  {Camera_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={camera_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("video_recording_frame_rate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Video Recording Frame rate"
                hasFeedback
                validateStatus={
                  video_recording_frame_rateerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Video Recording Frame rate"
                  onChange={(value) => {
                    setVideo_recording_frame_rate(value);
                    updateValues("video_recording_frame_rate", value);
                  }}
                  value={video_recording_frame_rate}
                  style={{ width: 240 }}
                >
                  {Video_recording_frame_rate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={video_recording_frame_rate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("hdd_available") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="HDD available"
                hasFeedback
                validateStatus={hdd_availableerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select HDD available"
                  onChange={(value) => {
                    setHdd_available(value);
                    updateValues("hdd_available", value);
                  }}
                  value={hdd_available}
                  style={{ width: 240 }}
                >
                  {Hdd_available.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={hdd_available === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("recording_format") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Recording Format"
                hasFeedback
                validateStatus={recording_formaterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Recording Format"
                  onChange={(value) => {
                    setRecording_format(value);
                    updateValues("recording_format", value);
                  }}
                  value={recording_format}
                  style={{ width: 240 }}
                >
                  {Recording_format.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={recording_format === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("display_frame_rate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Display Frame Rate"
                hasFeedback
                validateStatus={display_frame_rateerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Display Frame Rate"
                  onChange={(value) => {
                    setDisplay_frame_rate(value);
                    updateValues("display_frame_rate", value);
                  }}
                  value={display_frame_rate}
                  style={{ width: 240 }}
                >
                  {Display_frame_rate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={display_frame_rate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("record_modes") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Record Modes"
                hasFeedback
                validateStatus={record_modeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Record Modes"
                  onChange={(value) => {
                    setRecord_modes(value);
                    updateValues("record_modes", value);
                  }}
                  value={record_modes}
                  style={{ width: 240 }}
                >
                  {Record_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={record_modes === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_sensor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Image sensor"
                hasFeedback
                validateStatus={image_sensorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Image sensor"
                  onChange={(value) => {
                    setImage_sensor(value);
                    updateValues("image_sensor", value);
                  }}
                  value={image_sensor}
                  style={{ width: 240 }}
                >
                  {Image_sensor.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_sensor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("image_resolution") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Image Resolution"
                hasFeedback
                validateStatus={image_resolutionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Image Resolution"
                  onChange={(value) => {
                    setImage_resolution(value);
                    updateValues("image_resolution", value);
                  }}
                  value={image_resolution}
                  style={{ width: 240 }}
                >
                  {Image_resolution.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={image_resolution === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("minimum_illumination") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum Illumination"
                hasFeedback
                validateStatus={minimum_illuminationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Minimum Illumination"
                  onChange={(value) => {
                    setMinimum_illumination(value);
                    updateValues("minimum_illumination", value);
                  }}
                  value={minimum_illumination}
                  style={{ width: 240 }}
                >
                  {Minimum_illumination.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={minimum_illumination === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ir_distance") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="IR Distance"
                hasFeedback
                validateStatus={ir_distanceerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select IR Distance"
                  onChange={(value) => {
                    setIr_distance(value);
                    updateValues("ir_distance", value);
                  }}
                  value={ir_distance}
                  style={{ width: 240 }}
                >
                  {Ir_distance.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ir_distance === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("motin_triggered_recording") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Motin Triggered Recording"
                hasFeedback
                validateStatus={
                  motin_triggered_recordingerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Motin Triggered Recording"
                  onChange={(value) => {
                    setMotin_triggered_recording(value);
                    updateValues("motin_triggered_recording", value);
                  }}
                  value={motin_triggered_recording}
                  style={{ width: 240 }}
                >
                  {Motion_triggered_recording.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={motin_triggered_recording === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("audio_output") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Audio Output"
                hasFeedback
                validateStatus={audio_outputerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Audio Output"
                  onChange={(value) => {
                    setAudio_output(value);
                    updateValues("audio_output", value);
                  }}
                  value={audio_output}
                  style={{ width: 240 }}
                >
                  {Headphone_jack.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={audio_output === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("network_interface_time") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Network Interface Time"
                hasFeedback
                validateStatus={
                  network_interface_timeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Network Interface Time"
                  onChange={(value) => {
                    setNetwork_interface_time(value);
                    updateValues("network_interface_time", value);
                  }}
                  value={network_interface_time}
                  style={{ width: 240 }}
                >
                  {Network_interface_time.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={network_interface_time === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_ethiernet_ports") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Ethiernet Ports"
                hasFeedback
                validateStatus={
                  number_of_ethiernet_portserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number of Ethiernet Ports"
                  onChange={(value) => {
                    setNumber_of_ethiernet_ports(value);
                    updateValues("number_of_ethiernet_ports", value);
                  }}
                  value={number_of_ethiernet_ports}
                  style={{ width: 240 }}
                >
                  {Number_of_ethernet_ports.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_ethiernet_ports === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("power_consumption") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Power Consumption"
                hasFeedback
                validateStatus={power_consumptionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Power Consumption"
                  onChange={(value) => {
                    setPower_consumption(value);
                    updateValues("power_consumption", value);
                  }}
                  value={power_consumption}
                  style={{ width: 240 }}
                >
                  {Power_consumption.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={power_consumption === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("alarm_action") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Alarm Action"
                hasFeedback
                validateStatus={alarm_actionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Alarm Action"
                  onChange={(value) => {
                    setAlarm_action(value);
                    updateValues("alarm_action", value);
                  }}
                  value={alarm_action}
                  style={{ width: 240 }}
                >
                  {Alarm_action.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={alarm_action === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("application_support") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Application Support"
                hasFeedback
                validateStatus={application_supporterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Application Support"
                  onChange={(value) => {
                    setApplication_support(value);
                    updateValues("application_support", value);
                  }}
                  value={application_support}
                  style={{ width: 240 }}
                >
                  {Application_support.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={application_support === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("remote_connectivity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Remote Connectivity"
                hasFeedback
                validateStatus={remote_connectivityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Remote Connectivity"
                  onChange={(value) => {
                    setRemote_connectivity(value);
                    updateValues("remote_connectivity", value);
                  }}
                  value={remote_connectivity}
                  style={{ width: 240 }}
                >
                  {Remote_connectivity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={remote_connectivity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("closure_mechanism") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Closure Mechanism"
                hasFeedback
                validateStatus={closure_mechanismerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Closure Mechanism"
                  onChange={(value) => {
                    setClosure_mechanism(value);
                    updateValues("closure_mechanism", value);
                  }}
                  value={closure_mechanism}
                  style={{ width: 240 }}
                >
                  {Closure_mechanism.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={closure_mechanism === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("outer_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Outer Material"
                hasFeedback
                validateStatus={outer_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Outer Material"
                  onChange={(value) => {
                    setOuter_material(value);
                    updateValues("outer_material", value);
                  }}
                  value={outer_material}
                  style={{ width: 240 }}
                >
                  {Outer_body_materials.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={outer_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("inner_height") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Inner Height"
                hasFeedback
                validateStatus={inner_heighterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Inner Height"
                  onChange={(value) => {
                    setInner_height(value);
                    updateValues("inner_height", value);
                  }}
                  value={inner_height}
                  style={{ width: 240 }}
                >
                  {Inner_height.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={inner_height === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("inner_width") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Inner Width"
                hasFeedback
                validateStatus={inner_widtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Inner Width"
                  onChange={(value) => {
                    setInner_width(value);
                    updateValues("inner_width", value);
                  }}
                  value={inner_width}
                  style={{ width: 240 }}
                >
                  {Inner_width.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={inner_width === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("inner_depth") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Inner Depth"
                hasFeedback
                validateStatus={inner_deptherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Inner Depth"
                  onChange={(value) => {
                    setInner_depth(value);
                    updateValues("inner_depth", value);
                  }}
                  value={inner_depth}
                  style={{ width: 240 }}
                >
                  {Depth.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={inner_depth === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("head_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Head Type"
                hasFeedback
                validateStatus={head_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Head Type"
                  onChange={(value) => {
                    setHead_type(value);
                    updateValues("head_type", value);
                  }}
                  value={head_type}
                  style={{ width: 240 }}
                >
                  {Head_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={head_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("leg_lock_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Leg Lock type"
                hasFeedback
                validateStatus={leg_lock_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Leg Lock type"
                  onChange={(value) => {
                    setLeg_lock_type(value);
                    updateValues("leg_lock_type", value);
                  }}
                  value={leg_lock_type}
                  style={{ width: 240 }}
                >
                  {Leg_lock_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={leg_lock_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("independent_leg_spread") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Independent Leg Spread"
                hasFeedback
                validateStatus={
                  independent_leg_spreaderror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Independent Leg Spread"
                  onChange={(value) => {
                    setIndependent_leg_spread(value);
                    updateValues("independent_leg_spread", value);
                  }}
                  value={independent_leg_spread}
                  style={{ width: 240 }}
                >
                  {Independent_leg_spread.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={independent_leg_spread === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pack_of") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pack of"
                hasFeedback
                validateStatus={pack_oferror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Pack of"
                  onChange={(value) => {
                    setPack_of(value);
                    updateValues("pack_of", value);
                  }}
                  value={pack_of}
                  style={{ width: 240 }}
                >
                  {Pack_of.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pack_of === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("floded_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Floded length"
                hasFeedback
                validateStatus={floded_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Floded length"
                  onChange={(value) => {
                    setFloded_length(value);
                    updateValues("floded_length", value);
                  }}
                  value={floded_length}
                  style={{ width: 240 }}
                >
                  {Folded_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={floded_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_charging_cells") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number of Charging cells"
                hasFeedback
                validateStatus={
                  number_of_charging_cellserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number of Charging cells"
                  onChange={(value) => {
                    setNumber_of_charging_cells(value);
                    updateValues("number_of_charging_cells", value);
                  }}
                  value={number_of_charging_cells}
                  style={{ width: 240 }}
                >
                  {Number_of_charging_cells.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_charging_cells === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("no_memory_effect") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="No Memory Effect"
                hasFeedback
                validateStatus={no_memory_effecterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select No Memory Effect"
                  onChange={(value) => {
                    setNo_memory_effect(value);
                    updateValues("no_memory_effect", value);
                  }}
                  value={no_memory_effect}
                  style={{ width: 240 }}
                >
                  {No_memory_effect.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={no_memory_effect === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("maximum_shelf_life") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Maximum Shelf Life"
                hasFeedback
                validateStatus={maximum_shelf_lifeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Maximum Shelf Life"
                  onChange={(value) => {
                    setMaximum_shelf_life(value);
                    updateValues("maximum_shelf_life", value);
                  }}
                  value={maximum_shelf_life}
                  style={{ width: 240 }}
                >
                  {Maximum_shelf_life.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={maximum_shelf_life === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("magnification") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Magnification"
                hasFeedback
                validateStatus={magnificationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Magnification"
                  onChange={(value) => {
                    setMagnification(value);
                    updateValues("magnification", value);
                  }}
                  value={magnification}
                  style={{ width: 240 }}
                >
                  {Magnification.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={magnification === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("apparent_field_of_view") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Apparent Field of View"
                hasFeedback
                validateStatus={
                  apparent_field_of_viewerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Apparent Field of View"
                  onChange={(value) => {
                    setApparent_field_of_view(value);
                    updateValues("apparent_field_of_view", value);
                  }}
                  value={apparent_field_of_view}
                  style={{ width: 240 }}
                >
                  {Apparent_field_of_view.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={apparent_field_of_view === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Capacity"
                hasFeedback
                validateStatus={capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Capacity"
                  onChange={(value) => {
                    setCapacity(value);
                    updateValues("capacity", value);
                  }}
                  value={capacity}
                  style={{ width: 240 }}
                >
                  {Capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("manufacturing_year") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Manufacturing Year"
                hasFeedback
                validateStatus={manufacturing_yearerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Manufacturing Year"
                  onChange={(value) => {
                    setManufacturing_year(value);
                    updateValues("manufacturing_year", value);
                  }}
                  value={manufacturing_year}
                  style={{ width: 240 }}
                >
                  {Manufacturing_year.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={manufacturing_year === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cover_in_warranty") && (
            <Col className="gutter-row" span={12}>
              <span>Covered in warranty:</span>
              <Input
                status={cover_in_warrantyerror !== "" ? "error" : ""}
                suffix={
                  cover_in_warrantyerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Covered in warranty"
                label="Covered in warranty"
                value={cover_in_warranty}
                onChange={(e) => {
                  setCover_in_warranty(e.target.value);
                  updateValues("cover_in_warranty", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("not_cover_in_warranty") && (
            <Col className="gutter-row" span={12}>
              <span>Not Covered in warranty:</span>
              <Input
                status={not_cover_in_warrantyerror !== "" ? "error" : ""}
                suffix={
                  not_cover_in_warrantyerror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Not Covered in warranty"
                label="Not Covered in warranty"
                value={not_cover_in_warranty}
                onChange={(e) => {
                  setNot_cover_in_warranty(e.target.value);
                  updateValues("not_cover_in_warranty", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("preheat") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Preheat"
                hasFeedback
                validateStatus={preheaterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Preheat"
                  onChange={(value) => {
                    setPreheat(value);
                    updateValues("preheat", value);
                  }}
                  value={preheat}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={preheat === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("timer") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Timer"
                hasFeedback
                validateStatus={timererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Timer"
                  onChange={(value) => {
                    setTimer(value);
                    updateValues("timer", value);
                  }}
                  value={timer}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={timer === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_cook_menu_available") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Cook Menu Available"
                hasFeedback
                validateStatus={
                  auto_cook_menu_availableerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Auto Cook Menu Available"
                  onChange={(value) => {
                    setAuto_cook_menu_available(value);
                    updateValues("auto_cook_menu_available", value);
                  }}
                  value={auto_cook_menu_available}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_cook_menu_available === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("power_output") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Power Output"
                hasFeedback
                validateStatus={power_outputerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Power Output"
                  onChange={(value) => {
                    setPower_output(value);
                    updateValues("power_output", value);
                  }}
                  value={power_output}
                  style={{ width: 240 }}
                >
                  {Power_output.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={power_output === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sales_package") && (
            <Col className="gutter-row" span={12}>
              <span>Sales Package:</span>
              <Input
                status={sales_packageerror !== "" ? "error" : ""}
                suffix={
                  sales_packageerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Sales Package"
                label="Sales Package"
                value={sales_package}
                onChange={(e) => {
                  setSales_package(e.target.value);
                  updateValues("sales_package", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("rotisserie") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Rotisserie"
                hasFeedback
                validateStatus={rotisserieerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Rotisserie"
                  onChange={(value) => {
                    setRotisserie(value);
                    updateValues("rotisserie", value);
                  }}
                  value={rotisserie}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={rotisserie === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("rotisserie_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Rotisserie Type"
                hasFeedback
                validateStatus={rotisserie_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Rotisserie Type"
                  onChange={(value) => {
                    setRotisserie_type(value);
                    updateValues("rotisserie_type", value);
                  }}
                  value={rotisserie_type}
                  style={{ width: 240 }}
                >
                  {Rotisserie_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={rotisserie_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cavity_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cavity Material"
                hasFeedback
                validateStatus={cavity_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Cavity Material"
                  onChange={(value) => {
                    setCavity_material(value);
                    updateValues("cavity_material", value);
                  }}
                  value={cavity_material}
                  style={{ width: 240 }}
                >
                  {Cavity_Material_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cavity_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("temperature_control_range") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Temperature Control Range"
                hasFeedback
                validateStatus={
                  temperature_control_rangeerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Temperature Control Range"
                  onChange={(value) => {
                    setTemperature_control_range(value);
                    updateValues("temperature_control_range", value);
                  }}
                  value={temperature_control_range}
                  style={{ width: 240 }}
                >
                  {Temparature_control_range.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={temperature_control_range === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_menu") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Menu"
                hasFeedback
                validateStatus={auto_menuerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Auto Menu"
                  onChange={(value) => {
                    setAuto_menu(value);
                    updateValues("auto_menu", value);
                  }}
                  value={auto_menu}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_menu === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("number_of_auto_menu") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Number Of Auto Menu"
                hasFeedback
                validateStatus={number_of_auto_menuerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Number Of Auto Menu"
                  onChange={(value) => {
                    setNumber_of_auto_menu(value);
                    updateValues("number_of_auto_menu", value);
                  }}
                  value={number_of_auto_menu}
                  style={{ width: 240 }}
                >
                  {Number_of_auto_menu.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={number_of_auto_menu === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("power_requirement") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Power Requirement"
                hasFeedback
                validateStatus={power_requirementerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Power Requirement"
                  onChange={(value) => {
                    setPower_requirement(value);
                    updateValues("power_requirement", value);
                  }}
                  value={power_requirement}
                  style={{ width: 240 }}
                >
                  {Power_requirement.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={power_requirement === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Material"
                hasFeedback
                validateStatus={materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Material"
                  onChange={(value) => {
                    setMaterial(value);
                    updateValues("material", value);
                  }}
                  value={material}
                  style={{ width: 240 }}
                >
                  {Material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dry_grinding") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dry Grinding"
                hasFeedback
                validateStatus={dry_grindingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dry Grinding"
                  onChange={(value) => {
                    setDry_grinding(value);
                    updateValues("dry_grinding", value);
                  }}
                  value={dry_grinding}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dry_grinding === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("mincing") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Mincing"
                hasFeedback
                validateStatus={mincingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Mincing"
                  onChange={(value) => {
                    setMincing(value);
                    updateValues("mincing", value);
                  }}
                  value={mincing}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={mincing === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("chutey_grinding") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Chutey Grinding"
                hasFeedback
                validateStatus={chutey_grindingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Chutey Grinding"
                  onChange={(value) => {
                    setChutey_grinding(value);
                    updateValues("chutey_grinding", value);
                  }}
                  value={chutey_grinding}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={chutey_grinding === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("liguidizing_jar_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Liguidizing Jar Capacity"
                hasFeedback
                validateStatus={
                  liguidizing_jar_capacityerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Liguidizing Jar Capacity"
                  onChange={(value) => {
                    setLiguidizing_jar_capacity(value);
                    updateValues("liguidizing_jar_capacity", value);
                  }}
                  value={liguidizing_jar_capacity}
                  style={{ width: 240 }}
                >
                  {Liquidizing_jar_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={liguidizing_jar_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("chatey_jar_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Chatey Jar Capacity"
                hasFeedback
                validateStatus={chatey_jar_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Chatey Jar Capacity"
                  onChange={(value) => {
                    setChatey_jar_capacity(value);
                    updateValues("chatey_jar_capacity", value);
                  }}
                  value={chatey_jar_capacity}
                  style={{ width: 240 }}
                >
                  {Chutney_jar_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={chatey_jar_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("series") && (
            <Col className="gutter-row" span={8}>
              <span>Series :</span>
              <Input
                status={serieserror !== "" ? "error" : ""}
                suffix={serieserror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Series"
                label="Series"
                value={series}
                onChange={(e) => {
                  setSeries(e.target.value);
                  updateValues("series", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        {/* <Flex wrap="wrap">
        {distinctfield.includes("series") && (
          <Col className="gutter-row" span={12}>
            <span>Series:</span>
            <Input
              status={serieserror !== "" ? "error" : ""}
              suffix={
                serieserror !== "" ? (
                  <MdOutlineErrorOutline />
                ) : (
                  ""
                )
              }
              type="text"
              placeholder="Enter Series"
              label="Series"
              value={series}
              onChange={(e) => {
                setSeries(e.target.value);
                updateValues("series", e.target.value);
              }}
              style={{ width: 240 }}
            />
          </Col>
        )}
      </Flex> */}
        <Flex wrap="wrap">
          {distinctfield.includes("locbable_lid") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Locbable Lid"
                hasFeedback
                validateStatus={locbable_liderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Locbable Lid"
                  onChange={(value) => {
                    setLocbable_lid(value);
                    updateValues("locbable_lid", value);
                  }}
                  value={locbable_lid}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={locbable_lid === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dry_boil_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dry Boil Protection"
                hasFeedback
                validateStatus={dry_boil_protectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dry Boil Protection"
                  onChange={(value) => {
                    setDry_boil_protection(value);
                    updateValues("dry_boil_protection", value);
                  }}
                  value={dry_boil_protection}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dry_boil_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_switch_off") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Switch Off"
                hasFeedback
                validateStatus={auto_switch_offerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Auto Switch Off"
                  onChange={(value) => {
                    setAuto_switch_off(value);
                    updateValues("auto_switch_off", value);
                  }}
                  value={auto_switch_off}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_switch_off === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("heating_element") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Heating Element"
                hasFeedback
                validateStatus={heating_elementerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Heating Element"
                  onChange={(value) => {
                    setHeating_element(value);
                    updateValues("heating_element", value);
                  }}
                  value={heating_element}
                  style={{ width: 240 }}
                >
                  {Heating_element.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={heating_element === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("water_level_indicator") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Water Level Indicator"
                hasFeedback
                validateStatus={
                  water_level_indicatorerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Water Level Indicator"
                  onChange={(value) => {
                    setWater_level_indicator(value);
                    updateValues("water_level_indicator", value);
                  }}
                  value={water_level_indicator}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={water_level_indicator === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("card_length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cord Length"
                hasFeedback
                validateStatus={card_lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Cord Length"
                  onChange={(value) => {
                    setCard_length(value);
                    updateValues("card_length", value);
                  }}
                  value={card_length}
                  style={{ width: 240 }}
                >
                  {Cord_length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={card_length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("body_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Body Material"
                hasFeedback
                validateStatus={body_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Body Material"
                  onChange={(value) => {
                    setBody_material(value);
                    updateValues("body_material", value);
                  }}
                  value={body_material}
                  style={{ width: 240 }}
                >
                  {Body_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={body_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Control"
                hasFeedback
                validateStatus={controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Control"
                  onChange={(value) => {
                    setControl(value);
                    updateValues("control", value);
                  }}
                  value={control}
                  style={{ width: 240 }}
                >
                  {Control_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("present_cooking_menu") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Present Cooking Menu"
                hasFeedback
                validateStatus={present_cooking_menuerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Present Cooking Menu"
                  onChange={(value) => {
                    setPresent_cooking_menu(value);
                    updateValues("present_cooking_menu", value);
                  }}
                  value={present_cooking_menu}
                  style={{ width: 240 }}
                >
                  {Present_cooking_menu.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={present_cooking_menu === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("elements_on_cooktop") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Elements On Cooktop"
                hasFeedback
                validateStatus={elements_on_cooktoperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Elements On Cooktop"
                  onChange={(value) => {
                    setElements_on_cooktop(value);
                    updateValues("elements_on_cooktop", value);
                  }}
                  value={elements_on_cooktop}
                  style={{ width: 240 }}
                >
                  {Elements_on_cooktop.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={elements_on_cooktop === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("installation_and_demo") && (
            <Col className="gutter-row" span={12}>
              <span>Installation and Demo:</span>
              <Input
                status={installation_and_demoerror !== "" ? "error" : ""}
                suffix={
                  installation_and_demoerror !== "" ? (
                    <MdOutlineErrorOutline />
                  ) : (
                    ""
                  )
                }
                type="text"
                placeholder="Enter Installation and Demo"
                label="Installation and Demo"
                value={installation_and_demo}
                onChange={(e) => {
                  setInstallation_and_demo(e.target.value);
                  updateValues("installation_and_demo", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("finish") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Finish"
                hasFeedback
                validateStatus={finisherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Finish"
                  onChange={(value) => {
                    setFinish(value);
                    updateValues("finish", value);
                  }}
                  value={finish}
                  style={{ width: 240 }}
                >
                  {Finish.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={finish === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("air_section_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Air Section Capacity"
                hasFeedback
                validateStatus={air_section_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Air Section Capacity"
                  onChange={(value) => {
                    setAir_section_capacity(value);
                    updateValues("air_section_capacity", value);
                  }}
                  value={air_section_capacity}
                  style={{ width: 240 }}
                >
                  {Air_suction_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={air_section_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("ductless") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Ductless"
                hasFeedback
                validateStatus={ductlesserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Ductless"
                  onChange={(value) => {
                    setDuctless(value);
                    updateValues("ductless", value);
                  }}
                  value={ductless}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={ductless === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("filter_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Filter Type"
                hasFeedback
                validateStatus={filter_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Filter Type"
                  onChange={(value) => {
                    setFilter_type(value);
                    updateValues("filter_type", value);
                  }}
                  value={filter_type}
                  style={{ width: 240 }}
                >
                  {Filter_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={filter_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("speed_levels") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Speed Levels"
                hasFeedback
                validateStatus={speed_levelserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Speed Levels"
                  onChange={(value) => {
                    setSpeed_levels(value);
                    updateValues("speed_levels", value);
                  }}
                  value={speed_levels}
                  style={{ width: 240 }}
                >
                  {Speed_levels.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={speed_levels === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("filter_block_indicator") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Filter Block Indicator"
                hasFeedback
                validateStatus={
                  filter_block_indicatorerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Filter Block Indicator"
                  onChange={(value) => {
                    setFilter_block_indicator(value);
                    updateValues("filter_block_indicator", value);
                  }}
                  value={filter_block_indicator}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={filter_block_indicator === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("noise_level") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Noise Level"
                hasFeedback
                validateStatus={noise_levelerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Noise Level"
                  onChange={(value) => {
                    setNoise_level(value);
                    updateValues("noise_level", value);
                  }}
                  value={noise_level}
                  style={{ width: 240 }}
                >
                  {Noise_level.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={noise_level === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("phase") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Phase"
                hasFeedback
                validateStatus={phaseerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Phase"
                  onChange={(value) => {
                    setPhase(value);
                    updateValues("phase", value);
                  }}
                  value={phase}
                  style={{ width: 240 }}
                >
                  {Phase.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={phase === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("engine_power") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Engine Power"
                hasFeedback
                validateStatus={engine_powererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Engine Power"
                  onChange={(value) => {
                    setEngine_power(value);
                    updateValues("engine_power", value);
                  }}
                  value={engine_power}
                  style={{ width: 240 }}
                >
                  {Engine_power.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={engine_power === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("in_built_oil_collector") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="In Built Oil Collector"
                hasFeedback
                validateStatus={
                  in_built_oil_collectorerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select In Built Oil Collector"
                  onChange={(value) => {
                    setIn_built_oil_collector(value);
                    updateValues("in_built_oil_collector", value);
                  }}
                  value={in_built_oil_collector}
                  style={{ width: 240 }}
                >
                  {In_built_oil_collector.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={in_built_oil_collector === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("timer_present") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Timer Present"
                hasFeedback
                validateStatus={timer_presenterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Timer Present"
                  onChange={(value) => {
                    setTimer_present(value);
                    updateValues("timer_present", value);
                  }}
                  value={timer_present}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={timer_present === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dblade_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dblade Material"
                hasFeedback
                validateStatus={dblade_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dblade Material"
                  onChange={(value) => {
                    setDblade_material(value);
                    updateValues("dblade_material", value);
                  }}
                  value={dblade_material}
                  style={{ width: 240 }}
                >
                  {Blade_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dblade_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("blending") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Blending"
                hasFeedback
                validateStatus={blendingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Blending"
                  onChange={(value) => {
                    setBlending(value);
                    updateValues("blending", value);
                  }}
                  value={blending}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={blending === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>


        {/* input Nishitha.........🤞 */}

        <Flex wrap="wrap">
          {distinctfield.includes("brand") && (
            <Col className="gutter-row" span={12}>
              <span>Brand :</span>
              <Input
                status={branderror !== "" ? "error" : ""}
                suffix={
                  branderror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Brand"
                value={brand}
                onChange={(e) => {
                  setBrand(e.target.value);
                  updateValues("brand", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("warranty_service_type") && (
            <Col className="gutter-row" span={12}>
              <span>Warranty Service Typer:</span>
              <Input
                status={warranty_service_type !== "" ? "error" : ""}
                suffix={
                  warranty_service_type !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Warranty Service Typer"
                value={warranty_service_type}
                onChange={(e) => {
                  setWarranty_service_type(e.target.value);
                  updateValues("warranty_service_type", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("other_body_and_design_features") && (
            <Col className="gutter-row" span={12}>
              <span> Other Body And Design Features:</span>
              <Input
                status={other_body_and_design_featureserror !== "" ? "error" : ""}
                suffix={
                  other_body_and_design_featureserror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter  Other Body And Design Features"
                value={other_body_and_design_features}
                onChange={(e) => {
                  setOther_body_and_design_features(e.target.value);
                  updateValues("other_body_and_design_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("service_type") && (
            <Col className="gutter-row" span={12}>
              <span>Service Type :</span>
              <Input
                status={service_typeerror !== "" ? "error" : ""}
                suffix={
                  service_typeerror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Service Type"
                value={service_type}
                onChange={(e) => {
                  setService_type(e.target.value);
                  updateValues("service_type", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("safety_features") && (
            <Col className="gutter-row" span={12}>
              <span>Safety Features:</span>
              <Input
                status={safety_featureserror !== "" ? "error" : ""}
                suffix={
                  safety_featureserror !== "" ? <MdOutlineErrorOutline /> : ""
                }
                type="text"
                placeholder="Enter Safety Features"
                value={safety_features}
                onChange={(e) => {
                  setSafety_features(e.target.value);
                  updateValues("safety_features", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("pureing") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Pureing"
                hasFeedback
                validateStatus={pureingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select pureing"
                  onChange={(value) => {
                    setPureing(value);
                    updateValues("pureing", value);
                  }}
                  value={pureing}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={pureing === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("stirring") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Stirring"
                hasFeedback
                validateStatus={stirringerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Stirring"
                  onChange={(value) => {
                    setStirring(value);
                    updateValues("stirring", value);
                  }}
                  value={stirring}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={stirring === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("model_let") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Model Let"
                hasFeedback
                validateStatus={model_leterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Model Let"
                  onChange={(value) => {
                    setModel_let(value);
                    updateValues("model_let", value);
                  }}
                  value={model_let}
                  style={{ width: 240 }}
                >
                  {Model_let.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={model_let === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("slice_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Slice Capacity"
                hasFeedback
                validateStatus={slice_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Slice Capacity"
                  onChange={(value) => {
                    setSlice_capacity(value);
                    updateValues("slice_capacity", value);
                  }}
                  value={slice_capacity}
                  style={{ width: 240 }}
                >
                  {Slice_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={slice_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("working_plate") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Working Plate"
                hasFeedback
                validateStatus={working_plateerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Working Plate"
                  onChange={(value) => {
                    setWorking_plate(value);
                    updateValues("working_plate", value);
                  }}
                  value={working_plate}
                  style={{ width: 240 }}
                >
                  {Working_plate.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={working_plate === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lid_lock") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lid Lock"
                hasFeedback
                validateStatus={lid_lockerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Lid Lock"
                  onChange={(value) => {
                    setLid_lock(value);
                    updateValues("lid_lock", value);
                  }}
                  value={lid_lock}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lid_lock === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("no_of_slices") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="No Of Slices"
                hasFeedback
                validateStatus={no_of_sliceserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select No Of Slices"
                  onChange={(value) => {
                    setNo_of_slices(value);
                    updateValues("no_of_slices", value);
                  }}
                  value={no_of_slices}
                  style={{ width: 240 }}
                >
                  {No_of_slices.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={no_of_slices === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wide_slots_for_thick_breads") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wide Slots For Thick Breads"
                hasFeedback
                validateStatus={
                  wide_slots_for_thick_breadserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wide Slots For Thick Breads"
                  onChange={(value) => {
                    setWide_slots_for_thick_breads(value);
                    updateValues("wide_slots_for_thick_breads", value);
                  }}
                  value={wide_slots_for_thick_breads}
                  style={{ width: 240 }}
                >
                  {Wide_slots_for_thick_breads.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wide_slots_for_thick_breads === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("high_lift_for_small_breads") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="High Lift For Small Breads"
                hasFeedback
                validateStatus={
                  high_lift_for_small_breadserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select High Lift For Small Breads"
                  onChange={(value) => {
                    setHigh_lift_for_small_breads(value);
                    updateValues("high_lift_for_small_breads", value);
                  }}
                  value={high_lift_for_small_breads}
                  style={{ width: 240 }}
                >
                  {High_lift_for_small_breads.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={high_lift_for_small_breads === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_pop_up") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Pop Up"
                hasFeedback
                validateStatus={auto_pop_uperror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Auto Pop Up"
                  onChange={(value) => {
                    setAuto_pop_up(value);
                    updateValues("auto_pop_up", value);
                  }}
                  value={auto_pop_up}
                  style={{ width: 240 }}
                >
                  {Auto_pop_up.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_pop_up === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("removable_crumbtroy") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Removable Crumbtroy"
                hasFeedback
                validateStatus={removable_crumbtroyerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Removable Crumbtroy"
                  onChange={(value) => {
                    setRemovable_crumbtroy(value);
                    updateValues("removable_crumbtroy", value);
                  }}
                  value={removable_crumbtroy}
                  style={{ width: 240 }}
                >
                  {Removable_crumb_tray.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={removable_crumbtroy === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("toasting") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Toasting"
                hasFeedback
                validateStatus={toastingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Toasting"
                  onChange={(value) => {
                    setToasting(value);
                    updateValues("toasting", value);
                  }}
                  value={toasting}
                  style={{ width: 240 }}
                >
                  {Toasting.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={toasting === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("warming") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Warming"
                hasFeedback
                validateStatus={warmingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Warming"
                  onChange={(value) => {
                    setWarming(value);
                    updateValues("warming", value);
                  }}
                  value={warming}
                  style={{ width: 240 }}
                >
                  {Warming.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={warming === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("reheat") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Reheat"
                hasFeedback
                validateStatus={reheaterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Reheat"
                  onChange={(value) => {
                    setReheat(value);
                    updateValues("reheat", value);
                  }}
                  value={reheat}
                  style={{ width: 240 }}
                >
                  {Reheat.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={reheat === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("variable_browing") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Variable Browing"
                hasFeedback
                validateStatus={variable_browingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Variable Browing"
                  onChange={(value) => {
                    setVariable_browing(value);
                    updateValues("variable_browing", value);
                  }}
                  value={variable_browing}
                  style={{ width: 240 }}
                >
                  {Variable_browning.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={variable_browing === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("defrost") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Defrost"
                hasFeedback
                validateStatus={defrosterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Defrost"
                  onChange={(value) => {
                    setDefrost(value);
                    updateValues("defrost", value);
                  }}
                  value={defrost}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={defrost === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("card_storage") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Card Storage"
                hasFeedback
                validateStatus={card_storageerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Card Storage"
                  onChange={(value) => {
                    setCard_storage(value);
                    updateValues("card_storage", value);
                  }}
                  value={card_storage}
                  style={{ width: 240 }}
                >
                  {Card_storage.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={card_storage === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cool_touch") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cool Touch"
                hasFeedback
                validateStatus={cool_toucherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Cool Touch"
                  onChange={(value) => {
                    setCool_touch(value);
                    updateValues("cool_touch", value);
                  }}
                  value={cool_touch}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cool_touch === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("length") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Length"
                hasFeedback
                validateStatus={lengtherror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Length"
                  onChange={(value) => {
                    setLength(value);
                    updateValues("length", value);
                  }}
                  value={length}
                  style={{ width: 240 }}
                >
                  {Fan_Length.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={length === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("capacity_incups") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Capacity Incups"
                hasFeedback
                validateStatus={capacity_incupserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Capacity Incups"
                  onChange={(value) => {
                    setCapacity_incups(value);
                    updateValues("capacity_incups", value);
                  }}
                  value={capacity_incups}
                  style={{ width: 240 }}
                >
                  {Capacity_in_cups.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={capacity_incups === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("total_pan_capacity") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Total Pan Capacity"
                hasFeedback
                validateStatus={total_pan_capacityerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Total Pan Capacity"
                  onChange={(value) => {
                    setTotal_pan_capacity(value);
                    updateValues("total_pan_capacity", value);
                  }}
                  value={total_pan_capacity}
                  style={{ width: 240 }}
                >
                  {Total_pan_capacity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={total_pan_capacity === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("functions") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Functions"
                hasFeedback
                validateStatus={functionserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Functions"
                  onChange={(value) => {
                    setFunctions(value);
                    updateValues("functions", value);
                  }}
                  value={functions}
                  style={{ width: 240 }}
                >
                  {Functions.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={functions === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("material_lid") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Material Lid"
                hasFeedback
                validateStatus={material_liderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Material Lid"
                  onChange={(value) => {
                    setMaterial_lid(value);
                    updateValues("material_lid", value);
                  }}
                  value={material_lid}
                  style={{ width: 240 }}
                >
                  {Material_lid.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={material_lid === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("net_quantity_product_details") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Net Quantity Product Details"
                hasFeedback
                validateStatus={
                  net_quantity_product_detailserror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Net Quantity Product Details"
                  onChange={(value) => {
                    setNet_quantity_product_details(value);
                    updateValues("net_quantity_product_details", value);
                  }}
                  value={net_quantity_product_details}
                  style={{ width: 240 }}
                >
                  {Net_quantity.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={net_quantity_product_details === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("autokeep_warm_function_cooking") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Autokeep Warm Function Cooking"
                hasFeedback
                validateStatus={
                  autokeep_warm_function_cookingerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Autokeep Warm Function Cooking"
                  onChange={(value) => {
                    setAutokeep_warm_function_cooking(value);
                    updateValues("autokeep_warm_function_cooking", value);
                  }}
                  value={autokeep_warm_function_cooking}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={autokeep_warm_function_cooking === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("used_for") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Used For"
                hasFeedback
                validateStatus={used_forerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Used For"
                  onChange={(value) => {
                    setUsed_for(value);
                    updateValues("used_for", value);
                  }}
                  value={used_for}
                  style={{ width: 240 }}
                >
                  {Used_for.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={used_for === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("generator_compatibility") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Generator Compatibility"
                hasFeedback
                validateStatus={
                  generator_compatibilityerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Generator Compatibility"
                  onChange={(value) => {
                    setGenerator_compatibility(value);
                    updateValues("generator_compatibility", value);
                  }}
                  value={generator_compatibility}
                  style={{ width: 240 }}
                >
                  {Generator_compatibility.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={generator_compatibility === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("cooling") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Cooling"
                hasFeedback
                validateStatus={coolingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Cooling"
                  onChange={(value) => {
                    setCooling(value);
                    updateValues("cooling", value);
                  }}
                  value={cooling}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={cooling === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("mount_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Mount Type"
                hasFeedback
                validateStatus={mount_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Mount Type"
                  onChange={(value) => {
                    setMount_type(value);
                    updateValues("mount_type", value);
                  }}
                  value={mount_type}
                  style={{ width: 240 }}
                >
                  {Mount_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={mount_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("minimum_operating") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum Operating"
                hasFeedback
                validateStatus={minimum_operatingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Minimum Operating"
                  onChange={(value) => {
                    setMinimum_operating(value);
                    updateValues("minimum_operating", value);
                  }}
                  value={minimum_operating}
                  style={{ width: 240 }}
                >
                  {Minimum_operating.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={minimum_operating === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("under_vlot_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Under Vlot Protection"
                hasFeedback
                validateStatus={
                  under_vlot_protectionerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Under Vlot Protection"
                  onChange={(value) => {
                    setUnder_vlot_protection(value);
                    updateValues("under_vlot_protection", value);
                  }}
                  value={under_vlot_protection}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={under_vlot_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("over_vlot_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Over Vlot Protection"
                hasFeedback
                validateStatus={over_vlot_protectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Over Vlot Protection"
                  onChange={(value) => {
                    setOver_vlot_protection(value);
                    updateValues("over_vlot_protection", value);
                  }}
                  value={over_vlot_protection}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={over_vlot_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("overload_protection") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Overload Protection"
                hasFeedback
                validateStatus={overload_protectionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Overload Protection"
                  onChange={(value) => {
                    setOverload_protection(value);
                    updateValues("overload_protection", value);
                  }}
                  value={overload_protection}
                  style={{ width: 240 }}
                >
                  {Overload_protection.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={overload_protection === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("minimum_input_power") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Minimum Input Power"
                hasFeedback
                validateStatus={minimum_input_powererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Minimum Input Power"
                  onChange={(value) => {
                    setMinimum_input_power(value);
                    updateValues("minimum_input_power", value);
                  }}
                  value={minimum_input_power}
                  style={{ width: 240 }}
                >
                  {Minimum_input_power.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={minimum_input_power === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("suitable_for") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Suitable For"
                hasFeedback
                validateStatus={suitable_forerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Suitable For"
                  onChange={(value) => {
                    setSuitable_for(value);
                    updateValues("suitable_for", value);
                  }}
                  value={suitable_for}
                  style={{ width: 240 }}
                >
                  {Suitable_for.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={suitable_for === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("rated_pressure") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Rated Pressure"
                hasFeedback
                validateStatus={rated_pressureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Rated Pressure"
                  onChange={(value) => {
                    setRated_pressure(value);
                    updateValues("rated_pressure", value);
                  }}
                  value={rated_pressure}
                  style={{ width: 240 }}
                >
                  {Rated_pressure.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={rated_pressure === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("star_rating") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Star Rating"
                hasFeedback
                validateStatus={star_ratingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select star_rating"
                  onChange={(value) => {
                    setStar_rating(value);
                    updateValues("star_rating", value);
                  }}
                  value={star_rating}
                  style={{ width: 240 }}
                >
                  {Star_rating.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={star_rating === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>

        <Flex wrap="wrap">
          {distinctfield.includes("thermostot") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Thermostot"
                hasFeedback
                validateStatus={thermostoterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Thermostot"
                  onChange={(value) => {
                    setThermostot(value);
                    updateValues("thermostot", value);
                  }}
                  value={thermostot}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={thermostot === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("planting") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Planting"
                hasFeedback
                validateStatus={plantingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Planting"
                  onChange={(value) => {
                    setPlanting(value);
                    updateValues("planting", value);
                  }}
                  value={planting}
                  style={{ width: 240 }}
                >
                  {Planting.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={planting === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("heater_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Heater Material"
                hasFeedback
                validateStatus={heater_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Heater Material"
                  onChange={(value) => {
                    setHeater_material(value);
                    updateValues("heater_material", value);
                  }}
                  value={heater_material}
                  style={{ width: 240 }}
                >
                  {Heater_material.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={heater_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("heating_element_type") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Heating Element Type"
                hasFeedback
                validateStatus={heating_element_typeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Heating Element Type"
                  onChange={(value) => {
                    setHeating_element_type(value);
                    updateValues("heating_element_type", value);
                  }}
                  value={heating_element_type}
                  style={{ width: 240 }}
                >
                  {Heater_element_type.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={heating_element_type === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("washing_method") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Washing Method"
                hasFeedback
                validateStatus={washing_methoderror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Washing Method"
                  onChange={(value) => {
                    setWashing_method(value);
                    updateValues("washing_method", value);
                  }}
                  value={washing_method}
                  style={{ width: 240 }}
                >
                  {Washing_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={washing_method === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("in_built_heater") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="In Built Heater"
                hasFeedback
                validateStatus={in_built_heatererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select In Built Heater"
                  onChange={(value) => {
                    setIn_built_heater(value);
                    updateValues("in_built_heater", value);
                  }}
                  value={in_built_heater}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={in_built_heater === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("tub_material") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Tub Material"
                hasFeedback
                validateStatus={tub_materialerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Tub Material"
                  onChange={(value) => {
                    setTub_material(value);
                    updateValues("tub_material", value);
                  }}
                  value={tub_material}
                  style={{ width: 240 }}
                >
                  {Tub_materials.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={tub_material === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("digital_display") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Digital Display"
                hasFeedback
                validateStatus={digital_displayerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Digital Display"
                  onChange={(value) => {
                    setDigital_display(value);
                    updateValues("digital_display", value);
                  }}
                  value={digital_display}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={digital_display === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("water_level_selector") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Water Level Selector"
                hasFeedback
                validateStatus={water_level_selectorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Water Level Selector"
                  onChange={(value) => {
                    setWater_level_selector(value);
                    updateValues("water_level_selector", value);
                  }}
                  value={water_level_selector}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={water_level_selector === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wash_cycle_duration") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Wash Cycle Duration"
                hasFeedback
                validateStatus={wash_cycle_durationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wash Cycle Duration"
                  onChange={(value) => {
                    setWash_cycle_duration(value);
                    updateValues("wash_cycle_duration", value);
                  }}
                  value={wash_cycle_duration}
                  style={{ width: 240 }}
                >
                  {Wash_cycle_duration.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wash_cycle_duration === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("spin_cycle_duration") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Spin Cycle Duration"
                hasFeedback
                validateStatus={spin_cycle_durationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Spin Cycle Duration"
                  onChange={(value) => {
                    setSpin_cycle_duration(value);
                    updateValues("spin_cycle_duration", value);
                  }}
                  value={spin_cycle_duration}
                  style={{ width: 240 }}
                >
                  {Spin_cycle_duration.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={spin_cycle_duration === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("inverter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Inverter"
                hasFeedback
                validateStatus={invertererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder="Select Inverter"
                  onChange={(value) => {
                    setInverter(value);
                    updateValues("inverter", value);
                  }}
                  value={inverter}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={inverter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("steam") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Steam"
                hasFeedback
                validateStatus={steamerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Steam"
                  onChange={(value) => {
                    setSteam(value);
                    updateValues("steam", value);
                  }}
                  value={steam}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={steam === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("quick_wash") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Quick Wash"
                hasFeedback
                validateStatus={quick_washerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Quick Wash"
                  onChange={(value) => {
                    setQuick_wash(value);
                    updateValues("quick_wash", value);
                  }}
                  value={quick_wash}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={quick_wash === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("lint_filter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Lint Filter"
                hasFeedback
                validateStatus={lint_filtererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Lint Filter"
                  onChange={(value) => {
                    setLint_filter(value);
                    updateValues("lint_filter", value);
                  }}
                  value={lint_filter}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={lint_filter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("wash_program_types") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Wash Program Types"
                hasFeedback
                validateStatus={wash_program_typeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Wash Program Types"
                  onChange={(value) => {
                    setWash_program_types(value);
                    updateValues("wash_program_types", value);
                  }}
                  value={wash_program_types}
                  style={{ width: 240 }}
                >
                  {Wash_program_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={wash_program_types === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("compressor") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Compressor"
                hasFeedback
                validateStatus={compressorerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Compressor"
                  onChange={(value) => {
                    setCompressor(value);
                    updateValues("compressor", value);
                  }}
                  value={compressor}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={compressor === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dehumidification") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dehumidification"
                hasFeedback
                validateStatus={dehumidificationerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dehumidification"
                  onChange={(value) => {
                    setDehumidification(value);
                    updateValues("dehumidification", value);
                  }}
                  value={dehumidification}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dehumidification === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("remote_control") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Remote Control"
                hasFeedback
                validateStatus={remote_controlerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Remote Control"
                  onChange={(value) => {
                    setRemote_control(value);
                    updateValues("remote_control", value);
                  }}
                  value={remote_control}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={remote_control === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("operating_modes") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Operating Modes"
                hasFeedback
                validateStatus={operating_modeserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Operating Modes"
                  onChange={(value) => {
                    setOperating_modes(value);
                    updateValues("operating_modes", value);
                  }}
                  value={operating_modes}
                  style={{ width: 240 }}
                >
                  {Operating_modes.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={operating_modes === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("condenser_coil") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Condenser Coil"
                hasFeedback
                validateStatus={condenser_coilerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Condenser Coil"
                  onChange={(value) => {
                    setCondenser_coil(value);
                    updateValues("condenser_coil", value);
                  }}
                  value={condenser_coil}
                  style={{ width: 240 }}
                >
                  {condenser_coil_types.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={condenser_coil === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("indoor_w_h_d") && (
            <Col className="gutter-row" span={12} >
              <span>Indoor w x h x d :</span>
              <Input
                status={indoor_w_h_derror !== "" ? "error" : ""}
                suffix={indoor_w_h_derror !== "" ? <MdOutlineErrorOutline /> : ""}
                type="text"
                placeholder="Enter Indoor w x h x d"
                value={indoor_w_h_d}
                onChange={(e) => {
                  setIndoor_w_h_d(e.target.value);
                  updateValues("indoor_w_h_d", e.target.value);
                }}
                style={{ width: 240 }}
              />
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("indoor_unit_weight") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Indoor Unit Weight"
                hasFeedback
                validateStatus={indoor_unit_weighterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Indoor Unit Weight"
                  onChange={(value) => {
                    setIndoor_unit_weight(value);
                    updateValues("indoor_unit_weight", value);
                  }}
                  value={indoor_unit_weight}
                  style={{ width: 240 }}
                >
                  {Weight.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={indoor_unit_weight === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("child_lock") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Child Lock"
                hasFeedback
                validateStatus={child_lockerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Child Lock"
                  onChange={(value) => {
                    setChild_lock(value);
                    updateValues("child_lock", value);
                  }}
                  value={child_lock}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={child_lock === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_air_swing") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Air Swing"
                hasFeedback
                validateStatus={auto_air_swingerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Auto Air Swing"
                  onChange={(value) => {
                    setAuto_air_swing(value);
                    updateValues("auto_air_swing", value);
                  }}
                  value={auto_air_swing}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_air_swing === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("air_flow_direction") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Air Flow Direction"
                hasFeedback
                validateStatus={air_flow_directionerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Air Flow Direction"
                  onChange={(value) => {
                    setAir_flow_direction(value);
                    updateValues("air_flow_direction", value);
                  }}
                  value={air_flow_direction}
                  style={{ width: 240 }}
                >
                  {Air_flow.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={air_flow_direction === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("anti_bacteria_filter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Anti Bacteria Filter"
                hasFeedback
                validateStatus={anti_bacteria_filtererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Anti Bacteria Filter"
                  onChange={(value) => {
                    setAnti_bacteria_filter(value);
                    updateValues("anti_bacteria_filter", value);
                  }}
                  value={anti_bacteria_filter}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={anti_bacteria_filter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("dust_filter") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Dust Filter"
                hasFeedback
                validateStatus={dust_filtererror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Dust Filter"
                  onChange={(value) => {
                    setDust_filter(value);
                    updateValues("dust_filter", value);
                  }}
                  value={dust_filter}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={dust_filter === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("auto_restart") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Auto Restart"
                hasFeedback
                validateStatus={auto_restarterror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Auto Restart"
                  onChange={(value) => {
                    setAuto_restart(value);
                    updateValues("auto_restart", value);
                  }}
                  value={auto_restart}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={auto_restart === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("quit_mode") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Quit Mode"
                hasFeedback
                validateStatus={quit_modeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Quit Mode"
                  onChange={(value) => {
                    setQuit_mode(value);
                    updateValues("quit_mode", value);
                  }}
                  value={quit_mode}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={quit_mode === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("sleep_mode") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Sleep Mode"
                hasFeedback
                validateStatus={sleep_modeerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Sleep Mode"
                  onChange={(value) => {
                    setSleep_mode(value);
                    updateValues("sleep_mode", value);
                  }}
                  value={sleep_mode}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={sleep_mode === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("memory_feature") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Memory Feature"
                hasFeedback
                validateStatus={memory_featureerror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Memory Feature"
                  onChange={(value) => {
                    setMemory_feature(value);
                    updateValues("memory_feature", value);
                  }}
                  value={memory_feature}
                  style={{ width: 240 }}
                >
                  {yes_r_no.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={memory_feature === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("self_diagosis") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label="Self Diagosis"
                hasFeedback
                validateStatus={self_diagosiserror !== "" ? "error" : ""}
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Self Diagosis"
                  onChange={(value) => {
                    setSelf_diagosis(value);
                    updateValues("self_diagosis", value);
                  }}
                  value={self_diagosis}
                  style={{ width: 240 }}
                >
                  {Self_diagnosis.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={self_diagosis === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
        <Flex wrap="wrap">
          {distinctfield.includes("electricity_consumption") && (
            <Col className="gutter-row" span={6}>
              <Form.Item
                label=" Electricity Consumption"
                hasFeedback
                validateStatus={
                  electricity_consumptionerror !== "" ? "error" : ""
                }
                style={{ width: 240 }}
                name="layout"
              >
                <Select
                  placeholder=" Select Electricity Consumption"
                  onChange={(value) => {
                    setElectricity_consumption(value);
                    updateValues("electricity_consumption", value);
                  }}
                  value={electricity_consumption}
                  style={{ width: 240 }}
                >
                  {Electricity_consumption.map((data, index) => (
                    <Option
                      key={index}
                      value={data.label}
                      name={data.label}
                      selected={electricity_consumption === data.label}
                    >
                      {data.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Flex>
      </Row>
    </>
  );
};

export default ElectronicsSupercat;

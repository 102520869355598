import React, { useState } from "react";
import { Fragment } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { MdOutlineRemove } from "react-icons/md";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { CiEdit } from "react-icons/ci";
import { FaInfoCircle } from "react-icons/fa";
import { useToasts } from "react-toast-notifications";
import { Tooltip as ReactTooltip } from "react-tooltip";
import colorNamer from "color-namer";
import {
  PostField,
  getOneFieldStatus,
} from "../../../redux/fieldStatus/FieldStatusSlice";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const AddFieldsStatus = () => {
  const { superCatShow } = useSelector((store) => store.superCategory);
  const { categoryShow } = useSelector((store) => store.category);
  const { subcategoryShow } = useSelector((store) => store.subCategories);
  const { brandShow } = useSelector((store) => store.brand);
  const { fieldShow } = useSelector((store) => store.field);
  const { categoryTagShow, categoryTagTotal } = useSelector(
    (store) => store.categorytag
  );

  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [subcategory, setSubcategory] = useState("");
  const [subcategoryid, setSubcategoryid] = useState("");
  const [category, setCategory] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [catbysuper, setCatbysuper] = useState([]);
  const [supercategory, setSupercategory] = useState("");
  const [supercategoryid, setSupercategoryid] = useState("");
  const [subCat, setSubcat] = useState([]);
  const [brand, setBrand] = useState("");
  const [categoryTagsShow, setCategoryTagsShow] = useState([]);
  const [categoryTag, setCategoryTag] = useState("");
  const [categoryTagid, setCategoryTagid] = useState("");
  const [fieldBySupcatShow, setfieldBySupcatShow] = useState([]);
  const [selectedfield, setselectedfield] = useState([]);
  const [distinctfield, setdistinctfield] = useState([]);

  const [buttonPress, setbuttonPress] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [supcategroyError, setsupcategroyError] = useState("");
  const [categroyError, setcategroyError] = useState("");
  const [subcategroyError, setSubcategroyError] = useState("");
  const [categoryTagError, setcategoryTagError] = useState("");
  const [fieldError, setfieldError] = useState("");

  useEffect(() => {
    if (fieldShow.length > 0) {
      const distinctNames = [...new Set(fieldShow.map((item) => item.name))];
      setdistinctfield(distinctNames);
      // const notMatched = array1.filter(item => !array2.includes(item));
    }
  }, [fieldShow]);

  useEffect(() => {
    if (categoryTagid) {
      const getdata = async () => {
        const formData = {
          superCategoryId: supercategoryid,
          categoryId: categoryid,
          SubCategoryId: subcategoryid,
          CategoryTagId: categoryTagid,
        };

        const data = await dispatch(getOneFieldStatus(formData));
        if (data.payload.success === true) {
          const resp = data.payload.field;
          const filterShow = resp.selectedFields.filter(
            (data) => data.status === true
          );
          const distinctNames = [
            ...new Set(filterShow.map((item) => item.field_Name)),
          ];
          setselectedfield(distinctNames);
        } else {
          setselectedfield([]);
        }
      };
      getdata();
    }
  }, [subcategoryid, categoryid, supercategoryid, categoryTagid]);

  useEffect(() => {
    if (superCatShow.length === 1) {
      setSupercategory(superCatShow[0].name);
      setSupercategoryid(superCatShow[0]._id);
    }
    if (categoryShow.length === 1) {
      setCategory(categoryShow[0].name);
      setCategoryid(categoryShow[0]._id);
    }
    if (subcategoryShow.length === 1) {
      setSubcategory(subcategoryShow[0].name);
      setSubcategoryid(subcategoryShow[0]._id);
    }
  }, [superCatShow, categoryShow, subcategoryShow]);

  const handleFielSelectPress = (evt) => {
    if (selectedfield.includes(evt.target.value)) {
      setselectedfield(
        selectedfield.filter((e) => {
          return e !== evt.target.value;
        })
      );
    } else {
      setselectedfield((selectedfield) => [...selectedfield, evt.target.value]);
    }
  };
  
  const handleFielSelectHandlePress = (e, evt) => {
    if (selectedfield.includes(evt)) {
      setselectedfield(
        selectedfield.filter((e) => {
          return e !== evt;
        })
      );
    } else {
      setselectedfield((selectedfield) => [...selectedfield, evt]);
    }
  };

  useEffect(() => {
    if (buttonPress === true) {
      if (supercategoryid === "") {
        setsupcategroyError("Required");
      } else {
        setsupcategroyError("");
      }
      if (categoryid === "") {
        setcategroyError("Required");
      } else {
        setcategroyError("");
      }
      if (subcategoryid === "") {
        setSubcategroyError("Required");
      } else {
        setSubcategroyError("");
      }
      if (categoryTagid === "") {
        setcategoryTagError("Required");
      } else {
        setcategoryTagError("");
      }
      if (selectedfield.length === 0) {
        setfieldError("Plesae select at least one field");
      } else {
        setfieldError("");
      }
    }
  }, [
    buttonPress,
    subcategoryid,
    categoryid,
    supercategoryid,
    categoryTagid,
    selectedfield,
  ]);

  

  // useEffect(() => {
  //   if (selectedfield.length > 0) {
  //     let fieldarray = [];
  //     let finalfieldarray = [];
  //     for (let i = 0; i < selectedfield.length; i++) {
  //       let element = selectedfield[i];

  //       let obje = { [element]: "", field_Name: element, status: true };
  //       fieldarray = [...fieldarray, obje];
  //     }
  //     fieldarray = fieldarray;
  //     let unselectedData = [];
  //     const notMatched = distinctfield.filter(
  //       (item) => !selectedfield.includes(item)
  //     );
  //     for (let j = 0; j < notMatched.length; j++) {
  //       let element = notMatched[j];
  //       let obje = { [element]: "", field_Name: element, status: false };
  //       unselectedData = [...unselectedData, obje];
  //     }
  //     unselectedData = unselectedData;

  //     finalfieldarray = [...fieldarray, ...unselectedData];
  //   }
  // }, [selectedfield, distinctfield]);

  const selectsupCategory = (evt) => {
    const { value } = evt.target;
    setSupercategoryid(value);
    const textValue = superCatShow.find(
      (indSub) => indSub._id === evt.target.value
    );
    if (textValue) {
      setSupercategory(textValue.name);
    }
  };

  const selectCategory = (evt) => {
    const { value } = evt.target;
    const textValue = catbysuper.find((indCat) => indCat._id === value);
    setCategoryid(value);
    if (textValue) {
      setCategory(textValue.name);
    }
  };

  useEffect(() => {
    const catbysupercat = categoryShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setCatbysuper(catbysupercat);
    const fildbysupercat = fieldShow.filter(
      (cat) => cat.superCategoryId === supercategoryid
    );
    setfieldBySupcatShow(fildbysupercat);
  }, [supercategoryid, categoryShow, fieldShow]);

  const selectSubCategory = (evt) => {
    const { value } = evt.target;
    setSubcategoryid(value);
    const textValue = subCat.find((indSubCat) => indSubCat._id === value);
    if (textValue) {
      setSubcategory(textValue.name);
    }
  };
  useEffect(() => {
    const subcatbycat = subcategoryShow.filter(
      (subcat) => subcat.categoryId === categoryid
    );
    setSubcat(subcatbycat);
  }, [categoryid, subcategoryShow]);

  const selectCategoryTag = (evt) => {
    const { value } = evt.target;
    const textValue = categoryTagsShow.find((indTag) => indTag._id === value);
    setCategoryTagid(value);
    if (textValue) {
      setCategoryTag(textValue.name);
    }
  };

  useEffect(() => {
    const catTagbySubcat = categoryTagShow.filter(
      (subcat) => subcat.SubCategoryId === subcategoryid
    );
    setCategoryTagsShow(catTagbySubcat);
  }, [subcategoryid, categoryTagShow]);

  const handlesubmit = async (e) => {
    e.preventDefault();
    setbuttonPress(true);
    setbuttonLoading(true);
    if (supercategoryid === "") {
      setsupcategroyError("Required");
    } else {
      setsupcategroyError("");
    }
    if (categoryid === "") {
      setcategroyError("Required");
    } else {
      setcategroyError("");
    }
    if (subcategoryid === "") {
      setSubcategroyError("Required");
    } else {
      setSubcategroyError("");
    }
    if (categoryTagid === "") {
      setcategoryTagError("Required");
    } else {
      setcategoryTagError("");
    }

    if (selectedfield.length === 0) {
      setfieldError("Plesae select at least one field");
    } else {
      setfieldError("");
    }

    if (
      supercategoryid !== "" &&
      categoryid !== "" &&
      subcategoryid !== "" &&
      categoryTagid !== "" &&
      selectedfield.length > 0
    ) {
      let fieldarray = [];
      let finalfieldarray = [];
      for (let i = 0; i < selectedfield.length; i++) {
        let element = selectedfield[i];

        let obje = { [element]: "", field_Name: element, status: true };
        fieldarray = [...fieldarray, obje];
      }
      fieldarray = fieldarray;
      let unselectedData = [];
      const notMatched = distinctfield.filter(
        (item) => !selectedfield.includes(item)
      );
      for (let j = 0; j < notMatched.length; j++) {
        let element = notMatched[j];
        let obje = { [element]: "", field_Name: element, status: false };
        unselectedData = [...unselectedData, obje];
      }
      unselectedData = unselectedData;

      finalfieldarray = [...fieldarray, ...unselectedData];

      const formData = {
        superCategoryId: supercategoryid,
        superCategory: supercategory,
        category: category,
        categoryId: categoryid,
        SubCategory: subcategory,
        SubCategoryId: subcategoryid,
        CategoryTag: categoryTag,
        CategoryTagId: categoryTagid,
        selectedFields: finalfieldarray,
      };
      const data = await dispatch(PostField(formData));
      if (data.payload.success) {
        alert("Field Status Added");
        // addToast("Fiel Status Added", {
        //   appearance: "success",
        //   autoDismiss: true,
        //   autoDismissTimeout: 2000,
        //   placement: "bottom-right",
        //   style: { zIndex: 99999 },
        // });
        setbuttonPress(false);
        setbuttonLoading(false);
        setselectedfield([]);

        if (superCatShow.length > 1) {
          setSupercategory("");
          setSupercategoryid("");
        }
        if (categoryShow.length > 1) {
          setCategory("");
          setCategoryid("");
        }
        if (subcategoryShow.length > 1) {
          setSubcategory("");
          setSubcategoryid("");
        }
        if (categoryTagShow.length > 1) {
          setCategoryTag("");
          setCategoryTagid("");
        }
      } else {
        setbuttonLoading(false);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      setbuttonLoading(false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Fragment>
        <div className="content-wrapper">
          <div className="employeesContainer">
            <form onSubmit={handlesubmit}>
              <div className="card m-4">
                <div className="card-footer">
                  <h5>Add Field Status </h5>
                </div>
                <div className="card-body">
                  <Row className="g-2  m-2">
                    {superCatShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                            htmlFor="fName"
                            className="text-sm font-medium text-default-900"
                            style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                          >
                            Super Category<span style={{ color: "red" }}>*</span>
                          </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              {" "}
                              Select Super Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Super Category"
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              onChange={(e) => {
                                selectsupCategory(e);
                              }}
                              // value={superCatShow._id}
                              value={supercategoryid}
                              name={superCatShow.name}
                              className={
                                supcategroyError !== "" ? "error_class" : ""
                              }
                            >
                              {superCatShow.map((test, index) => (
                                <MenuItem
                                  key={test._id}
                                  value={test._id}
                                  name={test.name}
                                  selected={supercategoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}

                    {categoryShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                            >
                              Category<span style={{ color: "red" }}>*</span>
                            </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Category"
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              onChange={(e) => {
                                selectCategory(e);
                              }}
                              // value={catbysuper._id}
                              name={catbysuper.name}
                              value={categoryid}
                              className={
                                categroyError !== "" ? "error_class" : ""
                              }
                            >
                              {catbysuper.map((test, index) => (
                                <MenuItem
                                  key={test._id}
                                  value={test._id}
                                  name={test.name}
                                  selected={categoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                    {subcategoryShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                            >
                              Sub Category<span style={{ color: "red" }}>*</span>
                            </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Sub Category
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Sub Category"
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              onChange={(e) => {
                                selectSubCategory(e);
                              }}
                              // value={subCat._id}
                              value={subcategoryid}
                              name={subCat.name}
                              className={
                                subcategroyError !== "" ? "error_class" : ""
                              }
                            >
                              {subCat.map((test, index) => (
                                <MenuItem
                                  key={test._id}
                                  value={test._id}
                                  name={test.name}
                                  selected={subcategoryid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                    {categoryTagShow.length > 1 && (
                      <Col md>
                        <div className="space-y-1">
                          {/* <label
                              htmlFor="fName"
                              className="text-sm font-medium text-default-900"
                              style={{ fontWeight: '700', color: 'rgb(0, 0, 102)', fontSize: '22px' }}
                            >
                              Tag<span style={{ color: "red" }}>*</span>
                            </label> */}
                          <FormControl fullWidth className="textfield">
                            <InputLabel
                              id="demo-simple-Case-Type"
                              className="inputcenter"
                            >
                              Select Sub Category Tag
                            </InputLabel>
                            <Select
                              labelId="demo-simple-Case-Type"
                              id="demo-simple-select"
                              label="Select Sub Category"
                              style={{ height: "38px" }}
                              inputProps={{ style: { textAlign: "center" } }}
                              onChange={(e) => {
                                selectCategoryTag(e);
                              }}
                              value={categoryTagid}
                              // value={categoryTagsShow._id}
                              name={categoryTagsShow.name}
                              className={
                                categoryTagError !== "" ? "error_class" : ""
                              }
                            >
                              {categoryTagsShow.map((test, index) => (
                                <MenuItem
                                  key={test._id}
                                  value={test._id}
                                  name={test.name}
                                  selected={categoryTagid === test._id}
                                >
                                  {test.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row className="g-2  m-2">
                    <p style={{ color: "red" }}> {fieldError}</p>
                  </Row>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      marginTop: "0.2vh",
                    }}
                  >
                    {fieldBySupcatShow.map((data, index) => (
                      <div
                        className="me-4 d-flex"
                        style={{ width: "22%" }}
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="me-1"
                          value={data.name}
                          checked={selectedfield.includes(data.name)}
                          onChange={(e) => handleFielSelectPress(e, data.name)}
                          style={{ cursor: "pointer" }}
                        />
                        <Form.Label
                          className="mt-2 ml-2"
                          onClick={(e) =>
                            handleFielSelectHandlePress(e, data.name)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {index + 1}) {data.name}
                        </Form.Label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="card-footer">
                  {buttonLoading ? (
                    <button
                      className="btn btn-danger"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Field Status adding...
                    </button>
                  ) : (
                    <button
                      className="btn btn-danger"
                      type="submit"
                      style={{ background: "#000066", borderColor: "#000066" }}
                    >
                      Add Field Status
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AddFieldsStatus;
